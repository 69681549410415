<template>
  <div>
    <label class="col-form-label-lg">{{ $t("user.friend.list") }}</label>
    <table class="table">
      <thead>
        <tr>
          <th scope="col">{{ $t("user.friend.no") }}</th>
          <th scope="col">{{ $t("user.friend.member_id") }}</th>
          <th scope="col">{{ $t("user.friend.fullname") }}</th>
          <th scope="col">{{ $t("user.friend.email") }}</th>
          <th scope="col">{{ $t("user.friend.status") }}</th>
          <th scope="col">{{ $t("user.friend.created_at") }}</th>
        </tr>
      </thead>
      <tbody>
        <template v-if="friendReferralHistories.length > 0">
          <tr v-for="(friendReferralHistory, index) in friendReferralHistories" :key="index">
            <td>{{ index + 1 }}</td>
            <td>
              <a href="javascript:;" @click="redirectDetail(friendReferralHistory.id)">
                {{ friendReferralHistory.member_id }}
              </a>
            </td>
            <td>{{ friendReferralHistory.fullname }}</td>
            <td>{{ friendReferralHistory.email }}</td>
            <td>{{ friendReferralHistory.status }}</td>
            <td>{{ formatDatetime(friendReferralHistory.created_at) }}</td>
          </tr>
        </template>
        <template v-else>
          <tr class="text-center">
            <td colspan="6">{{ $t("user.friend.not_match_friend") }}</td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>
<script>
import moment from "moment";

export default {
  computed: {
    friendReferralHistories() {
      return this.$store.state.user.detail.friendReferralHistories;
    }
  },
  methods: {
    formatDatetime(datetime) {
      if (datetime) {
        return moment.utc(datetime).format('DD/MM/YYYY HH:mm:ss');
      }
      return '';
    },
    redirectDetail(id) {
      window.location.href = `/users/${id}/detail`;
    }
  },
};
</script>

<style lang="scss" scoped>
.table thead th {
  position: relative;
}
</style>
