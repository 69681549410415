var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "auth-layout",
    [
      _c(
        "main-header",
        { attrs: { title: _vm.$t("page.sliders.list") } },
        [
          _c(
            "router-link",
            {
              staticClass: "btn btn-success",
              attrs: { to: { name: "SlidersAdd" } }
            },
            [_vm._v(_vm._s(_vm.$t("page.sliders.create")))]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("sliders-navigation")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }