<template>
  <b-form-group :invalid-feedback="invalidFeedback" :state="stateValid">
    <label>
      {{ $t("userRanking.day_count") }}
    </label>
    <b-form-input
      v-model="userRankingDayCount"
      :state="stateValid"
      type="number"
      required
      trim
    ></b-form-input>
  </b-form-group>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {};
  },
  computed: {
    userRankingData() {
      return this.$store.getters["userRanking/userRankingData"];
    },
    userRankingDayCount: {
      get() {
        return this.userRankingData && this.userRankingData.day_count !== null
          ? Math.round(Number((this.userRankingData.day_count) / 30) * 10) / 10
          : null;
      },
      set(value) {
        this.changeAttributesData({
          key: "day_count",
          value: Number(value) * 30
        });
      }
    },
    stateValid() {
      if (!this.userRankingData || this.userRankingData.day_count === null)
        return null;
      if (this.userRankingDayCount < 0) return false;
      return true;
    },
    invalidFeedback() {
      if (this.userRankingDayCount && this.userRankingDayCount < 0) {
        return this.$t("userRanking.valueMin").replace(":min", "0");
      }
      return (
        this.$t("subText.required") +
        " " +
        this.$t("userRanking.day_count").toLowerCase()
      );
    }
  },
  methods: {
    ...mapActions("userRanking", ["changeAttributesData"])
  }
};
</script>

<style lang="scss" scoped></style>
