<template>
  <div>
    <b-form-group
      :invalid-feedback="invalidFeedback"
      :state="stateValid"
    >
      <label>
        {{ $t("userRanking.ranking_name") }}
        <span class="text-danger ml-1 small">{{ $t("subText.required") }}</span>
      </label>
      <b-form-input
        v-model="rankingName"
        :state="stateValid"
        required
        trim
      ></b-form-input>
    </b-form-group>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {};
  },
  computed: {
    userRankingData() {
      return this.$store.getters["userRanking/userRankingData"];
    },
    rankingName: {
      get() {
        return this.userRankingData ? this.userRankingData.ranking_name : "";
      },
      set(value) {
        this.changeAttributesData({
          key: "ranking_name",
          value: value
        });
      }
    },
    stateValid() {
      if (!this.userRankingData || this.userRankingData.ranking_name === null) return null;
      if (
        this.userRankingData.ranking_name === "" ||
        (this.userRankingData.ranking_name && this.userRankingData.ranking_name.length > 100)
      )
        return false;
      return true;
    },
    invalidFeedback() {
      if (this.rankingName && this.rankingName.length > 100) {
        return this.$t("userRanking.maxLength").replace(":length", "100");
      }
      return (
        this.$t("subText.required") +
        " " +
        this.$t("userRanking.ranking_name").toLowerCase()
      );
    }
  },
  methods: {
    ...mapActions("userRanking", ["changeAttributesData"])
  }
};
</script>
