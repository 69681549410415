require("./bootstrap");

import Vue from "vue";
import router from "./router";
import App from "./App.vue";
import VueRouter from "vue-router";
import VueWait from "vue-wait";
import store from "./store";
import { BootstrapVue, BVToastPlugin, BootstrapVueIcons } from "bootstrap-vue";

import VueI18n from "vue-i18n";
import VueAxios from "vue-axios";
import axios from "./axios";
import "./validate";
import "./filter";
import vi from "./lang/vi";
import en from "./lang/en";
import { DEFAULT_LOCALE } from "./constants/common";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import "./ckeditor5-31.0.0/build/ckeditor";

// components
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import AuthenticatedLayout from "./layouts/Authenticated.vue";
import Editor from '@tinymce/tinymce-vue';
import Pagination from "./components/common/Pagination.vue";
import ConfirmModal from "./components/common/ConfirmModal.vue";
import PageHeader from "./components/common/PageHeader.vue";

Vue.use(VueI18n);
Vue.use(VueAxios, axios);
Vue.use(BootstrapVue);
Vue.use(BVToastPlugin);
Vue.use(BootstrapVueIcons);
Vue.use(VueWait);
Vue.router = router;
App.router = Vue.router;
Vue.use(VueRouter);
Vue.use(CKEditor);

// Register components
Vue.component("auth-layout", AuthenticatedLayout);
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("clip-loader", ClipLoader);
Vue.component('editor', Editor);
Vue.component('pagination-bar', Pagination);
Vue.component('confirm-modal', ConfirmModal);
Vue.component('main-header', PageHeader);

router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.metaTags);
  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) document.title = nearestWithTitle.meta.title;

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(
    document.querySelectorAll("[data-vue-router-controlled]")
  ).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map(tagDef => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach(key => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create, so we don't interfere with other ones.
      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach(tag => document.head.appendChild(tag));

  next();
});

var wait = new VueWait();

axios.interceptors.request.use(request => {
  if (!request.headers.silent) {
    wait.start("loading");
  }

  return request;
});

// Add a response interceptor
axios.interceptors.response.use(
  response => {
    wait.end("loading");
    // Do something with response data
    return response;
  },
  error => {
    if (error.response.data.expired) {
      store.dispatch("auth/logout");
    }
    wait.end("loading");
    // Do something with response error
    return Promise.reject(error);
  }
);

const i18n = new VueI18n({
  locale: DEFAULT_LOCALE,
  messages: {
    en, vi
  }
});

const app = new Vue({
  router,
  store,
  wait,
  i18n,
  render: h => h(App)
}).$mount("#app");

window.app = app;
