<template>
  <div>
    <sliders-name-block></sliders-name-block>
    <sliders-image-block></sliders-image-block>
    <sliders-url-block></sliders-url-block>
    <sliders-description-block></sliders-description-block>
    <sliders-date-block></sliders-date-block>
    <sliders-order-block></sliders-order-block>
  </div>
</template>
<script>
import SlidersNameBlock from './SlidersNameBlock';
import SlidersImageBlock from './SlidersImageBlock';
import SlidersUrlBlock from './SlidersUrlBlock';
import SlidersDescriptionBlock from './SlidersDescriptionBlock';
import SlidersDateBlock from './SlidersDateBlock';
import SlidersOrderBlock from './SlidersOrderBlock';

export default {
  components: {
    SlidersNameBlock,
    SlidersImageBlock,
    SlidersUrlBlock,
    SlidersDescriptionBlock,
    SlidersDateBlock,
    SlidersOrderBlock,
  }
};
</script>
<style lang="scss">
</style>
