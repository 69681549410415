var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ValidationObserver", {
    ref: "observer",
    attrs: { tag: "form", mode: "passive" },
    on: {
      submit: function($event) {
        $event.preventDefault()
        return _vm.submit.apply(null, arguments)
      }
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var invalid = ref.invalid
          var errors = ref.errors
          return [
            invalid || _vm.errMessage
              ? _c(
                  "div",
                  {
                    ref: "errorDiv",
                    staticClass:
                      "text-danger d-flex justify-content-center mt-1"
                  },
                  [
                    _c(
                      "ul",
                      [
                        _vm._l(errors, function(error) {
                          return [
                            error[0]
                              ? _c("li", [_vm._v(_vm._s(error[0]))])
                              : _vm._e()
                          ]
                        }),
                        _vm._v(" "),
                        _vm.errMessage
                          ? _c("li", [_vm._v(_vm._s(_vm.errMessage))])
                          : _vm._e()
                      ],
                      2
                    )
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "b-form-group",
              [
                _c("label", { attrs: { for: "" } }, [
                  _vm._v(_vm._s(_vm.$t("user.fullname"))),
                  _c("span", { staticClass: "text-danger ml-1 small" }, [
                    _vm._v(_vm._s(_vm.$t("subText.required")))
                  ])
                ]),
                _vm._v(" "),
                _c("ValidationProvider", {
                  attrs: {
                    mode: "passive",
                    name: _vm.$t("user.fullname"),
                    rules: "required|max:100"
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(ref) {
                          var errors = ref.errors
                          return [
                            _c("b-form-input", {
                              class: {
                                "border border-danger": errors[0]
                              },
                              attrs: { type: "text" },
                              model: {
                                value: _vm.form.fullname,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "fullname", $$v)
                                },
                                expression: "form.fullname"
                              }
                            })
                          ]
                        }
                      }
                    ],
                    null,
                    true
                  )
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-form-row",
              [
                _c(
                  "b-col",
                  [
                    _c(
                      "b-form-group",
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v(_vm._s(_vm.$t("user.email"))),
                          _c(
                            "span",
                            { staticClass: "text-danger ml-1 small" },
                            [_vm._v(_vm._s(_vm.$t("subText.required")))]
                          )
                        ]),
                        _vm._v(" "),
                        _c("ValidationProvider", {
                          attrs: {
                            mode: "passive",
                            name: _vm.$t("user.email"),
                            rules: "required|max:100|email"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("b-form-input", {
                                      class: {
                                        "border border-danger": errors[0]
                                      },
                                      attrs: {
                                        type: "email",
                                        name: "user_email"
                                      },
                                      model: {
                                        value: _vm.form.email,
                                        callback: function($$v) {
                                          _vm.$set(_vm.form, "email", $$v)
                                        },
                                        expression: "form.email"
                                      }
                                    })
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-col",
                  [
                    _c(
                      "b-form-group",
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v(_vm._s(_vm.$t("auth.password"))),
                          _c(
                            "span",
                            { staticClass: "text-danger ml-1 small" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.isEdit
                                    ? _vm.$t("subText.unchangePwd")
                                    : _vm.$t("subText.required")
                                )
                              )
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("ValidationProvider", {
                          attrs: {
                            mode: "passive",
                            name: _vm.$t("auth.password"),
                            rules: {
                              required: !_vm.isEdit,
                              max: 100,
                              alpha_dash: true
                            }
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("b-form-input", {
                                      class: {
                                        "border border-danger": errors[0]
                                      },
                                      attrs: {
                                        type: "password",
                                        autocomplete: "new-password",
                                        name: "user_pwd"
                                      },
                                      model: {
                                        value: _vm.form.password,
                                        callback: function($$v) {
                                          _vm.$set(_vm.form, "password", $$v)
                                        },
                                        expression: "form.password"
                                      }
                                    })
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c(
              "b-form-row",
              [
                _c(
                  "b-col",
                  [
                    _c(
                      "b-form-group",
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v(_vm._s(_vm.$t("user.tel")))
                        ]),
                        _vm._v(" "),
                        _c("ValidationProvider", {
                          attrs: {
                            mode: "passive",
                            name: _vm.$t("user.tel"),
                            rules: "max:20"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("b-form-input", {
                                      class: {
                                        "border border-danger": errors[0]
                                      },
                                      attrs: { type: "tel" },
                                      model: {
                                        value: _vm.form.tel,
                                        callback: function($$v) {
                                          _vm.$set(_vm.form, "tel", $$v)
                                        },
                                        expression: "form.tel"
                                      }
                                    })
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-col",
                  [
                    _c(
                      "b-form-group",
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v(_vm._s(_vm.$t("user.birthday")))
                        ]),
                        _vm._v(" "),
                        _c("v-date-picker", {
                          attrs: {
                            mode: "date",
                            locale: _vm.currentLocale,
                            "model-config": _vm.dateConfig
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var inputValue = ref.inputValue
                                  var inputEvents = ref.inputEvents
                                  return [
                                    _c(
                                      "input",
                                      _vm._g(
                                        {
                                          staticClass: "form-control",
                                          attrs: { placeholder: "dd/mm/yyyy" },
                                          domProps: { value: inputValue }
                                        },
                                        inputEvents
                                      )
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          ),
                          model: {
                            value: _vm.form.birthday,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "birthday", $$v)
                            },
                            expression: "form.birthday"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-col",
                  [
                    _c(
                      "b-form-group",
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v(_vm._s(_vm.$t("user.gender")))
                        ]),
                        _vm._v(" "),
                        _c("multi-select", {
                          attrs: {
                            options: _vm.genderOptions,
                            label: "display_name",
                            "track-by": "id",
                            selectedLabel: _vm.$t("multiselect.selectedLabel"),
                            selectLabel: _vm.$t("multiselect.selectLabel"),
                            placeholder: "",
                            deselectLabel: _vm.$t("multiselect.deselectLabel")
                          },
                          model: {
                            value: _vm.form.gender,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "gender", $$v)
                            },
                            expression: "form.gender"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-form-row",
              [
                _c(
                  "b-col",
                  [
                    _c(
                      "b-form-group",
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v(_vm._s(_vm.$t("user.city")))
                        ]),
                        _vm._v(" "),
                        _c("multi-select", {
                          attrs: {
                            options: _vm.cityOptions,
                            label: "name",
                            "track-by": "id",
                            selectedLabel: _vm.$t("multiselect.selectedLabel"),
                            selectLabel: _vm.$t("multiselect.selectLabel"),
                            placeholder: "",
                            deselectLabel: _vm.$t("multiselect.deselectLabel")
                          },
                          model: {
                            value: _vm.form.city_id,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "city_id", $$v)
                            },
                            expression: "form.city_id"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-col",
                  [
                    _c(
                      "b-form-group",
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v(_vm._s(_vm.$t("user.district")))
                        ]),
                        _vm._v(" "),
                        _c("multi-select", {
                          attrs: {
                            options: _vm.currentDistricts,
                            label: "name",
                            "track-by": "id",
                            selectedLabel: _vm.$t("multiselect.selectedLabel"),
                            selectLabel: _vm.$t("multiselect.selectLabel"),
                            placeholder: "",
                            deselectLabel: _vm.$t("multiselect.deselectLabel")
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "noOptions",
                                fn: function() {
                                  return [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("multiselect.districtNoOption")
                                      )
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            true
                          ),
                          model: {
                            value: _vm.form.district_id,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "district_id", $$v)
                            },
                            expression: "form.district_id"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-col",
                  [
                    _c(
                      "b-form-group",
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v(_vm._s(_vm.$t("user.ward")))
                        ]),
                        _vm._v(" "),
                        _c("multi-select", {
                          attrs: {
                            options: _vm.currentWards,
                            label: "name",
                            "track-by": "id",
                            selectedLabel: _vm.$t("multiselect.selectedLabel"),
                            selectLabel: _vm.$t("multiselect.selectLabel"),
                            placeholder: "",
                            deselectLabel: _vm.$t("multiselect.deselectLabel")
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "noOptions",
                                fn: function() {
                                  return [
                                    _vm._v(
                                      _vm._s(_vm.$t("multiselect.wardNoOption"))
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            true
                          ),
                          model: {
                            value: _vm.form.ward_id,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "ward_id", $$v)
                            },
                            expression: "form.ward_id"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-form-row",
              [
                _c(
                  "b-col",
                  { attrs: { md: "8" } },
                  [
                    _c(
                      "b-form-group",
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v(_vm._s(_vm.$t("user.address")))
                        ]),
                        _vm._v(" "),
                        _c("ValidationProvider", {
                          attrs: {
                            mode: "passive",
                            name: _vm.$t("user.address"),
                            rules: "max:256"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("b-form-input", {
                                      class: {
                                        "border border-danger": errors[0]
                                      },
                                      attrs: { type: "text", maxlength: "256" },
                                      model: {
                                        value: _vm.form.address,
                                        callback: function($$v) {
                                          _vm.$set(_vm.form, "address", $$v)
                                        },
                                        expression: "form.address"
                                      }
                                    })
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-col",
                  [
                    _c(
                      "b-form-group",
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v(_vm._s(_vm.$t("user.rank")))
                        ]),
                        _vm._v(" "),
                        _c("multi-select", {
                          attrs: {
                            options: _vm.ranks,
                            label: "ranking_name",
                            "track-by": "id",
                            selectedLabel: _vm.$t("multiselect.selectedLabel"),
                            selectLabel: _vm.$t("multiselect.selectLabel"),
                            placeholder: "",
                            deselectLabel: _vm.$t("multiselect.deselectLabel")
                          },
                          model: {
                            value: _vm.form.rank_id,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "rank_id", $$v)
                            },
                            expression: "form.rank_id"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-form-row",
              [
                _c(
                  "b-col",
                  { attrs: { md: "4" } },
                  [
                    _c("label", { attrs: { for: "" } }, [
                      _vm._v(_vm._s(_vm.$t("user.avatar")))
                    ]),
                    _vm._v(" "),
                    _c("image-input", {
                      attrs: { data: _vm.form.avatar, "can-remove": false },
                      on: { "image-input": _vm.avatarInput }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-col",
                  {
                    staticClass: "d-flex flex-column justify-content-between",
                    attrs: { md: "8" }
                  },
                  [
                    _c(
                      "b-form-row",
                      [
                        _c(
                          "b-col",
                          [
                            _c(
                              "b-form-group",
                              [
                                _c("label", { attrs: { for: "" } }, [
                                  _vm._v(_vm._s(_vm.$t("user.maritalStatus")))
                                ]),
                                _vm._v(" "),
                                _c("multi-select", {
                                  attrs: {
                                    options: _vm.maritalOptions,
                                    label: "display_name",
                                    "track-by": "id",
                                    selectedLabel: _vm.$t(
                                      "multiselect.selectedLabel"
                                    ),
                                    selectLabel: _vm.$t(
                                      "multiselect.selectLabel"
                                    ),
                                    placeholder: "",
                                    deselectLabel: _vm.$t(
                                      "multiselect.deselectLabel"
                                    )
                                  },
                                  model: {
                                    value: _vm.form.marital_status,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "marital_status", $$v)
                                    },
                                    expression: "form.marital_status"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-col",
                          [
                            _c(
                              "b-form-group",
                              [
                                _c("label", { attrs: { for: "" } }, [
                                  _vm._v(_vm._s(_vm.$t("user.boyCount")))
                                ]),
                                _vm._v(" "),
                                _c("ValidationProvider", {
                                  attrs: {
                                    mode: "passive",
                                    name: _vm.$t("user.boyCount"),
                                    rules: "numeric"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var errors = ref.errors
                                          return [
                                            _c("b-form-input", {
                                              class: {
                                                "border border-danger":
                                                  errors[0]
                                              },
                                              attrs: { type: "number" },
                                              model: {
                                                value: _vm.form.boy_count,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "boy_count",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.boy_count"
                                              }
                                            })
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-col",
                          [
                            _c(
                              "b-form-group",
                              [
                                _c("label", { attrs: { for: "" } }, [
                                  _vm._v(_vm._s(_vm.$t("user.girlCount")))
                                ]),
                                _vm._v(" "),
                                _c("ValidationProvider", {
                                  attrs: {
                                    mode: "passive",
                                    name: _vm.$t("user.girlCount"),
                                    rules: "numeric"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var errors = ref.errors
                                          return [
                                            _c("b-form-input", {
                                              class: {
                                                "border border-danger":
                                                  errors[0]
                                              },
                                              attrs: { type: "number" },
                                              model: {
                                                value: _vm.form.girl_count,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "girl_count",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.girl_count"
                                              }
                                            })
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "b-form-row",
                      [
                        _c(
                          "b-col",
                          [
                            _c(
                              "b-form-group",
                              [
                                _c("label", { attrs: { for: "" } }, [
                                  _vm._v(_vm._s(_vm.$t("user.skinType")))
                                ]),
                                _vm._v(" "),
                                _c("multi-select", {
                                  attrs: {
                                    options: _vm.userSkinTypes,
                                    label: "display_name",
                                    "track-by": "id",
                                    selectedLabel: _vm.$t(
                                      "multiselect.selectedLabel"
                                    ),
                                    selectLabel: _vm.$t(
                                      "multiselect.selectLabel"
                                    ),
                                    placeholder: "",
                                    deselectLabel: _vm.$t(
                                      "multiselect.deselectLabel"
                                    )
                                  },
                                  model: {
                                    value: _vm.form.skin_type,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "skin_type", $$v)
                                    },
                                    expression: "form.skin_type"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-col",
                          [
                            _c(
                              "b-form-group",
                              [
                                _c("label", { attrs: { for: "" } }, [
                                  _vm._v(_vm._s(_vm.$t("user.pets")))
                                ]),
                                _vm._v(" "),
                                _c("multi-select", {
                                  attrs: {
                                    options: _vm.petOptions,
                                    multiple: "",
                                    searchable: false,
                                    label: "display_name",
                                    "track-by": "id",
                                    selectedLabel: _vm.$t(
                                      "multiselect.selectedLabel"
                                    ),
                                    selectLabel: _vm.$t(
                                      "multiselect.selectLabel"
                                    ),
                                    placeholder: "",
                                    deselectLabel: _vm.$t(
                                      "multiselect.deselectLabel"
                                    )
                                  },
                                  model: {
                                    value: _vm.form.pets,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "pets", $$v)
                                    },
                                    expression: "form.pets"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "b-form-row",
                      [
                        _c(
                          "b-col",
                          [
                            _c(
                              "b-form-group",
                              [
                                _c("label", { attrs: { for: "" } }, [
                                  _vm._v(_vm._s(_vm.$t("user.shirtSize")))
                                ]),
                                _vm._v(" "),
                                _c("multi-select", {
                                  attrs: {
                                    options: _vm.shirtSizes,
                                    searchable: false,
                                    label: "name",
                                    "track-by": "id",
                                    selectedLabel: _vm.$t(
                                      "multiselect.selectedLabel"
                                    ),
                                    selectLabel: _vm.$t(
                                      "multiselect.selectLabel"
                                    ),
                                    placeholder: "",
                                    deselectLabel: _vm.$t(
                                      "multiselect.deselectLabel"
                                    )
                                  },
                                  model: {
                                    value: _vm.form.shirt_size,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "shirt_size", $$v)
                                    },
                                    expression: "form.shirt_size"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-col",
                          [
                            _c(
                              "b-form-group",
                              [
                                _c("label", { attrs: { for: "" } }, [
                                  _vm._v(_vm._s(_vm.$t("user.pantsSize")))
                                ]),
                                _vm._v(" "),
                                _c("multi-select", {
                                  attrs: {
                                    options: _vm.pantsSizes,
                                    searchable: false,
                                    selectedLabel: _vm.$t(
                                      "multiselect.selectedLabel"
                                    ),
                                    selectLabel: _vm.$t(
                                      "multiselect.selectLabel"
                                    ),
                                    placeholder: "",
                                    deselectLabel: _vm.$t(
                                      "multiselect.deselectLabel"
                                    )
                                  },
                                  model: {
                                    value: _vm.form.pants_size,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "pants_size", $$v)
                                    },
                                    expression: "form.pants_size"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-col",
                          [
                            _c(
                              "b-form-group",
                              [
                                _c("label", { attrs: { for: "" } }, [
                                  _vm._v(_vm._s(_vm.$t("user.shoeSize")))
                                ]),
                                _vm._v(" "),
                                _c("multi-select", {
                                  attrs: {
                                    options: _vm.shoeSizes,
                                    searchable: false,
                                    selectedLabel: _vm.$t(
                                      "multiselect.selectedLabel"
                                    ),
                                    selectLabel: _vm.$t(
                                      "multiselect.selectLabel"
                                    ),
                                    placeholder: "",
                                    deselectLabel: _vm.$t(
                                      "multiselect.deselectLabel"
                                    )
                                  },
                                  model: {
                                    value: _vm.form.shoe_size,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "shoe_size", $$v)
                                    },
                                    expression: "form.shoe_size"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c("div", { staticClass: "pb-4 group-title" }, [
              _vm._v(_vm._s(_vm.$t("user.favorite")))
            ]),
            _vm._v(" "),
            _c(
              "b-form-row",
              { staticClass: "align-items-center favorite-group" },
              _vm._l(_vm.categories, function(c) {
                return _c(
                  "b-col",
                  {
                    key: c.id,
                    staticClass: "favorite-container",
                    attrs: { cols: "3" }
                  },
                  [
                    _c(
                      "div",
                      {
                        class: {
                          "favorite-tag": true,
                          active: _vm.isFavorited(c.id)
                        },
                        on: {
                          click: function($event) {
                            return _vm.toggleFavorite(c.id)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n        " + _vm._s(c.display_name) + "\n      "
                        )
                      ]
                    )
                  ]
                )
              }),
              1
            ),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c("div", { staticClass: "pb-4 group-title" }, [
              _vm._v(_vm._s(_vm.$t("user.socialLinked")))
            ]),
            _vm._v(" "),
            _c(
              "b-form-row",
              { staticClass: "align-items-center" },
              [
                _c(
                  "b-col",
                  { staticClass: "social-group", attrs: { cols: "6" } },
                  [
                    _c(
                      "div",
                      { staticClass: "social-url" },
                      [
                        _c("div", { staticClass: "pb-2" }, [
                          _vm._v(_vm._s(_vm.$t("user.facebookAccount")))
                        ]),
                        _vm._v(" "),
                        _c("b-form-input", {
                          attrs: { type: "text" },
                          model: {
                            value: _vm.form.facebook_id,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "facebook_id", $$v)
                            },
                            expression: "form.facebook_id"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "social-follower" },
                      [
                        _c("div", { staticClass: "pb-2" }, [
                          _vm._v(_vm._s(_vm.$t("user.facebookFollower")))
                        ]),
                        _vm._v(" "),
                        _c("b-form-input", {
                          attrs: { type: "number" },
                          model: {
                            value: _vm.form.facebook_follower,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "facebook_follower", $$v)
                            },
                            expression: "form.facebook_follower"
                          }
                        })
                      ],
                      1
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "b-col",
                  { staticClass: "social-group", attrs: { cols: "6" } },
                  [
                    _c(
                      "div",
                      { staticClass: "social-url" },
                      [
                        _c("div", { staticClass: "pb-2" }, [
                          _vm._v(_vm._s(_vm.$t("user.instagramAccount")))
                        ]),
                        _vm._v(" "),
                        _c("b-form-input", {
                          attrs: { type: "text" },
                          model: {
                            value: _vm.form.instagram_id,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "instagram_id", $$v)
                            },
                            expression: "form.instagram_id"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "social-follower" },
                      [
                        _c("div", { staticClass: "pb-2" }, [
                          _vm._v(_vm._s(_vm.$t("user.instagramFollower")))
                        ]),
                        _vm._v(" "),
                        _c("b-form-input", {
                          attrs: { type: "number" },
                          model: {
                            value: _vm.form.instagram_follower,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "instagram_follower", $$v)
                            },
                            expression: "form.instagram_follower"
                          }
                        })
                      ],
                      1
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "b-col",
                  { staticClass: "social-group", attrs: { cols: "6" } },
                  [
                    _c(
                      "div",
                      { staticClass: "social-url" },
                      [
                        _c("div", { staticClass: "pb-2" }, [
                          _vm._v(_vm._s(_vm.$t("user.tiktokAccount")))
                        ]),
                        _vm._v(" "),
                        _c("b-form-input", {
                          attrs: { type: "text" },
                          model: {
                            value: _vm.form.tiktok_id,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "tiktok_id", $$v)
                            },
                            expression: "form.tiktok_id"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "social-follower" },
                      [
                        _c("div", { staticClass: "pb-2" }, [
                          _vm._v(_vm._s(_vm.$t("user.tiktokFollower")))
                        ]),
                        _vm._v(" "),
                        _c("b-form-input", {
                          attrs: { type: "number" },
                          model: {
                            value: _vm.form.tiktok_follower,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "tiktok_follower", $$v)
                            },
                            expression: "form.tiktok_follower"
                          }
                        })
                      ],
                      1
                    )
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c("div", { staticClass: "pb-4 group-title" }, [
              _vm._v(_vm._s(_vm.$t("user.mostFollower")))
            ]),
            _vm._v(" "),
            _c(
              "b-form-row",
              [
                _c(
                  "b-col",
                  [
                    _c("label", { attrs: { for: "" } }, [
                      _vm._v(_vm._s(_vm.$t("user.mostFollowerArea")))
                    ]),
                    _vm._v(" "),
                    _c(
                      "b-select",
                      {
                        model: {
                          value: _vm.form.regions_follow,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "regions_follow", $$v)
                          },
                          expression: "form.regions_follow"
                        }
                      },
                      [
                        _c("option", { attrs: { value: "0" } }, [
                          _vm._v(_vm._s(_vm.$t("message.defaultChoose")))
                        ]),
                        _vm._v(" "),
                        _vm._l(_vm.REGION_FOLLOWS, function(region) {
                          return _c(
                            "option",
                            { domProps: { value: region.id } },
                            [_vm._v(_vm._s(region.display_name))]
                          )
                        })
                      ],
                      2
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-col",
                  [
                    _c("label", { attrs: { for: "" } }, [
                      _vm._v(_vm._s(_vm.$t("user.mostFollowerGender")))
                    ]),
                    _vm._v(" "),
                    _c(
                      "b-select",
                      {
                        model: {
                          value: _vm.form.gender_follow,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "gender_follow", $$v)
                          },
                          expression: "form.gender_follow"
                        }
                      },
                      [
                        _c("option", { attrs: { value: "0" } }, [
                          _vm._v(_vm._s(_vm.$t("message.defaultChoose")))
                        ]),
                        _vm._v(" "),
                        _vm._l(_vm.genderOptions, function(gender) {
                          return _c(
                            "option",
                            { domProps: { value: gender.id } },
                            [_vm._v(_vm._s(gender.display_name))]
                          )
                        })
                      ],
                      2
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c("div", { staticClass: "pb-4 group-title" }, [
              _vm._v(_vm._s(_vm.$t("user.bankInfo")))
            ]),
            _vm._v(" "),
            _c(
              "b-form-row",
              { staticClass: "pb-3" },
              [
                _c(
                  "b-col",
                  [
                    _c("label", { attrs: { for: "" } }, [
                      _vm._v(_vm._s(_vm.$t("user.bankName")))
                    ]),
                    _vm._v(" "),
                    _c("b-form-input", {
                      model: {
                        value: _vm.form.bank_name,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "bank_name", $$v)
                        },
                        expression: "form.bank_name"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-col",
                  [
                    _c("label", { attrs: { for: "" } }, [
                      _vm._v(_vm._s(_vm.$t("user.bankBranch")))
                    ]),
                    _vm._v(" "),
                    _c("b-form-input", {
                      model: {
                        value: _vm.form.bank_branch_name,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "bank_branch_name", $$v)
                        },
                        expression: "form.bank_branch_name"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-form-row",
              [
                _c(
                  "b-col",
                  [
                    _c("label", { attrs: { for: "" } }, [
                      _vm._v(_vm._s(_vm.$t("user.bankHolder")))
                    ]),
                    _vm._v(" "),
                    _c("b-form-input", {
                      model: {
                        value: _vm.form.bank_account_holders,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "bank_account_holders", $$v)
                        },
                        expression: "form.bank_account_holders"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-col",
                  [
                    _c("label", { attrs: { for: "" } }, [
                      _vm._v(_vm._s(_vm.$t("user.bankNumber")))
                    ]),
                    _vm._v(" "),
                    _c("b-form-input", {
                      model: {
                        value: _vm.form.bank_account_number,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "bank_account_number", $$v)
                        },
                        expression: "form.bank_account_number"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c(
              "b-form-group",
              [
                _c(
                  "b-button",
                  { attrs: { type: "submit", size: "lg", variant: "success" } },
                  [_vm._v(_vm._s(_vm.btnText))]
                )
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }