<template>
  <div>
    <label class="col-form-label-lg">{{
      $t("user.user_campaigns.history")
    }}</label>
    <table class="table">
      <thead>
        <tr>
          <th scope="col">{{ $t("user.user_campaigns.id") }}</th>
          <th scope="col">{{ $t("user.user_campaigns.campaign_name") }}</th>
          <th scope="col">{{ $t("user.user_campaigns.created_at") }}</th>
          <th scope="col">{{ $t("user.user_campaigns.approved") }}</th>
          <th scope="col">{{ $t("user.user_campaigns.review") }}</th>
        </tr>
      </thead>
      <tbody>
        <template v-if="campaignHistories.length > 0">
          <tr
            v-for="(campaignHistory, index) in campaignHistories"
            :key="index"
          >
            <td>{{ campaignHistory.id }}</td>
            <td>
              <router-link
                :to="{
                  name: 'CampaignEdit',
                  params: { id: campaignHistory.campaign_id }
                }"
              >
                {{ campaignHistory.name }}
              </router-link>
            </td>
            <td>{{ formatDatetime(campaignHistory.created_at) }}</td>
            <td>
              <b-icon-check
                v-if="campaignHistory.approve"
                variant="success"
                class="h1"
              ></b-icon-check>
            </td>
            <td>
              <b-button
                v-if="campaignHistory.review_status === 1"
                variant="outline-primary"
                @click="openDetail(campaignHistory.campaign_id)"
              >
                {{ $t("user.user_campaigns.detail_review") }}
              </b-button>
            </td>
          </tr>
        </template>
        <template v-else>
          <tr class="text-center">
            <td colspan="5">
              {{ $t("user.user_campaigns.not_match_campaign") }}
            </td>
          </tr>
        </template>
      </tbody>
    </table>
    <user-campaign-detail-modal
      :id="userCampaignDetailModalId"
      @close="closeModal"
    ></user-campaign-detail-modal>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";
import UserCampaignDetailModal from "./UserCampaignDetailModal";

export default {
  props: {
    userId: {
      type: Number,
      default: 0
    }
  },

  components: {
    UserCampaignDetailModal
  },
  data: () => ({
    userCampaignDetailModalId: "user-campaign-detail"
  }),
  computed: {
    campaignHistories() {
      return this.$store.state.user.detail.campaignHistories;
    }
  },
  methods: {
    ...mapActions("user", ["fetchReviewDetail"]),
    formatDatetime(datetime) {
      if (datetime) {
        return moment.utc(datetime).format("DD/MM/YYYY HH:mm:ss");
      }
      return "";
    },
    openDetail(id) {
      this.fetchReviewDetail({id, userId: this.userId}).then(data => {
        this.$bvModal.show(this.userCampaignDetailModalId);
      });
    },
    closeModal() {}
  }
};
</script>

<style lang="scss" scoped>
.table thead th {
  position: relative;
}
</style>
