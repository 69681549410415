var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-form-group",
        [
          _c("label", [
            _vm._v("\n      " + _vm._s(_vm.$t("rewards.userId")) + "\n    ")
          ]),
          _vm._v(" "),
          _c("multi-select", {
            attrs: {
              options: _vm.userOptions,
              label: "fullname",
              "track-by": "id",
              selectedLabel: _vm.$t("multiselect.selectedLabel"),
              selectLabel: _vm.$t("multiselect.selectLabel"),
              placeholder: "",
              deselectLabel: _vm.$t("multiselect.deselectLabel")
            },
            model: {
              value: _vm.userSelected,
              callback: function($$v) {
                _vm.userSelected = $$v
              },
              expression: "userSelected"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }