<template>
  <auth-layout>
    <main-header :title="$t('page.category.list')">
      <router-link :to="{ name: 'CategoryCreate' }" class="btn btn-success">{{
        $t("page.category.create")
      }}</router-link>
    </main-header>
    <div class="main-content fix-2">
      <div class="table-wrapper no-pagination">
        <table>
          <thead>
            <tr>
              <th>{{ $t("category.id") }}</th>
              <th>{{ $t("category.icon") }}</th>
              <th>{{ $t("category.iconInactive") }}</th>
              <th>{{ $t("category.iconWeb") }}</th>
              <th>{{ $t("category.displayName") }}</th>
              <th>{{ $t("category.slug") }}</th>
              <th>{{ $t("category.createdAt") }}</th>
              <th>{{ $t("category.active") }}</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <template v-for="item in categories">
            <tr>
              <td>{{ item.id }}</td>
              <td>
                <img class="contain icon-bg-purple" :src="item.icon" width="70" height="70" />
              </td>
              <td>
                <img class="contain" :src="item.icon_inactive" width="70" height="70" />
              </td>
              <td>
                <img class="contain" :src="item.icon_web" width="70" height="70" />
              </td>
              <td>{{ item.display_name }}</td>
              <td>{{ item.slug }}</td>
              <td>{{ item.created_at | datetimeFormat }}</td>
              <td>
                <b-icon-check v-if="item.active" variant="success" class="h1"></b-icon-check>
              </td>
              <td>
                <router-link
                  :to="{
                    name: 'CategoryEdit',
                    params: { id: item.id }
                  }"
                >
                  <b-icon-pencil
                    variant="secondary"
                    class="action-icon"
                  ></b-icon-pencil>
                </router-link>
              </td>
              <td>
                <b-icon-trash
                  variant="danger"
                  class="action-icon"
                  @click="openDeleteModal(item.id, item.display_name)"
                ></b-icon-trash>
              </td>
            </tr>
          </template>
        </table>
      </div>
      <confirm-modal
        :id="confirmModalId"
        :confirmText="confirmText"
        @confirmed="deleteConfirmed"
        @modal-discard="cancelModal"
      ></confirm-modal>
    </div>
  </auth-layout>
</template>
<script>
import { mapState, mapActions } from "vuex";

export default {
  data: () => ({
    confirmModalId: "category-delete-modal",
    deleteTarget: {
      id: null,
      title: null
    }
  }),

  computed: {
    ...mapState('category', ['categories']),
    confirmText() {
      return this.$t("category.confirmDelete", {
        title: this.deleteTarget.title
      });
    }
  },

  methods: {
    ...mapActions('category', ['fetchCategories', 'destroy']),
    openDeleteModal(id, title) {
      this.deleteTarget.id = id;
      this.deleteTarget.title = title;
      this.$bvModal.show(this.confirmModalId);
    },
    deleteConfirmed() {
      this.destroy({ id: this.deleteTarget.id })
        .then(() => {
          this.$bvToast.toast(this.$t("message.commonSuccess"), {
            title: this.$t("message.successTitle"),
            variant: "success",
            autoHideDelay: 3000,
            solid: true
          });
          this.deleteTarget.id = null;
          this.deleteTarget.title = null;
        })
        .catch((error) => {
          this.$bvToast.toast(error.message || this.$t("message.commonError"), {
            title: this.$t("message.errorTitle"),
            variant: "danger",
            autoHideDelay: 3000,
            solid: true
          });
        })
    },
    cancelModal() {
      this.deleteTarget.id = null;
      this.deleteTarget.title = null;
    }
  },

  mounted() {
    this.fetchCategories();
  }
};
</script>
