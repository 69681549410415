import axios from "../../axios";

const state = {
  listSliders: [],
  pageObject: {},
  slidersData: {}
};

const getters = {
  listSliders: state => state.listSliders,
  slidersData: state => state.slidersData,
  pageObject: state => state.pageObject
};

const actions = {
  fetchList({ commit }, payload) {
    return new Promise((resolve, reject) => {
      let { page } = payload;
      if (!page) {
        page = 1;
      }
      let url = `/api/sliders?page=${page}`;
      axios.get(url).then(({ data }) => {
        const listSliders = data.data.list_sliders;
        const currentPage = data.data.current_page;
        const lastPage = data.data.last_page;
        commit("setListSliders", listSliders);
        commit("setPagination", {
          currentPage,
          lastPage,
          canNext: currentPage < lastPage,
          canPrev: currentPage > 1
        });
      });
    });
  },
  destroy({}, payload) {
    return new Promise((resolve, reject) => {
      const { id } = payload;
      axios
        .delete(`/api/sliders/${id}`)
        .then(() => {
          resolve();
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },
  changeAttributesData({ commit }, payload) {
    commit("setAttribute", payload);
  },
  fetchById({ commit }, payload) {
    return new Promise((resolve, reject) => {
      const { id } = payload;
      axios
        .get(`/api/sliders/${id}`)
        .then(({ data }) => {
          const sliders = data.data;
          commit("setSlidersData", sliders);
          resolve(sliders);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },
  store({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/sliders", payload)
        .then(() => resolve())
        .catch(({ response }) => reject(response.data));
    });
  },
  update({ commit }, payload) {
    return new Promise((resolve, reject) => {
      const { id } = payload;
      axios
        .post(`/api/sliders/${id}`, payload)
        .then(() => resolve())
        .catch(({ response }) => reject(response.data));
    });
  }
};

const mutations = {
  setListSliders(state, data) {
    state.listSliders = data;
  },
  setPagination(state, data) {
    state.pageObject = data;
  },
  setAttribute(state, payload) {
    const { key, value } = payload;
    state.slidersData[key] = value;
  },
  setSlidersData(state, data) {
    data.date_from = formatDateTime(data.date_from);
    data.date_to = formatDateTime(data.date_to);
    state.slidersData = data;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

function formatDateTime(datetime) {
  if (datetime) {
    let localDateTime = new Date(datetime);
    return (
      localDateTime.getFullYear() +
      "-" +
      (localDateTime.getMonth() + 1) +
      "-" +
      localDateTime.getDate() +
      " " +
      localDateTime.getHours() +
      ":" +
      localDateTime.getMinutes() +
      ":" +
      localDateTime.getSeconds()
    );
  }
  return "";
}
