var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "auth-layout",
    [
      _c("main-header", { attrs: { title: _vm.$t("page.mail.list") } }),
      _vm._v(" "),
      _c("div", { staticClass: "main-content" }, [
        _c("div", { staticClass: "table-wrapper no-pagination" }, [
          _c(
            "table",
            [
              _c("thead", [
                _c("tr", [
                  _c("th", [_vm._v("No.")]),
                  _vm._v(" "),
                  _c("th", [_vm._v(_vm._s(_vm.$t("mail.title")))]),
                  _vm._v(" "),
                  _c("th", [_vm._v(_vm._s(_vm.$t("mail.isAutoSend")))]),
                  _vm._v(" "),
                  _c("th", [_vm._v(_vm._s(_vm.$t("mail.isAutoPush")))]),
                  _vm._v(" "),
                  _c("th")
                ])
              ]),
              _vm._v(" "),
              _vm._l(_vm.templates, function(item, index) {
                return [
                  _c("tr", [
                    _c("td", [_vm._v(_vm._s(index + 1))]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(item.title))]),
                    _vm._v(" "),
                    _c(
                      "td",
                      [
                        item.is_auto_send
                          ? _c("b-icon-check", {
                              staticClass: "h1",
                              attrs: { variant: "success" }
                            })
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      [
                        item.is_auto_push
                          ? _c("b-icon-check", {
                              staticClass: "h1",
                              attrs: { variant: "success" }
                            })
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                name: "TemplateEdit",
                                params: { id: item.id }
                              }
                            }
                          },
                          [
                            _c("b-icon-pencil", {
                              staticClass: "action-icon",
                              attrs: { variant: "secondary" }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                ]
              })
            ],
            2
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }