<template>
  <div>
    <b-form-group
      :invalid-feedback="invalidFeedback"
      :state="stateValid"
    >
      <label>
        {{ $t("sliders.url") }}
      </label>
      <b-form-input
        v-model="slidersUrl"
        :state="stateValid"
        required
        trim
      ></b-form-input>
    </b-form-group>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import ValidateHandler from './../../../../mixins/ValidateHandler'

export default {
  mixins: [ValidateHandler],
  data() {
    return {};
  },
  computed: {
    slidersData() {
      return this.$store.getters["sliders/slidersData"];
    },
    slidersUrl: {
      get() {
        return this.slidersData ? this.slidersData.url : "";
      },
      set(value) {
        this.changeAttributesData({
          key: "url",
          value: value
        });
      }
    },
    stateValid() {
      if (this.slidersData.url && this.slidersData.url.length > 256)
        return false;
      if (this.slidersData.url && !this.validateURL(this.slidersData.url)) return false;
      return null;
    },
    invalidFeedback() {
      if (this.slidersUrl && this.slidersUrl.length > 256) {
        return this.$t("sliders.maxLength").replace(":length", "256");
      }
      if (this.slidersUrl && !this.validateURL(this.slidersData.url)) {
        return this.$t("sliders.url_invalid");
      }
    }
  },
  methods: {
    ...mapActions("sliders", ["changeAttributesData"]),
  }
};
</script>
