var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dataFetched
    ? _c("ValidationObserver", {
        ref: "observer",
        attrs: { tag: "form", mode: "passive" },
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.submit.apply(null, arguments)
          }
        },
        scopedSlots: _vm._u(
          [
            {
              key: "default",
              fn: function(ref) {
                var invalid = ref.invalid
                var errors = ref.errors
                return [
                  invalid || _vm.errMessage
                    ? _c(
                        "div",
                        {
                          ref: "errorDiv",
                          staticClass:
                            "text-danger d-flex justify-content-center mt-1"
                        },
                        [
                          _c(
                            "ul",
                            [
                              _vm._l(errors, function(error) {
                                return [
                                  error[0]
                                    ? _c("li", [_vm._v(_vm._s(error[0]))])
                                    : _vm._e()
                                ]
                              }),
                              _vm._v(" "),
                              _vm.errMessage
                                ? _c("li", [_vm._v(_vm._s(_vm.errMessage))])
                                : _vm._e()
                            ],
                            2
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.variant
                    ? _c(
                        "b-alert",
                        { attrs: { variant: _vm.variant, show: "" } },
                        [_vm._v(_vm._s(_vm.message))]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c(
                        "b-form-row",
                        [
                          _c("b-col", { staticClass: "flex-200px" }, [
                            _c(
                              "label",
                              { attrs: { for: "force-update-app" } },
                              [_vm._v(_vm._s(_vm.$t("settings.forceUpdate")))]
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "b-col",
                            [
                              _c("b-form-checkbox", {
                                attrs: {
                                  id: "force-update-app",
                                  switch: "",
                                  size: "lg"
                                },
                                model: {
                                  value: _vm.form.force_update,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "force_update", $$v)
                                  },
                                  expression: "form.force_update"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v(_vm._s(_vm.$t("settings.iosVersion")))
                      ]),
                      _vm._v(" "),
                      _c("ValidationProvider", {
                        attrs: {
                          mode: "passive",
                          name: _vm.$t("settings.iosVersion"),
                          rules: "max:10"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var errors = ref.errors
                                return [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.form.ios_version,
                                        expression: "form.ios_version"
                                      }
                                    ],
                                    class: {
                                      "form-control": true,
                                      "border border-danger": errors[0]
                                    },
                                    domProps: { value: _vm.form.ios_version },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.form,
                                          "ios_version",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v(_vm._s(_vm.$t("settings.androidVersion")))
                      ]),
                      _vm._v(" "),
                      _c("ValidationProvider", {
                        attrs: {
                          mode: "passive",
                          name: _vm.$t("settings.androidVersion"),
                          rules: "max:10"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var errors = ref.errors
                                return [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.form.android_version,
                                        expression: "form.android_version"
                                      }
                                    ],
                                    class: {
                                      "form-control": true,
                                      "border border-danger": errors[0]
                                    },
                                    domProps: {
                                      value: _vm.form.android_version
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.form,
                                          "android_version",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v(_vm._s(_vm.$t("settings.campaignWarningText")))
                      ]),
                      _vm._v(" "),
                      _c("ValidationProvider", {
                        attrs: {
                          mode: "passive",
                          name: _vm.$t("settings.campaignWarningText")
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var errors = ref.errors
                                return [
                                  _c("textarea", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.form.campaign_warning_text,
                                        expression: "form.campaign_warning_text"
                                      }
                                    ],
                                    class: {
                                      "form-control": true,
                                      "border border-danger": errors[0]
                                    },
                                    attrs: { rows: "4" },
                                    domProps: {
                                      value: _vm.form.campaign_warning_text
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.form,
                                          "campaign_warning_text",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "btn btn-lg btn-success",
                          attrs: {
                            type: "submit",
                            variant: "primary",
                            disabled: _vm.processing
                          }
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.$t("settings.submit")) +
                              "\n      "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ]
              }
            }
          ],
          null,
          false,
          3470217094
        )
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }