<template>
  <auth-layout>
    <main-header :title="$t('page.settings.list')">
    </main-header>

    <div class="card">
      <div class="card-body">
        <settings-list></settings-list>
      </div>
    </div>

  </auth-layout>
</template>

<script>
import mapActions from "vuex";
import SettingsList from "./../../components/Settings/navigation/SettingsList.vue";

export default {
  components: {
    SettingsList
  }
}

</script>

<style lang="scss" scoped></style>