<template>
  <auth-layout>
    <main-header :title="$t('page.user.changePassword')"></main-header>
    <div class="card">
      <div class="card-body">
        <change-password-block></change-password-block>
      </div>
    </div>
  </auth-layout>
</template>

<script>
import ChangePasswordBlock from './../../components/User/ChangePasswordBlock.vue'

export default {
  components: {
    ChangePasswordBlock,
  },
  data: () => ({}),

  computed: {},

  methods: {},

  async mounted() {}
};
</script>
