<template>
  <div class="sidebar">
    <ul>
      <router-link :to="{ name: 'CampaignList' }" active-class="active">
        <li>{{ $t('sidebar.campaign') }}</li>
      </router-link>
      <router-link :to="{ name: 'CategoryList' }" active-class="active">
        <li>{{ $t('sidebar.category') }}</li>
      </router-link>
      <router-link :to="{ name: 'UserList' }" active-class="active">
        <li>{{ $t('sidebar.user') }}</li>
      </router-link>
      <router-link :to="{ name: 'UserRankingNavigation' }" active-class="active">
        <li>{{ $t('sidebar.userRanking') }}</li>
      </router-link>
      <router-link :to="{ name: 'RewardsNavigation' }" active-class="active">
        <li>{{ $t('sidebar.rewards') }}</li>
      </router-link>
      <router-link :to="{ name: 'NotificationList' }" active-class="active">
        <li>{{ $t('sidebar.notification') }}</li>
      </router-link>
      <router-link :to="{ name: 'NewsNavigation' }" active-class="active">
        <li>{{ $t('sidebar.news') }}</li>
      </router-link>
      <router-link :to="{ name: 'MerchantsNavigation' }" active-class="active">
        <li>{{ $t('sidebar.merchants') }}</li>
      </router-link>
      <router-link :to="{ name: 'SlidersNavigation' }" active-class="active">
        <li>{{ $t('sidebar.sliders') }}</li>
      </router-link>
      <router-link :to="{ name: 'SettingsList' }" active-class="active">
        <li>{{ $t('sidebar.settings') }}</li>
      </router-link>
      <router-link :to="{ name: 'TemplateList' }" active-class="active">
        <li>{{ $t('sidebar.template') }}</li>
      </router-link>
      <router-link :to="{ name: 'ChangePassword' }" active-class="active">
        <li>{{ $t('sidebar.changePassword') }}</li>
      </router-link>
      <li class="p-0">
        <div class="menu-toggle d-flex justify-content-between align-items-center" v-b-toggle.collapse-1>
          {{ $t('sidebar.export') }}
          <b-icon-caret-down></b-icon-caret-down>
        </div>
        <b-collapse id="collapse-1" :visible="$route.name === 'ExportCampaigns' || $route.name === 'ExportMembers'">
          <b-card-body class="p-0">
            <router-link :to="{ name: 'ExportCampaigns' }" active-class="active">
              <li class="pl-5">{{ $t('sidebar.exportCampaign') }}</li>
            </router-link>
            <router-link :to="{ name: 'ExportMembers' }" active-class="active">
              <li class="pl-5">{{ $t('sidebar.exportMember') }}</li>
            </router-link>
          </b-card-body>
        </b-collapse>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  methods: {
    ...mapActions('auth', ['logout']),
  }
}
</script>

<style lang="scss" scoped>
.sidebar {
  color: white;
  background-color: #6F319F;
  height: 100%;
  width: 100%;
  ul {
    list-style-type: none;
    margin-bottom: 0;
    padding-inline-start: 0;
    padding-top: 10px;
    a {
      color: white;
      text-decoration: none;
      &.active {
        cursor: default;
        li {
          cursor: default;
          background-color: rgb(142, 120, 156);
        }
      }
    }
    li {
      padding: 7px 16px;
      cursor: pointer;
    }
    .menu-toggle {
      padding: 7px 16px;
      cursor: pointer;
    }
  }
}
</style>
