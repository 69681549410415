<template>
  <ValidationObserver
    ref="observer"
    v-slot="{ invalid, errors }"
    tag="form"
    mode="passive"
    @submit.prevent="submit"
  >
    <div
      ref="errorDiv"
      v-if="invalid || errMessage"
      class="text-danger d-flex justify-content-center mt-1"
    >
      <ul>
        <template v-for="error in errors">
          <li v-if="error[0]">{{ error[0] }}</li>
        </template>
        <li v-if="errMessage">{{ errMessage }}</li>
      </ul>
    </div>

    <div class="form-group">
      <label for=""
        >{{ $t("campaign.name")
        }}<span class="text-danger ml-1 small">{{
          $t("subText.required")
        }}</span></label
      >
      <ValidationProvider
        mode="passive"
        :name="$t('campaign.name')"
        rules="required|max:128"
        v-slot="{ errors }"
      >
        <input
          v-model="form.name"
          :class="{
            'form-control': true,
            'border border-danger': errors[0]
          }"
        />
      </ValidationProvider>
    </div>

    <div class="form-group">
      <b-form-row>
        <b-col class="flex-200px">
          <label for="campaign-public">{{ $t("campaign.public") }}</label>
        </b-col>
        <b-col>
          <b-form-checkbox v-model="form.public" id="campaign-public" switch size="lg">
          </b-form-checkbox>
        </b-col>
      </b-form-row>
    </div>

    <div class="form-group">
      <b-form-row>
        <b-col>
          <label for=""
            >{{ $t("campaign.category")
            }}<span class="text-danger ml-1 small">{{
              $t("subText.required")
            }}</span></label
          >
          <ValidationProvider
            mode="passive"
            :name="$t('campaign.category')"
            rules="required"
            v-slot="{ errors }"
          >
            <multi-select
              :class="{
                'border border-danger': errors[0]
              }"
              v-model="form.category_id"
              :options="categoryOptions"
              label="display_name"
              track-by="id"
              :selectedLabel="$t('multiselect.selectedLabel')"
              :selectLabel="$t('multiselect.selectLabel')"
              placeholder=""
              :deselectLabel="$t('multiselect.deselectLabel')"
            ></multi-select>
          </ValidationProvider>
        </b-col>
        <b-col>
          <label for="">
            {{ $t("campaign.price") }}
          </label>
          <ValidationProvider
            mode="passive"
            :name="$t('campaign.price')"
            rules="max:100"
            v-slot="{ errors }"
          >
            <b-form-input
              :class="{
                'border border-danger': errors[0]
              }"
              type="text"
              maxlength="100"
              v-model="form.price"
            ></b-form-input>
          </ValidationProvider>
        </b-col>
        <b-col>
          <label for="">{{ $t("campaign.salePrice") }}</label>
          <ValidationProvider
            mode="passive"
            :name="$t('campaign.salePrice')"
            rules="max:100"
            v-slot="{ errors }"
          >
            <b-form-input
              :class="{
                'border border-danger': errors[0]
              }"
              type="text"
              maxlength="100"
              v-model="form.sale_price"
            ></b-form-input>
          </ValidationProvider>
        </b-col>
      </b-form-row>
    </div>

    <div class="form-group">
      <b-form-row>
        <b-col>
          <label for=""
            >{{ $t("campaign.city")
            }}<span class="text-danger ml-1 small">{{
              $t("subText.required")
            }}</span></label
          >
          <ValidationProvider
            mode="passive"
            :name="$t('campaign.city')"
            rules="required"
            v-slot="{ errors }"
          >
            <multi-select
              :class="{
                'border border-danger': errors[0]
              }"
              multiple
              @search-change="citySearching"
              :internal-search="false"
              v-model="form.city_id"
              :options="filteredCities"
              label="name"
              track-by="id"
              :selectedLabel="$t('multiselect.selectedLabel')"
              :selectLabel="$t('multiselect.selectLabel')"
              placeholder=""
              :deselectLabel="$t('multiselect.deselectLabel')"
            ></multi-select>
          </ValidationProvider>
        </b-col>
        <b-col>
          <label for="">{{ $t("campaign.social") }}</label>
          <ValidationProvider
            mode="passive"
            :name="$t('campaign.social')"
            rules=""
            v-slot="{ errors }"
          >
            <multi-select
              :options="socialOptions"
              :class="{
                'border border-danger': errors[0]
              }"
              :multiple="true"
              v-model="form.campaign_socials"
              label="display_name"
              track-by="id"
              :selectedLabel="$t('multiselect.selectedLabel')"
              :selectLabel="$t('multiselect.selectLabel')"
              placeholder=""
              :deselectLabel="$t('multiselect.deselectLabel')"
              :searchable="false"
              @remove="socialRemove"
            ></multi-select>
          </ValidationProvider>
        </b-col>
      </b-form-row>
    </div>

    <div class="form-group">
      <b-form-row>
        <b-col md="4">
          <label for=""
            >{{ $t("campaign.primaryImage")
            }}<span class="text-danger ml-1 small">{{
              $t("subText.required")
            }}</span></label
          >
          <image-input
            @image-input="primaryImageInput"
            :data="form.primary_image"
            :can-remove="false"
          ></image-input>
        </b-col>
        <b-col class="d-flex flex-column justify-content-between">
          <b-form-row>
            <b-col cols="6" class="mb-2">
              <label for=""
                >{{ $t("campaign.maxUser")
                }}<span class="text-danger ml-1 small">{{
                  $t("subText.required")
                }}</span></label
              >
              <ValidationProvider
                mode="passive"
                :name="$t('campaign.maxUser')"
                rules="required|numeric"
                v-slot="{ errors }"
              >
                <b-form-input
                  :class="{
                    'form-control': true,
                    'border border-danger': errors[0]
                  }"
                  type="number"
                  v-model.number="form.max_user"
                ></b-form-input>
              </ValidationProvider>
            </b-col>
            <b-col cols="6" class="mb-2">
              <label for=""
                >{{ $t("campaign.initUser")
                }}</label
              >
              <ValidationProvider
                mode="passive"
                :name="$t('campaign.initUser')"
                rules="numeric"
                v-slot="{ errors }"
              >
                <b-form-input
                  :class="{
                    'form-control': true,
                    'border border-danger': errors[0]
                  }"
                  type="number"
                  v-model.number="form.initial_user"
                ></b-form-input>
              </ValidationProvider>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col class="mb-2">
              <label for=""
                >{{ $t("campaign.slug")
                }}<span class="text-danger ml-1 small">{{
                  $t("subText.required")
                }}</span></label
              >
              <b-form-row>
                <b-col md="9">
                  <ValidationProvider
                    mode="passive"
                    :name="$t('campaign.slug')"
                    rules="required|alpha_dash|max:256"
                    v-slot="{ errors }"
                  >
                    <b-form-input
                      :class="{
                        'form-control': true,
                        'border border-danger': errors[0]
                      }"
                      type="text"
                      maxlength="256"
                      v-model="form.slug"
                    ></b-form-input>
                  </ValidationProvider>
                </b-col>
                <b-col md="3" ml="auto" class="input-group">
                  <button
                    class="btn btn-primary"
                    type="button"
                    @click="generateSlug"
                  >
                    {{ $t("campaign.AutoGenerateSlug") }}
                  </button>
                </b-col>
              </b-form-row>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col md="6" class="mb-2">
              <label for="">{{ $t("campaign.endTime") }}</label>
              <ValidationProvider
                mode="passive"
                :name="$t('campaign.endTime')"
                rules=""
                v-slot="{ errors }"
              >
                <v-date-picker
                  v-model="form.end_time"
                  mode="dateTime"
                  :min-date="minEndDate"
                  is24hr
                  :locale="currentLocale"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <input
                      class="form-control"
                      :value="inputValue"
                      v-on="inputEvents"
                      :placeholder="$t('datePicker.placeHolder')"
                    />
                  </template>
                </v-date-picker>
              </ValidationProvider>
            </b-col>
            <b-col md="6" class="mb-2">
              <label for="">{{ $t("campaign.approveEndTime") }}</label>
              <ValidationProvider
                mode="passive"
                :name="$t('campaign.approveEndTime')"
                rules=""
                v-slot="{ errors }"
              >
                <v-date-picker
                  v-model="form.approve_end_time"
                  mode="dateTime"
                  :min-date="minEndDate"
                  is24hr
                  :locale="currentLocale"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <input
                      class="form-control"
                      :value="inputValue"
                      v-on="inputEvents"
                      :placeholder="$t('datePicker.placeHolder')"
                    />
                  </template>
                </v-date-picker>
              </ValidationProvider>
            </b-col>
            <b-col md="6" class="mb-2">
              <label for="">{{ $t("campaign.postReviewTime") }}</label>
              <ValidationProvider
                mode="passive"
                :name="$t('campaign.postReviewTime')"
                rules=""
                v-slot="{ errors }"
              >
                <v-date-picker
                  v-model="form.post_review_time"
                  mode="dateTime"
                  :min-date="minEndDate"
                  is24hr
                  :locale="currentLocale"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <input
                      class="form-control"
                      :value="inputValue"
                      v-on="inputEvents"
                      :placeholder="$t('datePicker.placeHolder')"
                    />
                  </template>
                </v-date-picker>
              </ValidationProvider>
            </b-col>
            <b-col md="6" class="mb-2">
              <label for="">{{ $t("campaign.reviewEndTime") }}</label>
              <ValidationProvider
                mode="passive"
                :name="$t('campaign.reviewEndTime')"
                rules=""
                v-slot="{ errors }"
              >
                <v-date-picker
                  v-model="form.review_end_time"
                  mode="dateTime"
                  :min-date="minEndDate"
                  is24hr
                  :locale="currentLocale"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <input
                      class="form-control"
                      :value="inputValue"
                      v-on="inputEvents"
                      :placeholder="$t('datePicker.placeHolder')"
                    />
                  </template>
                </v-date-picker>
              </ValidationProvider>
            </b-col>
            <b-col md="6" class="mb-2">
              <label for="">{{ $t("campaign.closedDate") }}</label>
              <ValidationProvider
                mode="passive"
                :name="$t('campaign.closedDate')"
                rules=""
                v-slot="{ errors }"
              >
                <v-date-picker
                  v-model="form.closed_date"
                  mode="dateTime"
                  :min-date="minEndDate"
                  is24hr
                  :locale="currentLocale"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <input
                      class="form-control"
                      :value="inputValue"
                      v-on="inputEvents"
                      :placeholder="$t('datePicker.placeHolder')"
                    />
                  </template>
                </v-date-picker>
              </ValidationProvider>
            </b-col>
            <b-col md="6" class="mb-2">
              <label for="">{{ $t("campaign.crawlingTime") }}</label>
              <ValidationProvider
                mode="passive"
                :name="$t('campaign.crawlingTime')"
                rules=""
                v-slot="{ errors }"
              >
                <v-date-picker
                  v-model="form.crawling_time"
                  mode="dateTime"
                  :min-date="minEndDate"
                  is24hr
                  :locale="currentLocale"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <input
                      class="form-control"
                      :value="inputValue"
                      v-on="inputEvents"
                      :placeholder="$t('datePicker.placeHolder')"
                    />
                  </template>
                </v-date-picker>
              </ValidationProvider>
            </b-col>
          </b-form-row>
        </b-col>
      </b-form-row>
    </div>

    <div class="form-group">
      <label for=""
        >{{ $t("campaign.optionalImage") }}
        <span class="small text-gray">{{
          $t("subText.maxImageCount", { n: MAX_IMAGE_COUNT })
        }}</span></label
      >
      <button
        class="btn btn-primary ml-1 mb-2"
        type="button"
        @click="addImageSlot"
        :disabled="images.length >= MAX_IMAGE_COUNT"
      >
        {{ $t("button.addImageSlot") }}
      </button>
      <b-form-row>
        <b-col
          md="4"
          v-for="(image, index) in images"
          :key="`image_${index}`"
        >
          <image-input
            @image-removal="removeImageSlot"
            @image-input="addOrUpdateImage"
            :index="index"
            :data="image"
          ></image-input>
        </b-col>
      </b-form-row>
    </div>

    <div class="form-group">
      <label for="">{{ $t("campaign.description") }}</label>
      <ValidationProvider
        rules=""
        :name="$t('campaign.description')"
        mode="passive"
        v-slot="{ errors }"
      >
        <div :class="{ 'border border-danger': errors[0] }">
          <ckeditor :editor="editor" v-model="form.campaign_description" :config="editorConfig" name="campaign_description" tag-name="textarea"></ckeditor>
        </div>
      </ValidationProvider>
    </div>



    <div class="form-group">
      <b-form-row>
        <b-col cols="6">
          <b-form-group>
            <label for="">{{ $t('campaign.warningText') }}</label>
            <ValidationProvider
              mode="passive"
              :name="$t('campaign.warningText')"
              v-slot="{ errors }"
            >
              <b-form-textarea
                id="warning_text"
                :class="{
                  'border border-danger': errors[0]
                }"
                v-model="form.warning_text"
                maxlength="256"
                rows="3"
                max-rows="6"
              ></b-form-textarea>
            </ValidationProvider>
          </b-form-group>
        </b-col>
      </b-form-row>
    </div>

    <div class="form-group">
      <label for="">{{ $t('campaign.criteria') }}</label>
      <div class="px-5">
        <div class="col-group">
          <div class="label">{{ $t('campaignCriteria.age') }}</div>
          <div class="content">
            <ValidationProvider
              rules="numeric"
              name="age_from"
              mode="passive"
              v-slot="{ errors }"
            >
              <b-form-input
                type="number"
                min="0"
                v-model="form.criteria.age_from"
                :class="{
                  'border border-danger': errors[0],
                  'max-w-200px': true
                }"
              ></b-form-input>
            </ValidationProvider>
            <div class="period_separator mx-3">~</div>
            <ValidationProvider
              rules="numeric"
              name="age_to"
              mode="passive"
              v-slot="{ errors }"
            >
              <b-form-input
                type="number"
                min="0"
                v-model="form.criteria.age_to"
                :class="{
                  'border border-danger': errors[0],
                  'max-w-200px': true
                }"
              ></b-form-input>
            </ValidationProvider>
          </div>
        </div>
        <div class="col-group">
          <div class="label">{{ $t('user.gender') }}</div>
          <div class="content">
            <b-form-checkbox-group v-model="form.criteria.gender">
              <b-form-checkbox :value="GENDER_MALE">{{ $t('user.male') }}</b-form-checkbox>
              <b-form-checkbox :value="GENDER_FEMALE">{{ $t('user.female') }}</b-form-checkbox>
            </b-form-checkbox-group>
          </div>
        </div>
        <div class="col-group">
          <div class="label">{{ $t('campaignCriteria.instagramCount') }}</div>
          <div class="content">
            <ValidationProvider
              rules="numeric"
              name="instagram_count"
              mode="passive"
              v-slot="{ errors }"
            >
              <b-form-input
                type="number"
                min="0"
                v-model="form.criteria.instagram_count"
                :class="{
                  'border border-danger': errors[0],
                  'max-w-200px': true
                }"
              ></b-form-input>
            </ValidationProvider>
          </div>
        </div>
        <div class="col-group">
          <div class="label">{{ $t('campaignCriteria.facebookCount') }}</div>
          <div class="content">
            <ValidationProvider
              rules="numeric"
              name="facebook_count"
              mode="passive"
              v-slot="{ errors }"
            >
              <b-form-input
                type="number"
                min="0"
                v-model="form.criteria.facebook_count"
                :class="{
                  'border border-danger': errors[0],
                  'max-w-200px': true
                }"
              ></b-form-input>
            </ValidationProvider>
          </div>
        </div>
        <div class="col-group">
          <div class="label">{{ $t('campaignCriteria.tiktokCount') }}</div>
          <div class="content">
            <ValidationProvider
              rules="numeric"
              name="tiktok_count"
              mode="passive"
              v-slot="{ errors }"
            >
              <b-form-input
                type="number"
                min="0"
                v-model="form.criteria.tiktok_count"
                :class="{
                  'border border-danger': errors[0],
                  'max-w-200px': true
                }"
              ></b-form-input>
            </ValidationProvider>
          </div>
        </div>
        <div class="col-group">
          <div class="label">{{ $t('user.maritalStatus') }}</div>
          <div class="content">
            <b-form-checkbox-group v-model="form.criteria.marital_status">
              <b-form-checkbox value="1">{{ $t('user.single') }}</b-form-checkbox>
              <b-form-checkbox value="2">{{ $t('user.married') }}</b-form-checkbox>
              <b-form-checkbox value="3">{{ $t('user.hasChild') }}</b-form-checkbox>
            </b-form-checkbox-group>
          </div>
        </div>
        <div class="col-group">
          <div class="label">{{ $t('campaignCriteria.place') }}</div>
          <div class="content">
            <multi-select
              :class="{
                'border border-danger': errors[0],
                'max-w-400px': true
              }"
              v-model="form.criteria.city_id"
              :options="cityOptions"
              label="name"
              track-by="id"
              :selectedLabel="$t('multiselect.selectedLabel')"
              :selectLabel="$t('multiselect.selectLabel')"
              placeholder=""
              :deselectLabel="$t('multiselect.deselectLabel')"
            ></multi-select>
          </div>
        </div>
        <div class="col-group">
          <div class="label">{{ $t('campaignCriteria.contentType') }}</div>
          <div class="content">
            <b-form-checkbox-group v-model="form.criteria.content_type">
              <b-form-checkbox value="1">{{ $t('campaignCriteria.contentTypeImage') }}</b-form-checkbox>
              <b-form-checkbox value="2">{{ $t('campaignCriteria.contentTypeVideo') }}</b-form-checkbox>
            </b-form-checkbox-group>
          </div>
        </div>
        <div class="col-group">
          <div class="label">{{ $t('campaignCriteria.reviewHistory') }}</div>
          <div class="content">
            <b-form-radio-group v-model="form.criteria.review_history">
              <b-form-radio value="0">{{ $t('campaignCriteria.noChoice') }}</b-form-radio>
              <b-form-radio value="1">{{ $t('campaignCriteria.reviewHistoryPro') }}</b-form-radio>
              <b-form-radio value="2">{{ $t('campaignCriteria.reviewHistoryUsedTo') }}</b-form-radio>
              <b-form-radio value="3">{{ $t('campaignCriteria.reviewHistoryNotYet') }}</b-form-radio>
            </b-form-radio-group>
          </div>
        </div>
      </div>
    </div>

    <div class="form-group">
      <label for="">{{ $t("campaign.reviewRequirement") }}</label>
      <ckeditor :editor="editor" v-model="form.review_requirement" :config="editorConfig" name="review_requirement" tag-name="textarea"></ckeditor>
    </div>

    <div class="form-group">
      <label for="">{{ $t("campaign.requirement") }}</label>
      <ValidationProvider
        rules=""
        :name="$t('campaign.requirement')"
        mode="passive"
        v-slot="{ errors }"
      >
        <div :class="{ 'border border-danger': errors[0] }">
          <ckeditor :editor="editor" v-model="form.campaign_requirement" :config="editorConfig" name="campaign_requirement" tag-name="textarea"></ckeditor>
        </div>
      </ValidationProvider>
    </div>

    <div class="form-group">
      <label for="">{{ $t("campaign.endCampaignText") }}</label>
      <ckeditor :editor="editor" v-model="form.end_campaign_text" :config="editorConfig" name="end_campaign_text" tag-name="textarea"></ckeditor>
    </div>

    <div class="form-group">
      <b-form-row>
        <b-col class="flex-200px">
          <label for="campaign-only-member">{{ $t("campaign.onlyForMember") }}</label>
        </b-col>
        <b-col>
          <b-form-checkbox v-model="form.member_only" id="campaign-only-member" switch size="lg">
          </b-form-checkbox>
        </b-col>
      </b-form-row>
    </div>

    <hr />

    <div class="group-title">{{ $t('campaign.reward') }}</div>

    <rewards-thumbnail-block :isRequired="false"></rewards-thumbnail-block>
    <rewards-name-block :isRequired="false"></rewards-name-block>
    <rewards-description-block></rewards-description-block>

    <hr />
    <div class="form-group">
      <button
        type="submit"
        class="btn btn-lg btn-success"
        :disabled="processing"
      >
        {{ submitText }}
      </button>
    </div>
  </ValidationObserver>
</template>
<script>
import { mapState, mapActions } from "vuex";
import MultiSelect from "vue-multiselect";
import { MAX_IMAGE_COUNT } from "../../constants/campaign";
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import ImageInput from "../Input/ImageInput.vue";
import moment from "moment";
import MyUploadAdapter from '../../uploadAdapter';
import { GENDER_FEMALE, GENDER_MALE } from "../../constants/user"
import RewardsNameBlock from '../Rewards/data/blocks/RewardsNameBlock.vue'
import RewardsThumbnailBlock from '../Rewards/data/blocks/RewardsThumbnailBlock'
import RewardsDescriptionBlock from '../Rewards/data/blocks/RewardsDescriptionBlock'

export default {
  props: {
    errMessage: {
      type: String,
      default: ""
    },
    submitText: {
      type: String,
      default: "Submit"
    },
    defaultForm: {
      type: Object,
      default: () => ({})
    },
    images: {
      type: Array,
      default: () => []
    },
    warningText: {
      type: String,
      default: ""
    }
  },

  components: {
    MultiSelect,
    "v-date-picker": DatePicker,
    ImageInput,
    RewardsNameBlock,
    RewardsThumbnailBlock,
    RewardsDescriptionBlock,
  },

  watch: {
    warningText(val) {
      if (val != "") {
        this.form.warning_text = val;
      }
    }
  },

  data: () => ({
    MAX_IMAGE_COUNT: MAX_IMAGE_COUNT,
    GENDER_MALE: GENDER_MALE,
    GENDER_FEMALE: GENDER_FEMALE,
    dataStructure: {
      category_id: null,
      name: "",
      primary_image: null,
      campaign_description: "",
      campaign_requirement: "",
      price: null,
      sale_price: null,
      city_id: [],
      campaign_socials: [],
      end_time: null,
      approve_end_time: null,
      post_review_time: null,
      review_end_time: null,
      closed_date: null,
      reward_id: null,
      review_rule: "",
      review_requirement: "",
      social_minium_review: null,
      max_user: null,
      initial_user: null,
      slug: null,
      criteria: {
        age_from: null,
        age_to: null,
        gender: [],
        marital_status: [],
        facebook_count: null,
        instagram_count: null,
        tiktok_count: null,
        content_type: [],
        review_history: null
      },
      end_campaign_text: "",
      member_only: false,
      warning_text: "",
      crawling_time: null,
      public: false
    },
    processing: false,
    removalImageIds: [],
    removalSocialIds: [],
    editor: ClassicEditor,
    filteredCities: []
  }),

  computed: {
    ...mapState("campaign", [
      "categoryOptions",
      "socialOptions",
      "rewardOptions"
    ]),
    ...mapState("location", ["cityOptions"]),
    ...mapState("rewards", ["rewardsData"]),
    form: {
      get() {
        Object.assign(this.dataStructure, this.defaultForm);
        return this.dataStructure;
      },
      set(val) {
        this.form = val;
      }
    },
    currentLocale() {
      return this.locale;
    },
    minEndDate() {
      if (this.defaultForm.id) {
        return null
      }

      return new Date();
    },
    editorConfig() {
      return {
        fontSize: {
            options: [
                9,
                11,
                13,
                'default',
                17,
                19,
                21
            ]
        },
        toolbar: [
          'heading',
          '|',
          'bold',
          'italic',
          'link',
          'bulletedList',
          'numberedList',
          'underline',
          '|',
          'outdent',
          'indent',
          '|',
          'fontBackgroundColor',
          'fontColor',
          'fontSize',
          'highlight',
          '|',
          'imageInsert',
          'blockQuote',
          'insertTable',
          'undo',
          'redo',
          'sourceEditing'
        ],
        extraPlugins: [this.uploader],
      }
    }
  },

  methods: {
    ...mapActions("campaign", ["fetchCategoryOptions", "fetchSocialOptions", "fetchRewardOptions"]),
    ...mapActions("location", ["fetchCityOptions"]),
    generateSlug() {
      if (this.form.name) {
        let slug = this.form.name.toLowerCase();
        slug = slug.normalize("NFD").replace(/[\u0300-\u036f]/g, ""); //Separate and remove diacritics
        slug = slug.replace(/[đĐ]/g, 'd'); //đ changes to d
        slug = slug.replace(/[^a-z0-9 -]/g, ''); //remove non-alphanumeric characters
        slug = slug.replace(/  +/g, ' '); //remove extra whitespaces
        slug = slug.replace(/\s+/g, '-'); // spaces changes to -
        slug = slug.replace(/--+/g, '-'); // remove extra -

        this.form.slug = slug;
      }
    },
    async submit() {
      this.processing = true;
      this.$emit("setErrorMessage", "");
      this.$nextTick(() => this.$refs.errorDiv.scrollIntoView({
        behavior: "smooth",
        block: "center"
      }));
      const isValid = await this.$refs.observer.validate();

      const checkFileCount = this.multipleFileInput(this.images);

      if (isValid && checkFileCount) {
        let data = this.mapFormData();
        this.$emit("submit", data);
      }

      this.processing = false;
    },

    multipleFileInput(files) {
      if (files.length > MAX_IMAGE_COUNT) {
        this.$emit(
          "setErrorMessage",
          this.$t("message.maxFileCount", { count: MAX_IMAGE_COUNT })
        );
        this.$nextTick(() =>
          this.$refs.errorDiv.scrollIntoView({
            behavior: "smooth",
            block: "center"
          })
        );
        return false;
      }

      return true;
    },

    mapFormData() {
      let data = new FormData();

      const keys = Object.keys(this.form);

      for (let i = 0; i < keys.length; i++) {
        if (keys[i] == "campaign_socials") {
          for (let j = 0; j < this.form.campaign_socials.length; j++) {
            data.append(
              "campaign_social_" + j,
              this.form.campaign_socials[j].id
            );
          }
        } else if (keys[i] == "city_id") {
          for (let j = 0; j < this.form.city_id.length; j++) {
            data.append("city_id[]", this.form.city_id[j].id);
          }
        } else if (keys[i] == "primary_image") {
          if (this.form.primary_image.isDirty) {
            data.append(`primary_image`, this.form.primary_image.content);
          }
        } else if (this.form[keys[i]] == "") {
          data.append(keys[i], this.form[keys[i]]);
        } else if (!!this.form[keys[i]]) {
          data.append(keys[i], this.form[keys[i]]);
        }
      }

      for (let j = 0; j < this.images.length; j++) {
        if (this.images[j].isDirty) {
          if (null != this.images[j].id) {
            data.append(`image_${j}_id`, this.images[j].id);
          }

          data.append(`image_${j}`, this.images[j].content);
        }
      }

      // time parser
      if (data.has("end_time") && !!data.get("end_time")) {
        let end = moment(data.get("end_time"));
        if (end.isValid()) {
          data.set("end_time", end.utc().format());
        } else {
          data.delete('end_time');
        }
      }
      if (data.has("approve_end_time") && !!data.get("approve_end_time")) {
        let end = moment(data.get("approve_end_time"));
        if (end.isValid()) {
          data.set("approve_end_time", end.utc().format());
        } else {
          data.delete('approve_end_time');
        }
      }
      if (data.has("post_review_time") && !!data.get("post_review_time")) {
        let end = moment(data.get("post_review_time"));
        if (end.isValid()) {
          data.set("post_review_time", end.utc().format());
        } else {
          data.delete('post_review_time');
        }
      }
      if (data.has("review_end_time") && !!data.get("review_end_time")) {
        let end = moment(data.get("review_end_time"));
        if (end.isValid()) {
          data.set("review_end_time", end.utc().format());
        } else {
          data.delete('review_end_time');
        }
      }
      if (data.has("closed_date") && !!data.get("closed_date")) {
        let end = moment(data.get("closed_date"));
        if (end.isValid()) {
          data.set("closed_date", end.utc().format());
        } else {
          data.delete('closed_date');
        }
      }
      if (data.has("crawling_time") && !!data.get("crawling_time")) {
        let end = moment(data.get("crawling_time"));
        if (end.isValid()) {
          data.set("crawling_time", end.utc().format());
        } else {
          data.delete('crawling_time');
        }
      }

      if (data.has('category_id')) {
        data.set("category_id", this.form.category_id.id);
      }
      for (let i = 0; i < this.removalImageIds.length; i++) {
        data.append(`remove_image_${i}`, this.removalImageIds[i]);
      }
      for (let i = 0; i < this.removalSocialIds.length; i++) {
        data.append(`remove_social_${i}`, this.removalSocialIds[i]);
      }

      if (data.has('reward_id')) {
        data.set("reward_id", this.form.reward_id.id);
      }

      if (data.has('criteria')) {
        data.delete('criteria');
      }

      let { criteria } = this.form;

      if (criteria.age_from != null) {
        data.append("criteria[age_from]", criteria.age_from);
      } else {
        data.append("criteria[age_from]", 0);
      }
      if (criteria.age_to != null) {
        data.append("criteria[age_to]", criteria.age_to);
      }

      for (let i = 0; i < criteria.gender.length; i++) {
        data.append("criteria[gender][]", criteria.gender[i])
      }

      for (let i = 0; i < criteria.marital_status.length; i++) {
        data.append("criteria[marital_status][]", criteria.marital_status[i])
      }

      for (let i = 0; i < criteria.content_type.length; i++) {
        data.append("criteria[content_type][]", criteria.content_type[i])
      }

      if (criteria.city_id) {
        data.append("criteria[city_id]", criteria.city_id.id);
      }

      if (criteria.review_history != null) {
        data.append("criteria[review_history]", criteria.review_history);
      }
      if (criteria.instagram_count != null) {
        data.append("criteria[instagram_count]", criteria.instagram_count);
      }
      if (criteria.facebook_count != null) {
        data.append("criteria[facebook_count]", criteria.facebook_count);
      }
      if (criteria.tiktok_count != null) {
        data.append("criteria[tiktok_count]", criteria.tiktok_count);
      }

      data.set('member_only', this.form.member_only ? 1 : 0);

      data.set('public', this.form.public ? 1 : 0);

      if (this.rewardsData.name) {
        data.append('reward_name', this.rewardsData.name)
      }
      if (this.rewardsData.description) {
        data.append('reward_description', this.rewardsData.description)
      }
      if (this.rewardsData.thumbnail && typeof(this.rewardsData.thumbnail) !== 'string') {
        data.append('reward_thumbnail', this.rewardsData.thumbnail)
      }

      return data;
    },

    addImageSlot() {
      if (this.images.length < this.MAX_IMAGE_COUNT) {
        this.images.push({
          id: null,
          content: null,
          url: null,
          isDirty: false
        });
      }
    },

    removeImageSlot(payload) {
      if (null != payload.id) {
        this.removalImageIds.push(payload.id);
      }
      this.images.splice(payload.index, 1);
    },

    addOrUpdateImage(payload) {
      this.images[payload.index] = payload.data;
    },

    primaryImageInput(payload) {
      if (null != payload.data.id) {
        this.removalImageIds.push(payload.data.id);
      }
      this.form.primary_image = payload.data;
    },

    socialRemove(option) {
      if (!!option.model_id) {
        this.removalSocialIds.push(option.model_id);
      }
    },

    uploader(editor) {
      editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
        return new MyUploadAdapter( loader, 'campaigns' );
      };
    },

    vnStringIncludes(s1, s2) {
      let str1 = this.$options.filters.removeVietnameseTones(s1.toLowerCase())
      let str2 = this.$options.filters.removeVietnameseTones(s2.toLowerCase())

      return str1.includes(str2)
    },

    citySearching(query) {
      if (!query) {
        this.filteredCities = [...this.cityOptions]
      } else {
        this.filteredCities = this.cityOptions
          .filter( el => this.vnStringIncludes(el.name, query) )
      }
    }
  },

  async created() {
    if (this.categoryOptions.length == 0) {
      this.fetchCategoryOptions();
    }
    if (this.socialOptions.length == 0) {
      this.fetchSocialOptions();
    }
    if (this.cityOptions.length == 0) {
      await this.fetchCityOptions()
    }
    // if (this.rewardOptions.length == 0) {
    //   this.fetchRewardOptions();
    // }

    this.filteredCities = this.cityOptions
  },
};
</script>
<style lang="scss" scoped>
.col-group {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;

  .label {
    flex: 0 0 30%;
    max-width: 30%;
    font-size: 0.9rem;
    font-weight: 600;
    font-style: italic;
    padding: 0 0 0 10px;
  }
  .content {
    flex: 0 0 70%;
    max-width: 70%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 10px;
  }
}
</style>
