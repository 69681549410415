var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-form-group",
        [
          _c("label", [
            _vm._v("\n      " + _vm._s(_vm.$t("rewards.expireDate")) + "\n    ")
          ]),
          _vm._v(" "),
          _c("v-date-picker", {
            attrs: {
              mode: "dateTime",
              "min-date": _vm.minEndDate,
              is24hr: "",
              locale: _vm.currentLocale
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var inputValue = ref.inputValue
                  var inputEvents = ref.inputEvents
                  return [
                    _c(
                      "input",
                      _vm._g(
                        {
                          staticClass: "form-control",
                          attrs: {
                            placeholder: _vm.$t("datePicker.placeHolder")
                          },
                          domProps: { value: inputValue }
                        },
                        inputEvents
                      )
                    )
                  ]
                }
              }
            ]),
            model: {
              value: _vm.rewardsExpireDate,
              callback: function($$v) {
                _vm.rewardsExpireDate = $$v
              },
              expression: "rewardsExpireDate"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }