var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "auth-layout",
    [
      _c(
        "main-header",
        {
          attrs: {
            title: _vm.$t("mail.templateTitle", { title: _vm.mailTitle })
          }
        },
        [
          _c(
            "router-link",
            {
              staticClass: "btn btn-outline-secondary",
              attrs: { to: { name: "TemplateList" } }
            },
            [_vm._v(_vm._s(_vm.$t("page.mail.list")))]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "card" }, [
        _c(
          "div",
          { staticClass: "card-body" },
          [
            _vm.template
              ? _c("ValidationObserver", {
                  ref: "observer",
                  attrs: { tag: "form", mode: "passive" },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.submit.apply(null, arguments)
                    }
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(ref) {
                          var invalid = ref.invalid
                          var errors = ref.errors
                          return [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: invalid || _vm.errMessage,
                                    expression: "invalid || errMessage"
                                  }
                                ],
                                ref: "errorDiv",
                                staticClass:
                                  "text-danger d-flex justify-content-center mt-1"
                              },
                              [
                                _c(
                                  "ul",
                                  [
                                    _vm._l(errors, function(error) {
                                      return [
                                        error[0]
                                          ? _c("li", [_vm._v(_vm._s(error[0]))])
                                          : _vm._e()
                                      ]
                                    }),
                                    _vm._v(" "),
                                    _vm.errMessage
                                      ? _c("li", [
                                          _vm._v(_vm._s(_vm.errMessage))
                                        ])
                                      : _vm._e()
                                  ],
                                  2
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "b-form-row",
                              {
                                staticClass:
                                  "mb-2 p-2 justify-content-center align-items-center"
                              },
                              [
                                _c(
                                  "b-col",
                                  {
                                    staticClass:
                                      "d-flex align-items-center justify-content-between text-strong text-grey",
                                    attrs: { md: "3" }
                                  },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(_vm.$t("mail.subject")) +
                                        "\n          "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-col",
                                  [
                                    _c("ValidationProvider", {
                                      attrs: {
                                        mode: "passive",
                                        name: _vm.$t("mail.subject"),
                                        rules: "required|max:100"
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(ref) {
                                              var errors = ref.errors
                                              return [
                                                _c("b-form-input", {
                                                  class: {
                                                    "border border-danger":
                                                      errors[0]
                                                  },
                                                  attrs: {
                                                    type: "text",
                                                    maxlength: "100"
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.template.mail_subject,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.template,
                                                        "mail_subject",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "template.mail_subject"
                                                  }
                                                })
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _vm.template.mail_subject_postfix
                                  ? _c("b-col", { attrs: { md: "2" } }, [
                                      _vm._v(
                                        "[" +
                                          _vm._s(
                                            _vm.template.mail_subject_postfix
                                          ) +
                                          "]"
                                      )
                                    ])
                                  : _vm._e()
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "b-form-row",
                              {
                                staticClass: "mb-2 p-2 justify-content-center"
                              },
                              [
                                _c("b-col", { attrs: { md: "3" } }, [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.$t("mail.mailContent")) +
                                      "\n            "
                                  ),
                                  _c("p", { staticClass: "content-rule" }, [
                                    _c("i", [
                                      _vm._v(_vm._s(_vm.$t("mail.contentRule")))
                                    ])
                                  ])
                                ]),
                                _vm._v(" "),
                                _c(
                                  "b-col",
                                  { attrs: { md: "9" } },
                                  [
                                    _c("ValidationProvider", {
                                      attrs: {
                                        mode: "passive",
                                        name: _vm.$t("mail.mailContent"),
                                        rules: "required|max:1024"
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(ref) {
                                              var errors = ref.errors
                                              return [
                                                _c("ckeditor", {
                                                  attrs: {
                                                    editor: _vm.editor,
                                                    config: _vm.editorConfig,
                                                    "tag-name": "textarea"
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.template.mail_content,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.template,
                                                        "mail_content",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "template.mail_content"
                                                  }
                                                })
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "b-form-row",
                              {
                                staticClass:
                                  "mb-2 p-2 justify-content-center align-items-center"
                              },
                              [
                                _c(
                                  "b-col",
                                  {
                                    staticClass:
                                      "d-flex align-items-center justify-content-between",
                                    attrs: { md: "3" }
                                  },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(_vm.$t("mail.isAutoSend")) +
                                        "\n          "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-col",
                                  [
                                    _c("b-form-checkbox", {
                                      attrs: { switch: "" },
                                      model: {
                                        value: _vm.template.is_auto_send,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.template,
                                            "is_auto_send",
                                            $$v
                                          )
                                        },
                                        expression: "template.is_auto_send"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.template.has_notification
                              ? [
                                  _c("hr"),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-row",
                                    {
                                      staticClass:
                                        "mb-2 p-2 justify-content-center align-items-center"
                                    },
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          staticClass:
                                            "d-flex align-items-center justify-content-between",
                                          attrs: { md: "3" }
                                        },
                                        [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(
                                                _vm.$t("mail.notificationTitle")
                                              ) +
                                              "\n            "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-col",
                                        [
                                          _c("ValidationProvider", {
                                            attrs: {
                                              mode: "passive",
                                              name: _vm.$t(
                                                "mail.notificationTitle"
                                              ),
                                              rules: "required|max:100"
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var errors = ref.errors
                                                    return [
                                                      _c("b-form-input", {
                                                        class: {
                                                          "border border-danger":
                                                            errors[0]
                                                        },
                                                        attrs: {
                                                          type: "text",
                                                          maxlength: "100"
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.template
                                                              .notification_title,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.template,
                                                              "notification_title",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "template.notification_title"
                                                        }
                                                      })
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-row",
                                    {
                                      staticClass:
                                        "mb-2 p-2 justify-content-center align-items-center"
                                    },
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          staticClass:
                                            "d-flex align-items-center justify-content-between",
                                          attrs: { md: "3" }
                                        },
                                        [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(
                                                _vm.$t("mail.isAutoPush")
                                              ) +
                                              "\n            "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-col",
                                        [
                                          _c("b-form-checkbox", {
                                            attrs: { switch: "" },
                                            model: {
                                              value: _vm.template.is_auto_push,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.template,
                                                  "is_auto_push",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "template.is_auto_push"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              : _vm._e(),
                            _vm._v(" "),
                            _c("hr"),
                            _vm._v(" "),
                            _c(
                              "b-form-group",
                              [
                                _c(
                                  "b-button",
                                  {
                                    attrs: {
                                      type: "submit",
                                      size: "lg",
                                      variant: "success"
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.$t("button.update")))]
                                )
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    3327450190
                  )
                })
              : _vm._e()
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            centered: "",
            id: _vm.routeLeaveModalId,
            title: _vm.$t("message.warning"),
            "ok-title": _vm.$t("button.ok"),
            "cancel-title": _vm.$t("button.cancel")
          },
          on: {
            ok: function($event) {
              return _vm.leave(_vm.targetRouteName)
            }
          }
        },
        [_vm._v("\n    " + _vm._s(_vm.$t("message.routeLeave")) + "\n  ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }