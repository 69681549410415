var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pagination" },
    [
      _vm.lastPage > 1
        ? [
            _c("div", { staticClass: "paginate-btn" }, [
              _vm.currentPage > 1
                ? _c(
                    "div",
                    {
                      class: {
                        "step first": true,
                        disabled: _vm.currentPage == 1
                      },
                      on: {
                        click: function($event) {
                          _vm.currentPage > 1 &&
                            _vm.changePage(_vm.currentPage - 1)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("pagination.toPreviousPage")) +
                          "\n      "
                      )
                    ]
                  )
                : _vm._e()
            ]),
            _vm._v(" "),
            _vm.currentPage > 1
              ? _c(
                  "div",
                  {
                    staticClass: "page-number",
                    on: {
                      click: function($event) {
                        return _vm.changePage(1)
                      }
                    }
                  },
                  [_vm._v("1")]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.currentPage > 4 ? _c("div", [_vm._v("…")]) : _vm._e(),
            _vm._v(" "),
            _vm._l(_vm.prevPages, function(pageNumber) {
              return _c(
                "div",
                {
                  staticClass: "page-number",
                  on: {
                    click: function($event) {
                      return _vm.changePage(pageNumber)
                    }
                  }
                },
                [_vm._v("\n      " + _vm._s(pageNumber) + "\n    ")]
              )
            }),
            _vm._v(" "),
            _c("div", { staticClass: "page-number active" }, [
              _vm._v(_vm._s(_vm.currentPage))
            ]),
            _vm._v(" "),
            _vm._l(_vm.nextPages, function(pageNumber) {
              return _c(
                "div",
                {
                  staticClass: "page-number",
                  on: {
                    click: function($event) {
                      return _vm.changePage(pageNumber)
                    }
                  }
                },
                [_vm._v("\n      " + _vm._s(pageNumber) + "\n    ")]
              )
            }),
            _vm._v(" "),
            _vm.currentPage < _vm.lastPage - 4
              ? _c("div", [_vm._v("…")])
              : _vm._e(),
            _vm._v(" "),
            _vm.currentPage < _vm.lastPage
              ? _c(
                  "div",
                  {
                    staticClass: "page-number",
                    on: {
                      click: function($event) {
                        return _vm.changePage(_vm.lastPage)
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.lastPage))]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "paginate-btn" }, [
              _vm.currentPage < _vm.lastPage
                ? _c(
                    "div",
                    {
                      class: {
                        "step last": true,
                        disabled: _vm.currentPage == _vm.lastPage
                      },
                      on: {
                        click: function($event) {
                          _vm.currentPage < _vm.lastPage &&
                            _vm.changePage(_vm.currentPage + 1)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("pagination.toNextPage")) +
                          "\n      "
                      )
                    ]
                  )
                : _vm._e()
            ])
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }