var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card" }, [
    _c(
      "div",
      { staticClass: "card-title" },
      [
        _vm.canRemove
          ? _c(
              "button",
              {
                staticClass: "btn btn-danger",
                attrs: { type: "button" },
                on: { click: _vm.removeImage }
              },
              [_c("b-icon-trash")],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c("b-form-file", {
          attrs: {
            placeholder: _vm.$t("filePicker.placeHolder"),
            "drop-placeholder": _vm.$t("filePicker.dropPlaceHolder"),
            "browse-text": _vm.$t("filePicker.browse"),
            accept: "image/*"
          },
          on: { input: _vm.imageInput },
          model: {
            value: _vm.model.content,
            callback: function($$v) {
              _vm.$set(_vm.model, "content", $$v)
            },
            expression: "model.content"
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "card-body" }, [
      _c("div", { staticClass: "img-ctn" }, [
        _c("img", { attrs: { src: _vm.imageUrl, alt: "" } })
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }