var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "auth-layout",
    [
      _c(
        "main-header",
        { attrs: { title: _vm.$t("page.category.list") } },
        [
          _c(
            "router-link",
            {
              staticClass: "btn btn-success",
              attrs: { to: { name: "CategoryCreate" } }
            },
            [_vm._v(_vm._s(_vm.$t("page.category.create")))]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "main-content fix-2" },
        [
          _c("div", { staticClass: "table-wrapper no-pagination" }, [
            _c(
              "table",
              [
                _c("thead", [
                  _c("tr", [
                    _c("th", [_vm._v(_vm._s(_vm.$t("category.id")))]),
                    _vm._v(" "),
                    _c("th", [_vm._v(_vm._s(_vm.$t("category.icon")))]),
                    _vm._v(" "),
                    _c("th", [_vm._v(_vm._s(_vm.$t("category.iconInactive")))]),
                    _vm._v(" "),
                    _c("th", [_vm._v(_vm._s(_vm.$t("category.iconWeb")))]),
                    _vm._v(" "),
                    _c("th", [_vm._v(_vm._s(_vm.$t("category.displayName")))]),
                    _vm._v(" "),
                    _c("th", [_vm._v(_vm._s(_vm.$t("category.slug")))]),
                    _vm._v(" "),
                    _c("th", [_vm._v(_vm._s(_vm.$t("category.createdAt")))]),
                    _vm._v(" "),
                    _c("th", [_vm._v(_vm._s(_vm.$t("category.active")))]),
                    _vm._v(" "),
                    _c("th"),
                    _vm._v(" "),
                    _c("th")
                  ])
                ]),
                _vm._v(" "),
                _vm._l(_vm.categories, function(item) {
                  return [
                    _c("tr", [
                      _c("td", [_vm._v(_vm._s(item.id))]),
                      _vm._v(" "),
                      _c("td", [
                        _c("img", {
                          staticClass: "contain icon-bg-purple",
                          attrs: { src: item.icon, width: "70", height: "70" }
                        })
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _c("img", {
                          staticClass: "contain",
                          attrs: {
                            src: item.icon_inactive,
                            width: "70",
                            height: "70"
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _c("img", {
                          staticClass: "contain",
                          attrs: {
                            src: item.icon_web,
                            width: "70",
                            height: "70"
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _c("td", [_vm._v(_vm._s(item.display_name))]),
                      _vm._v(" "),
                      _c("td", [_vm._v(_vm._s(item.slug))]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(
                          _vm._s(_vm._f("datetimeFormat")(item.created_at))
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        [
                          item.active
                            ? _c("b-icon-check", {
                                staticClass: "h1",
                                attrs: { variant: "success" }
                              })
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        [
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: {
                                  name: "CategoryEdit",
                                  params: { id: item.id }
                                }
                              }
                            },
                            [
                              _c("b-icon-pencil", {
                                staticClass: "action-icon",
                                attrs: { variant: "secondary" }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        [
                          _c("b-icon-trash", {
                            staticClass: "action-icon",
                            attrs: { variant: "danger" },
                            on: {
                              click: function($event) {
                                return _vm.openDeleteModal(
                                  item.id,
                                  item.display_name
                                )
                              }
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ]
                })
              ],
              2
            )
          ]),
          _vm._v(" "),
          _c("confirm-modal", {
            attrs: { id: _vm.confirmModalId, confirmText: _vm.confirmText },
            on: {
              confirmed: _vm.deleteConfirmed,
              "modal-discard": _vm.cancelModal
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }