<template>
  <auth-layout>
    <main-header :title="$t('page.userRanking.list')">
      <router-link :to="{ name: 'UserRankingAdd' }" class="btn btn-success">{{
        $t("page.userRanking.create")
      }}</router-link>
    </main-header>
    <user-ranking-navigation></user-ranking-navigation>
  </auth-layout>
</template>

<script>
import { mapActions } from "vuex";
import UserRankingNavigation from "./../../components/UserRanking/navigation/UserRankingNavigation.vue";

export default {
  components: {
    UserRankingNavigation
  },
  data: () => ({ }),
  computed: { },
  methods: {
    ...mapActions("userRanking", ["fetchList"]),
    fetchData(page) {
      this.fetchList({ page });
    },
  },
  async mounted() {
    await this.fetchData(1);
  }
};
</script>

<style lang="scss" scoped></style>
