var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    "invalid-feedback": _vm.invalidFeedback,
                    state: _vm.stateValid
                  }
                },
                [
                  _c("label", [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("sliders.date_from")) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("v-date-picker", {
                    attrs: {
                      mode: "dateTime",
                      "min-date": _vm.minDateFrom,
                      is24hr: "",
                      locale: _vm.currentLocale
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var inputValue = ref.inputValue
                          var inputEvents = ref.inputEvents
                          return [
                            _c(
                              "input",
                              _vm._g(
                                {
                                  staticClass: "form-control sliders-date",
                                  attrs: {
                                    placeholder: _vm.$t(
                                      "datePicker.placeHolder"
                                    )
                                  },
                                  domProps: { value: inputValue }
                                },
                                inputEvents
                              )
                            )
                          ]
                        }
                      }
                    ]),
                    model: {
                      value: _vm.slideDateFrom,
                      callback: function($$v) {
                        _vm.slideDateFrom = $$v
                      },
                      expression: "slideDateFrom"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    "invalid-feedback": _vm.invalidFeedback,
                    state: _vm.stateValid
                  }
                },
                [
                  _c("label", [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("sliders.date_to")) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("v-date-picker", {
                    attrs: {
                      mode: "dateTime",
                      "min-date": _vm.minDateTo,
                      is24hr: "",
                      locale: _vm.currentLocale
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var inputValue = ref.inputValue
                          var inputEvents = ref.inputEvents
                          return [
                            _c(
                              "input",
                              _vm._g(
                                {
                                  staticClass: "form-control sliders-date",
                                  attrs: {
                                    placeholder: _vm.$t(
                                      "datePicker.placeHolder"
                                    )
                                  },
                                  domProps: { value: inputValue }
                                },
                                inputEvents
                              )
                            )
                          ]
                        }
                      }
                    ]),
                    model: {
                      value: _vm.slideDateTo,
                      callback: function($$v) {
                        _vm.slideDateTo = $$v
                      },
                      expression: "slideDateTo"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }