<template>
  <div>
    <b-alert v-if="variant" :variant="variant" show dismissible>{{ message }}</b-alert>
    <b-form @submit="onSubmit" @reset="onReset">
      <b-form-group
        :label="$t('user.currentPassword')"
        label-for="current_password"
        :invalid-feedback="invalidCurrentPassword"
        :state="stateCurrentPassword"
      >
        <b-form-input
          type="password"
          id="current_password"
          v-model="model.current_password"
          :state="stateCurrentPassword"
          trim
        ></b-form-input>
      </b-form-group>

      <b-form-group
        :label="$t('auth.new_password')"
        label-for="password"
        :invalid-feedback="invalidNewPassword"
        :state="stateNewPassword"
      >
        <b-form-input
          type="password"
          id="password"
          v-model="model.password"
          :state="stateNewPassword"
          trim
        ></b-form-input>
      </b-form-group>

      <b-form-group
        :label="$t('user.passwordConfirmation')"
        label-for="password_confirmation"
        :invalid-feedback="invalidConfirmationPassword"
        :state="stateConfirmationPassword"
      >
        <b-form-input
          type="password"
          id="password_confirmation"
          v-model="model.password_confirmation"
          :state="stateConfirmationPassword"
          trim
        ></b-form-input>
      </b-form-group>

      <b-button type="submit" variant="primary">{{
        $t("button.update")
      }}</b-button>
      <b-button type="reset" variant="danger">Reset</b-button>
    </b-form>
  </div>
</template>
<script>
import axios from "../../axios";

export default {
  data: () => ({
    model: {
      current_password: null,
      password: null,
      password_confirmation: null
    },
    variant: '',
    message: '',
  }),

  computed: {
    stateCurrentPassword() {
      if (this.model.current_password === null) return null;
      if (this.model.current_password.trim().length === 0) return false;
      return true;
    },
    invalidCurrentPassword() {
      if (this.model.current_password === null) return null;
      if (this.model.current_password.trim().length === 0) {
        const msg = this.$t("validation.required");
        const key = this.$t("auth.new_password");
        return msg.replace(":attribute", key);
      }
      return null;
    },

    stateNewPassword() {
      if (this.model.password === null) return null;
      if (this.model.password.trim().length === 0) return false;
      if (
        this.model.password.trim().length < 8 ||
        this.model.password.trim().length > 16
      )
        return false;
      return true;
    },
    invalidNewPassword() {
      if (this.model.password === null) return null;
      const key = this.$t("auth.new_password");
      if (this.model.password.trim().length === 0) {
        const msg = this.$t("validation.required");
        return msg.replace(":attribute", key);
      }
      if (this.model.password.trim().length < 8) {
        const msg = this.$t("validation.min");
        return msg.replace(":attribute", key).replace(":length", 8);
      }
      if (this.model.password.trim().length > 16) {
        const msg = this.$t("validation.max");
        return msg.replace(":attribute", key).replace(":length", 16);
      }
      return null;
    },

    stateConfirmationPassword() {
      if (this.model.password_confirmation === null) return null;
      if (this.model.password_confirmation.trim().length === 0) return false;
      if (
        this.model.password_confirmation.trim().length < 8 ||
        this.model.password_confirmation.trim().length > 16
      )
        return false;
      if (
        this.model.password.trim() !== this.model.password_confirmation.trim()
      )
        return false;
      return true;
    },
    invalidConfirmationPassword() {
      if (this.model.password_confirmation === null) return null;
      const key = this.$t("auth.new_password");
      if (this.model.password_confirmation.trim().length === 0) {
        const msg = this.$t("validation.required");
        return msg.replace(":attribute", key);
      }
      if (this.model.password_confirmation.trim().length < 8) {
        const msg = this.$t("validation.min");
        return msg.replace(":attribute", key).replace(":length", 8);
      }
      if (this.model.password_confirmation.trim().length > 16) {
        const msg = this.$t("validation.max");
        return msg.replace(":attribute", key).replace(":length", 16);
      }
      if (
        this.model.password.trim() !== this.model.password_confirmation.trim()
      ) {
        const msg = this.$t("validation.not_match");
        return msg.replace(":attribute", key);
      }
      return null;
    }
  },

  methods: {
    onSubmit(event) {
      event.preventDefault();
      if (!this.stateCurrentPassword || !this.stateNewPassword || !this.stateConfirmationPassword) {
        return;
      }
      return new Promise((resolve, reject) => {
        axios.post('/api/user/change-password', this.model)
          .then(res => {
            this.variant = 'success'
            this.message = this.$t('message.successTitle')
            resolve(res)
            this.onReset(event)
          })
          .catch(err => {
            this.variant = 'danger'
            this.message = err.response.data.message
            reject(err)
          })
      });
    },
    onReset(event) {
      event.preventDefault();
      this.model.current_password = null;
      this.model.password = null;
      this.model.password_confirmation = null;
    }
  }
};
</script>

<style lang="scss" scoped></style>
