<template>
  <auth-layout>
    <main-header :title="$t('page.category.edit')">
      <router-link
        :to="{ name: 'CategoryList' }"
        class="btn btn-outline-secondary"
        >{{ $t("page.category.list") }}</router-link
      >
    </main-header>
    <div class="card">
      <div class="card-body">
        <category-form
          :errMessage="errMessage"
          :btnText="$t('button.update')"
          :defaultForm="form"
          @submit="submit"
        ></category-form>
      </div>
    </div>
    <b-modal
      centered
      :id="routeLeaveModalId"
      :title="$t('message.warning')"
      :ok-title="$t('button.ok')"
      :cancel-title="$t('button.cancel')"
      @ok="leave(targetRouteName)"
    >
      {{ $t('message.routeLeave') }}
    </b-modal>
  </auth-layout>
</template>
<script>
import CategoryForm from "../../components/Form/CategoryForm.vue";
import { mapActions } from "vuex";
import routeLeaveWarning from '../../mixins/routeLeaveWarning';

export default {
  mixins: [routeLeaveWarning],

  components: {
    CategoryForm
  },

  data: () => ({
    form: {},
    errMessage: '',
  }),

  methods: {
    ...mapActions('category', ['fetchCategory', 'update']),
    submit(formData) {
      formData.id = this.$route.params.id;
      this.update(formData)
        .then(() => this.leave("CategoryList"))
        .catch((error) => this.errMessage = error.message || this.$t('message.commonError'))
    },
  },

  async created() {
    try {
      let res = await this.fetchCategory({ id: this.$route.params.id })

      res.icon = {
        content: null,
        name: res.icon_name
      }

      res.iconInactive = {
        content: null,
        name: res.icon_inactive_name
      }

      res.iconWeb = {
        content: null,
        name: res.icon_web_name
      }

      this.form = res;
    } catch (error) {
      this.$router.go(-1);
    }
  }
}
</script>
