<template>
  <auth-layout>
    <div ref="mainHeader"></div>
    <main-header :title="$t('page.campaign.edit')">
      <router-link
        :to="{ name: 'CampaignList' }"
        class="btn btn-outline-secondary"
        >{{ $t("page.campaign.list") }}</router-link
      >
    </main-header>

    <div class="card">
      <div class="card-body">
        <campaign-form
          :errMessage="errMessage"
          :defaultForm="formData"
          :images="images"
          :submitText="$t('button.update')"
          @setErrorMessage="setErrorMessage"
          @submit="submit"
        ></campaign-form>
      </div>
    </div>
    <b-modal
      centered
      :id="routeLeaveModalId"
      :title="$t('message.warning')"
      :ok-title="$t('button.ok')"
      :cancel-title="$t('button.cancel')"
      @ok="leave(targetRouteName)"
    >
      {{ $t('message.routeLeave') }}
    </b-modal>
  </auth-layout>
</template>

<script>
import { mapActions } from "vuex";
import CampaignForm from "../../components/Form/CampaignForm.vue";
import routeLeaveWarning from '../../mixins/routeLeaveWarning';

export default {
  mixins: [routeLeaveWarning],

  components: {
    CampaignForm
  },

  data: () => ({
    formData: {},
    images: [],
    errMessage: "",
  }),

  methods: {
    ...mapActions("campaign", ["fetchCampaignById", "update"]),
    ...mapActions("rewards", ["fetchById"]),
    setErrorMessage(message) {
      this.errMessage = message;
    },

    submit(formData) {
      formData.id = this.$route.params.id;
      this.update(formData)
        .then(() => {
          this.$nextTick(() => this.$refs.mainHeader.scrollIntoView({
            behavior: "smooth",
            block: "start"
          }));
          this.$bvToast.toast(this.$t("campaign.updateSuccess"), {
            title: this.$t("message.successTitle"),
            variant: "success",
            autoHideDelay: 3000,
            solid: true
          });
        })
        .catch(error => (this.errMessage = error.message));
    }
  },

  async created() {
    try {
      const res = await this.fetchCampaignById({ id: this.$route.params.id });

      if (res.reward_id && res.reward_id.id) {
        this.fetchById({ id: res.reward_id.id })
      } else {
        this.fetchById({ id: 0 });
      }

      this.images = [...res.images];
      delete res.images;

      if (res.end_time) {
        res.end_time = new Date(res.end_time);
      }
      if (res.approve_end_time) {
        res.approve_end_time = new Date(res.approve_end_time);
      }
      if (res.post_review_time) {
        res.post_review_time = new Date(res.post_review_time);
      }
      if (res.review_end_time) {
        res.review_end_time = new Date(res.review_end_time);
      }
      if (res.closed_date) {
        res.closed_date = new Date(res.closed_date);
      }
      if (res.crawling_time) {
        res.crawling_time = new Date(res.crawling_time);
      }

      this.formData = res;
    } catch (e) {
      this.$router.go(-1);
    }
  }
};
</script>

<style lang="scss" scoped></style>
