export const ACCEPTED_LOCALES = [
  'vi',
  'en'
];
export const DEFAULT_LOCALE = 'vi';
export const TINE_MCE_API_KEY = 'ur4faycfs8r3iqoefkrktee9il6wqc1f7n36r3suoq6k37c5';
export const MAX_IMAGE_COUNT = 5;
export const RATING_STAR_TOTAL = 5;

export const REGION_FOLLOWS = [
  {
    id: 4,
    display_name: 'Hà Nội'
  },
  {
    id: 5,
    display_name: 'Hồ Chí Minh'
  },
  {
    id: 1,
    display_name: 'Miền Bắc'
  },
  {
    id: 2,
    display_name: 'Miền Trung'
  },
  {
    id: 3,
    display_name: 'Miền Nam'
  }
]
