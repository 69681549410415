import { localize, extend } from "vee-validate";
import {
  required,
  max,
  min,
  regex,
  digits,
  numeric,
  max_value,
  email,
  alpha_num,
  alpha_dash
} from "vee-validate/dist/rules";
import Vue from "vue";
import en from "vee-validate/dist/locale/en.json";
import vi from "vee-validate/dist/locale/vi.json";
import { DEFAULT_LOCALE, ACCEPTED_LOCALES } from "./constants/common";
import axios from "./axios";

// Install built-in rules.
extend("required", required);
extend("max", max);
extend("min", min);
extend("regex", regex);
extend("digits", digits);
extend("numeric", numeric);
extend("max_value", max_value);
extend("email", email);
extend("alpha_num", alpha_num);
extend("alpha_dash", alpha_dash);

localize({
  en: {
    messages: en.messages,
    names: {
      username: "Username",
      password: "Password"
    },
  },
  vi: {
    messages: vi.messages,
    names: {
      username: "Tên đăng nhập",
      password: "Mật khẩu"
    }
  }
})

let LOCALE = navigator.language;
if (!ACCEPTED_LOCALES.includes(LOCALE)) {
  LOCALE = DEFAULT_LOCALE;
}
localize(LOCALE);

// A simple get/set interface to manage our locale in components.
// This is not reactive, so don't create any computed properties/watchers off it.
Object.defineProperty(Vue.prototype, "locale", {
  get() {
    return LOCALE;
  },
  set(val) {
    LOCALE = val;
    localize(val);
    axios.defaults.headers.locale = LOCALE;
  }
});
