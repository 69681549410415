<template>
  <auth-layout>
    <div class="filter-bar">
      <b-col md="3">
        <b-input
          v-model="keyword"
          :placeholder="$t('user.memberID')"
          class="form-control"
          type="search"
          @keydown.enter="fetchData"
        ></b-input>
      </b-col>

      <b-col md="1">
        <b-button variant="primary" @click="fetchData" :disabled="!keyword"><b-icon-search></b-icon-search></b-button>
      </b-col>

      <b-col md="8" class="d-flex justify-content-end">
        <b-button variant="success" @click="exportData" :disabled="!canClickExport">{{ $t('button.exportUserReport') }}</b-button>
      </b-col>
    </div>
    <div class="main-content">
      <div class="table-wrapper">
        <table>
          <thead>
            <tr>
              <th>{{ $t("user.id") }}</th>
              <th>{{ $t("user.avatar") }}</th>
              <th>{{ $t("user.fullname") }}</th>
              <th>{{ $t("user.email") }}</th>
              <th>{{ $t("user.memberID") }}</th>
              <th>{{ $t("user.rank") }}</th>
              <th>{{ $t("user.createdAt") }}</th>
              <th>
                <input type="checkbox" v-model="selectAll">
              </th>
              <th>{{ $t("user.instagramCrawlingTime") }}</th>
              <th>{{ $t("user.tiktokCrawlingTime") }}</th>              
              <th>
              </th>
            </tr>
          </thead>
          <template v-for="item in users">
            <tr>
              <td>{{ item.id }}</td>
              <td>
                <div class="avatar-rounded">
                  <a :href="'/users/' + item.id + '/detail'">
                    <img :src="item.avatar_url" :alt="item.fullname" />
                  </a>
                </div>
              </td>
              <td>{{ item.fullname }}</td>
              <td>{{ item.email }}</td>
              <td>{{ item.member_id }}</td>
              <td>{{ item.rank_name }}</td>
              <td>{{ item.created_at | datetimeFormat }}</td>
              <td class="px-3">
                <input type="checkbox" :value="item.id" v-model="selected">
              </td>
              <td>{{ item.instagram_crawling_time | datetimeFormat }}</td>
              <td>{{ item.tiktok_crawling_time | datetimeFormat }}</td>
              <td>
                <b-button variant="primary" @click="updateSNS(item.id)">{{ $t('button.updateSNS') }}</b-button>
              </td>
            </tr>
          </template>
          <template v-if="searching && users.length == 0">
            <tr>
              <td colspan="8" class="text-center border-0">{{ $t('message.notFound', { item: $t('item.user') }) }}</td>
            </tr>
          </template>
        </table>
      </div>
    </div>
  </auth-layout>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {

  data: () => ({
    keyword: '',
    users: [],
    selected: [],
    searching: false
  }),

  computed: {
    canClickExport() {
      return this.selected.length > 0
    },
    selectAll: {
      get() {
        return this.selected.length === this.users.length
      },
      set(val) {
        var selected = []
        if (val) {
          selected.push(...this.users.map(el => el.id))
        }
        this.selected = selected
      }
    }
  },

  methods: {
    ...mapActions("report", ["fetchUsers", "downloadMemberData"]),
    ...mapActions("user", ["refreshSocialInfo"]),
    async fetchData() {
      let { keyword } = this;

      if (!keyword) return

      this.selected.length = 0
      this.users = await this.fetchUsers({ keyword });
      this.searching = true
    },

    exportData() {
      this.downloadMemberData({ ids: this.selected })
    },

    async updateSNS(id) {
      this.refreshSocialInfo({id})
        .then(({ instagram, tiktok }) => {
          let instagramMsg = this.$t("message.refreshInstagramInfoSuccess")
          let instagramVariant = 'success'

          if (instagram.error) {
            instagramVariant = 'danger'
            if (instagram.error == 'sns_not_found') {
              instagramVariant = 'warning'
              instagramMsg = this.$t("message.userHaveNoSNSInfo", { sns: "Instagram" })
            } else {
              instagramMsg = this.$t("message.refreshInstagramInfoFail")
            }
          }

          if (instagramVariant === 'success') {
            this.updateCrawlingTime({...instagram, type: 'instagram'})
          }

          this.$bvToast.toast(instagramMsg, {
            title: instagramVariant === 'success' ? this.$t("message.successTitle") : instagramVariant === 'danger' ? this.$t("message.errorTitle") : '',
            variant: instagramVariant,
            autoHideDelay: 3000,
            solid: true,
          });

          let tiktokMsg = this.$t("message.refreshTikTokInfoSuccess")
          let tiktokVariant = 'success'

          if (tiktok.error) {
            tiktokVariant = 'danger'
            if (tiktok.error == 'sns_not_found') {
              tiktokVariant = 'warning'
              tiktokMsg = this.$t("message.userHaveNoSNSInfo", { sns: "TikTok" })
            } else {
              tiktokMsg = this.$t("message.refreshTikTokInfoFail")
            }
          }

          if (tiktokVariant === 'success') {
            this.updateCrawlingTime({...tiktok, type: 'tiktok'})
          }

          this.$bvToast.toast(tiktokMsg, {
            title: tiktokVariant === 'success' ? this.$t("message.successTitle") : tiktokVariant === 'danger' ? this.$t("message.errorTitle") : '',
            variant: tiktokVariant,
            autoHideDelay: 3000,
            solid: true
          });
        })
        .catch((error) => {
          this.$bvToast.toast(error.message || this.$t("message.refreshSNSInfoFail"), {
            title: this.$t("message.errorTitle"),
            variant: "danger",
            autoHideDelay: 3000,
            solid: true
          });
        })
    },

    async updateCrawlingTime(payload) {
      let index = await this.users.findIndex(el => el.id === payload.user_id)

      if (index == -1) return

      if (payload.type === 'instagram') {
        this.users[index].instagram_crawling_time = payload.new_crawling_time
      }
      if (payload.type === 'tiktok') {
        this.users[index].tiktok_crawling_time = payload.new_crawling_time
      }
    }
  },

  async created() {
    this.users = await this.fetchUsers({ keyword: '' });
  },
};
</script>

<style lang="scss" scoped>
.main-content {
  height: calc(100% - 60px);
}
</style>
