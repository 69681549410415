var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-form-group",
        {
          attrs: {
            "invalid-feedback": _vm.invalidFeedback,
            state: _vm.stateValid
          }
        },
        [
          _c("label", [
            _vm._v("\n      " + _vm._s(_vm.$t("sliders.url")) + "\n    ")
          ]),
          _vm._v(" "),
          _c("b-form-input", {
            attrs: { state: _vm.stateValid, required: "", trim: "" },
            model: {
              value: _vm.slidersUrl,
              callback: function($$v) {
                _vm.slidersUrl = $$v
              },
              expression: "slidersUrl"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }