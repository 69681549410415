<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div class="img-ctn">
          <img :src="form.avatar.url" alt="avatar" />
        </div>
      </div>
    </div>
    <table class="table table-borderless user-info">
      <tr>
        <td>{{ $t("user.fullname") }}</td>
        <td>{{ form.fullname }}</td>
      </tr>
      <tr>
        <td>{{ $t("user.email") }}</td>
        <td>{{ form.email }}</td>
      </tr>
      <tr>
        <td>{{ $t("user.tel") }}</td>
        <td>{{ form.tel }}</td>
      </tr>
      <tr>
        <td>{{ $t("user.birthday") }}</td>
        <td>{{ form.birthday }}</td>
      </tr>
      <tr>
        <td>{{ $t("user.gender") }}</td>
        <td>{{ form.gender ? form.gender.display_name : "" }}</td>
      </tr>
      <tr>
        <td>{{ $t("user.city") }}</td>
        <td>{{ form.city_id ? form.city_id.name : "" }}</td>
      </tr>
      <tr>
        <td>{{ $t("user.district") }}</td>
        <td>{{ form.district_id ? form.district_id.name : "" }}</td>
      </tr>
      <tr>
        <td>{{ $t("user.ward") }}</td>
        <td>{{ form.ward_id ? form.ward_id.name : "" }}</td>
      </tr>
      <tr>
        <td>{{ $t("user.address") }}</td>
        <td>{{ form.address }}</td>
      </tr>
      <tr>
        <td>{{ $t("user.rank") }}</td>
        <td>{{ form.rank_id ? form.rank_id.ranking_name : "" }}</td>
      </tr>
      <!-- Facebook account -->
      <tr>
        <td>{{ $t("user.facebookAccount") }}</td>
        <td>
          <template v-if="form.facebook_account">
            <a :href="form.facebook_account.url" target="_blank" rel="noopener noreferrer">{{ form.facebook_account.url }}</a>
          </template>
        </td>
      </tr>
      <tr>
        <td>{{ $t("user.facebookFollower") }}</td>
        <td>{{ form.facebook_account ? form.facebook_account.number_friend : "" }}</td>
      </tr>
      <!-- Facebook account -->

      <!-- Instagram account -->
      <tr>
        <td>{{ $t("user.instagramAccount") }}</td>
        <td>
          <template v-if="form.instagram_account">
            <a :href="form.instagram_account.url" target="_blank" rel="noopener noreferrer">{{ form.instagram_account.url }}</a>
          </template>
        </td>
      </tr>
      <tr>
        <td>{{ $t("user.instagramFollower") }}</td>
        <td>{{ form.instagram_account ? form.instagram_account.number_friend : "" }}</td>
      </tr>
      <!-- Instagram account -->

      <!-- TikTok account -->
      <tr>
        <td>{{ $t("user.tiktokAccount") }}</td>
        <td>
          <template v-if="form.tiktok_account">
            <a :href="form.tiktok_account.url" target="_blank" rel="noopener noreferrer">{{ form.tiktok_account.url }}</a>
          </template>
        </td>
      </tr>
      <tr>
        <td>{{ $t("user.tiktokFollower") }}</td>
        <td>{{ form.tiktok_account ? form.tiktok_account.number_friend : "" }}</td>
      </tr>
      <!-- TikTok account -->

      <tr>
        <td>{{ $t("user.maritalStatus") }}</td>
        <td>
          {{ form.marital_status ? form.marital_status.display_name : "" }}
        </td>
      </tr>
      <tr>
        <td>{{ $t("user.boyCount") }}</td>
        <td>{{ form.boy_count }}</td>
      </tr>
      <tr>
        <td>{{ $t("user.girlCount") }}</td>
        <td>{{ form.girl_count }}</td>
      </tr>
      <tr>
        <td>{{ $t("user.skinType") }}</td>
        <td>{{ form.skin_type ? form.skin_type.display_name : "" }}</td>
      </tr>
      <tr>
        <td>{{ $t("user.pets") }}</td>
        <td>{{ formatPets(form.pets) }}</td>
      </tr>
      <tr>
        <td>{{ $t("user.shirtSize") }}</td>
        <td>{{ form.shirt_size ? form.shirt_size.name : "" }}</td>
      </tr>
      <tr>
        <td>{{ $t("user.pantsSize") }}</td>
        <td>{{ form.pants_size }}</td>
      </tr>
      <tr>
        <td>{{ $t("user.shoeSize") }}</td>
        <td>{{ form.shoe_size }}</td>
      </tr>
      <tr>
        <td>{{ $t("user.profileFinishDate") }}</td>
        <td>{{ form.profile_finish_date | datetimeFormatVN }}</td>
      </tr>
      <tr>
        <td>{{ $t("user.joinAt") }}</td>
        <td>{{ form.created_at | datetimeFormatVN }}</td>
      </tr>

      <tr>
        <td>{{ $t("user.favorite") }}</td>
        <td>{{ form.favorites_text || '' }}</td>
      </tr>

      <tr>
        <td>{{ $t("user.mostFollowerArea") }} {{ $t("user.shortMostFollower") }}</td>
        <td>{{ regionsFollow }}</td>
      </tr>
      <tr>
        <td>{{ $t("user.mostFollowerGender") }} {{ $t("user.shortMostFollower") }}</td>
        <td>{{ genderFollow }}</td>
      </tr>
      <tr>
        <td>{{ $t("user.totalPoint") }}</td>
        <td>{{ totalPoint }}</td>
      </tr>
      <tr>
        <td>{{ $t("user.percentProfile") }}</td>
        <td>{{ percentProfile }}</td>
      </tr>
    </table>

    <label class="col-form-label-lg">{{ $t("user.bankInfo") }}</label>
    <table class="table table-borderless user-info">
      <tr>
        <td>{{ $t("user.bankName") }}</td>
        <td>{{ form.bank_name }}</td>
      </tr>
      <tr>
        <td>{{ $t("user.bankBranch") }}</td>
        <td>{{ form.bank_branch_name }}</td>
      </tr>
      <tr>
        <td>{{ $t("user.bankHolder") }}</td>
        <td>{{ form.bank_account_holders }}</td>
      </tr>
      <tr>
        <td>{{ $t("user.bankNumber") }}</td>
        <td>{{ form.bank_account_number }}</td>
      </tr>
    </table>

    <campaign-list :userId="defaultForm.id"></campaign-list>
    <friend-list></friend-list>
    <point-list></point-list>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import CampaignList from "./CampaignList"
import FriendList from "./FriendList"
import PointList from "./PointList"
import { REGION_FOLLOWS } from "../../../constants/common"
import { GENDER_FEMALE, GENDER_MALE, GENDER_OTHER } from '../../../constants/user'

export default {
  props: {
    defaultForm: {
      type: Object,
      default: () => ({})
    }
  },

  components: {
    CampaignList,
    FriendList,
    PointList,
  },

  data: () => ({
    dataStructure: {
      fullname: "",
      tel: null,
      email: "",
      password: "",
      birthday: null,
      avatar: {
        id: null,
        url: null,
        content: null,
        isDirty: false
      },
      gender: null,
      address: null,
      city_id: null,
      district_id: null,
      ward_id: null,
      marital_status: null,
      boy_count: null,
      girl_count: null,
      skin_type: null,
      pets: [],
      shirt_size: null,
      pants_size: null,
      shoe_size: null,
      rank_id: null,
      profile_finish_date: null,
      created_at: null
    },
  }),

  computed: {
    ...mapState('user', ['detail']),
    form() {
      return Object.assign(this.dataStructure, this.defaultForm);
    },

    regionsFollow() {
      let index = REGION_FOLLOWS.findIndex(i => i.id == this.form.regions_follow);

      if (index != -1) return REGION_FOLLOWS[index].display_name

      return ''
    },

    genderFollow() {
      let gender = this.form.gender_follow || null;

      if (gender == GENDER_MALE) return this.$t("user.male")

      if (gender == GENDER_FEMALE) return this.$t("user.female")

      if (gender == GENDER_OTHER) return this.$t("user.genderOther")

      return '';
    },
    totalPoint() {
      const pointHistories = this.detail.pointHistories;
      if (pointHistories) {
        return pointHistories.reduce((total, item) => {
          return total + Number(item.points);
        }, 0);
      }
      return 0;
    },
    percentProfile() {
      let percent = 0;
      const percentProfile = this.detail.percentProfile;
      if (percentProfile) {
        percent = percentProfile.profile_percent;
      }
      return `${percent}%`;
    },
  },

  methods: {
    formatPets(pets) {
      let result = "";
      if (pets) {
        result = pets
          .map(item => {
            return item.display_name;
          })
          .join(", ");
      }
      return result;
    }
  },
};
</script>

<style lang="scss" scoped>
.card {
  .card-title {
    display: flex;
    justify-content: space-between;
  }
}
.card-body {
  padding: 0;
  .img-ctn {
    width: 100%;
    height: 200px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
.user-info tr td:first-child {
  width: 25%;
  font-weight: 700;
  color: #726f6f;
}
</style>
