<template>
  <div class="main-content fix-3">
    <div class="table-wrapper">
      <table>
        <thead>
          <tr>
            <th>{{ $t("merchants.id") }}</th>
            <th>{{ $t("merchants.branch_name") }}</th>
            <th class="text-center">{{ $t("merchants.service_type") }}</th>
            <th class="text-center">{{ $t("merchants.tel") }}</th>
            <th class="text-center">{{ $t("merchants.email") }}</th>
            <th class="text-center">{{ $t("merchants.primaryMarket") }}</th>
            <th></th>
          </tr>
        </thead>
        <template v-for="item in listMerchants">
          <tr :key="item.id">
            <td>{{ item.id }}</td>
            <td>{{ item.branch_name }}</td>
            <td class="text-center">{{ item.service_type }}</td>
            <td class="text-center">{{ item.tel }}</td>
            <td class="text-center">{{ item.email }}</td>
            <td class="text-center">{{ formatPrimaryMarket(item.primary_market) }}</td>
            <td>
              <b-icon-trash
                variant="danger"
                class="action-icon"
                @click="openDeleteModal(item.id)"
              ></b-icon-trash>
            </td>
          </tr>
        </template>
        <template v-if="listMerchants.length == 0">
          <tr>
            <td colspan="7" class="text-center border-0">{{ $t('message.notFound', { item: $t('item.merchants') }) }}</td>
          </tr>
        </template>
      </table>
    </div>
    <pagination-bar
      :currentPage="currentPage"
      :lastPage="lastPage"
      :canNext="canNext"
      :canPrev="canPrev"
      @change-page="fetchData"
    ></pagination-bar>
    <confirm-modal
      :id="confirmModalId"
      :confirmText="confirmText"
      @confirmed="onDelete"
      @modal-discard="cancelModal"
    ></confirm-modal>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    branchName: {
      type: String,
      required: true
    },
    serviceType: {
      type: String,
      required: true
    },
    selectedPrimaryMarkets: null,
  },
  data() {
    return {
      confirmModalId: "merchants-delete-modal",
      deleteId: null,
    };
  },
  computed: {
    listMerchants() {
      return this.$store.getters["merchants/listMerchants"];
    },
    pageObject() {
      return this.$store.getters["merchants/pageObject"];
    },
    currentPage() {
      return this.pageObject.currentPage;
    },
    lastPage() {
      return this.pageObject.lastPage;
    },
    canNext() {
      return this.pageObject.canNext;
    },
    canPrev() {
      return this.pageObject.canPrev;
    },
    confirmText() {
      return this.$t("rewards.deleteConfirm");
    },
  },
  methods: {
    ...mapActions("merchants", ["fetchList", "destroy"]),
    fetchData(page) {
      this.fetchList({
        primary_markets: this.selectedPrimaryMarkets,
        branch_name: this.branchName,
        service_type: this.serviceType,
        page
      });
    },
    openDeleteModal(id) {
      this.deleteId = id;
      this.$bvModal.show(this.confirmModalId);
    },
    cancelModal() {
      this.deleteId = null;
    },
    onDelete() {
      this.destroy({ id: this.deleteId })
        .then(() => {
          this.$bvToast.toast(this.$t("message.commonSuccess"), {
            title: this.$t("message.successTitle"),
            variant: "success",
            autoHideDelay: 3000,
            solid: true
          });
          this.fetchData(this.pageObject.currentPage)
        })
        .catch(data => {
          this.$bvToast.toast(data.message || this.$t("message.commonError"), {
            title: this.$t("message.errorTitle"),
            variant: "danger",
            autoHideDelay: 3000,
            solid: true
          });
        });
    },
    formatPrimaryMarket(primaryMarket) {
      let result = [];
      let option = this.$t("merchants.primaryMarketOption");
      primaryMarket = primaryMarket ? JSON.parse(primaryMarket) : [];
      if (Array.isArray(primaryMarket)) {
        primaryMarket.forEach(item => {
          let primaryMarketOption = option.find(data => data.id === item)
          result.push(primaryMarketOption.value);
        })
      } else {
        let primaryMarketOption = option.find(data => data.id === primaryMarket)
        result.push(primaryMarketOption.value);
      }
      return result.join(", ");
    }
  }
};
</script>
