var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ValidationObserver", {
    ref: "observer",
    attrs: { tag: "form", mode: "passive" },
    on: {
      submit: function($event) {
        $event.preventDefault()
        return _vm.submit.apply(null, arguments)
      }
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var invalid = ref.invalid
          var errors = ref.errors
          return [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: invalid || _vm.errMessage,
                    expression: "invalid || errMessage"
                  }
                ],
                ref: "errorDiv",
                staticClass: "text-danger d-flex justify-content-center mt-1"
              },
              [
                _c(
                  "ul",
                  [
                    _vm._l(errors, function(error) {
                      return [
                        error[0]
                          ? _c("li", [_vm._v(_vm._s(error[0]))])
                          : _vm._e()
                      ]
                    }),
                    _vm._v(" "),
                    _vm.errMessage
                      ? _c("li", [_vm._v(_vm._s(_vm.errMessage))])
                      : _vm._e()
                  ],
                  2
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "b-form-row",
              {
                staticClass:
                  "mb-2 p-2 justify-content-center align-items-center"
              },
              [
                _c(
                  "b-col",
                  {
                    staticClass:
                      "d-flex align-items-center justify-content-between mr-2",
                    attrs: { md: "3" }
                  },
                  [
                    _vm._v(
                      "\n      " +
                        _vm._s(_vm.$t("category.displayName")) +
                        "\n      "
                    ),
                    _c("span", { staticClass: "text-danger small" }, [
                      _vm._v(_vm._s(_vm.$t("subText.required")))
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "b-col",
                  { attrs: { md: "5" } },
                  [
                    _c("ValidationProvider", {
                      attrs: {
                        mode: "passive",
                        name: _vm.$t("category.displayName"),
                        rules: "required|max:100"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(ref) {
                              var errors = ref.errors
                              return [
                                _c("b-form-input", {
                                  class: {
                                    "border border-danger": errors[0]
                                  },
                                  attrs: { type: "text", maxlength: "100" },
                                  model: {
                                    value: _vm.form.display_name,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "display_name", $$v)
                                    },
                                    expression: "form.display_name"
                                  }
                                })
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-form-row",
              {
                staticClass:
                  "mb-2 p-2 justify-content-center align-items-center"
              },
              [
                _c(
                  "b-col",
                  {
                    staticClass:
                      "d-flex align-items-center justify-content-between mr-2",
                    attrs: { md: "3" }
                  },
                  [
                    _vm._v(
                      "\n      " + _vm._s(_vm.$t("category.icon")) + "\n      "
                    ),
                    _c("span", { staticClass: "text-danger small" }, [
                      _vm._v(_vm._s(_vm.$t("subText.required")))
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "b-col",
                  { attrs: { md: "5" } },
                  [
                    _c("b-form-file", {
                      attrs: {
                        placeholder:
                          _vm.iconName || _vm.$t("filePicker.placeHolder"),
                        "drop-placeholder": _vm.$t(
                          "filePicker.dropPlaceHolder"
                        ),
                        "browse-text": _vm.$t("filePicker.browse"),
                        accept: "image/*"
                      },
                      model: {
                        value: _vm.form.icon.content,
                        callback: function($$v) {
                          _vm.$set(_vm.form.icon, "content", $$v)
                        },
                        expression: "form.icon.content"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-form-row",
              {
                staticClass:
                  "mb-2 p-2 justify-content-center align-items-center"
              },
              [
                _c(
                  "b-col",
                  {
                    staticClass:
                      "d-flex align-items-center justify-content-between mr-2",
                    attrs: { md: "3" }
                  },
                  [
                    _vm._v(
                      "\n      " +
                        _vm._s(_vm.$t("category.iconWeb")) +
                        "\n      "
                    ),
                    _c("span", { staticClass: "text-danger small" }, [
                      _vm._v(_vm._s(_vm.$t("subText.required")))
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "b-col",
                  { attrs: { md: "5" } },
                  [
                    _c("b-form-file", {
                      attrs: {
                        placeholder:
                          _vm.iconWebName || _vm.$t("filePicker.placeHolder"),
                        "drop-placeholder": _vm.$t(
                          "filePicker.dropPlaceHolder"
                        ),
                        "browse-text": _vm.$t("filePicker.browse"),
                        accept: "image/*"
                      },
                      model: {
                        value: _vm.form.iconWeb.content,
                        callback: function($$v) {
                          _vm.$set(_vm.form.iconWeb, "content", $$v)
                        },
                        expression: "form.iconWeb.content"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-form-row",
              {
                staticClass:
                  "mb-2 p-2 justify-content-center align-items-center"
              },
              [
                _c(
                  "b-col",
                  {
                    staticClass:
                      "d-flex align-items-center justify-content-between mr-2",
                    attrs: { md: "3" }
                  },
                  [
                    _vm._v(
                      "\n      " +
                        _vm._s(_vm.$t("category.iconInactive")) +
                        "\n      "
                    ),
                    _c("span", { staticClass: "text-danger small" }, [
                      _vm._v(_vm._s(_vm.$t("subText.required")))
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "b-col",
                  { attrs: { md: "5" } },
                  [
                    _c("b-form-file", {
                      attrs: {
                        placeholder:
                          _vm.iconInactiveName ||
                          _vm.$t("filePicker.placeHolder"),
                        "drop-placeholder": _vm.$t(
                          "filePicker.dropPlaceHolder"
                        ),
                        "browse-text": _vm.$t("filePicker.browse"),
                        accept: "image/*"
                      },
                      model: {
                        value: _vm.form.iconInactive.content,
                        callback: function($$v) {
                          _vm.$set(_vm.form.iconInactive, "content", $$v)
                        },
                        expression: "form.iconInactive.content"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-form-row",
              {
                staticClass:
                  "mb-2 p-2 justify-content-center align-items-center"
              },
              [
                _c(
                  "b-col",
                  {
                    staticClass:
                      "d-flex align-items-center justify-content-between mr-2",
                    attrs: { md: "3" }
                  },
                  [
                    _vm._v(
                      "\n      " + _vm._s(_vm.$t("category.slug")) + "\n      "
                    ),
                    _c("span", { staticClass: "text-danger small" }, [
                      _vm._v(_vm._s(_vm.$t("subText.required")))
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "b-col",
                  { attrs: { md: "5" } },
                  [
                    _c("ValidationProvider", {
                      attrs: {
                        mode: "passive",
                        name: _vm.$t("category.slug"),
                        rules: "required|max:128|alpha_dash"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(ref) {
                              var errors = ref.errors
                              return [
                                _c("b-form-input", {
                                  class: {
                                    "border border-danger": errors[0]
                                  },
                                  attrs: { type: "text", maxlength: "128" },
                                  model: {
                                    value: _vm.form.slug,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "slug", $$v)
                                    },
                                    expression: "form.slug"
                                  }
                                })
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-form-row",
              {
                staticClass:
                  "mb-2 p-2 justify-content-center align-items-center"
              },
              [
                _c(
                  "b-col",
                  {
                    staticClass:
                      "d-flex align-items-center justify-content-between mr-2",
                    attrs: { md: "3" }
                  },
                  [
                    _vm._v(
                      "\n      " +
                        _vm._s(_vm.$t("category.active")) +
                        "\n      "
                    ),
                    _c("span", { staticClass: "text-danger small" }, [
                      _vm._v(_vm._s(_vm.$t("subText.required")))
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "b-col",
                  { attrs: { md: "5" } },
                  [
                    _c("b-form-radio-group", {
                      attrs: { options: _vm.activeOptions },
                      model: {
                        value: _vm.form.active,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "active", $$v)
                        },
                        expression: "form.active"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c(
              "b-form-group",
              [
                _c(
                  "b-button",
                  { attrs: { type: "submit", size: "lg", variant: "success" } },
                  [_vm._v(_vm._s(_vm.btnText))]
                )
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }