<template>
  <div class="card">
    <div class="card-title">
      <button
        v-if="canRemove"
        class="btn btn-danger"
        type="button"
        @click="removeImage"
      >
        <b-icon-trash></b-icon-trash>
      </button>
      <b-form-file
        v-model="model.content"
        :placeholder="$t('filePicker.placeHolder')"
        :drop-placeholder="$t('filePicker.dropPlaceHolder')"
        :browse-text="$t('filePicker.browse')"
        accept="image/*"
        @input="imageInput"
      ></b-form-file>
    </div>
    <div class="card-body">
      <div class="img-ctn">
        <img :src="imageUrl" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: ""
    },
    data: {
      type: Object,
      default: () => ({
        id: null,
        content: null,
        isDirty: false,
        url: null,
      })
    },
    index: {
      type: Number,
      default: null
    },
    canRemove: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    defaultModel: {
      id: null,
      content: null,
      url: null,
      isDirty: false
    }
  }),
  computed: {
    model: {
      get() {
        return Object.assign(this.defaultModel, this.data);
      },
      set(newModel) {
        this.model = newModel;
      }
    },
    imageUrl() {
      if (this.model.content) {
        return URL.createObjectURL(this.model.content);
      }

      if (this.model.url) {
        return this.model.url;
      }

      return "/images/img_plHolder.png";
    }
  },
  methods: {
    removeImage() {
      this.$emit("image-removal", {
        id: this.model.id,
        index: this.index
      });
    },
    imageInput() {
      this.model.isDirty = true;
      this.$emit("image-input", {
        index: this.index,
        data: this.model
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.card {
  .card-title {
    display: flex;
    justify-content: space-between;
  }
}
.card-body {
  padding: 0;
  .img-ctn {
    width: 100%;
    height: 200px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
</style>
