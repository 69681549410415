<template>
  <b-form-group>
    <label class="only-member__title">{{ $t("news.public") }}</label>
    <b-form-checkbox
      v-model="itemSelected"
      name="check-button"
      switch
    ></b-form-checkbox>
  </b-form-group>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      checked: true
    };
  },
  computed: {
    newsData() {
      return this.$store.getters["news/newsData"];
    },
    itemSelected: {
      get() {
        return this.newsData && this.newsData.public
          ? Boolean(this.newsData.public)
          : false;
      },
      set(value) {
        this.changeAttributesData({
          key: "public",
          value: Number(value)
        });
      }
    }
  },
  methods: {
    ...mapActions("news", ["changeAttributesData"])
  }
};
</script>

<style lang="scss" scoped>
.only-member__title {
  padding-right: 1rem;
}
.custom-control.custom-switch {
  display: inline-block;
}
</style>
