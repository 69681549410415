import axios from "../../axios";

export default {
  namespaced: true,
  state: {
    templates: [],
  },
  mutations: {
    setTemplates(state, templates) {
      if (Array.isArray(templates)) {
        state.templates.length = 0;
        state.templates = [...templates];
      }
    },
  },
  actions: {
    fetchTemplates({commit}) {
      return new Promise((resolve, reject) => {
        axios.get('/api/templates')
          .then(({data}) => {
            commit('setTemplates', data);
            resolve();
          })
          .catch(() => reject())
      });
    },

    retrieve({}, payload) {
      const { id } = payload;
      return new Promise((resolve, reject) => {
        axios.get(`/api/templates/${id}`)
          .then(({data}) => resolve(data))
          .catch(() => reject())
      })
    },

    update({}, payload) {
      return new Promise((resolve, reject) => {
        const { id } = payload;
        axios.put(`/api/templates/${id}`, payload)
          .then(() => resolve())
          .catch(({response}) => reject(response.data))
      });
    },
  },
};
