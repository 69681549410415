var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("label", { staticClass: "col-form-label-lg" }, [
      _vm._v(_vm._s(_vm.$t("user.points.list")))
    ]),
    _vm._v(" "),
    _c("table", { staticClass: "table table-borderless user-info" }, [
      _c("tr", [
        _c("td", [_vm._v(_vm._s(_vm.$t("user.points.sum")))]),
        _vm._v(" "),
        _c("td", [_vm._v(_vm._s(_vm.userPointSum))])
      ])
    ]),
    _vm._v(" "),
    _c("table", { staticClass: "table" }, [
      _c("thead", [
        _c("tr", [
          _c("th", { attrs: { scope: "col" } }, [
            _vm._v(_vm._s(_vm.$t("user.points.no")))
          ]),
          _vm._v(" "),
          _c("th", { attrs: { scope: "col" } }, [
            _vm._v(_vm._s(_vm.$t("user.points.type")))
          ]),
          _vm._v(" "),
          _c("th", { attrs: { scope: "col" } }, [
            _vm._v(_vm._s(_vm.$t("user.points.point")))
          ]),
          _vm._v(" "),
          _c("th", { attrs: { scope: "col" } }, [
            _vm._v(_vm._s(_vm.$t("user.points.relation_object")))
          ]),
          _vm._v(" "),
          _c("th", { attrs: { scope: "col" } }, [
            _vm._v(_vm._s(_vm.$t("user.points.date")))
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "tbody",
        [
          _vm.userPointHistories.length > 0
            ? _vm._l(_vm.userPointHistories, function(userPointHistory, index) {
                return _c("tr", { key: index }, [
                  _c("td", [_vm._v(" " + _vm._s(index + 1) + " ")]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.formatPointType(userPointHistory.type)) +
                        " "
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.formatPoints(userPointHistory.points)) +
                        " "
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.showName(userPointHistory, true)) + ": "
                      )
                    ]),
                    _vm._v(" "),
                    userPointHistory.related_id
                      ? _c(
                          "a",
                          {
                            attrs: { href: "javascript:;" },
                            on: {
                              click: function($event) {
                                return _vm.openDetail(userPointHistory)
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.showName(userPointHistory)) +
                                "\n            "
                            )
                          ]
                        )
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.formatDatetime(userPointHistory.created_at)
                        ) +
                        " "
                    )
                  ])
                ])
              })
            : [
                _c("tr", { staticClass: "text-center" }, [
                  _c("td", { attrs: { colspan: "6" } }, [
                    _vm._v(_vm._s(_vm.$t("user.points.no_point")))
                  ])
                ])
              ]
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }