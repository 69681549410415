<template>
  <div class="form-group">
    <button
      type="button"
      class="btn btn-lg btn-success"
      :disabled="!canSave"
      @click="onSave()"
    >
      {{ $t("button.add") }}
    </button>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";
import ValidateHandler from './../../../../mixins/ValidateHandler'

export default {
  mixins: [ValidateHandler],
  data() {
    return {
      canSave: false
    };
  },
  computed: {
    slidersData() {
      return this.$store.getters["sliders/slidersData"];
    }
  },
  watch: {
    slidersData: {
      handler: function(val, oldVal) {
        this.canSave = this.isValid();
      },
      deep: true
    },
  },
  methods: {
    ...mapActions("sliders", ["store"]),
    isValid() {
      const resultValidateURL = this.slidersData.url ? this.validateURL(this.slidersData.url) : true;
      return (
        this.slidersData.name &&
        this.slidersData.image &&
        (this.slidersData.date_from <= this.slidersData.date_to) &&
        resultValidateURL
      );
    },
    onSave() {
      const formData = new FormData();
      formData.append('name', this.slidersData.name);
      formData.append('image', this.slidersData.image);
      if (this.slidersData.url) {
        formData.append('url', this.slidersData.url);
      }
      if (this.slidersData.description) {
        formData.append('description', this.slidersData.description);
      }
      if (this.slidersData.date_from) {
        let dateFrom = moment(this.slidersData.date_from);
        formData.append('date_from', dateFrom.utc().format());
      }
      if (this.slidersData.date_to) {
        let dateTo = moment(this.slidersData.date_to);
        formData.append('date_to', dateTo.utc().format());
      }
      if (this.slidersData.slide_order) {
        formData.append('slide_order', this.slidersData.slide_order);
      }

      this.store(formData)
        .then(() => this.$emit('leave', "SlidersNavigation"))
        .catch((error) => {
          this.$bvToast.toast(error.message || this.$t("message.commonError"), {
            title: this.$t("message.errorTitle"),
            variant: "danger",
            autoHideDelay: 3000,
            solid: true
          });
        });
    }
  }
};
</script>
