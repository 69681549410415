<template>
  <auth-layout>
    <main-header :title="$t('page.userCampaign.list', { name: campaignName })">
      <div class="d-flex">
        <router-link
          :to="{ name: 'CampaignList' }"
          class="btn btn-outline-secondary"
          >{{ $t("page.campaign.list") }}</router-link
        >
      </div>
    </main-header>
    <div class="filter-bar justify-content-end">
      <b-button variant="primary" @click="download">{{ $t('button.exportExcel') }}</b-button>
      <b-button class="ml-2" variant="primary" @click="downloadReview">{{ $t('button.exportReviewExcel') }}</b-button>
      <router-link :to="{ name: 'UserCampaignCreate', params: { id } }" class="btn btn-success ml-2">{{
        $t("page.userCampaign.create")
      }}</router-link>
    </div>

    <div class="main-content has-filter-bar">
      <div class="table-wrapper">
        <table>
          <thead>
            <tr>
              <th>{{ $t("user.id") }}</th>
              <th>{{ $t("user.avatar") }}</th>
              <th>{{ $t("user.fullname") }}</th>
              <th>{{ $t("user.email") }}</th>
              <th>{{ $t("user.tel") }}</th>
              <th>{{ $t("user.memberID") }}</th>
              <th>{{ $t("userCampaign.createdAt") }}</th>
              <th>{{ $t("userCampaign.approval") }}</th>
              <th>{{ $t("userCampaign.reviewStatus") }}</th>
              <th>{{ $t("userCampaign.reviewApproval") }}</th>
              <th>Action</th>
            </tr>
          </thead>
          <template v-for="item in users">
            <tr>
              <td>{{ item.user ? item.user.id : '' }}</td>
              <td>
                <div class="avatar-rounded">
                  <a target="_blank" :href="'/users/' + item.user.id + '/detail'">
                    <img :src="item.user ? item.user.avatar_url : '/images/avatar.png'" :alt="item.user ? item.user.fullname : ''" />
                  </a>
                </div>
              </td>
              <td>{{ item.user ? item.user.fullname : '' }}</td>
              <td>{{ item.user ? item.user.email : '' }}</td>
              <td>{{ item.user ? item.user.tel : '' }}</td>
              <td>{{ item.user ? item.user.member_id : '' }}</td>
              <td>{{ item.created_at | datetimeFormat }}</td>
              <td>
                <b-icon-check v-if="item.approve == USER_CAMPAIGN_APPROVED" variant="success" class="h1"></b-icon-check>
              </td>
              <td>
                <router-link
                  v-if="item.review_status === 1"
                  :to="{ name: 'UserCampaignReview', params: { id, userId: item.user_id + '' } }"
                >
                  {{ $t("userCampaign.reviewStatusReviewed") }}
                </router-link>
                <template v-else>
                  {{ $t("userCampaign.reviewStatusNotReviewedYet") }}
                </template>
              </td>
              <td>
                <b-icon-check v-if="item.approve_review == 1" variant="success" class="h1"></b-icon-check>
              </td>
              <td>
                <b-button
                  v-if="item.approve != USER_CAMPAIGN_APPROVED"
                  variant="success"
                  @click="openApprovalModal(item.id)"
                >
                  {{ $t('button.approve') }}
                </b-button>

                <b-button
                  variant="danger"
                  @click="openCancelApprovalModal(item.id)"
                  v-if="item.approve == USER_CANPAIGN_NOT_APPROVED_YET"
                >
                  {{ $t('button.unapprove') }}
                </b-button>

                <b-button
                  v-if="item.approve != USER_CAMPAIGN_APPROVED"
                  variant="outline-danger"
                  outline @click="openDeleteModal(item.id)"
                >
                  {{ $t('button.delete') }}
                </b-button>

                <router-link
                  v-if="item.review_status === 1"
                  :to="{ name: 'UserCampaignReview', params: { id, userId: item.user_id + '' } }"
                  class="btn btn-outline-primary"
                >
                  {{ $t("user.user_campaigns.detail_review") }}
                </router-link>
              </td>
            </tr>
          </template>
          <template v-if="users.length == 0">
            <tr>
              <td colspan="8" class="text-center border-0">{{ $t('message.notFound', { item: $t('item.userCampaign') }) }}</td>
            </tr>
          </template>
        </table>
      </div>
      <pagination-bar
        :currentPage="pageObject.currentPage"
        :lastPage="pageObject.lastPage"
        :canNext="pageObject.canNext"
        :canPrev="pageObject.canPrev"
        @change-page="fetchData"
      ></pagination-bar>
    </div>
    <confirm-modal
      :id="confirmModalId"
      :confirmText="confirmText"
      @confirmed="removeUserFromCampaign"
      @modal-discard="cancelModal"
    ></confirm-modal>
    <confirm-modal
      :id="approveModalId"
      :confirmText="$t('userCampaign.approveConfirm')"
      @confirmed="approveUser"
      @modal-discard="discardApprovalModal"
      okButtonVariant="primary"
    ></confirm-modal>
    <confirm-modal
      :id="cancelApproveModalId"
      :confirmText="$t('userCampaign.unapproveConfirm')"
      @confirmed="cancelUser"
      @modal-discard="discardCancelModal"
    ></confirm-modal>
  </auth-layout>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { USER_CANPAIGN_NOT_APPROVED_YET, USER_CAMPAIGN_APPROVED, USER_CAMPAIGN_CANCEL_APPROVED } from "../../constants/campaign";

export default {
  props: {
    id: {
      type: String,
      required: true
    }
  },

  data: () => ({
    USER_CANPAIGN_NOT_APPROVED_YET: USER_CANPAIGN_NOT_APPROVED_YET,
    USER_CAMPAIGN_APPROVED: USER_CAMPAIGN_APPROVED,
    USER_CAMPAIGN_CANCEL_APPROVED: USER_CAMPAIGN_CANCEL_APPROVED,
    confirmModalId: "user-campaign-delete-modal",
    approveModalId: "approval-modal",
    cancelApproveModalId: "cancel-approval-modal",
    deleteId: null,
    approvalId: null,
    cancelApprovalId: null,
    campaignName: null
  }),

  computed: {
    ...mapState("userCampaign", ["users", "pageObject"]),
    confirmText() {
      return this.$t("userCampaign.deleteConfirm");
    },
    approveConfirmText() {
      return this.approval ? this.$t("userCampaign.approveConfirm") : this.$t("userCampaign.unapproveConfirm");
    },
  },

  methods: {
    ...mapActions("userCampaign", ["fetchUserCampaigns", "approve", "cancelApprove", "destroy", "downloadExcel", "downloadReviewExcel"]),
    fetchData(page) {
      const { id } = this;
      this.fetchUserCampaigns({ page, id })
        .then((data) => this.campaignName = data.campaign_name || '')
        .catch(() => this.$router.go(-1));
    },
    openDeleteModal(id) {
      this.deleteId = id;
      this.$bvModal.show(this.confirmModalId);
    },
    openApprovalModal(id) {
      this.approvalId = id;
      this.$bvModal.show(this.approveModalId);
    },
    openCancelApprovalModal(id) {
      this.cancelApprovalId = id;
      this.$bvModal.show(this.cancelApproveModalId);
    },
    cancelModal() {
      this.deleteId = null;
    },
    discardApprovalModal() {
      this.approvalId = null;
    },
    discardCancelModal() {
      this.cancelApprovalId = null;
    },
    removeUserFromCampaign() {
      this.destroy({ id: this.deleteId })
        .then((data) => {
          this.$bvToast.toast(data.message || this.$t("message.commonSuccess"), {
            title: this.$t("message.successTitle"),
            variant: "success",
            autoHideDelay: 3000,
            solid: true
          });
          this.fetchData(1)
        })
        .catch(error => {
          this.$bvToast.toast(error.message || this.$t("message.commonError"), {
            title: this.$t("message.errorTitle"),
            variant: "danger",
            autoHideDelay: 3000,
            solid: true
          });
        });
    },
    approveUser() {
      this.approve({ id: this.approvalId })
        .then((data) => {
          this.$bvToast.toast(data.message || this.$t("message.commonSuccess"), {
            title: this.$t("message.successTitle"),
            variant: "success",
            autoHideDelay: 3000,
            solid: true
          });
        })
        .catch((error) => {
          this.$bvToast.toast(error.message || this.$t("message.commonError"), {
            title: this.$t("message.errorTitle"),
            variant: "danger",
            autoHideDelay: 3000,
            solid: true
          });
        })
    },
    cancelUser() {
      this.cancelApprove({ id: this.cancelApprovalId })
        .then((data) => {
          this.$bvToast.toast(data.message || this.$t("message.commonSuccess"), {
            title: this.$t("message.successTitle"),
            variant: "success",
            autoHideDelay: 3000,
            solid: true
          });
        })
        .catch((error) => {
          this.$bvToast.toast(error.message || this.$t("message.commonError"), {
            title: this.$t("message.errorTitle"),
            variant: "danger",
            autoHideDelay: 3000,
            solid: true
          });
        })
    },
    download() {
      const { id } = this;
      this.downloadExcel({ id });
    },
    downloadReview() {
      const { id } = this
      this.downloadReviewExcel({ id })
    },
  },

  mounted() {
    this.fetchData(1)
  },
};
</script>

<style lang="scss" scoped></style>
