<template>
  <auth-layout>
    <main-header :title="$t('page.sliders.list')">
      <router-link :to="{ name: 'SlidersAdd' }" class="btn btn-success">{{
        $t("page.sliders.create")
      }}</router-link>
    </main-header>
    <sliders-navigation></sliders-navigation>
  </auth-layout>
</template>

<script>
import { mapActions } from "vuex";
import SlidersNavigation from "./../../components/Sliders/navigation/SlidersNavigation.vue";

export default {
  components: {
    SlidersNavigation
  },
  data: () => ({ }),
  computed: {
    pageObject() {
      return this.$store.getters["sliders/pageObject"];
    }
  },
  methods: {
    ...mapActions("sliders", ["fetchList"]),
    fetchData(page) {
      this.fetchList({ page });
    },
  },
  async mounted() {
    await this.fetchData(1);
  }
};
</script>

<style lang="scss" scoped></style>
