import axios from "axios";
import Cookies from "js-cookie";
import _ from "lodash";
import { ACCEPTED_LOCALES, DEFAULT_LOCALE } from "./constants/common"

let locale = navigator.language;
if (!ACCEPTED_LOCALES.includes(locale)) {
  locale = DEFAULT_LOCALE;
}

let client = axios.create({
  headers: {
    locale
  }
});

const token = Cookies.get("wf-token");

if (!_.isUndefined(token)) {
  client.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}

export default client;
