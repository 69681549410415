<template>
  <div>
    <b-form-group>
      <label>
        {{ $t("rewards.userId") }}
      </label>
      <multi-select
        v-model="userSelected"
        :options="userOptions"
        label="fullname"
        track-by="id"
        :selectedLabel="$t('multiselect.selectedLabel')"
        :selectLabel="$t('multiselect.selectLabel')"
        placeholder=""
        :deselectLabel="$t('multiselect.deselectLabel')"
      ></multi-select>
    </b-form-group>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import MultiSelect from "vue-multiselect";

export default {
  components: {
    MultiSelect
  },
  data() {
    return {};
  },
  computed: {
    rewardsData() {
      return this.$store.getters["rewards/rewardsData"];
    },
    userOptions() {
      return this.$store.getters["rewards/users"];
    },
    userSelected: {
      get() {
        if (this.userOptions) {
          const userId = this.rewardsData.user_id;
          return this.userOptions.find(item => {
            return item.id === userId;
          });
        }
        return null;
      },
      set(value) {
        this.changeRewardAttributesData({
          key: "user_id",
          value: value.id
        });
      }
    }
  },
  methods: {
    ...mapActions("rewards", ["changeRewardAttributesData"])
  }
};
</script>
