<template>
  <auth-layout>
    <main-header :title="$t('page.user.create')">
      <router-link
        :to="{ name: 'UserCampaignList', params: { id } }"
        class="btn btn-outline-secondary"
        >{{ $t("page.userCampaign.backToList") }}</router-link
      >
    </main-header>
    <div class="card">
      <div class="card-body">
        <user-form :errMessage="errMessage" @submit="submit" :campaignId="id" :btnText="$t('button.addMember')"></user-form>
      </div>
    </div>
  </auth-layout>
</template>
<script>
import UserForm from "../../components/Form/UserCampaignForm.vue";
import { mapActions } from "vuex";

export default {
  props: {
    id: {
      type: String,
      required: true
    }
  },
  components: {
    UserForm
  },

  data: () => ({
    errMessage: '',
  }),

  methods: {
    ...mapActions('userCampaign', ['store']),
    submit(formData) {
      this.store(formData)
        .then(() => this.$router.push({ name: "UserCampaignList", params: { id: this.id } }))
        .catch((error) => this.errMessage = error.message || this.$t('message.commonError'))
    }
  },
}
</script>
