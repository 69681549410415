<template>
  <b-form-group :invalid-feedback="invalidFeedback" :state="stateValid">
    <label>
      {{ $t("news.order") }}
    </label>
    <b-form-input
      v-model="newsOrder"
      :state="stateValid"
      type="number"
      required
      trim
    ></b-form-input>
  </b-form-group>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {};
  },
  computed: {
    newsData() {
      return this.$store.getters["news/newsData"];
    },
    newsOrder: {
      get() {
        return (this.newsData && this.newsData.news_order !== null)
          ? Number(this.newsData.news_order)
          : null;
      },
      set(value) {
        this.changeAttributesData({
          key: "news_order",
          value: value
        });
      }
    },
    stateValid() {
      if (!this.newsData || this.newsData.order === null) return null;
      if (
        this.newsData.order === "" ||
        (this.newsData.order && this.newsData.order.length > 100)
      )
        return false;
      return true;
    },
    invalidFeedback() {
      if (this.newsOrder && this.newsOrder.length > 100) {
        return this.$t("news.maxLength").replace(":length", "100");
      }
      return (
        this.$t("subText.required") + " " + this.$t("news.order").toLowerCase()
      );
    }
  },
  methods: {
    ...mapActions("news", ["changeAttributesData"])
  }
};
</script>
