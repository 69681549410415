<template>
  <ValidationObserver
    ref="observer"
    v-slot="{ invalid, errors }"
    tag="form"
    mode="passive"
    @submit.prevent="submit"
  >
    <div
      ref="errorDiv"
      v-if="invalid || errMessage"
      class="text-danger d-flex justify-content-center mt-1"
    >
      <ul>
        <template v-for="error in errors">
          <li v-if="error[0]">{{ error[0] }}</li>
        </template>
        <li v-if="errMessage">{{ errMessage }}</li>
      </ul>
    </div>
    <b-form-group>
      <label for=""
        >{{ $t("user.fullname")
        }}<span class="text-danger ml-1 small">{{
          $t("subText.required")
        }}</span></label
      >
      <ValidationProvider
        mode="passive"
        :name="$t('user.fullname')"
        rules="required|max:100"
        v-slot="{ errors }"
      >
        <b-form-input
          type="text"
          v-model="form.fullname"
          :class="{
            'border border-danger': errors[0]
          }"
        />
      </ValidationProvider>
    </b-form-group>
    <b-form-row>
      <b-col>
        <b-form-group>
          <label for=""
            >{{ $t("user.email")
            }}<span class="text-danger ml-1 small">{{
              $t("subText.required")
            }}</span></label
          >
          <ValidationProvider
            mode="passive"
            :name="$t('user.email')"
            rules="required|max:100|email"
            v-slot="{ errors }"
          >
            <b-form-input
              type="email"
              name="user_email"
              v-model="form.email"
              :class="{
                'border border-danger': errors[0]
              }"
            />
          </ValidationProvider>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group>
          <label for=""
            >{{ $t("auth.password")
            }}<span class="text-danger ml-1 small">{{
              isEdit ? $t("subText.unchangePwd") : $t("subText.required")
            }}</span></label
          >
          <ValidationProvider
            mode="passive"
            :name="$t('auth.password')"
            :rules="{
              required: !isEdit,
              max: 100,
              alpha_dash: true
            }"
            v-slot="{ errors }"
          >
            <b-form-input
              type="password"
              autocomplete="new-password"
              name="user_pwd"
              v-model="form.password"
              :class="{
                'border border-danger': errors[0]
              }"
            />
          </ValidationProvider>
        </b-form-group>
      </b-col>
    </b-form-row>
    <hr />
    <b-form-row>
      <b-col>
        <b-form-group>
          <label for="">{{ $t("user.tel") }}</label>
          <ValidationProvider
            mode="passive"
            :name="$t('user.tel')"
            rules="max:20"
            v-slot="{ errors }"
          >
            <b-form-input
              type="tel"
              v-model="form.tel"
              :class="{
                'border border-danger': errors[0]
              }"
            />
          </ValidationProvider>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group>
          <label for="">{{ $t("user.birthday") }}</label>
          <v-date-picker
            v-model="form.birthday"
            mode="date"
            :locale="currentLocale"
            :model-config="dateConfig"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <input
                placeholder="dd/mm/yyyy"
                class="form-control"
                :value="inputValue"
                v-on="inputEvents"
              />
            </template>
          </v-date-picker>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group>
          <label for="">{{ $t("user.gender") }}</label>
          <multi-select
            v-model="form.gender"
            :options="genderOptions"
            label="display_name"
            track-by="id"
            :selectedLabel="$t('multiselect.selectedLabel')"
            :selectLabel="$t('multiselect.selectLabel')"
            placeholder=""
            :deselectLabel="$t('multiselect.deselectLabel')"
          ></multi-select>
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col>
        <b-form-group>
          <label for="">{{ $t("user.city") }}</label>
          <multi-select
            v-model="form.city_id"
            :options="cityOptions"
            label="name"
            track-by="id"
            :selectedLabel="$t('multiselect.selectedLabel')"
            :selectLabel="$t('multiselect.selectLabel')"
            placeholder=""
            :deselectLabel="$t('multiselect.deselectLabel')"
          ></multi-select>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group>
          <label for="">{{ $t("user.district") }}</label>
          <multi-select
            v-model="form.district_id"
            :options="currentDistricts"
            label="name"
            track-by="id"
            :selectedLabel="$t('multiselect.selectedLabel')"
            :selectLabel="$t('multiselect.selectLabel')"
            placeholder=""
            :deselectLabel="$t('multiselect.deselectLabel')"
          >
            <template #noOptions>{{ $t("multiselect.districtNoOption") }}</template>
          </multi-select>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group>
          <label for="">{{ $t("user.ward") }}</label>
          <multi-select
            v-model="form.ward_id"
            :options="currentWards"
            label="name"
            track-by="id"
            :selectedLabel="$t('multiselect.selectedLabel')"
            :selectLabel="$t('multiselect.selectLabel')"
            placeholder=""
            :deselectLabel="$t('multiselect.deselectLabel')"
          >
            <template #noOptions>{{ $t("multiselect.wardNoOption") }}</template>
          </multi-select>
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col md="8">
        <b-form-group>
          <label for="">{{ $t("user.address") }}</label>
          <ValidationProvider
            mode="passive"
            :name="$t('user.address')"
            rules="max:256"
            v-slot="{ errors }"
          >
            <b-form-input
              type="text"
              v-model="form.address"
              maxlength="256"
              :class="{
                'border border-danger': errors[0]
              }"
            />
          </ValidationProvider>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group>
          <label for="">{{ $t("user.rank") }}</label>
          <multi-select
            v-model="form.rank_id"
            :options="ranks"
            label="ranking_name"
            track-by="id"
            :selectedLabel="$t('multiselect.selectedLabel')"
            :selectLabel="$t('multiselect.selectLabel')"
            placeholder=""
            :deselectLabel="$t('multiselect.deselectLabel')"
          >
          </multi-select>
        </b-form-group>
      </b-col>
    </b-form-row>

    <b-form-row>
      <b-col md="4">
        <label for="">{{ $t("user.avatar") }}</label>
        <image-input :data="form.avatar" :can-remove="false" @image-input="avatarInput"></image-input>
      </b-col>
      <b-col md="8" class="d-flex flex-column justify-content-between">
        <b-form-row>
          <b-col>
            <b-form-group>
              <label for="">{{ $t("user.maritalStatus") }}</label>
              <multi-select
                v-model="form.marital_status"
                :options="maritalOptions"
                label="display_name"
                track-by="id"
                :selectedLabel="$t('multiselect.selectedLabel')"
                :selectLabel="$t('multiselect.selectLabel')"
                placeholder=""
                :deselectLabel="$t('multiselect.deselectLabel')"
              >
              </multi-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group>
              <label for="">{{ $t("user.boyCount") }}</label>
              <ValidationProvider
                mode="passive"
                :name="$t('user.boyCount')"
                rules="numeric"
                v-slot="{ errors }"
              >
                <b-form-input
                  type="number"
                  v-model="form.boy_count"
                  :class="{
                    'border border-danger': errors[0]
                  }"
                />
              </ValidationProvider>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group>
              <label for="">{{ $t("user.girlCount") }}</label>
              <ValidationProvider
                mode="passive"
                :name="$t('user.girlCount')"
                rules="numeric"
                v-slot="{ errors }"
              >
                <b-form-input
                  type="number"
                  v-model="form.girl_count"
                  :class="{
                    'border border-danger': errors[0]
                  }"
                />
              </ValidationProvider>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group>
              <label for="">{{ $t("user.skinType") }}</label>
              <multi-select
                v-model="form.skin_type"
                :options="userSkinTypes"
                label="display_name"
                track-by="id"
                :selectedLabel="$t('multiselect.selectedLabel')"
                :selectLabel="$t('multiselect.selectLabel')"
                placeholder=""
                :deselectLabel="$t('multiselect.deselectLabel')"
              >
              </multi-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group>
              <label for="">{{ $t("user.pets") }}</label>
              <multi-select
                v-model="form.pets"
                :options="petOptions"
                multiple
                :searchable="false"
                label="display_name"
                track-by="id"
                :selectedLabel="$t('multiselect.selectedLabel')"
                :selectLabel="$t('multiselect.selectLabel')"
                placeholder=""
                :deselectLabel="$t('multiselect.deselectLabel')"
              >
              </multi-select>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group>
              <label for="">{{ $t("user.shirtSize") }}</label>
              <multi-select
                v-model="form.shirt_size"
                :options="shirtSizes"
                :searchable="false"
                label="name"
                track-by="id"
                :selectedLabel="$t('multiselect.selectedLabel')"
                :selectLabel="$t('multiselect.selectLabel')"
                placeholder=""
                :deselectLabel="$t('multiselect.deselectLabel')"
              >
              </multi-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group>
              <label for="">{{ $t("user.pantsSize") }}</label>
              <multi-select
                v-model="form.pants_size"
                :options="pantsSizes"
                :searchable="false"
                :selectedLabel="$t('multiselect.selectedLabel')"
                :selectLabel="$t('multiselect.selectLabel')"
                placeholder=""
                :deselectLabel="$t('multiselect.deselectLabel')"
              >
              </multi-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group>
              <label for="">{{ $t("user.shoeSize") }}</label>
              <multi-select
                v-model="form.shoe_size"
                :options="shoeSizes"
                :searchable="false"
                :selectedLabel="$t('multiselect.selectedLabel')"
                :selectLabel="$t('multiselect.selectLabel')"
                placeholder=""
                :deselectLabel="$t('multiselect.deselectLabel')"
              >
              </multi-select>
            </b-form-group>
          </b-col>
        </b-form-row>
      </b-col>
    </b-form-row>
    <hr />
    <div class="pb-4 group-title">{{ $t("user.favorite") }}</div>
    <b-form-row class="align-items-center favorite-group">
      <b-col cols="3" class="favorite-container" v-for="c in categories" :key="c.id">
        <div :class="{'favorite-tag': true, 'active': isFavorited(c.id)}" @click="toggleFavorite(c.id)">
          {{ c.display_name }}
        </div>
      </b-col>
    </b-form-row>
    <hr />
    <div class="pb-4 group-title">{{ $t("user.socialLinked") }}</div>
    <b-form-row class="align-items-center">
      <b-col cols="6" class="social-group">
        <div class="social-url">
          <div class="pb-2">{{ $t("user.facebookAccount") }}</div>
          <b-form-input type="text" v-model="form.facebook_id" />
        </div>
        <div class="social-follower">
          <div class="pb-2">{{ $t("user.facebookFollower") }}</div>
          <b-form-input type="number" v-model="form.facebook_follower" />
        </div>
      </b-col>
      <b-col cols="6" class="social-group">
        <div class="social-url">
          <div class="pb-2">{{ $t("user.instagramAccount") }}</div>
          <b-form-input type="text" v-model="form.instagram_id" />
        </div>
        <div class="social-follower">
          <div class="pb-2">{{ $t("user.instagramFollower") }}</div>
          <b-form-input type="number" v-model="form.instagram_follower" />
        </div>
      </b-col>
      <b-col cols="6" class="social-group">
        <div class="social-url">
          <div class="pb-2">{{ $t("user.tiktokAccount") }}</div>
          <b-form-input type="text" v-model="form.tiktok_id" />
        </div>
        <div class="social-follower">
          <div class="pb-2">{{ $t("user.tiktokFollower") }}</div>
          <b-form-input type="number" v-model="form.tiktok_follower" />
        </div>
      </b-col>
    </b-form-row>
    <hr />
    <div class="pb-4 group-title">{{ $t("user.mostFollower") }}</div>
    <b-form-row>
      <b-col>
        <label for="">{{ $t("user.mostFollowerArea") }}</label>
        <b-select v-model="form.regions_follow">
          <option value="0">{{ $t("message.defaultChoose") }}</option>
          <option v-for="region in REGION_FOLLOWS" :value="region.id">{{ region.display_name }}</option>
        </b-select>
      </b-col>
      <b-col>
        <label for="">{{ $t("user.mostFollowerGender") }}</label>
        <b-select v-model="form.gender_follow">
          <option value="0">{{ $t("message.defaultChoose") }}</option>
          <option v-for="gender in genderOptions" :value="gender.id">{{ gender.display_name }}</option>
        </b-select>
      </b-col>
    </b-form-row>
    <hr />
    <div class="pb-4 group-title">{{ $t("user.bankInfo") }}</div>
    <b-form-row class="pb-3">
      <b-col>
        <label for="">{{ $t("user.bankName") }}</label>
        <b-form-input v-model="form.bank_name"></b-form-input>
      </b-col>
      <b-col>
        <label for="">{{ $t("user.bankBranch") }}</label>
        <b-form-input v-model="form.bank_branch_name"></b-form-input>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col>
        <label for="">{{ $t("user.bankHolder") }}</label>
        <b-form-input v-model="form.bank_account_holders"></b-form-input>
      </b-col>
      <b-col>
        <label for="">{{ $t("user.bankNumber") }}</label>
        <b-form-input v-model="form.bank_account_number"></b-form-input>
      </b-col>
    </b-form-row>
    <hr />
    <b-form-group>
      <b-button type="submit" size="lg" variant="success">{{
        btnText
      }}</b-button>
    </b-form-group>
  </ValidationObserver>
</template>
<script>
import {
  USER_SKIN_NO_CHOICE,
  USER_SKIN_DRY,
  USER_SKIN_OIL,
  USER_SKIN_NORMAL,
  USER_SKIN_MIXED,
  GENDER_MALE,
  GENDER_FEMALE,
  GENDER_OTHER,
  USER_SINGLE,
  USER_MARRIED,
  PET_DOG,
  PET_CAT,
  PET_OTHER
} from "../../constants/user";
import { REGION_FOLLOWS } from "../../constants/common";
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import ImageInput from "../Input/ImageInput.vue";
import MultiSelect from "vue-multiselect";
import { mapState, mapActions } from "vuex";

export default {
  props: {
    errMessage: {
      type: String,
      default: ""
    },
    btnText: {
      type: String,
      required: true
    },
    defaultForm: {
      type: Object,
      default: () => ({})
    },
    isEdit: {
      type: Boolean,
      default: false
    }
  },

  components: {
    "v-date-picker": DatePicker,
    ImageInput,
    MultiSelect
  },

  data: () => ({
    dataStructure: {
      fullname: "",
      tel: null,
      email: "",
      password: "",
      birthday: null,
      avatar: {
        id: null,
        url: null,
        content: null,
        isDirty: false
      },
      gender: null,
      address: null,
      city_id: null,
      district_id: null,
      ward_id: null,
      marital_status: null,
      boy_count: null,
      girl_count: null,
      skin_type: null,
      pets: [],
      shirt_size: null,
      pants_size: null,
      shoe_size: null,
      rank_id: null,
      favorites: [],
      facebook_id: null,
      facebook_follower: null,
      instagram_id: null,
      instagram_follower: null,
      tiktok_id: null,
      tiktok_follower: null,
      gender_follow: null,
      regions_follow: null,
      bank_name: null,
      bank_branch_name: null,
      bank_account_holders: null,
      bank_account_number: null,
    },
    dateConfig: {
      type: 'string',
      mask: 'YYYY-MM-DD'
    },
    REGION_FOLLOWS: REGION_FOLLOWS,
  }),

  computed: {
    ...mapState("location", ["cityOptions", "districtOptions", "wardOptions"]),
    ...mapState("user", ["shirtSizes", "pantsSizes", "shoeSizes", "ranks"]),
    ...mapState("category", ["categories"]),
    form: {
      get() {
        return Object.assign(this.dataStructure, this.defaultForm);
      },
      set(form) {
        this.form = form;
      }
    },
    currentLocale() {
      return this.locale;
    },
    userSkinTypes() {
      return [
        {
          id: USER_SKIN_NO_CHOICE,
          display_name: this.$t("user.skinNoChoice")
        },
        {
          id: USER_SKIN_DRY,
          display_name: this.$t("user.skinDry")
        },
        {
          id: USER_SKIN_OIL,
          display_name: this.$t("user.skinOily")
        },
        {
          id: USER_SKIN_NORMAL,
          display_name: this.$t("user.skinNormal")
        },
        {
          id: USER_SKIN_MIXED,
          display_name: this.$t("user.skinMixed")
        }
      ];
    },
    genderOptions() {
      return [
        {
          id: GENDER_MALE,
          display_name: this.$t("user.male")
        },
        {
          id: GENDER_FEMALE,
          display_name: this.$t("user.female")
        },
        {
          id: GENDER_OTHER,
          display_name: this.$t("user.genderOther")
        }
      ];
    },
    currentDistricts() {
      if (this.form.city_id) {
        return this.districtOptions.filter(
          el => el.city_code == this.form.city_id.code
        );
      }

      return [];
    },
    currentWards() {
      if (this.form.district_id) {
        return this.wardOptions.filter(
          el => el.district_code == this.form.district_id.code
        );
      }

      return [];
    },
    maritalOptions() {
      return [
        {
          id: USER_SINGLE,
          display_name: this.$t("user.single")
        },
        {
          id: USER_MARRIED,
          display_name: this.$t("user.married")
        }
      ];
    },
    petOptions() {
      return [
        {
          id: PET_DOG,
          display_name: this.$t('user.petDog')
        },
        {
          id: PET_CAT,
          display_name: this.$t('user.petCat')
        },
        {
          id: PET_OTHER,
          display_name: this.$t('user.petOther')
        }
      ];
    }
  },

  methods: {
    ...mapActions("location", [
      "fetchCityOptions",
      "fetchDistrictOptions",
      "fetchWardOptions"
    ]),
    ...mapActions("user", [
      "fetchSizeOptions",
      "fetchRankOptions"
    ]),
    ...mapActions("category", ["fetchCategories"]),
    async submit() {
      this.setErrorMessage();
      const isValid = await this.$refs.observer.validate();

      if (!isValid) {
        this.$refs.errorDiv.scrollIntoView({
          behavior: "smooth",
        })
        return;
      } else {
        let data = this.mapFormData();

        this.$emit("submit", data);
      }
    },

    setErrorMessage(message = "") {
      this.$emit("setErrorMessage", message);
    },

    mapFormData() {
      let data = new FormData();

      let keys = Object.keys(this.form);
      for (let i = 0; i < keys.length; i++) {
        if (keys[i] == "avatar") {
          if (this.form.avatar.isDirty) {
            data.append(keys[i], this.form.avatar.content);
          }
        } else if (null != this.form[keys[i]] && this.form[keys[i]] != '') {
          data.append(keys[i], this.form[keys[i]]);
        }
      }

      if (data.has("city_id")) {
        data.set("city_id", this.form.city_id.id);
      }
      if (data.has("district_id")) {
        data.set("district_id", this.form.district_id.id);
      }
      if (data.has("ward_id")) {
        data.set("ward_id", this.form.ward_id.id);
      }
      if (data.has("gender")) {
        data.set("gender", this.form.gender.id);
      }
      if (data.has("marital_status")) {
        data.set("marital_status", this.form.marital_status.id);
      }
      if (data.has("skin_type")) {
        data.set("skin_type", this.form.skin_type.id);
      }
      if (data.has('pets')) {
        for (let i = 0; i < this.form.pets.length; i++) {
          data.append('pets[]', this.form.pets[i].id);
        }
        data.delete('pets')
      }

      if (data.has("shirt_size")) {
        data.set("shirt_size", this.form.shirt_size.id);
      }

      if (data.has("rank_id")) {
        data.set("rank_id", this.form.rank_id.id);
      }

      if (data.has('favorites')) {
        for (let i = 0; i < this.form.favorites.length; i++) {
          data.append('favorites[]', this.form.favorites[i]);
        }
        data.delete('favorites')
      }

      return data;
    },

    avatarInput(payload) {
      this.form.avatar = payload.data
    },

    isFavorited(id) {
      return this.form.favorites && this.form.favorites.includes(id);
    },

    toggleFavorite(id) {
      if (!this.form.favorites) return

      let index = this.form.favorites.indexOf(id);
      if (index != -1) {
        this.form.favorites.splice(index, 1);
      } else {
        this.form.favorites.push(id);
      }
    }
  },
  mounted() {
    if (this.cityOptions.length == 0) {
      this.fetchCityOptions();
    }
    if (this.districtOptions.length == 0) {
      this.fetchDistrictOptions();
    }
    if (this.wardOptions.length == 0) {
      this.fetchWardOptions();
    }
    if (this.shirtSizes.length == 0) {
      this.fetchSizeOptions();
    }
    if (this.ranks.length == 0) {
      this.fetchRankOptions();
    }
    if (this.categories.length == 0) {
      this.fetchCategories();
    }
  }
};
</script>
<style lang="scss" scoped>
.group-title {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  color: #b6bdcb;
  text-transform: uppercase;
  padding-bottom: 12px;
}
.favorite-group {
  .favorite-container {
    padding-left: 4px;
    padding-right: 4px;
    .favorite-tag {
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #f4f5f7;
      border-radius: 4px;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      color: #627683;
      cursor: pointer;
      margin-bottom: 0.5rem;

      &.active {
        color: #6f319f;
        background: #f1e6f5;
      }
    }
  }
}
.social-group {
  display: flex;
  margin-bottom: 15px;
  padding: 0 10px;
  .social-url {
    flex: 0 0 60%;
    max-width: 60%;
    padding-right: 10px;
  }
  .social-follower {
    flex: 0 0 40%;
    max-width: 40%;
  }
}
</style>
