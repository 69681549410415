export default {
  auth: {
    username: "Username",
    password: "Password",
    new_password: "Mật khẩu mới",
  },
  sidebar: {
    campaign: "Campaign",
    user: "User",
    category: "Category",
    rewards: "Rewards",
    notification: "Notification",
    news: "News",
    userRanking: "User Rankings",
    merchants: "Merchants",
    sliders: "Sliders",
    settings: "Settings",
    template: "Template",
    changePassword: "Change password",
    export: "Export",
    exportCampaign: "Campaign",
    exportMember: "Member",
  },
  page: {
    campaign: {
      list: "Campaign list",
      create: "Create new campaign",
      edit: "Update campaign"
    },
    user: {
      list: "User list",
      create: "Add new user",
      edit: "Update user's information",
      detail: "Detail user's information",
      changePassword: "Change password",
    },
    category: {
      list: "Category list",
      create: "Add new category",
      edit: "Update category"
    },
    rewards: {
      list: "Rewards list",
      create: "Create new rewards",
      edit: "Update rewards"
    },
    notification: {
      list: "Notification list",
      create: "Send notification"
    },
    news: {
      list: "News list",
      create: "Create new news",
      edit: "Update news"
    },
    userRanking: {
      list: "User Ranking list",
      create: "Create new ranking",
      edit: "Update ranking"
    },
    sliders: {
      list: "List slide",
      create: "Create slide",
      edit: "Update slide"
    },
    merchants: {
      list: "Merchants list"
    },
    userCampaign: {
      list: "Member list of '{name}'",
      create: "Add member",
      backToList: "Back to member list",
      review: "Review detail",
    },
    mail: {
      list: "List template"
    },
    settings: {
      list: "System settings",
    }
  },
  button: {
    login: "Login",
    logout: "Logout",
    add: "Create",
    update: "Update",
    addImageSlot: "Add image",
    ok: "OK",
    cancel: "Cancel",
    approve: "Approve",
    unapprove: "Cancel approval",
    delete: "Delete",
    addMember: "Add member",
    close: "Close",
    exportExcel: "Export to Excel",
    approveReview: "Approve Review",
    unapproveReview: "Unapprove Review",
    back: "Back",
    exportReviewExcel: "Export review to Excel",
    exportCampaignReport: "Export campaign report",
    exportCommentReport: "Export comment report",
    exportMemberReport: "Export registered member",
    exportUserReport: "Export user report",
    updateSNS: "Update SNS",
  },
  subText: {
    required: "*Required",
    maxImageCount: "(Max: {n} image)",
    unchangePwd: "Leave blank if you do not want to change",
  },
  filePicker: {
    placeHolder: "Drag and drop file here",
    dropPlaceHolder: "Drop file here",
    browse: "Browse"
  },
  datePicker: {
    placeHolder: "No date selected",
  },
  multiselect: {
    deselectLabel: "Enter to deselect",
    selectedLabel: "Selected",
    selectLabel: "Enter to select",
    districtNoOption: "Please choose city first",
    wardNoOption: "Please choose district first",
    noOptions: "List empty",
  },
  pagination: {
    toPreviousPage: "Previous",
    toNextPage: "Next",
  },
  message: {
    errorTitle: "Error!!!",
    successTitle: "Success",
    confirmTitle: "Confirm",
    commonSuccess: "Done.",
    commonError: "Opps! Somethings went wrong.",
    maxFileCount: "You can only upload with maximum of {count} file.",
    notFound: "There is no {item} available.",
    selectedCount: "{count} {item} selected",
    routeLeave: "You are about to leave this page? New changes can not be submitted.",
    warning: "Attention",
    defaultChoose: "No choice",
    refreshTikTokInfoSuccess: "Update Tiktok info done.",
    refreshTikTokInfoFail: "Update Tiktok info fail.",
    refreshInstagramInfoSuccess: "Update Instagram info done.",
    refreshInstagramInfoFail: "Update Instagram info fail.",
    refreshSNSInfoFail: "Unable to update SNS info.",
    userHaveNoSNSInfo: "User have no {sns} account."
  },
  item: {
    campaign: "campaign",
    category: 'category',
    merchants: 'merchants',
    primaryMarket: 'Primary market',
    userCampaign: 'campaign member',
    user: 'user',
  },
  campaign: {
    id: "ID",
    name: "Name",
    image: "Image",
    userCount: "Number of member",
    createdAt: "Created Time",
    createdFrom: "Created From",
    deleteConfirm: "You are about to delete campaign?",
    description: "Description",
    requirement: "Requirement",
    category: "Category",
    price: "Cash",
    salePrice: "Sale price",
    city: "Province",
    social: "Social",
    endTime: "End time",
    approveEndTime: "Browse listings, prepare products",
    postReviewTime: "Post review time",
    reviewEndTime: "Review end time",
    closedDate: "Finish time",
    reward: "Reward",
    criteria: "Criterias",
    reviewRequirement: "Review requirement",
    minReview: "Minimum of review",
    maxUser: "Maximum of review",
    initUser: "Initial number of user",
    slug: "Slug",
    primaryImage: "Primary image",
    optionalImage: "Optional image",
    endCampaignText: "Contents of the report instructions",
    userList: "User list",
    memberOnly: "Member only",
    onlyForMember: "Only for member",
    warningText: "Warning content",
    crawlingTime: "Review statistics update time",
    public: "Public campaign",
    publicState: "Public",
    AutoGenerateSlug: "Auto generate",
    updateSuccess: "Successfully updated campaign",
    createSuccess: "Successfully created campaign",
  },
  user: {
    id: "ID",
    avatar: "Avatar",
    fullname: "Fullname",
    email: "E-mail",
    memberID: "Member ID",
    rank: "Level",
    createdAt: "Created Time",
    disableConfirm: "You are about to lock [ {fullname} ]?",
    reactiveConfirm: "You are about to unlock [ {fullname} ]?",
    deleteConfirm: "You are about to delete [ {fullname} ]?",
    skinType: "Skin type",
    skinNoChoice: "No selected",
    skinDry: "Dry",
    skinOily: "Oily",
    skinNormal: "Normal",
    skinMixed: "Mixed",
    tel: "Telephone",
    birthday: "Birthday",
    gender: "Gender",
    male: "Male",
    female: "Female",
    genderOther: "Other",
    address: "Address",
    city: "City",
    district: "District",
    ward: "Ward",
    maritalStatus: "Marital status",
    single: "Single",
    married: "Married",
    hasChild: "Has children",
    boyCount: "Boy count",
    girlCount: "Girl count",
    pets: "Pets",
    petDog: "Dog",
    petCat: "Cat",
    petOther: "Other",
    shirtSize: "Shirt size",
    pantsSize: "Pants size",
    shoeSize: "Shoe size",
    info: "User information",
    instagramCrawlingTime: "Instagram updated at",
    tiktokCrawlingTime: "Tiktok updated at",
    user_campaigns: {
      history: 'History user campaign',
      id: 'System ID',
      campaign_name: 'Campaign name',
      created_at: 'Created at',
      approved: 'Approved',
      review: 'Review',
      not_match_campaign: 'Data not match list campaign',
      detail_review: 'Detail review'
    },
    friend: {
      list: 'History friend intro',
      no: 'No',
      member_id: 'Member ID',
      fullname: 'Member name',
      email: 'Email',
      status: 'Status',
      created_at: 'Created at',
      not_match_friend: 'Data not match list friend',
    },
    points: {
      list: 'History points',
      no: 'No',
      type: 'Point type',
      point: 'Amount of points',
      relation_object: 'Object',
      date: 'Date',
      sum: 'Total points',
      no_point: 'No points yet',
      share: "Share",
      like: "Like",
      invite: "Invited friend",
      invited: "Invited by friend",
      cancel_campaign: "Campaign registration cancelled",
      cancel_review: "Review cancelled",
    },
    modal: {
      user_campaign_detail_title: "Detail review",
      rating_point: "Rating point",
      link_social_1: "Link review social 1",
      link_social_2: "Link review social 2",
      link_social_3: "Link review social 3",
      review_price: "Review price",
      review_customer_care: "Review customer care",
      review_packaging: "Review packaging",
      review_loyal: "Review loyal",
      count_like: "Count like",
      count_share: "Count share",
      count_comment: "Count comment",
      ARR_REVIEW_PRICE: [
        {
          code: 1,
          text: 'Expensive'
        },
        {
          code: 2,
          text: 'Reasonable price'
        }
      ],
      ARR_REVIEW_PACKAGING: [
        {
          code: 1,
          text: 'Spectacular'
        },
        {
          code: 2,
          text: 'Not pretty'
        }
      ],
      ARR_REVIEW_CUSTOMER_CARE: [
        {
          code: 1,
          text: 'Satisfied'
        },
        {
          code: 2,
          text: 'Not good'
        },
        {
          code: 3,
          text: 'Not experienced yet'
        }
      ],
      ARR_REVIEW_LOYAL: [
        {
          code: 1,
          text: 'Maybe'
        },
        {
          code: 2,
          text: 'Not'
        }
      ],
      review_images: "Review images",
    },
    searchable: "Fullname / Email / Tel",
    profileFinishDate: "Profile finish date",
    joinAt: "Join date",
    currentPassword: "Current password",
    passwordConfirmation: "Password confirmation",
    campaign: "Campaign",
    user: 'User',
    facebookAccount: "Facebook account",
    facebookFollower: "Number of friend/follower",
    instagramAccount: "Instagram account",
    instagramFollower: "Number of follower",
    tiktokAccount: "TikTok account",
    tiktokFollower: "Number of follower",
    favorite: "Favorites",
    socialLinked: "Social media account",
    mostFollower: "Follower information (most)",
    shortMostFollower: "follower (most)",
    mostFollowerGender: "Gender",
    mostFollowerArea: "Area",
    bankInfo: "Bank information",
    bankName: "Name",
    bankBranch: "Branch",
    bankHolder: "Holder",
    bankNumber: "Account number",
    totalPoint: "Total point",
    percentProfile: "Update profile",
  },
  category: {
    confirmDelete: "You are about to delete [{title}]?",
    id: "ID",
    displayName: "Name",
    icon: "Icon (App)",
    slug: "Slug",
    createdAt: "Created Time",
    iconInactive: "Icon (App Inactive)",
    iconWeb: "Icon (Web)",
    active: "Active",
    inactive: "Inactive"
  },
  notification: {
    id: "ID",
    title: "Title",
    content: "Content",
    type: "Type",
    type_all: "All",
    users: "User to push",
    type_selected_user: "Select user",
    createdAt: "Send time"
  },
  rewards: {
    id: "ID",
    userId: "User",
    campaignId: "Campaign",
    name: "Name",
    note: "Note",
    description: "Description",
    thumbnail: "Thumbnail",
    expireDate: "Expire Date",
    createdBy: "Created By",
    createdAt: "Created At",
    updatedBy: "Updated By",
    updatedAt: "Updated At",
    deletedBy: "Deleted By",
    deletedAt: "Deleted At",
    deleteConfirm: "You are about to delete rewards?",
    required: 'Please enter',
    maxLength: 'Only a maximum of :length characters is allowed'
  },
  news: {
    id: "ID",
    imagePrimary: "Image primary",
    displayMainImage: "Display image primary",
    imageSecondary: 'Image secondary',
    title: "Title",
    description: "Description",
    public: "Public news",
    memberOnly: "Member only",
    content: "Content",
    order: "Order",
    createdAt: "Created at",
    deleteConfirm: "You are about to delete news?",
    maxLength: 'Only a maximum of :length characters is allowed',
    slug: "Slug",
    alphaDash: "The :attribute must only contain letters, numbers, dashes and underscores.",
    AutoGenerateSlug: "Auto generate",
    updateSuccess: "Successfully updated news",
    createSuccess: "Successfully created news",
  },
  userRanking: {
    id: "ID",
    ranking_name: "Ranking name",
    percent_complete: 'Percent complete (%)',
    day_count: "Day count (month)",
    createdAt: "Created at",
    deleteConfirm: "You are about to delete ranking?",
    unitCountDay: 'month',
    maxLength: 'Only a maximum of :length characters is allowed',
    valueLimit: 'Input value must be more than :min and less than :max',
    valueMin: 'Input value must more than :min'
  },
  sliders: {
    id: "ID",
    name: "Slide name",
    image: 'Image',
    url: "Url",
    description: "Description",
    date_from: "Date from",
    date_to: "Date to",
    slide_order: "Order",
    deleteConfirm: "You are about to delete sliders?",
    url_invalid: "URL invalid",
    maxLength: 'Only a maximum of :length characters is allowed',
    valueMin: 'Input value must more than :min',
    date_from_to_invalid: 'Date from less than date to'
  },
  merchants: {
    id: "ID",
    branch_name: "Branch name",
    service_type: 'Service type',
    tel: "Tel",
    email: "Email",
    primaryMarket: "Primary market",
    deleteConfirm: "You are about to delete merchants?",
    primaryMarketOption: [
      {
        id: 1,
        value: "Ha Noi"
      },
      {
        id: 2,
        value: "Ho Chi Minh"
      },
      {
        id: 3,
        value: "Other provinces"
      }
    ],
  },
  campaignCriteria: {
    age: "Age",
    place: "Place",
    contentType: "Content type",
    contentTypeImage: "Image",
    contentTypeVideo: "Video",
    reviewHistory: "Review history",
    reviewHistoryPro: "Specialized",
    reviewHistoryUsedTo: "Reviewed",
    reviewHistoryNotYet: "Not reviewed yet",
    noChoice: "No choice",
    instagramCount: "Number of follower on Instagram",
    facebookCount: "Number of follower on Facebook",
    tiktokCount: "Number of follower on Tiktok"
  },
  userCampaign: {
    createdAt: "Registered Date",
    approval: "Approval",
    reviewStatus: "Review status",
    reviewStatusReviewed: "Reviewed",
    reviewStatusNotReviewedYet: "Not Review Yet",
    reviewApproval: "Review Approval",
    deleteConfirm: "This action can not be undo. Are you sure?",
    approveConfirm: "You are about to approve this member?",
    unapproveConfirm: "You are about to cancel the approval of this member?",
    reviewApproveConfirm: "You are about to approve this review article?",
    reviewUnapproveConfirm: "You are about to cancel the approval of this review article?",
  },

  // Mail Setting
  mail: {
    title: "Title",
    templateTitle: "Template [{title}]",
    isAutoSend: "Auto send mail",
    isAutoPush: "Auto push notification",
    notificationTitle: "Notification title",
    mailContent: "Mail content",
    contentRule: "Please do not remove any word like %VARIABLE% because they will be replaced with real information.",
  },
  settings: {
    updateSuccess: "Successfully updated settings",
    forceUpdate: "Force update app",
    iosVersion: "iOS version",
    androidVersion: "Android version",
    campaignWarningText: "Campaign warning text",
    campaignMatchingCriterias: "Campaign matching criterias",
    hobby: "Hobby",
    age: "Age",
    gender: "Gender",
    submit: "Save settings",
  },
  validation: {
    required: ':attribute is required',
    min: ':attribute min :length character',
    max: ':attribute max :length character',
    not_match: ':attribute not match',
  }
};
