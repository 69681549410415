<template>
  <b-form-group :invalid-feedback="invalidFeedback" :state="stateValid">
    <label>
      {{ $t("news.content") }}
      <span class="text-danger ml-1 small">{{ $t("subText.required") }}</span>
    </label>
    <ckeditor :editor="editor" v-model="newsContent" :config="editorConfig" name="newsContent" tag-name="textarea"></ckeditor>
  </b-form-group>
</template>

<script>
import { mapActions } from "vuex";
import MyUploadAdapter from '../../../../uploadAdapter';

export default {
  data() {
    return {
      editor: ClassicEditor
    };
  },
  computed: {
    newsData() {
      return this.$store.getters["news/newsData"];
    },
    newsContent: {
      get() {
        return (this.newsData && this.newsData.content)
          ? this.newsData.content
          : "";
      },
      set(value) {
        this.changeAttributesData({
          key: "content",
          value: value
        });
      }
    },
    stateValid() {
      if (!this.newsData || this.newsData.content === null) return null;
      if (this.newsData.content === "") return false;
      return true;
    },
    invalidFeedback() {
      return (
        this.$t("subText.required") +
        " " +
        this.$t("news.content").toLowerCase()
      );
    },
    editorConfig() {
      return {
        fontSize: {
            options: [
                9,
                11,
                13,
                'default',
                17,
                19,
                21
            ]
        },
        toolbar: [
          'heading',
          '|',
          'bold',
          'italic',
          'link',
          'bulletedList',
          'numberedList',
          'underline',
          '|',
          'outdent',
          'indent',
          '|',
          'fontBackgroundColor',
          'fontColor',
          'fontSize',
          'highlight',
          '|',
          'imageInsert',
          'blockQuote',
          'insertTable',
          'undo',
          'redo',
          'sourceEditing',
          'htmlEmbed',
        ],
        extraPlugins: [this.uploader],
      }
    }
  },
  methods: {
    ...mapActions("news", ["changeAttributesData"]),
    uploader(editor) {
      editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
        return new MyUploadAdapter( loader, 'news' );
      };
    }
  }
};
</script>
