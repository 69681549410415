var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        id: _vm.id,
        size: "xl",
        title: _vm.$t("user.modal.user_campaign_detail_title"),
        "ok-only": true,
        "ok-title": _vm.$t("button.close")
      },
      on: { hide: _vm.close }
    },
    [
      _c(
        "b-card",
        {
          attrs: {
            "footer-tag": "footer",
            "footer-class": "d-flex justify-content-center"
          }
        },
        [
          _c(
            "b-row",
            { staticClass: "align-items-center px-3 py-1" },
            [
              _c(
                "b-col",
                { staticClass: "title-strong", attrs: { cols: "3" } },
                [_vm._v(_vm._s(_vm.$t("user.modal.rating_point")))]
              ),
              _vm._v(" "),
              _c(
                "b-col",
                [
                  _vm._l(_vm.ratingPointTotal, function(index) {
                    return [
                      _c("b-icon-star-fill", {
                        key: index,
                        class: {
                          "icon-social": true,
                          "icon-star-fill": _vm.ratingPoint >= index,
                          "icon-star": _vm.ratingPoint < index
                        },
                        attrs: { "font-scale": "2" }
                      })
                    ]
                  }),
                  _vm._v(" "),
                  _vm.ratingPoint > 0
                    ? _c("span", { staticClass: "total-star__text" }, [
                        _vm._v(_vm._s(_vm.ratingPoint.toFixed(1)))
                      ])
                    : _vm._e()
                ],
                2
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-row",
            { staticClass: "align-items-center px-3 py-2" },
            [
              _c(
                "b-col",
                { staticClass: "title-strong", attrs: { cols: "3" } },
                [_vm._v(_vm._s(_vm.$t("user.modal.link_social_1")))]
              ),
              _vm._v(" "),
              _c("b-col", { staticClass: "d-flex", attrs: { cols: "9" } }, [
                _c("span", { staticClass: "icon-social icon-facebook" }),
                _vm._v(" "),
                _c("div", { staticClass: "w-75 text-truncate" }, [
                  _vm.linkSocial1
                    ? _c(
                        "a",
                        { attrs: { href: _vm.linkSocial1, target: "_blank" } },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.linkSocial1) +
                              "\n          "
                          )
                        ]
                      )
                    : _vm._e()
                ])
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-row",
            { staticClass: "align-items-center px-3 py-2" },
            [
              _c("b-col", { attrs: { offset: "3", cols: "3" } }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("user.modal.count_like")) +
                    " : " +
                    _vm._s(_vm.countLike1) +
                    "\n      "
                )
              ]),
              _vm._v(" "),
              _c("b-col", { attrs: { cols: "3" } }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("user.modal.count_share")) +
                    " : " +
                    _vm._s(_vm.countShare1) +
                    "\n      "
                )
              ]),
              _vm._v(" "),
              _c("b-col", { attrs: { cols: "3" } }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("user.modal.count_comment")) +
                    " : " +
                    _vm._s(_vm.countComment1) +
                    "\n      "
                )
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-row",
            { staticClass: "align-items-center px-3 py-2" },
            [
              _c(
                "b-col",
                { staticClass: "title-strong", attrs: { cols: "3" } },
                [_vm._v(_vm._s(_vm.$t("user.modal.link_social_2")))]
              ),
              _vm._v(" "),
              _c("b-col", { staticClass: "d-flex", attrs: { cols: "9" } }, [
                _c("span", { staticClass: "icon-social icon-instagram" }),
                _vm._v(" "),
                _c("div", { staticClass: "w-75 text-truncate" }, [
                  _vm.linkSocial2
                    ? _c(
                        "a",
                        { attrs: { href: _vm.linkSocial2, target: "_blank" } },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.linkSocial2) +
                              "\n          "
                          )
                        ]
                      )
                    : _vm._e()
                ])
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-row",
            { staticClass: "align-items-center px-3 py-2" },
            [
              _c("b-col", { attrs: { offset: "3", cols: "3" } }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("user.modal.count_like")) +
                    " : " +
                    _vm._s(_vm.countLike2) +
                    "\n      "
                )
              ]),
              _vm._v(" "),
              _c("b-col", { attrs: { cols: "3" } }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("user.modal.count_share")) +
                    " : " +
                    _vm._s(_vm.countShare2) +
                    "\n      "
                )
              ]),
              _vm._v(" "),
              _c("b-col", { attrs: { cols: "3" } }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("user.modal.count_comment")) +
                    " : " +
                    _vm._s(_vm.countComment2) +
                    "\n      "
                )
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-row",
            { staticClass: "align-items-center px-3 py-2" },
            [
              _c(
                "b-col",
                { staticClass: "title-strong", attrs: { cols: "3" } },
                [_vm._v(_vm._s(_vm.$t("user.modal.link_social_3")))]
              ),
              _vm._v(" "),
              _c("b-col", { staticClass: "d-flex", attrs: { cols: "9" } }, [
                _c("span", { staticClass: "icon-social icon-tiktok" }),
                _vm._v(" "),
                _c("div", { staticClass: "w-75 text-truncate" }, [
                  _vm.linkSocial3
                    ? _c(
                        "a",
                        { attrs: { href: _vm.linkSocial3, target: "_blank" } },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.linkSocial3) +
                              "\n          "
                          )
                        ]
                      )
                    : _vm._e()
                ])
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-row",
            { staticClass: "align-items-center px-3 py-2" },
            [
              _c("b-col", { attrs: { offset: "3", cols: "3" } }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("user.modal.count_like")) +
                    " : " +
                    _vm._s(_vm.countLike3) +
                    "\n      "
                )
              ]),
              _vm._v(" "),
              _c("b-col", { attrs: { cols: "3" } }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("user.modal.count_share")) +
                    " : " +
                    _vm._s(_vm.countShare3) +
                    "\n      "
                )
              ]),
              _vm._v(" "),
              _c("b-col", { attrs: { cols: "3" } }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("user.modal.count_comment")) +
                    " : " +
                    _vm._s(_vm.countComment3) +
                    "\n      "
                )
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-row",
            { staticClass: "align-items-center px-3 py-2" },
            [
              _c(
                "b-col",
                { staticClass: "title-strong", attrs: { cols: "4" } },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("user.modal.review_price")) +
                      "\n      "
                  )
                ]
              ),
              _vm._v(" "),
              _c("b-col", { attrs: { cols: "2" } }, [
                _vm._v("\n        " + _vm._s(_vm.reviewPrice) + "\n      ")
              ]),
              _vm._v(" "),
              _c(
                "b-col",
                { staticClass: "title-strong", attrs: { cols: "4" } },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("user.modal.review_packaging")) +
                      "\n      "
                  )
                ]
              ),
              _vm._v(" "),
              _c("b-col", { attrs: { cols: "2" } }, [
                _vm._v("\n        " + _vm._s(_vm.reviewPackaging) + "\n      ")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-row",
            { staticClass: "align-items-center px-3 py-2" },
            [
              _c(
                "b-col",
                { staticClass: "title-strong", attrs: { cols: "4" } },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("user.modal.review_customer_care")) +
                      "\n      "
                  )
                ]
              ),
              _vm._v(" "),
              _c("b-col", { attrs: { cols: "2" } }, [
                _vm._v(
                  "\n        " + _vm._s(_vm.reviewCustomerCare) + "\n      "
                )
              ]),
              _vm._v(" "),
              _c(
                "b-col",
                { staticClass: "title-strong", attrs: { cols: "4" } },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("user.modal.review_loyal")) +
                      "\n      "
                  )
                ]
              ),
              _vm._v(" "),
              _c("b-col", { attrs: { cols: "2" } }, [
                _vm._v("\n        " + _vm._s(_vm.reviewLoyal) + "\n      ")
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }