var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-form-group", [
        _c("label", [
          _vm._v("\n      " + _vm._s(_vm.$t("news.imagePrimary")) + "\n      "),
          _c("span", { staticClass: "small text-gray" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.$t("subText.maxImageCount", { n: 1 })) +
                "\n      "
            )
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "text-danger ml-1 small" }, [
            _vm._v(_vm._s(_vm.$t("subText.required")))
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          [
            _c("image-input", {
              attrs: { data: _vm.primaryImage, "can-remove": false },
              on: { "image-input": _vm.primaryImageInput }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          [
            _c(
              "b-form-checkbox",
              {
                attrs: { name: "check-button", switch: "" },
                model: {
                  value: _vm.displayMainImage,
                  callback: function($$v) {
                    _vm.displayMainImage = $$v
                  },
                  expression: "displayMainImage"
                }
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("news.displayMainImage")) +
                    "\n      "
                )
              ]
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }