<template>
  <div class="form-group">
    <button
      type="button"
      class="btn btn-lg btn-success"
      :disabled="!canSave"
      @click="onSave()"
    >
      {{ $t("button.add") }}
    </button>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      canSave: false
    };
  },
  computed: {
    userRankingData() {
      return this.$store.getters["userRanking/userRankingData"];
    }
  },
  watch: {
    userRankingData: {
      handler: function(val, oldVal) {
        this.canSave = this.isValid();
      },
      deep: true
    },
  },
  methods: {
    ...mapActions("userRanking", ["store"]),
    isValid() {
      return (
        this.userRankingData.ranking_name &&
        this.userRankingData.percent_complete >= 0 &&
        this.userRankingData.percent_complete <= 100 &&
        this.userRankingData.day_count >= 0
      );
    },
    onSave() {
      const formData = new FormData();
      formData.append('ranking_name', this.userRankingData.ranking_name);
      formData.append('percent_complete', this.userRankingData.percent_complete);
      formData.append('day_count', this.userRankingData.day_count);

      this.store(formData)
        .then(() => this.$emit("UserRankingNavigation"))
        .catch((error) => {
          this.$bvToast.toast(error.message || this.$t("message.commonError"), {
            title: this.$t("message.errorTitle"),
            variant: "danger",
            autoHideDelay: 3000,
            solid: true
          });
        });
    }
  }
};
</script>
