<template>
  <b-modal
    :id="id"
    :title="$t('message.confirmTitle')"
    :ok-variant="okButtonVariant"
    :ok-title="$t('button.ok')"
    :cancel-title="$t('button.cancel')"
    @hide="cancel"
    @ok="confirm"
  >
    <p class="my-4">{{ confirmText }}</p>
  </b-modal>
</template>
<script>
export default {
  props: {
    id: {
      type: String,
      required: true
    },
    confirmText: {
      type: String,
      required: true
    },
    okButtonVariant: {
      type: String,
      default: 'danger'
    }
  },
  methods: {
    confirm() {
      this.$emit("confirmed");
    },
    cancel() {
      this.$emit("modal-discard");
    }
  }
};
</script>
