<template>
  <auth-layout>
    <main-header :title="$t('page.mail.list')"></main-header>

    <div class="main-content">
      <div class="table-wrapper no-pagination">
        <table>
          <thead>
            <tr>
              <th>No.</th>
              <th>{{ $t("mail.title") }}</th>
              <th>{{ $t("mail.isAutoSend") }}</th>
              <th>{{ $t("mail.isAutoPush") }}</th>
              <th></th>
            </tr>
          </thead>
          <template v-for="(item, index) in templates">
            <tr>
              <td>{{ index + 1 }}</td>
              <td>{{ item.title }}</td>
              <td>
                <b-icon-check v-if="item.is_auto_send" variant="success" class="h1"></b-icon-check>
              </td>
              <td>
                <b-icon-check v-if="item.is_auto_push" variant="success" class="h1"></b-icon-check>
              </td>
              <td>
                <router-link
                  :to="{
                    name: 'TemplateEdit',
                    params: { id: item.id }
                  }"
                >
                  <b-icon-pencil
                    variant="secondary"
                    class="action-icon"
                  ></b-icon-pencil>
                </router-link>
              </td>
            </tr>
          </template>
        </table>
      </div>
    </div>
  </auth-layout>
</template>
<script>
import { mapState, mapActions } from "vuex";

export default {
  computed: {
    ...mapState('template', ['templates']),
  },
  methods: {
    ...mapActions('template', ['fetchTemplates']),
  },

  created() {
    this.fetchTemplates();
  },
}
</script>
