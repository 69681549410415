<template>
  <auth-layout>
    <div class="filter-bar">
      <b-col md="3">
        <b-input
          v-model="keyword"
          :placeholder="$t('campaign.name') + ' / ' + $t('campaign.id')"
          class="form-control"
          type="search"
          @keydown.enter="fetchData"
        ></b-input>
      </b-col>

      <b-col md="1">
        <b-button variant="primary" @click="fetchData" :disabled="!keyword"><b-icon-search></b-icon-search></b-button>
      </b-col>

      <b-col md="8" class="d-flex justify-content-end">
        <b-button variant="success" @click="exportCampaigns" class="mr-1" :disabled="!canClickExport">{{ $t('button.exportCampaignReport') }}</b-button>
        <b-button variant="success" @click="exportComments" class="mr-1" :disabled="!canClickExport">{{ $t('button.exportCommentReport') }}</b-button>
        <b-button variant="success" @click="exportMembers" :disabled="!canClickExport">{{ $t('button.exportMemberReport') }}</b-button>
      </b-col>
    </div>
    <div class="main-content">
      <div class="table-wrapper">
        <table>
          <thead>
            <tr>
              <th>{{ $t("campaign.id") }}</th>
              <th>{{ $t("campaign.name") }}</th>
              <th>{{ $t("campaign.image") }}</th>
              <th>{{ $t("campaign.endTime") }}</th>
              <th>{{ $t("campaign.userCount") }}</th>
              <th>{{ $t("campaign.category") }}</th>
              <th>{{ $t("campaign.createdAt") }}</th>
              <th>{{ $t("campaign.publicState") }}</th>
              <th>{{ $t("campaign.memberOnly") }}</th>
              <th>
                <input type="checkbox" v-model="selectAll">
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-for="item in campaigns">
              <tr>
                <td>{{ item.id }}</td>
                <td>
                  <a :href="'/campaigns/' + item.id + '/edit'" class="highlight-text clickable">
                    {{ item.name }}
                  </a>
                </td>
                <td>
                  <a :href="'/campaigns/' + item.id + '/edit'">
                    <img
                      class="contain clickable"
                      :src="item.primary_image_url"
                      width="90"
                    />
                  </a>
                </td>
                <td>{{ item.end_time | datetimeFormat }}</td>
                <td>{{ item.user_count | numberFormat }}</td>
                <td>{{ item.category_name }}</td>
                <td>{{ item.created_at | datetimeFormat }}</td>
                <td>
                  <b-icon-check v-if="item.public" variant="success" class="h1"></b-icon-check>
                </td>
                <td>
                  <b-icon-check v-if="item.member_only" variant="success" class="h1"></b-icon-check>
                </td>
                <td class="px-3">
                  <input type="checkbox" :value="item.id" v-model="selected">
                </td>
              </tr>
            </template>
          </tbody>
          <template v-if="searching && campaigns.length == 0">
            <tr>
              <td colspan="8" class="text-center border-0">{{ $t('message.notFound', { item: $t('item.campaign') }) }}</td>
            </tr>
          </template>
        </table>
      </div>
    </div>
  </auth-layout>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {

  data: () => ({
    keyword: '',
    campaigns: [],
    selected: [],
    searching: false
  }),

  computed: {
    canClickExport() {
      return this.selected.length > 0
    },
    selectAll: {
      get() {
        return this.selected.length === this.campaigns.length
      },
      set(val) {
        var selected = []
        if (val) {
          selected.push(...this.campaigns.map(el => el.id))
        }
        this.selected = selected
      }
    }
  },

  methods: {
    ...mapActions("report", ["fetchCampaigns", "downloadCampaigns", "downloadComments", "downloadMembers"]),
    async fetchData() {
      let { keyword } = this;

      if (!keyword) return

      this.selected.length = 0
      this.campaigns = await this.fetchCampaigns({ keyword });
      this.searching = true
    },

    exportCampaigns() {
      this.downloadCampaigns({ ids: this.selected })
    },

    exportComments() {
      this.downloadComments({ ids: this.selected })
    },

    exportMembers() {
      this.downloadMembers({ ids: this.selected })
    },
  },

  async created() {
    this.campaigns = await this.fetchCampaigns({ keyword: '' });
  },
};
</script>

<style lang="scss" scoped>
.main-content {
  height: calc(100% - 60px);
}
</style>
