import axios from "../../axios";

export default {
  namespaced: true,
  state: {
    cityOptions: [],
    districtOptions: [],
    wardOptions: [],
  },
  mutations: {
    setCityOptions(state, options) {
      if (Array.isArray(options)) {
        state.cityOptions.length = 0;
        state.cityOptions = [...options];
      }
    },
    setDistrictOptions(state, options) {
      if (Array.isArray(options)) {
        state.districtOptions.length = 0;
        state.districtOptions = [...options];
      }
    },
    setWardOptions(state, options) {
      if (Array.isArray(options)) {
        state.wardOptions.length = 0;
        state.wardOptions = [...options];
      }
    },
  },
  actions: {
    fetchCityOptions({commit}) {
      return new Promise((resolve, reject) => {
        axios.get('/api/locations/cities')
          .then(({data}) => {
            commit('setCityOptions', data);
            resolve();
          })
      });
    },
    fetchDistrictOptions({commit}) {
      return new Promise((resolve, reject) => {
        axios.get('/api/locations/districts')
          .then(({data}) => {
            commit('setDistrictOptions', data);
            resolve();
          })
      });
    },
    fetchWardOptions({commit}) {
      return new Promise((resolve, reject) => {
        axios.get('/api/locations/wards')
          .then(({data}) => {
            commit('setWardOptions', data);
            resolve();
          })
      });
    },
  },
}
