<template>
  <div>
    <b-form-group
      :invalid-feedback="invalidFeedback"
      :state="stateValid"
    >
      <label>
        {{ $t("sliders.name") }}
        <span class="text-danger ml-1 small">{{ $t("subText.required") }}</span>
      </label>
      <b-form-input
        v-model="rankingName"
        :state="stateValid"
        required
        trim
      ></b-form-input>
    </b-form-group>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {};
  },
  computed: {
    slidersData() {
      return this.$store.getters["sliders/slidersData"];
    },
    rankingName: {
      get() {
        return this.slidersData ? this.slidersData.name : "";
      },
      set(value) {
        this.changeAttributesData({
          key: "name",
          value: value
        });
      }
    },
    stateValid() {
      if (!this.slidersData || this.slidersData.name === null) return null;
      if (
        this.slidersData.name === "" ||
        (this.slidersData.name && this.slidersData.name.length > 256)
      )
        return false;
      return true;
    },
    invalidFeedback() {
      if (this.rankingName && this.rankingName.length > 256) {
        return this.$t("sliders.maxLength").replace(":length", "256");
      }
      return (
        this.$t("subText.required") +
        " " +
        this.$t("sliders.name").toLowerCase()
      );
    }
  },
  methods: {
    ...mapActions("sliders", ["changeAttributesData"])
  }
};
</script>
