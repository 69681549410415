<template>
  <div>
    <b-form-group>
      <label>
        {{ $t("rewards.thumbnail") }}
        <span class="text-danger ml-1 small" v-if="isRequired">{{ $t("subText.required") }}</span>
      </label>
      <div>
        <input
          style="display: none;"
          type="file"
          class="hide"
          ref="fileInput"
          @change="onChange"
          accept="image/*"
        />
        <b-img
          thumbnail
          fluid
          :src="imageURL"
          :alt="rewardsName"
          @click="$refs.fileInput.click()"
          class="thumbnail"
        ></b-img>
      </div>
    </b-form-group>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import ImageInput from "./../../../Input/ImageInput.vue";

export default {
  props: {
    isRequired: {
      type: Boolean,
      default: true
    }
  },

  components: {
    ImageInput
  },
  data() {
    return {
      imageURL: "/images/img_plHolder.png"
    };
  },
  computed: {
    rewardsData() {
      return this.$store.getters["rewards/rewardsData"];
    },
    rewardsName() {
      return this.rewardsData ? this.rewardsData.name : "";
    },
    rewardsThumbnail() {
      return this.rewardsData ? this.rewardsData.thumbnail : null;
    }
  },
  watch: {
    rewardsThumbnail: {
      handler: function(val, oldVal) {
        if (val && typeof val === 'string') {
          this.imageURL = val;
        }
      },
      deep: true
    }
  },
  methods: {
    ...mapActions("rewards", ["changeRewardAttributesData"]),
    onChange(event) {
      const { files } = event.target;
      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        this.imageURL = fileReader.result;
      });
      fileReader.readAsDataURL(files[0]);
      this.changeRewardAttributesData({
        key: "thumbnail",
        value: files[0]
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.thumbnail {
  max-width: 250px;
  max-height: 250px;
  cursor: pointer;
}
</style>
