var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-form-group",
        { attrs: { state: _vm.stateValid } },
        [
          _c("label", [
            _vm._v(
              "\n      " + _vm._s(_vm.$t("rewards.description")) + "\n    "
            )
          ]),
          _vm._v(" "),
          _c("ckeditor", {
            attrs: { editor: _vm.editor, config: _vm.editorConfig },
            model: {
              value: _vm.rewardsDescription,
              callback: function($$v) {
                _vm.rewardsDescription = $$v
              },
              expression: "rewardsDescription"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }