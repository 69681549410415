import axios from "../../axios";
import moment from "moment";

export default {
  namespaced: true,
  state: {
    users: [],
    campaignSlug: null,
    pageObject: {},
  },
  mutations: {
    setUsers(state, users) {
      if (Array.isArray(users)) {
        state.users.length = 0;
        state.users = [...users];
      }
    },
    setPaginationObject(state, pageObject) {
      Object.assign(state.pageObject, pageObject);
    },
    updateApproval(state, payload) {
      const index = state.users.findIndex(el => el.id == payload.id);
      if (index != -1) {
        state.users[index].approve = payload.approve;
      }
    },
    setCampaignSlug(state, campaignSlug) {
      state.campaignSlug = campaignSlug;
    }
  },
  actions: {
    fetchUserCampaigns({commit}, payload) {
      return new Promise((resolve, reject) => {
        const { id, page } = payload;
        axios.get(`/api/campaigns/${id}/users`, { params: { page } })
          .then(({data}) => {
            commit('setUsers', data.data)
            commit('setPaginationObject', {
              currentPage: data.current_page,
              lastPage: data.last_page,
              canNext: data.current_page < data.last_page,
              canPrev: data.current_page > 1
            })
            commit('setCampaignSlug', data.slug)

            resolve(data)
          })
          .catch(({response}) => reject(response.data))
      })
    },
    store({}, payload) {
      return new Promise((resolve, reject) => {
        const { campaign_id } = payload;
        axios.post(`/api/campaigns/${campaign_id}/users`, payload)
          .then(() => resolve())
          .catch(({response}) => reject(response.data))
      })
    },
    approve({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const { id } = payload;
        axios.patch(`/api/user-campaigns/${id}/approve`, payload)
          .then(({data}) => {
            commit('updateApproval', data.data);
            resolve(data);
          })
          .catch(({response}) => reject(response.data))
      })
    },
    cancelApprove({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const { id } = payload;
        axios.patch(`/api/user-campaigns/${id}/cancel-approve`, payload)
          .then(({data}) => {
            commit('updateApproval', data.data);
            resolve(data);
          })
          .catch(({response}) => reject(response.data))
      })
    },
    destroy({}, payload) {
      return new Promise((resolve, reject) => {
        const { id } = payload;
        axios.delete(`/api/user-campaigns/${id}`)
          .then(({data}) => resolve(data))
          .catch(({response}) => reject(response.data))
      })
    },

    findUser({}, payload) {
      return new Promise((resolve, reject) => {
        const { keyword, campaignId } = payload;
        let url = `/api/user-campaigns/${campaignId}/options`;

        if (keyword) {
          url += `?search=${keyword}`;
        }

        axios.get(url, {
          headers: {
            silent: true
          }
        })
          .then(({data}) => {
            resolve(data);
          })
      });
    },

    // download excel data
    downloadExcel({state}, payload) {
      return new Promise((resolve, reject) => {
        let { id } = payload;

        let url = `/api/campaigns/${id}/users/export`;

        axios({
          method: 'GET',
          url,
          responseType: 'blob', // Don't forget this. This is very important. Read more: https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/Sending_and_Receiving_Binary_Data
        })
          .then((response) => {
            const currentTime = moment().format('YYYYMMDDHHmmss');

            let filename = `${state.campaignSlug || ""}_` + currentTime + ".xlsx";
            let blob = new Blob([response.data], { type: 'text/excel' });
            if (window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveBlob(blob, filename);
            }
            else {
              var elem = window.document.createElement('a');
              elem.href = window.URL.createObjectURL(blob);
              elem.download = filename;
              document.body.appendChild(elem);
              elem.click();
              document.body.removeChild(elem);
            }

            resolve();
          })
          .catch(error => reject(error))
      });
    },

    // review approval
    approveReview({}, payload) {
      const { campaignId, userId } = payload;
      return new Promise((resolve, reject) => {
        axios
          .patch(`/api/campaigns/${campaignId}/users/${userId}/approve-review`)
          .then(() => resolve())
          .catch(({response}) => reject(response.data))
      });
    },
    cancelReview({}, payload) {
      const { campaignId, userId } = payload;
      return new Promise((resolve, reject) => {
        axios
          .patch(`/api/campaigns/${campaignId}/users/${userId}/cancel-review`)
          .then(() => resolve())
          .catch(({response}) => reject(response.data))
      });
    },

    // download review excel data
    downloadReviewExcel({state}, payload) {
      return new Promise((resolve, reject) => {
        let { id } = payload;

        let url = `/api/campaigns/${id}/users/export-review`;

        axios({
          method: 'GET',
          url,
          responseType: 'blob', // Don't forget this. This is very important. Read more: https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/Sending_and_Receiving_Binary_Data
        })
          .then((response) => {
            const currentTime = moment().format('YYYYMMDDHHmmss');

            let filename = `${state.campaignSlug || ""}_review_` + currentTime + ".xlsx";
            let blob = new Blob([response.data], { type: 'text/excel' });
            if (window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveBlob(blob, filename);
            }
            else {
              var elem = window.document.createElement('a');
              elem.href = window.URL.createObjectURL(blob);
              elem.download = filename;
              document.body.appendChild(elem);
              elem.click();
              document.body.removeChild(elem);
            }

            resolve();
          })
          .catch(error => reject(error))
      });
    },
  },
}
