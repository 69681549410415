<template>
  <auth-layout>
    <main-header :title="$t('page.user.detail')">
      <router-link
        :to="{ name: 'UserList' }"
        class="btn btn-outline-secondary"
        >{{ $t("page.user.list") }}</router-link
      >
    </main-header>
    <div class="card">
      <div class="card-body">
        <user-detail-block
          :errMessage="errMessage"
          :defaultForm="formData"
          :btnText="$t('button.update')"
          @submit="submit"
        ></user-detail-block>
      </div>
    </div>
  </auth-layout>
</template>
<script>
import UserDetailBlock from "../../components/User/details/UserDetailBlock"
import { mapActions } from "vuex";
import {
  GENDER_FEMALE,
  GENDER_MALE,
  GENDER_OTHER,
  PET_CAT,
  PET_DOG,
  PET_OTHER,
  USER_MARRIED,
  USER_SINGLE,
  USER_SKIN_DRY,
  USER_SKIN_MIXED,
  USER_SKIN_NORMAL,
  USER_SKIN_NO_CHOICE,
  USER_SKIN_OIL
} from "../../constants/user";

export default {
  components: {
    UserDetailBlock
  },

  data: () => ({
    formData: {},
    errMessage: ""
  }),

  methods: {
    ...mapActions("user", ["update", "fetchUserInfo"]),
    submit(formData) {
      formData.id = this.$route.params.id;
      this.update(formData)
        .then(() => this.$router.push({ name: "UserList" }))
        .catch(
          error =>
            (this.errMessage = error.message || this.$t("message.commonError"))
        );
    },
    genderName(id) {
      switch (id) {
        case GENDER_MALE:
          return this.$t("user.male");
        case GENDER_FEMALE:
          return this.$t("user.female");
        case GENDER_OTHER:
          return this.$t("user.genderOther");

        default:
          return "";
      }
    },
    marital(type) {
      switch (type) {
        case USER_SINGLE:
          return this.$t("user.single");
        case USER_MARRIED:
          return this.$t("user.married");

        default:
          return "";
      }
    },
    skinType(type) {
      switch (type) {
        case USER_SKIN_NO_CHOICE:
          return this.$t("user.skinNoChoice");
        case USER_SKIN_DRY:
          return this.$t("user.skinDry");
        case USER_SKIN_OIL:
          return this.$t("user.skinOily");
        case USER_SKIN_NORMAL:
          return this.$t("user.skinNormal");
        case USER_SKIN_MIXED:
          return this.$t("user.skinMixed");

        default:
          return "";
      }
    },
    petType(type) {
      switch (type) {
        case PET_DOG:
          return this.$t("user.petDog");
        case PET_CAT:
          return this.$t("user.petCat");
        case PET_OTHER:
          return this.$t("user.petOther");

        default:
          return "";
      }
    }
  },

  async created() {
    try {
      let user = await this.fetchUserInfo({ id: this.$route.params.id });
      if (user.gender) {
        user.gender = {
          id: user.gender,
          display_name: this.genderName(parseInt(user.gender))
        };
      }
      if (user.marital_status) {
        user.marital_status = {
          id: user.marital_status,
          display_name: this.marital(parseInt(user.marital_status))
        };
      }
      if (null != user.skin_type) {
        user.skin_type = {
          id: user.skin_type,
          display_name: this.skinType(parseInt(user.skin_type))
        };
      }
      if (user.pets && user.pets.length > 0) {
        user.pets = user.pets.map(el => ({
          id: el,
          display_name: this.petType(parseInt(el))
        }));
      }

      user.avatar = {
        id: null,
        content: null,
        url: user.avatar,
        isDirty: false
      };

      this.formData = user;
    } catch (error) {
      this.$router.go(-1);
    }
  }
};
</script>
