import axios from "../../axios";

export default {
  namespaced: true,
  state: {
    notifications: [],
    pageObject: {}
  },
  mutations: {
    setNotifications(state, notifications) {
      if (Array.isArray(notifications)) {
        state.notifications.length = 0;
        state.notifications = [...notifications];
      }
    },
    setPageObject(state, pageObject) {
      Object.assign(state.pageObject, pageObject);
    },
  },
  actions: {
    fetchNotifications({commit}) {
      return new Promise((resolve, reject) => {
        axios.get('/api/notifications')
          .then(({data}) => {
            commit('setNotifications', data.data);
            commit('setPageObject', {
              currentPage: data.current_page,
              lastPage: data.last_page,
              canNext: data.current_page < data.last_page,
              canPrev: data.current_page > 1
            });
            resolve();
          })
          .catch(() => reject())
      });
    },

    findUser({}, payload) {
      return new Promise((resolve, reject) => {
        const { keyword } = payload;
        let url = '/api/users/options';

        if (keyword) {
          url += `?search=${keyword}`;
        }

        axios.get(url, {
          headers: {
            silent: true
          }
        })
          .then(({data}) => {
            resolve(data);
          })
      });
    },

    store({}, payload) {
      return new Promise((resolve, reject) => {
        axios.post('/api/notifications', payload)
          .then(() => resolve())
          .catch(({response}) => reject(response.data))
      });
    },
  },
};
