var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-form-group",
    [
      _c("label", { staticClass: "only-member__title" }, [
        _vm._v(_vm._s(_vm.$t("news.memberOnly")))
      ]),
      _vm._v(" "),
      _c("b-form-checkbox", {
        attrs: { name: "check-button", switch: "" },
        model: {
          value: _vm.memberOnlySelected,
          callback: function($$v) {
            _vm.memberOnlySelected = $$v
          },
          expression: "memberOnlySelected"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }