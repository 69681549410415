import { render, staticRenderFns } from "./UserRankingDataBlocks.vue?vue&type=template&id=096a9178&"
import script from "./UserRankingDataBlocks.vue?vue&type=script&lang=js&"
export * from "./UserRankingDataBlocks.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/WEFREE_ADMIN/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('096a9178')) {
      api.createRecord('096a9178', component.options)
    } else {
      api.reload('096a9178', component.options)
    }
    module.hot.accept("./UserRankingDataBlocks.vue?vue&type=template&id=096a9178&", function () {
      api.rerender('096a9178', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/UserRanking/data/blocks/UserRankingDataBlocks.vue"
export default component.exports