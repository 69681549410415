<template>
  <div>
    <b-form-group>
      <label>
        {{ $t("news.imagePrimary") }}
        <span class="small text-gray">
          {{ $t("subText.maxImageCount", { n: 1 }) }}
        </span>
        <span class="text-danger ml-1 small">{{ $t("subText.required") }}</span>
      </label>
      <div>
        <image-input
          @image-input="primaryImageInput"
          :data="primaryImage"
          :can-remove="false"
        ></image-input>
      </div>
      <div>
        <b-form-checkbox v-model="displayMainImage" name="check-button" switch>
          {{ $t("news.displayMainImage") }}
        </b-form-checkbox>
      </div>
    </b-form-group>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import ImageInput from "../../../Input/ImageInput.vue";

export default {
  components: {
    ImageInput
  },
  data() {
    return {};
  },
  computed: {
    newsData() {
      return this.$store.getters["news/newsData"];
    },
    newsImages() {
      return this.newsData ? this.newsData.news_images : [];
    },
    primaryImage: {
      set(value) {
        this.changeAttributesData({
          key: "primary_image",
          value: value
        });
      },
      get() {
        return this.newsData && this.newsData.primary_image !== null
          ? this.newsData.primary_image
          : {
              id: null,
              content: null,
              url: null,
              isDirty: false
            };
      }
    },
    displayMainImage: {
      set(value) {
        this.changeAttributesData({
          key: "display_main_image",
          value: Number(value)
        });
      },
      get() {
        return this.newsData && this.newsData.display_main_image !== null
          ? Boolean(this.newsData.display_main_image)
          : false;
      }
    },
    removalImageIds: {
      set(value) {
        this.addRemovalImageIds({
          id: value
        });
      },
      get() {
        return this.$store.getters["news/removalImageIds"];
      }
    }
  },
  methods: {
    ...mapActions("news", ["changeAttributesData", "addRemovalImageIds"]),
    primaryImageInput(payload) {
      if (null != payload.data.id) {
        this.removalImageIds = payload.data.id;
      }
      this.primaryImage = payload.data;
    }
  },
};
</script>

<style lang="scss" scoped>
.thumbnail {
  width: 100%;
  max-height: 300px;
  cursor: pointer;
  object-fit: contain;
}
</style>
