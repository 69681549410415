<template>
  <auth-layout>
    <main-header :title="$t('page.userRanking.create')">
      <router-link
        :to="{ name: 'UserRankingNavigation' }"
        class="btn btn-outline-secondary"
        >{{ $t("page.userRanking.list") }}</router-link
      >
    </main-header>

    <div class="card">
      <div class="card-body">
        <user-ranking-data-blocks></user-ranking-data-blocks>
        <user-ranking-save-button-add @leave="leave"></user-ranking-save-button-add>
      </div>
    </div>

    <b-modal
      centered
      :id="routeLeaveModalId"
      :title="$t('message.warning')"
      :ok-title="$t('button.ok')"
      :cancel-title="$t('button.cancel')"
      @ok="leave(targetRouteName)"
    >
      {{ $t('message.routeLeave') }}
    </b-modal>
  </auth-layout>
</template>

<script>
import { mapActions } from 'vuex';
import UserRankingDataBlocks from './../../components/UserRanking/data/blocks/UserRankingDataBlocks'
import UserRankingSaveButtonAdd from './../../components/UserRanking/data/add/UserRankingSaveButtonAdd'
import routeLeaveWarning from '../../mixins/routeLeaveWarning';

export default {
  mixins: [routeLeaveWarning],

  components: {
    UserRankingDataBlocks,
    UserRankingSaveButtonAdd
  },
  data: () => ({}),
  methods: {
    ...mapActions('userRanking', ['fetchById', 'store'])
  },
  mounted() {
    this.fetchById({ id: 0 });
  }
};
</script>

<style lang="scss" scoped></style>
