<template>
  <auth-layout>
    <main-header :title="$t('page.notification.list')">
      <router-link :to="{ name: 'NotificationCreate' }" class="btn btn-success">{{
        $t("page.notification.create")
      }}</router-link>
    </main-header>

    <div class="main-content">
      <div class="table-wrapper">
        <table>
          <thead>
            <tr>
              <th>{{ $t("notification.id") }}</th>
              <th>{{ $t("notification.title") }}</th>
              <th>{{ $t("notification.content") }}</th>
              <th>{{ $t("notification.type") }}</th>
              <th>{{ $t("notification.createdAt") }}</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="item in notifications">
              <tr>
                <td>{{ item.id }}</td>
                <td>{{ item.title }}</td>
                <td>{{ item.content }}</td>
                <td>{{ transNotificationType(item.type) }}</td>
                <td>{{ item.created_at | datetimeFormat }}</td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <pagination-bar
        :currentPage="pageObject.currentPage"
        :lastPage="pageObject.lastPage"
        :canNext="pageObject.canNext"
        :canPrev="pageObject.canPrev"
        @change-page="fetchData"
      ></pagination-bar>
    </div>
  </auth-layout>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { TYPE_ALL, TYPE_SELECTED_USER } from "../../constants/notification";

export default {
  data: () => ({
  }),

  computed: {
    ...mapState("notification", ["notifications", "pageObject"]),
  },

  methods: {
    ...mapActions("notification", ["fetchNotifications"]),
    fetchData(page) {
      this.fetchNotifications({ page });
    },
    transNotificationType(type) {
      switch (type) {
        case TYPE_ALL:
          return this.$t('notification.type_all')

        case TYPE_SELECTED_USER:
          return this.$t('notification.type_selected_user')

        default:
          return '';
      }
    }
  },

  async mounted() {
    await this.fetchData(1);
  }
};
</script>

<style lang="scss" scoped>
</style>
