<template>
  <ValidationObserver
    ref="observer"
    v-slot="{ invalid, errors }"
    tag="form"
    mode="passive"
    @submit.prevent="submit"
  >
    <div
      ref="errorDiv"
      v-show="invalid || errMessage"
      class="text-danger d-flex justify-content-center mt-1"
    >
      <ul>
        <template v-for="error in errors">
          <li v-if="error[0]">{{ error[0] }}</li>
        </template>
        <li v-if="errMessage">{{ errMessage }}</li>
      </ul>
    </div>
    <b-form-row class="mb-2 p-2 justify-content-center align-items-center">
      <b-col md="3" class="d-flex align-items-center justify-content-between mr-2">
        {{ $t("category.displayName") }}
        <span class="text-danger small">{{ $t("subText.required") }}</span>
      </b-col>
      <b-col md="5">
        <ValidationProvider
          mode="passive"
          :name="$t('category.displayName')"
          rules="required|max:100"
          v-slot="{ errors }"
        >
          <b-form-input
            v-model="form.display_name"
            type="text"
            maxlength="100"
            :class="{
              'border border-danger': errors[0]
            }"
          ></b-form-input>
        </ValidationProvider>
      </b-col>
    </b-form-row>
    <b-form-row class="mb-2 p-2 justify-content-center align-items-center">
      <b-col md="3" class="d-flex align-items-center justify-content-between mr-2">
        {{ $t("category.icon") }}
        <span class="text-danger small">{{ $t("subText.required") }}</span>
      </b-col>
      <b-col md="5">
        <b-form-file
          v-model="form.icon.content"
          :placeholder="iconName || $t('filePicker.placeHolder')"
          :drop-placeholder="$t('filePicker.dropPlaceHolder')"
          :browse-text="$t('filePicker.browse')"
          accept="image/*"
        ></b-form-file>
      </b-col>
    </b-form-row>
    <b-form-row class="mb-2 p-2 justify-content-center align-items-center">
      <b-col md="3" class="d-flex align-items-center justify-content-between mr-2">
        {{ $t("category.iconWeb") }}
        <span class="text-danger small">{{ $t("subText.required") }}</span>
      </b-col>
      <b-col md="5">
        <b-form-file
          v-model="form.iconWeb.content"
          :placeholder="iconWebName || $t('filePicker.placeHolder')"
          :drop-placeholder="$t('filePicker.dropPlaceHolder')"
          :browse-text="$t('filePicker.browse')"
          accept="image/*"
        ></b-form-file>
      </b-col>
    </b-form-row>
    <b-form-row class="mb-2 p-2 justify-content-center align-items-center">
      <b-col md="3" class="d-flex align-items-center justify-content-between mr-2">
        {{ $t("category.iconInactive") }}
        <span class="text-danger small">{{ $t("subText.required") }}</span>
      </b-col>
      <b-col md="5">
        <b-form-file
          v-model="form.iconInactive.content"
          :placeholder="iconInactiveName || $t('filePicker.placeHolder')"
          :drop-placeholder="$t('filePicker.dropPlaceHolder')"
          :browse-text="$t('filePicker.browse')"
          accept="image/*"
        ></b-form-file>
      </b-col>
    </b-form-row>
    <b-form-row class="mb-2 p-2 justify-content-center align-items-center">
      <b-col md="3" class="d-flex align-items-center justify-content-between mr-2">
        {{ $t("category.slug") }}
        <span class="text-danger small">{{ $t("subText.required") }}</span>
      </b-col>
      <b-col md="5">
        <ValidationProvider
          mode="passive"
          :name="$t('category.slug')"
          rules="required|max:128|alpha_dash"
          v-slot="{ errors }"
        >
          <b-form-input
            v-model="form.slug"
            type="text"
            maxlength="128"
            :class="{
              'border border-danger': errors[0]
            }"
          ></b-form-input>
        </ValidationProvider>
      </b-col>
    </b-form-row>
    <b-form-row class="mb-2 p-2 justify-content-center align-items-center">
      <b-col md="3" class="d-flex align-items-center justify-content-between mr-2">
        {{ $t("category.active") }}
        <span class="text-danger small">{{ $t("subText.required") }}</span>
      </b-col>
      <b-col md="5">
        <b-form-radio-group
          :options="activeOptions"
          v-model="form.active"
        ></b-form-radio-group>
      </b-col>
    </b-form-row>
    <hr />
    <b-form-group>
      <b-button type="submit" size="lg" variant="success">{{ btnText }}</b-button>
    </b-form-group>
  </ValidationObserver>
</template>
<script>

export default {
  props: {
    errMessage: {
      type: String,
      default: ""
    },
    btnText: {
      type: String,
      required: true
    },
    defaultForm: {
      type: Object,
      default: () => ({})
    }
  },

  data: () => ({
    dataStructure: {
      display_name: "",
      icon: {
        content: null,
        name: null
      },
      slug: null,
      iconInactive: {
        content: null,
        name: null
      },
      iconWeb: {
        content: null,
        name: null
      },
      active: true
    }
  }),

  computed: {
    form: {
      get() {
        return Object.assign(this.dataStructure, this.defaultForm);
      }
    },
    iconName() {
      if (this.form.icon.content) {
        return this.form.icon.content.name;
      }
      return this.form.icon.name;
    },
    iconInactiveName() {
      if (this.form.iconInactive.content) {
        return this.form.iconInactive.content.name;
      }
      return this.form.iconInactive.name;
    },
    iconWebName() {
      if (this.form.iconWeb.content) {
        return this.form.iconWeb.content.name;
      }
      return this.form.iconWeb.name;
    },
    activeOptions() {
      return [
        {
          value: true,
          text: this.$t('category.active')
        },
        {
          value: false,
          text: this.$t('category.inactive')
        }
      ]
    }
  },

  methods: {
    setErrorMessage(message = "") {
      this.$emit("setErrorMessage", message);
    },
    async submit() {
      this.setErrorMessage();

      const isValid = await this.$refs.observer.validate();

      if (!isValid) {
        return;
      }

      let data = this.mapFormData();
      this.$emit("submit", data);
    },

    mapFormData() {
      let data = new FormData();

      data.append("display_name", this.form.display_name);
      data.append("slug", this.form.slug);
      data.append("active", this.form.active ? 1 : 0);

      if (this.form.icon.content) {
        data.append("icon", this.form.icon.content);
      }

      if (this.form.iconInactive.content) {
        data.append("icon_inactive", this.form.iconInactive.content);
      }

      if (this.form.iconWeb.content) {
        data.append("icon_web", this.form.iconWeb.content);
      }

      return data;
    }
  }
};
</script>
