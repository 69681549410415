var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-form-group", [
        _c("label", [
          _vm._v("\n      " + _vm._s(_vm.$t("sliders.image")) + "\n      "),
          _c("span", { staticClass: "text-danger ml-1 small" }, [
            _vm._v(_vm._s(_vm.$t("subText.required")))
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          [
            _c("input", {
              ref: "fileInput",
              staticClass: "hide",
              staticStyle: { display: "none" },
              attrs: { type: "file", accept: "image/*" },
              on: { change: _vm.onChange }
            }),
            _vm._v(" "),
            _c("b-img", {
              staticClass: "thumbnail",
              attrs: {
                thumbnail: "",
                fluid: "",
                src: _vm.imageURL,
                alt: _vm.slidersName
              },
              on: {
                click: function($event) {
                  return _vm.$refs.fileInput.click()
                }
              }
            })
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }