<template>
  <auth-layout>
    <main-header :title="$t('page.rewards.edit')">
      <router-link
        :to="{ name: 'RewardsNavigation' }"
        class="btn btn-outline-secondary"
        >{{ $t("page.rewards.list") }}</router-link
      >
    </main-header>

    <div class="card">
      <div class="card-body">
        <rewards-data-blocks></rewards-data-blocks>
        <rewards-save-button-edit @leave="leave"></rewards-save-button-edit>
      </div>
    </div>

    <b-modal
      centered
      :id="routeLeaveModalId"
      :title="$t('message.warning')"
      :ok-title="$t('button.ok')"
      :cancel-title="$t('button.cancel')"
      @ok="leave(targetRouteName)"
    >
      {{ $t('message.routeLeave') }}
    </b-modal>
  </auth-layout>
</template>

<script>
import { mapActions } from 'vuex';
import RewardsDataBlocks from './../../components/Rewards/data/blocks/RewardsDataBlocks'
import RewardsSaveButtonEdit from './../../components/Rewards/data/edit/RewardsSaveButtonEdit'
import routeLeaveMixin from '../../mixins/routeLeaveWarning'

export default {
  mixins: [routeLeaveMixin],

  components: {
    RewardsDataBlocks,
    RewardsSaveButtonEdit
  },

  data: () => ({}),

  methods: {
    ...mapActions('rewards', ['fetchById', 'store', 'fetchCampaigns', 'fetchUsers'])
  },

  mounted() {
    this.fetchById({ id: this.$route.params.id });
    this.fetchCampaigns();
    this.fetchUsers();
  }
};
</script>

<style lang="scss" scoped></style>
