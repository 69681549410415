var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "auth-layout",
    [
      _c(
        "main-header",
        { attrs: { title: _vm.$t("page.user.create") } },
        [
          _c(
            "router-link",
            {
              staticClass: "btn btn-outline-secondary",
              attrs: {
                to: { name: "UserCampaignList", params: { id: _vm.id } }
              }
            },
            [_vm._v(_vm._s(_vm.$t("page.userCampaign.backToList")))]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "card" }, [
        _c(
          "div",
          { staticClass: "card-body" },
          [
            _c("user-form", {
              attrs: {
                errMessage: _vm.errMessage,
                campaignId: _vm.id,
                btnText: _vm.$t("button.addMember")
              },
              on: { submit: _vm.submit }
            })
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }