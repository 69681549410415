<template>
  <div>
    <user-ranking-name-block></user-ranking-name-block>
    <user-ranking-percent-complete-block></user-ranking-percent-complete-block>
    <user-ranking-day-count-block></user-ranking-day-count-block>
  </div>
</template>
<script>
import UserRankingNameBlock from './UserRankingNameBlock';
import UserRankingPercentCompleteBlock from './UserRankingPercentCompleteBlock';
import UserRankingDayCountBlock from './UserRankingDayCountBlock';

export default {
  components: {
    UserRankingNameBlock,
    UserRankingPercentCompleteBlock,
    UserRankingDayCountBlock,
  }
};
</script>
<style lang="scss">
</style>
