var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-content has-filter-bar" },
    [
      _c("div", { staticClass: "table-wrapper" }, [
        _c(
          "table",
          [
            _c("thead", [
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("news.id")))]),
                _vm._v(" "),
                _c("th", [_vm._v(_vm._s(_vm.$t("news.imagePrimary")))]),
                _vm._v(" "),
                _c("th", { staticClass: "w__35--percent" }, [
                  _vm._v(_vm._s(_vm.$t("news.title")))
                ]),
                _vm._v(" "),
                _c("th", { staticClass: "text-center" }, [
                  _vm._v(_vm._s(_vm.$t("news.public")))
                ]),
                _vm._v(" "),
                _c("th", { staticClass: "text-center" }, [
                  _vm._v(_vm._s(_vm.$t("news.memberOnly")))
                ]),
                _vm._v(" "),
                _c("th", { staticClass: "text-center" }, [
                  _vm._v(_vm._s(_vm.$t("news.order")))
                ]),
                _vm._v(" "),
                _c("th", { staticClass: "text-center" }, [
                  _vm._v(_vm._s(_vm.$t("news.createdAt")))
                ]),
                _vm._v(" "),
                _c("th", { staticClass: "text-center" })
              ])
            ]),
            _vm._v(" "),
            _vm._l(_vm.listNews, function(item) {
              return [
                _c("tr", { key: item.id }, [
                  _c("td", [_vm._v(_vm._s(item.id))]),
                  _vm._v(" "),
                  _c("td", [
                    _c("img", {
                      staticClass: "contain",
                      attrs: { src: item.path, width: "90" }
                    })
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _c("a", { attrs: { href: item.url, target: "_blank" } }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(item.title) +
                          "\n            "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "text-center" },
                    [
                      item.public
                        ? _c("b-icon", {
                            attrs: {
                              icon: "check",
                              scale: "2",
                              variant: "success"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "text-center" },
                    [
                      item.member_only
                        ? _c("b-icon", {
                            attrs: {
                              icon: "check",
                              scale: "2",
                              variant: "success"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(item.news_order))]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(_vm._s(_vm.formatDatetime(item.created_at)))
                  ]),
                  _vm._v(" "),
                  _c(
                    "td",
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "NewsEdit",
                              params: { id: item.id }
                            }
                          }
                        },
                        [
                          _c("b-icon-pencil", {
                            staticClass: "action-icon",
                            attrs: { variant: "secondary" }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("b-icon-trash", {
                        staticClass: "action-icon",
                        attrs: { variant: "danger" },
                        on: {
                          click: function($event) {
                            return _vm.openDeleteModal(item.id)
                          }
                        }
                      })
                    ],
                    1
                  )
                ])
              ]
            })
          ],
          2
        )
      ]),
      _vm._v(" "),
      _c("pagination-bar", {
        attrs: {
          currentPage: _vm.currentPage,
          lastPage: _vm.lastPage,
          canNext: _vm.canNext,
          canPrev: _vm.canPrev
        },
        on: { "change-page": _vm.fetchData }
      }),
      _vm._v(" "),
      _c("confirm-modal", {
        attrs: { id: _vm.confirmModalId, confirmText: _vm.confirmText },
        on: { confirmed: _vm.deleteNews, "modal-discard": _vm.cancelModal }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }