<template>
  <ValidationObserver
    ref="observer"
    v-slot="{ invalid, errors }"
    v-if="dataFetched"
    tag="form"
    mode="passive"
    @submit.prevent="submit"
  >

    <div
      ref="errorDiv"
      v-if="invalid || errMessage"
      class="text-danger d-flex justify-content-center mt-1"
    >
      <ul>
        <template v-for="error in errors">
          <li v-if="error[0]">{{ error[0] }}</li>
        </template>
        <li v-if="errMessage">{{ errMessage }}</li>
      </ul>
    </div>

    <b-alert v-if="variant" :variant="variant" show>{{ message }}</b-alert>

    <div class="form-group">
      <b-form-row>
        <b-col class="flex-200px">
          <label for="force-update-app">{{ $t("settings.forceUpdate") }}</label>
        </b-col>
        <b-col>
          <b-form-checkbox v-model="form.force_update" id="force-update-app" switch size="lg">
          </b-form-checkbox>
        </b-col>
      </b-form-row>
    </div>
    
    <div class="form-group">
      <label for=""
        >{{ $t("settings.iosVersion")
        }}</label
      >
      <ValidationProvider
        mode="passive"
        :name="$t('settings.iosVersion')"
        rules="max:10"
        v-slot="{ errors }"
      >
        <input
          v-model="form.ios_version"
          :class="{
            'form-control': true,
            'border border-danger': errors[0]
          }"
        />
      </ValidationProvider>
    </div>

    <div class="form-group">
      <label for=""
        >{{ $t("settings.androidVersion")
        }}</label
      >
      <ValidationProvider
        mode="passive"
        :name="$t('settings.androidVersion')"
        rules="max:10"
        v-slot="{ errors }"
      >
        <input
          v-model="form.android_version"
          :class="{
            'form-control': true,
            'border border-danger': errors[0]
          }"
        />
      </ValidationProvider>
    </div>

    <div class="form-group">
      <label for=""
        >{{ $t("settings.campaignWarningText")
        }}</label
      >
      <ValidationProvider
        mode="passive"
        :name="$t('settings.campaignWarningText')"
        v-slot="{ errors }"
      >
        <textarea
          v-model="form.campaign_warning_text"
          rows="4"
          :class="{
            'form-control': true,
            'border border-danger': errors[0]
          }"
        />
      </ValidationProvider>
    </div>
<!--
    <div class="col-group">
      <div class="label">{{ $t('settings.campaignMatchingCriterias') }}</div>
      <div class="content">
        <b-form-checkbox-group v-model="form.campaign_matching_criterias">
          <b-form-checkbox :value="'1'">{{ $t('settings.hobby') }}</b-form-checkbox>
          <b-form-checkbox :value="'2'">{{ $t('settings.age') }}</b-form-checkbox>
          <b-form-checkbox :value="'3'">{{ $t('settings.gender') }}</b-form-checkbox>
        </b-form-checkbox-group>
      </div>
    </div>
-->
    <div class="form-group">
      <b-button
        type="submit"
        variant="primary"
        class="btn btn-lg btn-success"
        :disabled="processing"
      >
        {{ $t('settings.submit') }}
      </b-button>    
    </div>
    
    </div>
  </ValidationObserver>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";


export default {
  props: {
    successMessage: {
      type: String,
      default: ""
    },
    errMessage: {
      type: String,
      default: ""
    },
    submitText: {
      type: String,
      default: "Submit"
    },
    defaultForm: {
      type: Object,
      default: () => ({})
    },
  },
  components: {},
  data: () => ({
    dataStructure: {
      force_update: false,
      ios_version: "",
      android_version: "",
      campaign_warning_text: "",
      campaign_matching_criterias: [],
    },
    processing: false,
    variant: "",
    message: "",
    dataFetched: false,
  }),
  mounted () {
    this.fetchData();
  },
  computed: {
    listSettings() {
      return this.$store.getters["settings/listSettings"];
    },

    form: {
      get() {
        Object.assign(this.dataStructure, this.listSettings);
        return this.dataStructure;        
      },
      set(val) {
        this.form = val;
      }
    },
  },
  methods: {
    ...mapActions("settings", ["fetchSettings", "store"]),

    fetchData() {
      this.fetchSettings()
        .finally(() => {this.dataFetched = true})
    },

    async submit() {
      this.processing = true;
      this.errMessage = "";
      this.variant = "";
      this.$nextTick(() => this.$refs.errorDiv.scrollIntoView({
        behavior: "smooth",
        block: "center"
      }));
      const isValid = await this.$refs.observer.validate();
      
      if (isValid) {
        let data = this.mapFormData();
        this.store(data)
          .then(() => {
            this.variant = "success";
            this.message = this.$t("settings.updateSuccess");
          })
          .catch((error) => {
            this.variant = "danger";
            this.message = error.message;
          });
      };

      this.processing = false;
    },

    mapFormData() {
      let data = new FormData();

      data.set("force_update", this.form.force_update ? 1 : 0);
      data.set("ios_version", this.form.ios_version);
      data.set("android_version", this.form.android_version);
      data.set("campaign_warning_text", this.form.campaign_warning_text);
      
      for (let i = 0; i < this.form.campaign_matching_criterias.length; i++) {
        data.append("campaign_matching_criterias[]", this.form.campaign_matching_criterias[i])
      };

      return data;
    }
  }
};
</script>

<style lang="scss" scoped>
.w__35--percent {
  width: 35%;
}
</style>