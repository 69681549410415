<template>
  <auth-layout>
    <main-header :title="$t('page.news.list')">
      <router-link :to="{ name: 'NewsAdd' }" class="btn btn-success">{{
        $t("page.news.create")
      }}</router-link>
    </main-header>
    <div class="filter-bar">
      <b-col md="3" class="pl-0">
        <b-input
          v-model="keyword"
          :placeholder="$t('news.title')"
          class="form-control"
        ></b-input>
      </b-col>
      <b-button variant="primary" @click="fetchData(1)"><b-icon-search></b-icon-search></b-button>
    </div>
    <news-navigation></news-navigation>
  </auth-layout>
</template>

<script>
import { mapActions } from "vuex";
import NewsNavigation from "./../../components/News/navigation/NewsNavigation.vue";

export default {
  components: {
    NewsNavigation
  },
  data: () => ({
  }),
  computed: {
    keyword: {
      set(keyword) {
        this.changeKeyword({ keyword })
      },
      get() {
        return this.$store.getters["news/keyword"];
      }
    }
  },
  methods: {
    ...mapActions("news", ["fetchList", "changeKeyword"]),
    fetchData(page) {
      this.fetchList({ page });
    },
  },
  async mounted() {
    await this.fetchData(1);
  }
};
</script>

<style lang="scss" scoped></style>
