<template>
  <div class="header-container">
    <img src="/images/wefree_logo.png" alt="">
    <div class="d-flex align-items-center justify-content-center">
      <span>{{ user.fullname }}</span>
      <div class="separate"></div>
      <div class="logout" @click.once="logout">{{ $t('button.logout') }}</div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  computed: {
    ...mapState('auth', ['user']),
  },
  methods: {
    ...mapActions('auth', ['logout']),
  }
}
</script>

<style lang="scss" scoped>
.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  height: 50px;
  .separate {
    width: 1px;
    height: 20px;
    background-color: #ededed;
    margin: 0 10px;
  }
  .logout {
    cursor: pointer;
    color: blue;
  }
}
</style>
