var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-wait",
        { attrs: { for: "loading" } },
        [
          _c("template", { slot: "waiting" }, [
            _c(
              "div",
              {
                staticClass:
                  "loader-container d-flex justify-content-center align-items-center"
              },
              [
                _c("clip-loader", {
                  staticClass: "loader",
                  attrs: { loading: true, color: "#e72d2a", size: "100px" }
                })
              ],
              1
            )
          ])
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "component-fade", mode: "out-in" } },
        [_c("router-view")],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }