import Cookies from "js-cookie";
import axios from "./../../axios";
import router from "../../router";

export default {
  namespaced: true,
  state: {
    token: Cookies.get("wf-token") || "",
    user: {},
  },
  getters: {
    isAuthenticated: (state) => !!state.token,
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
    },
    setUser(state, user) {
      state.user = user;
    },
  },
  actions: {
    async login({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post("/api/auth/login", payload)
          .then(({ data }) => {
            Cookies.set("wf-token", data.token);
            Cookies.set("wf-user", data.user);
            commit("setToken", data.token);
            commit("setUser", data.user);

            axios.defaults.headers.common["Authorization"] = `Bearer ${data.token}`;
            resolve();
          })
          .catch((error) => {
            reject(error.response.data);
          });
      });
    },
    async setUser({ commit }, payload) {
      commit("setUser", payload);
    },
    async logout({ commit }) {
      return new Promise((resolve, reject) => {
        axios.post('/api/auth/logout')
          .then(() => {
            resolve();
          })
          .catch(() => {
            reject();
          })
          .finally(() => {
            Cookies.remove("wf-token");
            commit("setUser", {});
            commit("setToken", "");

            // remove the axios default header
            delete axios.defaults.headers.common["Authorization"];
            router.push({ name: "Login" });
          })
      })

    },
  },
};
