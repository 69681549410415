<template>
  <ValidationObserver
    ref="observer"
    v-slot="{ invalid, errors }"
    tag="form"
    mode="passive"
    @submit.prevent="submit"
  >
    <div
      ref="errorDiv"
      v-show="invalid || errMessage"
      class="text-danger d-flex justify-content-center mt-1"
    >
      <ul>
        <template v-for="error in errors">
          <li v-if="error[0]">{{ error[0] }}</li>
        </template>
        <li v-if="errMessage">{{ errMessage }}</li>
      </ul>
    </div>

    <b-form-row class="mb-2 p-2 justify-content-center align-items-center">
      <b-col md="3">Thành viên</b-col>
      <b-col>
        <multi-select
          v-model="form.user"
          :options="userOptions"
          :class="{ 'border border-danger': errors[0] }"
          label="fullname"
          track-by="id"
          :selectedLabel="$t('multiselect.selectedLabel')"
          :selectLabel="$t('multiselect.selectLabel')"
          placeholder=""
          :deselectLabel="$t('multiselect.deselectLabel')"
          @search-change="asyncFind"
          :internal-search="false"
          :show-no-results="true"
          :limit="3"
          :options-limit="300"
          open-direction="bottom"
          :loading="isLoading"
        >
          <span slot="noResult">{{ $t('message.notFound', { item: $t('item.user') }) }}</span>
          <span slot="noOptions">{{ $t('message.notFound', { item: $t('item.user') }) }}</span>
        </multi-select>
      </b-col>
    </b-form-row>
    <template v-if="form.user">
      <hr />
      <b-form-row class="mb-2 p-2 justify-content-center align-items-center">
        <b-col class="text-center h2">{{ $t('user.info') }}</b-col>
      </b-form-row>
      <b-form-row class="mb-2 info-row justify-content-center align-items-center">
        <b-col md="2" offset="2">
          <strong>{{ $t('user.fullname') }}</strong>
        </b-col>
        <b-col>{{ form.user.fullname || '' }}</b-col>
      </b-form-row>
      <b-form-row class="mb-2 info-row justify-content-center align-items-center">
        <b-col md="2" offset="2">
          <strong>{{ $t('user.email') }}</strong>
        </b-col>
        <b-col>{{ form.user.email || '' }}</b-col>
      </b-form-row>
      <b-form-row class="mb-2 info-row justify-content-center align-items-center">
        <b-col md="2" offset="2">
          <strong>{{ $t('user.tel') }}</strong>
        </b-col>
        <b-col>{{ form.user.tel || '' }}</b-col>
      </b-form-row>
      <b-form-row class="mb-2 info-row justify-content-center align-items-center">
        <b-col md="2" offset="2">
          <strong>{{ $t('user.memberID') }}</strong>
        </b-col>
        <b-col>{{ form.user.member_id || '' }}</b-col>
      </b-form-row>
    </template>
    <hr />
    <b-form-group>
      <b-button type="submit" size="lg" variant="success" :disabled="!form.user">{{ btnText }}</b-button>
    </b-form-group>
  </ValidationObserver>
</template>
<script>
import { mapActions } from "vuex";
import MultiSelect from "vue-multiselect";

export default {
  props: {
    campaignId: {
      type: String,
      required: true,
    },
    errMessage: {
      type: String,
      default: ""
    },
    btnText: {
      type: String,
      required: true
    },
    defaultForm: {
      type: Object,
      default: () => ({})
    }
  },

  components: {
    MultiSelect
  },

  data: () => ({
    userOptions: [],
    isLoading: false,
    dataStructure: {
      user: null
    }
  }),

  computed: {
    form: {
      get() {
        return Object.assign(this.dataStructure, this.defaultForm);
      }
    },
  },

  methods: {
    ...mapActions('userCampaign', ['findUser']),
    setErrorMessage(message = "") {
      this.$emit("setErrorMessage", message);
    },
    async submit() {
      this.setErrorMessage();

      const isValid = await this.$refs.observer.validate();

      if (!isValid) {
        return;
      }

      let data = {
        user_id: this.form.user.id,
        campaign_id: this.campaignId
      }
      this.$emit("submit", data);
    },

    asyncFind(keyword) {
      this.isLoading = true
      this.findUser({ keyword, campaignId: this.campaignId }).then(response => {
        this.userOptions = response
        this.isLoading = false
      })
    },
  },

  created() {
    this.asyncFind('');
  }
};
</script>
<style lang="scss" scoped>
.info-row {
  padding: 10px 20px 10px 40px;
}
</style>
