<template>
  <div>
    <b-form-group>
      <label>
        {{ $t("rewards.expireDate") }}
      </label>
      <v-date-picker
        v-model="rewardsExpireDate"
        mode="dateTime"
        :min-date="minEndDate"
        is24hr
        :locale="currentLocale"
      >
        <template v-slot="{ inputValue, inputEvents }">
          <input
            class="form-control"
            :value="inputValue"
            v-on="inputEvents"
            :placeholder="$t('datePicker.placeHolder')"
          />
        </template>
      </v-date-picker>
    </b-form-group>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";
import DatePicker from "v-calendar/lib/components/date-picker.umd";

export default {
  components: {
    "v-date-picker": DatePicker
  },
  data() {
    return {};
  },
  computed: {
    rewardsData() {
      return this.$store.getters["rewards/rewardsData"];
    },
    rewardsExpireDate: {
      get() {
        return this.rewardsData ? this.rewardsData.expire_date : "";
      },
      set(value) {
        let expireDate = moment(value);
        expireDate = expireDate.utc().format()
        this.changeRewardAttributesData({
          key: "expire_date",
          value: expireDate
        });
      }
    },
    minEndDate() {
      return new Date();
    },
    currentLocale() {
      return this.locale;
    },
  },
  methods: {
    ...mapActions("rewards", ["changeRewardAttributesData"])
  }
};
</script>
