<template>
  <b-form-group :invalid-feedback="invalidFeedback" :state="stateValid">
    <label>
      {{ $t("news.title") }}
      <span class="text-danger ml-1 small">{{ $t("subText.required") }}</span>
    </label>
    <b-form-input
      v-model="newsTitle"
      :state="stateValid"
      required
      trim
    ></b-form-input>
  </b-form-group>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {};
  },
  computed: {
    newsData() {
      return this.$store.getters["news/newsData"];
    },
    newsTitle: {
      get() {
        return this.newsData && this.newsData.title
          ? this.newsData.title
          : "";
      },
      set(value) {
        this.changeAttributesData({
          key: "title",
          value: value
        });
      }
    },
    stateValid() {
      if (!this.newsData || this.newsData.title === null) return null;
      if (
        this.newsData.title === "" ||
        (this.newsData.title && this.newsData.title.length > 100)
      )
        return false;
      return true;
    },
    invalidFeedback() {
      if (this.newsTitle && this.newsTitle.length > 100) {
        return this.$t("news.maxLength").replace(":length", "100");
      }
      return (
        this.$t("subText.required") + " " + this.$t("news.title").toLowerCase()
      );
    }
  },
  methods: {
    ...mapActions("news", ["changeAttributesData"])
  }
};
</script>
