<template>
  <div class="pagination">
    <template v-if="lastPage > 1">
      <div class="paginate-btn">
        <div
          v-if="currentPage > 1"
          :class="{'step first': true, disabled: currentPage == 1}"
          @click="(currentPage > 1) && changePage(currentPage - 1)"
        >
          {{ $t("pagination.toPreviousPage") }}
        </div>
      </div>
      <div v-if="currentPage > 1" class="page-number" @click="changePage(1)">1</div>
      <div v-if="currentPage > 4">…</div>
      <div
        class="page-number"
        v-for="pageNumber in prevPages"
        @click="changePage(pageNumber)"
      >
        {{ pageNumber }}
      </div>
      <div class="page-number active">{{ currentPage }}</div>
      <div
        class="page-number"
        v-for="pageNumber in nextPages"
        @click="changePage(pageNumber)"
      >
        {{ pageNumber }}
      </div>
      <div v-if="currentPage < lastPage - 4">…</div>
      <div class="page-number" v-if="currentPage < lastPage" @click="changePage(lastPage)">{{ lastPage }}</div>
      <div class="paginate-btn">
        <div
          v-if="currentPage < lastPage"
          :class="{'step last': true, disabled: currentPage == lastPage}"
          @click="(currentPage < lastPage) && changePage(currentPage + 1)"
        >
          {{ $t("pagination.toNextPage") }}
        </div>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  props: {
    currentPage: {
      type: Number
    },
    lastPage: {
      type: Number
    },
    canNext: {
      type: Boolean
    },
    canPrev: {
      type: Boolean
    }
  },

  computed: {
    prevPages() {
      let prevs = [];

      if (this.currentPage - 2 > 1) {
        prevs.push(this.currentPage - 2);
      }
      if (this.currentPage - 1 > 1) {
        prevs.push(this.currentPage - 1);
      }

      return prevs;
    },
    nextPages() {
      let nexts = [];

      if (this.currentPage + 1 < this.lastPage) {
        nexts.push(this.currentPage + 1);
      }
      if (this.currentPage + 2 < this.lastPage) {
        nexts.push(this.currentPage + 2);
      }
      if (this.currentPage + 3 < this.lastPage) {
        nexts.push(this.currentPage + 3);
      }

      return nexts;
    }
  },

  methods: {
    changePage(page) {
      this.$emit("change-page", page);
      window.scrollTo(0, 0);
    }
  }
};
</script>
<style lang="scss" scoped>
.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.07;
  letter-spacing: -0.01px;
  font-size: 14px;
  color: #5d5d5d;
  background-color: white;

  .step {
    cursor: pointer;
    color: #72b9ff;
    &.disabled {
      color: #5d5d5d;
      opacity: 0.5;
      cursor: default;
    }
  }
  .first {
    padding-right: 46px;
    text-align: right;
  }
  .last {
    padding-left: 46px;
  }
  .page-number {
    padding: 0 7px;
    color: #72b9ff;
    cursor: pointer;
    &.active {
      color: #343a40;
      cursor: default;
    }
  }
  .paginate-btn {
    width: 140px;
  }
}
</style>
