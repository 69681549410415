<template>
  <auth-layout>
    <main-header :title="$t('page.category.create')">
      <router-link
        :to="{ name: 'CategoryList' }"
        class="btn btn-outline-secondary"
        >{{ $t("page.category.list") }}</router-link
      >
    </main-header>
    <div class="card">
      <div class="card-body">
        <category-form
          :errMessage="errMessage"
          :btnText="$t('button.add')"
          @submit="submit"
        ></category-form>
      </div>
    </div>

    <b-modal
      centered
      :id="routeLeaveModalId"
      :title="$t('message.warning')"
      :ok-title="$t('button.ok')"
      :cancel-title="$t('button.cancel')"
      @ok="leave(targetRouteName)"
    >
      {{ $t('message.routeLeave') }}
    </b-modal>
  </auth-layout>
</template>
<script>
import CategoryForm from "../../components/Form/CategoryForm.vue";
import { mapActions } from "vuex";
import routeLeaveWarning from '../../mixins/routeLeaveWarning';

export default {
  mixins: [routeLeaveWarning],

  components: {
    CategoryForm
  },

  data: () => ({
    errMessage: '',
  }),

  methods: {
    ...mapActions('category', ['store']),
    submit(formData) {
      this.store(formData)
        .then(() => this.leave("CategoryList"))
        .catch((error) => this.errMessage = error.message || this.$t('message.commonError'))
    }
  },
}
</script>
