var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sidebar" }, [
    _c(
      "ul",
      [
        _c(
          "router-link",
          { attrs: { to: { name: "CampaignList" }, "active-class": "active" } },
          [_c("li", [_vm._v(_vm._s(_vm.$t("sidebar.campaign")))])]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          { attrs: { to: { name: "CategoryList" }, "active-class": "active" } },
          [_c("li", [_vm._v(_vm._s(_vm.$t("sidebar.category")))])]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          { attrs: { to: { name: "UserList" }, "active-class": "active" } },
          [_c("li", [_vm._v(_vm._s(_vm.$t("sidebar.user")))])]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            attrs: {
              to: { name: "UserRankingNavigation" },
              "active-class": "active"
            }
          },
          [_c("li", [_vm._v(_vm._s(_vm.$t("sidebar.userRanking")))])]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            attrs: {
              to: { name: "RewardsNavigation" },
              "active-class": "active"
            }
          },
          [_c("li", [_vm._v(_vm._s(_vm.$t("sidebar.rewards")))])]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            attrs: {
              to: { name: "NotificationList" },
              "active-class": "active"
            }
          },
          [_c("li", [_vm._v(_vm._s(_vm.$t("sidebar.notification")))])]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            attrs: { to: { name: "NewsNavigation" }, "active-class": "active" }
          },
          [_c("li", [_vm._v(_vm._s(_vm.$t("sidebar.news")))])]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            attrs: {
              to: { name: "MerchantsNavigation" },
              "active-class": "active"
            }
          },
          [_c("li", [_vm._v(_vm._s(_vm.$t("sidebar.merchants")))])]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            attrs: {
              to: { name: "SlidersNavigation" },
              "active-class": "active"
            }
          },
          [_c("li", [_vm._v(_vm._s(_vm.$t("sidebar.sliders")))])]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          { attrs: { to: { name: "SettingsList" }, "active-class": "active" } },
          [_c("li", [_vm._v(_vm._s(_vm.$t("sidebar.settings")))])]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          { attrs: { to: { name: "TemplateList" }, "active-class": "active" } },
          [_c("li", [_vm._v(_vm._s(_vm.$t("sidebar.template")))])]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            attrs: { to: { name: "ChangePassword" }, "active-class": "active" }
          },
          [_c("li", [_vm._v(_vm._s(_vm.$t("sidebar.changePassword")))])]
        ),
        _vm._v(" "),
        _c(
          "li",
          { staticClass: "p-0" },
          [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "b-toggle",
                    rawName: "v-b-toggle.collapse-1",
                    modifiers: { "collapse-1": true }
                  }
                ],
                staticClass:
                  "menu-toggle d-flex justify-content-between align-items-center"
              },
              [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("sidebar.export")) + "\n        "
                ),
                _c("b-icon-caret-down")
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-collapse",
              {
                attrs: {
                  id: "collapse-1",
                  visible:
                    _vm.$route.name === "ExportCampaigns" ||
                    _vm.$route.name === "ExportMembers"
                }
              },
              [
                _c(
                  "b-card-body",
                  { staticClass: "p-0" },
                  [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: { name: "ExportCampaigns" },
                          "active-class": "active"
                        }
                      },
                      [
                        _c("li", { staticClass: "pl-5" }, [
                          _vm._v(_vm._s(_vm.$t("sidebar.exportCampaign")))
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: { name: "ExportMembers" },
                          "active-class": "active"
                        }
                      },
                      [
                        _c("li", { staticClass: "pl-5" }, [
                          _vm._v(_vm._s(_vm.$t("sidebar.exportMember")))
                        ])
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }