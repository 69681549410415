<template>
  <div class="form-group">
    <button
      type="button"
      class="btn btn-lg btn-success"
      :disabled="!canSave"
      @click="onSave()"
    >
      {{ $t('button.update') }}
    </button>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      canSave: false
    };
  },
  computed: {
    rewardsData() {
      return this.$store.getters["rewards/rewardsData"];
    }
  },
  watch: {
    rewardsData: {
      handler: function(val, oldVal) {
        this.canSave = this.isValid();
      },
      deep: true
    }
  },
  methods: {
    ...mapActions("rewards", ["update"]),
    isValid() {
      return (
        // this.rewardsData.campaign_id &&
        this.rewardsData.name &&
        this.rewardsData.thumbnail
      );
    },
    onSave() {
      const formData = new FormData();
      formData.append('campaign_id', 1);
      if (this.rewardsData.user_id) {
        formData.append('user_id', this.rewardsData.user_id);
      }
      formData.append('description', this.rewardsData.description);
      if (this.rewardsData.expire_date) {
        formData.append('expire_date', this.rewardsData.expire_date.utc().format());
      }
      formData.append('name', this.rewardsData.name);
      formData.append('note', this.rewardsData.note ? this.rewardsData.note : '');
      if (typeof(this.rewardsData.thumbnail) !== 'string') {
        formData.append('thumbnail', this.rewardsData.thumbnail);
      }

      formData.id = this.$route.params.id;

      this.update(formData)
        .then(() => this.$emit('leave', "RewardsNavigation"))
        .catch((error) => {
          this.$bvToast.toast(error.message || this.$t("message.commonError"), {
            title: this.$t("message.errorTitle"),
            variant: "danger",
            autoHideDelay: 3000,
            solid: true
          });
        });
    }
  }
};
</script>
