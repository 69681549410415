<template>
  <auth-layout>
    <main-header :title="$t('page.sliders.edit')">
      <router-link
        :to="{ name: 'SlidersNavigation' }"
        class="btn btn-outline-secondary"
        >{{ $t("page.sliders.list") }}</router-link
      >
    </main-header>

    <div class="card">
      <div class="card-body">
        <sliders-data-blocks></sliders-data-blocks>
        <sliders-save-button-edit @leave="leave"></sliders-save-button-edit>
      </div>
    </div>

    <b-modal
      centered
      :id="routeLeaveModalId"
      :title="$t('message.warning')"
      :ok-title="$t('button.ok')"
      :cancel-title="$t('button.cancel')"
      @ok="leave(targetRouteName)"
    >
      {{ $t('message.routeLeave') }}
    </b-modal>
  </auth-layout>
</template>

<script>
import { mapActions } from 'vuex';
import routeLeaveWarning from '../../mixins/routeLeaveWarning'
import SlidersDataBlocks from './../../components/Sliders/data/blocks/SlidersDataBlocks'
import SlidersSaveButtonEdit from './../../components/Sliders/data/edit/SlidersSaveButtonEdit'

export default {
  mixins: [routeLeaveWarning],

  components: {
    SlidersDataBlocks,
    SlidersSaveButtonEdit
  },

  data: () => ({}),

  methods: {
    ...mapActions('sliders', ['fetchById'])
  },

  mounted() {
    this.fetchById({ id: this.$route.params.id });
  }
};
</script>

<style lang="scss" scoped></style>
