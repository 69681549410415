var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "header-container" }, [
    _c("img", { attrs: { src: "/images/wefree_logo.png", alt: "" } }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "d-flex align-items-center justify-content-center" },
      [
        _c("span", [_vm._v(_vm._s(_vm.user.fullname))]),
        _vm._v(" "),
        _c("div", { staticClass: "separate" }),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "logout",
            on: {
              "~click": function($event) {
                return _vm.logout.apply(null, arguments)
              }
            }
          },
          [_vm._v(_vm._s(_vm.$t("button.logout")))]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }