<template>
  <auth-layout>
    <main-header :title="$t('page.userCampaign.review')">
    </main-header>

    <b-card footer-tag="footer" footer-class="d-flex justify-content-center">
      <b-row class="align-items-center px-3 py-1">
        <b-col cols="3" class="title-strong">{{ $t("user.modal.rating_point") }}</b-col>
        <b-col>
          <template v-for="index in ratingPointTotal">
            <b-icon-star-fill
              font-scale="2"
              :class="{
                'icon-social': true,
                'icon-star-fill': ratingPoint >= index,
                'icon-star': ratingPoint < index,
              }"
              :key="index"
            ></b-icon-star-fill>
          </template>
          <span v-if="ratingPoint > 0" class="total-star__text">{{
            ratingPoint.toFixed(1)
          }}</span>
        </b-col>
      </b-row>
      <b-row class="align-items-center px-3 py-2">
        <b-col cols="3" class="title-strong">{{ $t("user.modal.link_social_1") }}</b-col>
        <b-col cols="9" class="d-flex">
          <span :class="iconClass(socialType1)"></span>
          <div class="w-75 text-truncate">
            <a v-if="linkSocial1" :href="linkSocial1" target="_blank">
              {{ linkSocial1 }}
            </a>
          </div>
        </b-col>
      </b-row>
      <b-row class="align-items-center px-3 py-2">
        <b-col offset="3" cols="3">
          {{ $t("user.modal.count_like") }} : {{ countLike1 }}
        </b-col>
        <b-col cols="3">
          {{ $t("user.modal.count_share") }} : {{ countShare1 }}
        </b-col>
        <b-col cols="3">
          {{ $t("user.modal.count_comment") }} : {{ countComment1 }}
        </b-col>
      </b-row>
      <b-row class="align-items-center px-3 py-2">
        <b-col cols="3" class="title-strong">{{ $t("user.modal.link_social_2") }}</b-col>
        <b-col cols="9" class="d-flex">
          <span :class="iconClass(socialType2)"></span>
          <div class="w-75 text-truncate">
            <a v-if="linkSocial2" :href="linkSocial2" target="_blank">
              {{ linkSocial2 }}
            </a>
          </div>
        </b-col>
      </b-row>
      <b-row class="align-items-center px-3 py-2">
        <b-col offset="3" cols="3">
          {{ $t("user.modal.count_like") }} : {{ countLike2 }}
        </b-col>
        <b-col cols="3">
          {{ $t("user.modal.count_share") }} : {{ countShare2 }}
        </b-col>
        <b-col cols="3">
          {{ $t("user.modal.count_comment") }} : {{ countComment2 }}
        </b-col>
      </b-row>
      <b-row class="align-items-center px-3 py-2">
        <b-col cols="3" class="title-strong">{{ $t("user.modal.link_social_3") }}</b-col>
        <b-col cols="9" class="d-flex">
          <span :class="iconClass(socialType3)"></span>
          <div class="w-75 text-truncate">
            <a v-if="linkSocial3" :href="linkSocial3" target="_blank">
              {{ linkSocial3 }}
            </a>
          </div>
        </b-col>
      </b-row>
      <b-row class="align-items-center px-3 py-2">
        <b-col offset="3" cols="3">
          {{ $t("user.modal.count_like") }} : {{ countLike3 }}
        </b-col>
        <b-col cols="3">
          {{ $t("user.modal.count_share") }} : {{ countShare3 }}
        </b-col>
        <b-col cols="3">
          {{ $t("user.modal.count_comment") }} : {{ countComment3 }}
        </b-col>
      </b-row>
      <b-row class="align-items-center px-3 py-2">
        <b-col cols="4" class="title-strong">
          {{ $t("user.modal.review_price") }}
        </b-col>
        <b-col cols="2">
          {{ reviewPrice }}
        </b-col>
        <b-col cols="4" class="title-strong">
          {{ $t("user.modal.review_packaging") }}
        </b-col>
        <b-col cols="2">
          {{ reviewPackaging }}
        </b-col>
      </b-row>
      <b-row class="align-items-center px-3 py-2">
        <b-col cols="4" class="title-strong">
          {{ $t("user.modal.review_customer_care") }}
        </b-col>
        <b-col cols="2">
          {{ reviewCustomerCare }}
        </b-col>
        <b-col cols="4" class="title-strong">
          {{ $t("user.modal.review_loyal") }}
        </b-col>
        <b-col cols="2">
          {{ reviewLoyal }}
        </b-col>
      </b-row>
      <b-row class="align-items-center px-3 py-2" v-if="images">
        <b-col cols="12" class="title-strong mb-2">{{ $t('user.modal.review_images') }}</b-col>
        <img v-for="i in images" :src="i.url" alt="Review image" width="400px" height="auto" class="m-1">
      </b-row>
      <template #footer>
        <router-link
          :to="{ name: 'UserCampaignList', params: { id } }"
          class="btn btn-outline-secondary mr-2"
          >{{ $t("button.back") }}</router-link
        >
        <b-button
          v-if="canApprove"
          variant="success"
          @click="openApprovalModal"
        >
          {{ $t('button.approveReview') }}
        </b-button>

        <b-button
          v-if="canCancel"
          variant="danger"
          @click="openCancelApprovalModal"
        >
          {{ $t('button.unapproveReview') }}
        </b-button>
      </template>
    </b-card>

    <confirm-modal
      :id="approveModalId"
      :confirmText="$t('userCampaign.reviewApproveConfirm')"
      @confirmed="approve"
      okButtonVariant="primary"
    ></confirm-modal>
    <confirm-modal
      :id="cancelApproveModalId"
      :confirmText="$t('userCampaign.reviewUnapproveConfirm')"
      @confirmed="cancel"
    ></confirm-modal>
  </auth-layout>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { RATING_STAR_TOTAL } from "../../constants/common";
import { SOCIAL_TYPE_FB, SOCIAL_TYPE_INSTAGRAM, SOCIAL_TYPE_TIKTOK } from "../../constants/campaign"

export default {
  props: {
    id: {
      type: String,
      required: true
    },
    userId: {
      type: String,
      required: true
    }
  },

  data: () => ({
    approveModalId: "review-approval-modal",
    cancelApproveModalId: "cancel-review-approval-modal",
    ratingPointTotal: RATING_STAR_TOTAL,
  }),

  computed: {
    ...mapState('user', ['reviewDetail']),
    canApprove() {
      return this.reviewDetail.approve_review == 0;
    },
    canCancel() {
      return this.reviewDetail.approve_review == 1;
    },
    linkSocial1() {
      return this.reviewDetail.link_social_1;
    },
    countLike1() {
      return Number(this.reviewDetail.count_like_1);
    },
    countShare1() {
      return Number(this.reviewDetail.count_share_1);
    },
    countComment1() {
      return Number(this.reviewDetail.count_comment_1);
    },
    socialType1() {
      return this.reviewDetail.social_type_1;
    },

    linkSocial2() {
      return this.reviewDetail.link_social_2;
    },
    countLike2() {
      return Number(this.reviewDetail.count_like_2);
    },
    countShare2() {
      return Number(this.reviewDetail.count_share_2);
    },
    countComment2() {
      return Number(this.reviewDetail.count_comment_2);
    },
    socialType2() {
      return this.reviewDetail.social_type_2;
    },

    linkSocial3() {
      return this.reviewDetail.link_social_3;
    },
    countLike3() {
      return Number(this.reviewDetail.count_like_3);
    },
    countShare3() {
      return Number(this.reviewDetail.count_share_3);
    },
    countComment3() {
      return Number(this.reviewDetail.count_comment_3);
    },
    socialType3() {
      return this.reviewDetail.social_type_3;
    },

    ratingPoint() {
      return Number(this.reviewDetail.rating_point);
    },
    reviewCustomerCare() {
      const review_customer_care = Number(this.reviewDetail.review_customer_care);
      const arrReviewCustomerCare = this.$t("user.modal.ARR_REVIEW_CUSTOMER_CARE");
      const result = arrReviewCustomerCare.find(item => {
        return item.code === review_customer_care;
      })
      return result ? result.text : '';

    },
    reviewLoyal() {
      const review_loyal = Number(this.reviewDetail.review_loyal);
      const arrReviewLoyal = this.$t("user.modal.ARR_REVIEW_LOYAL");
      const result = arrReviewLoyal.find(item => {
        return item.code === review_loyal;
      })
      return result ? result.text : '';
    },
    reviewPackaging() {
      const review_packaging = Number(this.reviewDetail.review_packaging);
      const arrReviewPackaging = this.$t("user.modal.ARR_REVIEW_PACKAGING");
      const result = arrReviewPackaging.find(item => {
        return item.code === review_packaging;
      })
      return result ? result.text : '';
    },
    reviewPrice() {
      const review_price = Number(this.reviewDetail.review_price);
      const arrReviewPrice = this.$t("user.modal.ARR_REVIEW_PRICE");
      const result = arrReviewPrice.find(item => {
        return item.code === review_price;
      })
      return result ? result.text : '';
    },

    // review images
    images() {
      return this.reviewDetail.images;
    },
  },

  methods: {
    ...mapActions("user", ["fetchReviewDetail"]),
    ...mapActions("userCampaign", ["approveReview", "cancelReview"]),
    // functions for approve modal
    openApprovalModal() {
      this.$bvModal.show(this.approveModalId);
    },
    approve() {
      this.approveReview({ campaignId: this.id, userId: this.userId })
        .then(() => {
          this.$router.push({ name: 'UserCampaignList', params: { id: this.id } })
        })
        .catch((error) => {
          this.$bvToast.toast(error.message || this.$t("message.commonError"), {
            title: this.$t("message.errorTitle"),
            variant: "danger",
            autoHideDelay: 3000,
            solid: true
          });
        })
    },

    // function for cancel approve modal
    openCancelApprovalModal() {
      this.$bvModal.show(this.cancelApproveModalId);
    },
    cancel() {
      this.cancelReview({ campaignId: this.id, userId: this.userId })
        .then(() => {
          this.$router.push({ name: 'UserCampaignList', params: { id: this.id } })
        })
        .catch((error) => {
          this.$bvToast.toast(error.message || this.$t("message.commonError"), {
            title: this.$t("message.errorTitle"),
            variant: "danger",
            autoHideDelay: 3000,
            solid: true
          });
        })
    },

    iconClass(type) {
      let classes = []
      switch (type) {
        case SOCIAL_TYPE_FB:
          classes.push('icon-facebook')
          break
        case SOCIAL_TYPE_INSTAGRAM:
          classes.push('icon-instagram')
          break
        case SOCIAL_TYPE_TIKTOK:
          classes.push('icon-tiktok')
          break

        default:
          break
      }

      if (classes.length > 0) {
        classes.push('icon-social')
      }

      return classes;
    }
  },

  created() {
    this.fetchReviewDetail({id: this.id, userId: this.userId})
      .catch(() => this.$router.go(-1))
  },
};
</script>

<style lang="scss" scoped>
.title-strong {
  width: 25%;
  font-weight: 700;
  color: #726f6f;
}
.icon-star-fill {
  color: #f5be26;
  font-size: 25px !important;
  margin-right: 15px;
}
.icon-star {
  color: #d5dbdd;
  font-size: 25px !important;
  margin-right: 15px;
}
.total-star__text {
  font-size: 25px;
  font-weight: 700;
  color: #f5be26;
  margin-left: 15px;
}
span.icon-social {
  display: inline-block;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-right: 20px;
  background-size: cover;
}

span.icon-facebook {
  background-image: url("/images/icon-facebook.ico");
}
.icon-instagram {
  background-image: url("/images/icon-instagram.ico");
}
.icon-tiktok {
  background-image: url("/images/icon-tiktok.png");
}
</style>
