<template>
  <div>
    <label class="col-form-label-lg">{{
      $t("user.points.list")
    }}</label>
    <table class="table table-borderless user-info">
      <tr>
        <td>{{ $t("user.points.sum") }}</td>
        <td>{{ userPointSum }}</td>
      </tr>
    </table>
    <table class="table">
      <thead>
        <tr>
          <th scope="col">{{ $t("user.points.no") }}</th>
          <th scope="col">{{ $t("user.points.type") }}</th>
          <th scope="col">{{ $t("user.points.point") }}</th>
          <th scope="col">{{ $t("user.points.relation_object") }}</th>
          <th scope="col">{{ $t("user.points.date") }}</th>
        </tr>
      </thead>
      <tbody>
        <template v-if="userPointHistories.length > 0">
          <tr v-for="(userPointHistory, index) in userPointHistories" :key="index">
            <td> {{ index + 1 }} </td>
            <td> {{ formatPointType(userPointHistory.type) }} </td>
            <td> {{ formatPoints(userPointHistory.points) }} </td>
            <td>
              <span>{{ showName(userPointHistory, true) }}: </span>
              <a v-if="userPointHistory.related_id" href="javascript:;" @click="openDetail(userPointHistory)">
                {{ showName(userPointHistory) }}
              </a>
            </td>
            <td> {{ formatDatetime(userPointHistory.created_at) }} </td>
          </tr>
        </template>
        <template v-else>
          <tr class="text-center">
            <td colspan="6">{{ $t("user.points.no_point") }}</td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import moment from "moment";
import { TYPE_RELATE_CAMPAIGN, TYPE_RELATE_USER } from '../../../constants/user';
import routeLeaveWarning from '../../../mixins/routeLeaveWarning';

export default {
  mixins: [routeLeaveWarning],
  computed: {
    userPointHistories() {
      return this.$store.state.user.detail.pointHistories;
    },
    userPointSum() {
      let sum = this.$store.state.user.detail.pointHistories.reduce(
        (acc, currentHistory) => {
          return acc + currentHistory.points;
        }, 0
      );
      return sum;
    }
  },
  methods: {
    formatPointType(type) {
      if (type) {
        let USER_POINT_TYPE = [this.$t("user.points.share"),
                               this.$t("user.points.like"),
                               this.$t("user.points.invite"),
                               this.$t("user.points.invited"),
                               this.$t("user.points.cancel_campaign"),
                               this.$t("user.points.cancel_review")]
        return USER_POINT_TYPE[type - 1];
      }
    },
    formatPoints(points) {
      if (points) {
        if (points > 0) {
          return `+${points}`;
        } else {
          return `${points}`;
        }
      }
    },
    formatDatetime(datetime) {
      if (datetime) {
        return moment.utc(datetime).format('DD/MM/YYYY HH:mm:ss');
      }
      return '';
    },
    showName(item, isLabel = false) {
      let name = ''
      if (TYPE_RELATE_USER.indexOf(item.type) > -1) {
        let value = ''
        if (item.fullname) {
          value = item.fullname
        } else if (item.email) {
          value = item.email
        } else if (item.tel) {
          value = item.tel
        }
        name = isLabel ? this.$t('user.user') : value
      }
      if (TYPE_RELATE_CAMPAIGN.indexOf(item.type) > -1) {
        name = isLabel ? this.$t('user.campaign') : item.name
      }
      return name
    },
    openDetail(item) {
      if (TYPE_RELATE_USER.indexOf(item.type) > -1) {
        window.open(`/users/${item.related_id}/detail`, "_blank")
      }
      if (TYPE_RELATE_CAMPAIGN.indexOf(item.type) > -1) {
        window.open(`/campaigns/${item.related_id}/edit`, "_blank")
      }
    }
  },
}

</script>

<style lang="scss" scoped>
.table thead th {
  position: relative;
}
.user-info tr td:first-child {
  width: 25%;
  font-weight: 700;
  color: #726f6f;
}
</style>
