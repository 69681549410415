import axios from "../../axios";

const state = {
  listMerchants: [],
  pageObject: {},
};

const getters = {
  listMerchants: state => state.listMerchants,
  pageObject: state => state.pageObject,
};

const actions = {
  fetchList({ commit }, payload) {
    return new Promise((resolve, reject) => {
      let { page, branch_name, service_type, primary_markets } = payload;
      if (!page) {
        page = 1;
      }
      let url = `/api/merchants?page=${page}&branch_name=${branch_name}&service_type=${service_type}&primary_markets=${primary_markets}`;
      axios.get(url).then(({ data }) => {
        const listMerchants = data.data.list_merchants;
        const currentPage = data.data.current_page;
        const lastPage = data.data.last_page;
        commit("setListMerchants", listMerchants);
        commit("setPagination", {
          currentPage,
          lastPage,
          canNext: currentPage < lastPage,
          canPrev: currentPage > 1
        });
      });
    });
  },
  destroy({}, payload) {
    return new Promise((resolve, reject) => {
      const { id } = payload;
      axios
        .delete(`/api/merchants/${id}`)
        .then(() => {
          resolve();
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },
};

const mutations = {
  setListMerchants(state, data) {
    state.listMerchants = data;
  },
  setPagination(state, data) {
    state.pageObject = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
