import axios from "../../axios";
import moment from "moment";

export default {
  namespaced: true,
  state: {
    campaigns: [],
    pageObject: {},
    categoryOptions: [],
    socialOptions: [],
    rewardOptions: [],
  },
  mutations: {
    setCategoryOptions(state, options) {
      if (Array.isArray(options)) {
        state.categoryOptions.length = 0;
        state.categoryOptions = [...options];
      }
    },
    setSocialOptions(state, options) {
      if (Array.isArray(options)) {
        state.socialOptions.length = 0;
        state.socialOptions = [...options];
      }
    },
    setCampaigns(state, campaigns) {
      if (Array.isArray(campaigns)) {
        state.campaigns.length = 0;
        state.campaigns = [...campaigns];
      }
    },
    setPaginationObject(state, pageObject) {
      Object.assign(state.pageObject, pageObject);
    },
    setRewardOptions(state, options) {
      if (Array.isArray(options)) {
        state.rewardOptions.length = 0;
        state.rewardOptions = [...options];
      }
    },
  },
  actions: {
    fetchCampaigns({commit}, payload) {
      return new Promise((resolve, reject) => {
        let { page, selectedCategoryIds, keyword, createdFrom, createdTo  } = payload;
        let url = `/api/campaigns`;
        if (!page) {
          page = 1
        }

        axios.get(url, {
          params: {
            category: selectedCategoryIds,
            name: _.isEmpty(keyword) ? null : keyword,
            created_from: _.isEmpty(createdFrom) ? null : createdFrom,
            created_to: _.isEmpty(createdTo) ? null : createdTo,
            page
          }
        })
          .then(({data}) => {
            commit('setCampaigns', data.data);
            commit('setPaginationObject', {
              currentPage: data.current_page,
              lastPage: data.last_page,
              canNext: data.current_page < data.last_page,
              canPrev: data.current_page > 1
            })
          })
      });
    },
    fetchCategoryOptions({commit}) {
      return new Promise((resolve, reject) => {
        axios.get('/api/campaigns/category-options')
          .then(({data}) => {
            commit('setCategoryOptions', data);
            resolve();
          })
      });
    },
    fetchSocialOptions({commit}) {
      return new Promise((resolve, reject) => {
        axios.get('/api/campaigns/social-options')
          .then(({data}) => {
            commit('setSocialOptions', data);
            resolve();
          })
      });
    },
    store({commit}, payload) {
      return new Promise((resolve, reject) => {
        axios.post('/api/campaigns', payload)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({response}) => reject(response.data))
      })
    },
    fetchCampaignById({commit}, payload) {
      return new Promise((resolve, reject) => {
        const {id} = payload;
        axios.get(`/api/campaigns/${id}`)
          .then(({data}) => {
            data.data.end_time = moment.utc(data.data.end_time).format();
            resolve(data.data)
          })
          .catch(({response}) => reject(response.data))
      })
    },
    update({commit}, payload) {
      return new Promise((resolve, reject) => {
        const {id} = payload;
        axios.post(`/api/campaigns/${id}`, payload)
          .then(() => resolve())
          .catch(({response}) => reject(response.data))
      })
    },
    destroy({}, payload) {
      return new Promise((resolve, reject) => {
        const { id } = payload;
        axios.delete(`/api/campaigns/${id}`)
          .then(() => resolve())
          .catch(({response}) => reject(response.data))
      })
    },
    fetchRewardOptions({commit}) {
      return new Promise((resolve, reject) => {
        axios.get('/api/campaigns/reward-options')
          .then(({data}) => {
            commit('setRewardOptions', data);
            resolve();
          })
      });
    },
    getDefaultWarningText({}) {
      return new Promise((resolve, reject) => {
        axios.get('/api/settings/warning-text')
          .then(({data}) => resolve(data.warning_text))
          .catch((error) => reject(error))
      })
    }
  },
}
