<template>
  <div>
    <b-row>
      <b-col>
        <b-form-group :invalid-feedback="invalidFeedback" :state="stateValid">
          <label>
            {{ $t("sliders.date_from") }}
          </label>
          <v-date-picker
            v-model="slideDateFrom"
            mode="dateTime"
            :min-date="minDateFrom"
            is24hr
            :locale="currentLocale"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <input
                class="form-control sliders-date"
                :value="inputValue"
                v-on="inputEvents"
                :placeholder="$t('datePicker.placeHolder')"
              />
            </template>
          </v-date-picker>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group :invalid-feedback="invalidFeedback" :state="stateValid">
          <label>
            {{ $t("sliders.date_to") }}
          </label>
          <v-date-picker
            v-model="slideDateTo"
            mode="dateTime"
            :min-date="minDateTo"
            is24hr
            :locale="currentLocale"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <input
                class="form-control sliders-date"
                :value="inputValue"
                v-on="inputEvents"
                :placeholder="$t('datePicker.placeHolder')"
              />
            </template>
          </v-date-picker>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";
import DatePicker from "v-calendar/lib/components/date-picker.umd";

export default {
  components: {
    "v-date-picker": DatePicker,
  },
  data() {
    return {
      minDateFrom: null,
      minDateTo: null,
    };
  },
  computed: {
    currentLocale() {
      return this.locale;
    },
    slidersData() {
      return this.$store.getters["sliders/slidersData"];
    },
    slideDateFrom: {
      get() {
        return this.slidersData && this.slidersData.date_from !== null
          ? this.slidersData.date_from
          : null;
      },
      set(value) {
        let dateFrom = moment(value);
        dateFrom = dateFrom.utc().format()
        this.changeAttributesData({
          key: "date_from",
          value: dateFrom
        });
      }
    },
    slideDateTo: {
      get() {
        return this.slidersData && this.slidersData.date_to !== null
          ? this.slidersData.date_to
          : null;
      },
      set(value) {
        let dateTo = moment(value);
        dateTo = dateTo.utc().format()
        this.changeAttributesData({
          key: "date_to",
          value: dateTo
        });
      }
    },
    stateValid() {
      if (this.slidersData.date_from > this.slidersData.date_to)
        return false;
      return null;
    },
    invalidFeedback() {
      if (this.slidersData.date_from > this.slidersData.date_to) {
        return this.$t("sliders.date_from_to_invalid");
      }
    }
  },
  methods: {
    ...mapActions("sliders", ["changeAttributesData"])
  },
  created() {
    this.minDateTo = new Date();
  },
};
</script>

<style lang="scss" scoped></style>
