<template>
  <auth-layout>
    <main-header :title="$t('mail.templateTitle', { title: mailTitle })">
      <router-link
        :to="{ name: 'TemplateList' }"
        class="btn btn-outline-secondary"
        >{{ $t("page.mail.list") }}</router-link
      >
    </main-header>

    <div class="card">
      <div class="card-body">
        <ValidationObserver
          ref="observer"
          v-slot="{ invalid, errors }"
          tag="form"
          mode="passive"
          @submit.prevent="submit"
          v-if="template"
        >
          <div
            ref="errorDiv"
            v-show="invalid || errMessage"
            class="text-danger d-flex justify-content-center mt-1"
          >
            <ul>
              <template v-for="error in errors">
                <li v-if="error[0]">{{ error[0] }}</li>
              </template>
              <li v-if="errMessage">{{ errMessage }}</li>
            </ul>
          </div>
          <b-form-row class="mb-2 p-2 justify-content-center align-items-center">
            <b-col md="3" class="d-flex align-items-center justify-content-between text-strong text-grey">
              {{ $t("mail.subject") }}
            </b-col>
            <b-col>
              <ValidationProvider
                mode="passive"
                :name="$t('mail.subject')"
                rules="required|max:100"
                v-slot="{ errors }"
              >
                <b-form-input
                  v-model="template.mail_subject"
                  type="text"
                  maxlength="100"
                  :class="{
                    'border border-danger': errors[0]
                  }"
                ></b-form-input>
              </ValidationProvider>
            </b-col>
            <b-col md="2" v-if="template.mail_subject_postfix">[{{ template.mail_subject_postfix }}]</b-col>
          </b-form-row>
          <b-form-row class="mb-2 p-2 justify-content-center">
            <b-col md="3" class="">
              {{ $t("mail.mailContent") }}
              <p class="content-rule"><i>{{$t('mail.contentRule')}}</i></p>
            </b-col>
            <b-col md="9">
              <ValidationProvider
                mode="passive"
                :name="$t('mail.mailContent')"
                rules="required|max:1024"
                v-slot="{ errors }"
              >
                <ckeditor :editor="editor" v-model="template.mail_content" :config="editorConfig" tag-name="textarea"></ckeditor>
              </ValidationProvider>
            </b-col>
          </b-form-row>
          <b-form-row class="mb-2 p-2 justify-content-center align-items-center">
            <b-col md="3" class="d-flex align-items-center justify-content-between">
              {{ $t("mail.isAutoSend") }}
            </b-col>
            <b-col>
              <b-form-checkbox switch v-model="template.is_auto_send"></b-form-checkbox>
            </b-col>
          </b-form-row>
          <template v-if="template.has_notification">
            <hr />
            <b-form-row class="mb-2 p-2 justify-content-center align-items-center">
              <b-col md="3" class="d-flex align-items-center justify-content-between">
                {{ $t("mail.notificationTitle") }}
              </b-col>
              <b-col>
                <ValidationProvider
                mode="passive"
                :name="$t('mail.notificationTitle')"
                rules="required|max:100"
                v-slot="{ errors }"
              >
                <b-form-input
                  v-model="template.notification_title"
                  type="text"
                  maxlength="100"
                  :class="{
                    'border border-danger': errors[0]
                  }"
                ></b-form-input>
              </ValidationProvider>
              </b-col>
            </b-form-row>
            <b-form-row class="mb-2 p-2 justify-content-center align-items-center">
              <b-col md="3" class="d-flex align-items-center justify-content-between">
                {{ $t("mail.isAutoPush") }}
              </b-col>
              <b-col>
                <b-form-checkbox switch v-model="template.is_auto_push"></b-form-checkbox>
              </b-col>
            </b-form-row>
          </template>
          <hr />
          <b-form-group>
            <b-button type="submit" size="lg" variant="success">{{ $t('button.update') }}</b-button>
          </b-form-group>
        </ValidationObserver>
      </div>
    </div>

    <b-modal
      centered
      :id="routeLeaveModalId"
      :title="$t('message.warning')"
      :ok-title="$t('button.ok')"
      :cancel-title="$t('button.cancel')"
      @ok="leave(targetRouteName)"
    >
      {{ $t('message.routeLeave') }}
    </b-modal>
  </auth-layout>
</template>
<script>
import { mapActions } from 'vuex'
import routeLeaveWarning from '../../mixins/routeLeaveWarning'

export default {
  mixins: [routeLeaveWarning],

  props: {
    id: {
      required: true
    },
  },

  data() {
    return {
      template: null,
      editor: ClassicEditor,
      errMessage: null
    }
  },

  computed: {
    mailTitle() {
      return this.template ? this.template.title : '';
    },
    editorConfig() {
      return {
        fontSize: {
            options: [
                9,
                11,
                13,
                'default',
                17,
                19,
                21
            ]
        },
        toolbar: [
          'heading',
          '|',
          'bold',
          'italic',
          'link',
          'bulletedList',
          'numberedList',
          'underline',
          '|',
          'outdent',
          'indent',
          '|',
          'fontBackgroundColor',
          'fontColor',
          'fontSize',
          '|',
          'blockQuote',
          'undo',
          'redo',
          '|',
          'sourceEditing'
        ],
      }
    },
  },

  methods: {
    ...mapActions('template', ['retrieve', 'update']),
    async submit() {
      const isValid = await this.$refs.observer.validate();

      if (!isValid) {
        return;
      }

      this.update(this.template)
        .then(() => this.leave("TemplateList"))
        .catch((error) => this.errMessage = error.message)
    }
  },

  created() {
    this.retrieve({ id: this.$route.params.id })
      .then((data) => this.template = data)
      .catch(() => this.leave("TemplateList"))
  }
}
</script>
<style lang="scss" scoped>
.content-rule {
  font-size: 0.85rem;
  color: blue;
}
</style>
