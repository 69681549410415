<template>
  <b-form-group>
    <label>
      {{ $t("news.imageSecondary") }}
      <span class="small text-gray">
        {{ $t("subText.maxImageCount", { n: MAX_IMAGE_COUNT }) }}
      </span>
    </label>
    <button
      class="btn btn-primary ml-1 mb-2"
      type="button"
      @click="addImageSlot"
      :disabled="disabled"
    >
      {{ $t("button.addImageSlot") }}
    </button>
    <b-form-row>
      <b-col
        md="4"
        v-for="(image, index) in secondaryImage"
        :key="`image_${index}`"
      >
        <image-input
          @image-removal="removeImageSlot"
          @image-input="addOrUpdateImage"
          :index="index"
          :data="image"
        ></image-input>
      </b-col>
    </b-form-row>
  </b-form-group>
</template>

<script>
import { mapActions } from "vuex";
import { MAX_IMAGE_COUNT } from "../../../../constants/common";
import ImageInput from "../../../Input/ImageInput.vue";

export default {
  components: {
    ImageInput
  },
  data() {
    return {
      MAX_IMAGE_COUNT: MAX_IMAGE_COUNT,
      images: []
    };
  },
  computed: {
    newsData() {
      return this.$store.getters["news/newsData"];
    },
    secondaryImage: {
      set(value) {
        this.changeAttributesData({
          key: "secondary_image",
          value: value
        });
      },
      get() {
        return (this.newsData && this.newsData.secondary_image)
          ? this.newsData.secondary_image
          : [];
      }
    },
    removalImageIds: {
      set(value) {
        this.addRemovalImageIds({
          id: value
        });
      },
      get() {
        return this.removalImageIds ? this.removalImageIds : [];
      }
    },
    disabled() {
      return (
        this.secondaryImage && this.secondaryImage.length >= MAX_IMAGE_COUNT
      );
    }
  },
  methods: {
    ...mapActions("news", ["changeAttributesData", "addRemovalImageIds"]),
    removeImageSlot(payload) {
      if (null != payload.id) {
        this.removalImageIds = payload.id;
      }
      let images = this.secondaryImage;
      images.splice(payload.index, 1);
      this.secondaryImage = images;
    },
    addOrUpdateImage(payload) {
      let images = this.secondaryImage;
      images[payload.index] = payload.data;
      this.secondaryImage = images;
    },
    addImageSlot() {
      if (this.images.length < this.MAX_IMAGE_COUNT) {
        let images = this.secondaryImage;
        images.push({
          id: null,
          content: null,
          url: null,
          isDirty: false
        });
        this.secondaryImage = images;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.thumbnail {
  max-width: 250px;
  max-height: 250px;
  cursor: pointer;
}
</style>
