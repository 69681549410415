<template>
  <ValidationObserver
    ref="observer"
    v-slot="{ invalid, errors }"
    tag="form"
    mode="passive"
    @submit.prevent="submit"
  >
    <div
      ref="errorDiv"
      v-show="invalid || errMessage"
      class="text-danger d-flex justify-content-center mt-1"
    >
      <ul>
        <template v-for="error in errors">
          <li v-if="error[0]">{{ error[0] }}</li>
        </template>
        <li v-if="errMessage">{{ errMessage }}</li>
      </ul>
    </div>
    <b-form-group>
      <label for="">
        {{ $t("notification.title") }}
        <span class="text-danger small">{{ $t("subText.required") }}</span>
      </label>
      <ValidationProvider
        mode="passive"
        :name="$t('notification.title')"
        rules="required|max:256"
        v-slot="{ errors }"
      >
        <b-form-input
          v-model="form.title"
          maxlength="256"
          :class="{ 'border border-danger': errors[0] }"
        ></b-form-input>
      </ValidationProvider>
    </b-form-group>
    <b-form-group>
      <label for="">
        {{ $t("notification.content") }}
        <span class="text-danger small">{{ $t("subText.required") }}</span>
      </label>
      <ValidationProvider
        mode="passive"
        :name="$t('notification.content')"
        rules="required|max:256"
        v-slot="{ errors }"
      >
        <b-form-textarea
          v-model="form.content"
          maxlength="256"
          :class="{ 'border border-danger': errors[0] }"
        ></b-form-textarea>
      </ValidationProvider>
    </b-form-group>
    <b-form-row>
      <b-col md="4">
        <b-form-group>
          <label for="">
            {{ $t("notification.type") }}
            <span class="text-danger small">{{ $t("subText.required") }}</span>
          </label>
          <ValidationProvider
            mode="passive"
            :name="$t('notification.type')"
            rules="required"
            v-slot="{ errors }"
          >
            <multi-select
              v-model="form.type"
              :options="typeOptions"
              :class="{ 'border border-danger': errors[0] }"
              :searchable="false"
              label="display_name"
              track-by="id"
              :selectedLabel="$t('multiselect.selectedLabel')"
              :selectLabel="$t('multiselect.selectLabel')"
              placeholder=""
              :deselectLabel="$t('multiselect.deselectLabel')"
            ></multi-select>
          </ValidationProvider>
        </b-form-group>
      </b-col>
      <b-col>
        <template v-if="form.type && form.type.id == TYPE_SELECTED_USER">
          <label for="">
            {{ $t("notification.users") }}
            <span class="text-danger small">{{ $t("subText.required") }}</span>
          </label>
          <ValidationProvider
            mode="passive"
            :name="$t('notification.users')"
            rules="required"
            v-slot="{ errors }"
          >
            <multi-select
              id="ajax"
              v-model="form.users"
              :options="userOptions"
              multiple
              :class="{ 'border border-danger': errors[0] }"
              label="fullname"
              track-by="id"
              :selectedLabel="$t('multiselect.selectedLabel')"
              :selectLabel="$t('multiselect.selectLabel')"
              placeholder=""
              :deselectLabel="$t('multiselect.deselectLabel')"
              @search-change="asyncFind"
              :clear-on-select="false"
              :close-on-select="false"
              :internal-search="false"
              :show-no-results="false"
              :hide-selected="true"
              :limit="3"
              :options-limit="300"
              open-direction="bottom"
              :loading="isLoading"
            >
              <template slot="clear" slot-scope="props">
                <div class="multiselect__clear" v-if="form.users.length" @mousedown.prevent.stop="clearAll(props.search)"></div>
              </template>
              <span slot="noResult">{{ $t('message.notFound', { item: $t('item.user') }) }}</span>
            </multi-select>
          </ValidationProvider>
        </template>
      </b-col>
    </b-form-row>
    <hr />
    <b-form-group>
      <b-button type="submit" size="lg" variant="success">{{
        btnText
      }}</b-button>
    </b-form-group>
  </ValidationObserver>
</template>
<script>
import MultiSelect from "vue-multiselect";
import { TYPE_ALL, TYPE_SELECTED_USER } from "../../constants/notification";
import { mapActions } from "vuex";

export default {
  props: {
    errMessage: {
      type: String,
      default: ""
    },
    btnText: {
      type: String,
      required: true
    },
    defaultForm: {
      type: Object,
      default: () => ({})
    }
  },

  components: {
    MultiSelect
  },

  data: () => ({
    TYPE_SELECTED_USER: TYPE_SELECTED_USER,
    dataStructure: {
      title: "",
      content: "",
      type: null,
      users: []
    },
    userOptions: [],
    isLoading: false,
  }),

  computed: {
    form: {
      get() {
        return Object.assign(this.dataStructure, this.defaultForm);
      }
    },
    typeOptions() {
      return [
        {
          id: TYPE_ALL,
          display_name: this.$t("notification.type_all")
        },
        {
          id: TYPE_SELECTED_USER,
          display_name: this.$t("notification.type_selected_user")
        }
      ];
    }
  },

  methods: {
    ...mapActions('notification', ['findUser']),
    setErrorMessage(message = "") {
      this.$emit("setErrorMessage", message);
    },
    async submit() {
      this.setErrorMessage();

      const isValid = await this.$refs.observer.validate();

      if (!isValid) {
        return;
      }

      let data = this.mapFormData();
      this.$emit("submit", data);
    },

    mapFormData() {
      let data = {
        title: this.form.title,
        content: this.form.content,
        type: null
      };

      if (this.form.type.id) {
        data.type = this.form.type.id;
      }

      if (data.type == TYPE_SELECTED_USER && this.form.users.length > 0) {
        data.users = this.form.users.map(el => el.id);
      }

      return data;
    },

    asyncFind(keyword) {
      this.isLoading = true
      this.findUser({keyword}).then(response => {
        this.userOptions = response
        this.isLoading = false
      })
    },
  },

  created() {
    this.asyncFind('');
  }
};
</script>
