<template>
  <div class="form-group">
    <button
      type="button"
      class="btn btn-lg btn-success"
      :disabled="!canSave"
      @click="onSave()"
    >
      {{ $t('button.update') }}
    </button>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";
import ValidateHandler from './../../../../mixins/ValidateHandler'

export default {
  mixins: [ValidateHandler],
  data() {
    return {
      canSave: false
    };
  },
  computed: {
    slidersData() {
      return this.$store.getters["sliders/slidersData"];
    },
    slidersImage() {
      return this.slidersData.image;
    }
  },
  watch: {
    slidersData: {
      handler: function(val, oldVal) {
        this.canSave = this.isValid();
      },
      deep: true
    },
  },
  methods: {
    ...mapActions("sliders", ["update"]),
    isValid() {
      const resultValidateURL = this.slidersData.url ? this.validateURL(this.slidersData.url) : true;
      return (
        this.slidersData.name &&
        this.slidersImage &&
        (this.slidersData.date_from <= this.slidersData.date_to) &&
        resultValidateURL
      );
    },
    onSave() {
      const formData = new FormData();
      formData.append('name', this.slidersData.name);
      if (this.slidersImage && typeof this.slidersImage.name === 'string') {
        formData.append('image', this.slidersImage);
      }
      formData.append('url', this.slidersData.url);
      formData.append('description', this.slidersData.description);
      formData.append('date_from', this.slidersData.date_from);
      formData.append('date_to', this.slidersData.date_to);
      formData.append('slide_order', this.slidersData.slide_order);

      formData.id = this.$route.params.id;

      this.update(formData)
        .then(() => this.$emit('leave', "SlidersNavigation"))
        .catch((error) => {
          this.$bvToast.toast(error.message || this.$t("message.commonError"), {
            title: this.$t("message.errorTitle"),
            variant: "danger",
            autoHideDelay: 3000,
            solid: true
          });
        });
    }
  }
};
</script>
