import axios from "../../axios";
import moment from "moment";

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    fetchCampaigns({}, payload) {
      return new Promise((resolve, reject) => {
        let { keyword  } = payload;
        let url = `/api/reports/campaigns`;

        axios.get(url, {
          params: {
            q: _.isEmpty(keyword) ? null : keyword,
          }
        })
          .then(({data}) => resolve(data))
          .catch(({response}) => reject(response.data))
      });
    },

    downloadCampaigns({}, payload) {
      return new Promise((resolve, reject) => {
        const { ids } = payload
        const url = "/api/reports/campaigns/download";
        const params = {
          campaign_ids: ids
        }

        axios({
          method: 'GET',
          url,
          responseType: 'blob', // Don't forget this. This is very important. Read more: https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/Sending_and_Receiving_Binary_Data
          params
        })
          .then((response) => {
            const currentTime = moment().format('YYYYMMDDHHmmss');

            let filename = "campaign_reports_" + currentTime + ".xlsx";
            let blob = new Blob([response.data], { type: 'text/excel' });
            if (window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveBlob(blob, filename);
            }
            else {
              var elem = window.document.createElement('a');
              elem.href = window.URL.createObjectURL(blob);
              elem.download = filename;
              document.body.appendChild(elem);
              elem.click();
              document.body.removeChild(elem);
            }

            resolve();
          })
          .catch(error => reject(error))
      })
    },

    downloadComments({}, payload) {
      return new Promise((resolve, reject) => {
        const { ids } = payload
        const url = "/api/reports/comments/download";
        const params = {
          campaign_ids: ids
        }

        axios({
          method: 'GET',
          url,
          responseType: 'blob', // Don't forget this. This is very important. Read more: https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/Sending_and_Receiving_Binary_Data
          params
        })
          .then((response) => {
            const currentTime = moment().format('YYYYMMDDHHmmss');

            let filename = "comment_reports_" + currentTime + ".xlsx";
            let blob = new Blob([response.data], { type: 'text/excel' });
            if (window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveBlob(blob, filename);
            }
            else {
              var elem = window.document.createElement('a');
              elem.href = window.URL.createObjectURL(blob);
              elem.download = filename;
              document.body.appendChild(elem);
              elem.click();
              document.body.removeChild(elem);
            }

            resolve();
          })
          .catch(error => reject(error))
      })
    },

    downloadMembers({}, payload) {
      return new Promise((resolve, reject) => {
        const { ids } = payload
        const url = "/api/reports/members/download";
        const params = {
          campaign_ids: ids
        }

        axios({
          method: 'GET',
          url,
          responseType: 'blob', // Don't forget this. This is very important. Read more: https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/Sending_and_Receiving_Binary_Data
          params
        })
          .then((response) => {
            const currentTime = moment().format('YYYYMMDDHHmmss');

            let filename = "member_reports_" + currentTime + ".xlsx";
            let blob = new Blob([response.data], { type: 'text/excel' });
            if (window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveBlob(blob, filename);
            }
            else {
              var elem = window.document.createElement('a');
              elem.href = window.URL.createObjectURL(blob);
              elem.download = filename;
              document.body.appendChild(elem);
              elem.click();
              document.body.removeChild(elem);
            }

            resolve();
          })
          .catch(error => reject(error))
      })
    },

    fetchUsers({}, payload) {
      return new Promise((resolve, reject) => {
        let { keyword  } = payload;
        let url = `/api/reports/users`;

        axios.get(url, {
          params: {
            q: _.isEmpty(keyword) ? null : keyword,
          }
        })
          .then(({data}) => resolve(data))
          .catch(({response}) => reject(response.data))
      });
    },

    downloadMemberData({}, payload) {
      return new Promise((resolve, reject) => {
        const { ids } = payload
        const url = `/api/reports/member-reports/download`;

        axios({
          method: 'GET',
          url,
          responseType: 'blob', // Don't forget this. This is very important. Read more: https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/Sending_and_Receiving_Binary_Data
          params: { ids }
        })
          .then((response) => {
            const currentTime = moment().format('YYYYMMDDHHmmss');

            let filename = "member_reports_" + currentTime + ".xlsx";
            let blob = new Blob([response.data], { type: 'text/excel' });
            if (window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveBlob(blob, filename);
            }
            else {
              var elem = window.document.createElement('a');
              elem.href = window.URL.createObjectURL(blob);
              elem.download = filename;
              document.body.appendChild(elem);
              elem.click();
              document.body.removeChild(elem);
            }

            resolve();
          })
          .catch(error => reject(error))
      })
    },

  },
}
