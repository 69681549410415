<template>
  <auth-layout>
    <div class="filter-bar">
      <b-col md="3" class="primary-market--multi-select">
        <multi-select
          v-model="primaryMarkets"
          :options="options"
          label="value"
          track-by="id"
          multiple
          class="campaign-category-selector"
          :selectedLabel="$t('multiselect.selectedLabel')"
          selectLabel=""
          :searchable="false"
          :close-on-select="false"
          :clear-on-select="false"
          :placeholder="$t('merchants.primaryMarket')"
          :deselectLabel="$t('multiselect.deselectLabel')"
        >
          <template slot="selection" slot-scope="{ values, isOpen }">
            <span class="multiselect__single" v-if="values.length && !isOpen">
              {{ $t('message.selectedCount', { item: $t('item.primaryMarket'), count: values.length }) }}
            </span>
          </template>
        </multi-select>
      </b-col>
      <b-col md="3">
        <b-input
          v-model="branchName"
          :placeholder="$t('merchants.branch_name')"
          class="form-control"
        ></b-input>
      </b-col>
      <b-col md="3">
        <b-input
          v-model="serviceType"
          :placeholder="$t('merchants.service_type')"
          class="form-control"
        ></b-input>
      </b-col>
      <b-button variant="primary" @click="fetchData(1)"><b-icon-search></b-icon-search></b-button>
    </div>
    <merchants-navigation :branchName="branchName" :serviceType="serviceType" :selectedPrimaryMarkets="selectedPrimaryMarkets"></merchants-navigation>
  </auth-layout>
</template>

<script>
import { mapActions } from "vuex";
import MerchantsNavigation from "./../../components/Merchants/navigation/MerchantsNavigation.vue";
import MultiSelect from "vue-multiselect";

export default {
  components: {
    MultiSelect,
    MerchantsNavigation
  },
  data: () => ({
    branchName: "",
    branchName: "",
    serviceType: "",
    primaryMarkets: null
  }),
  computed: {
    options() {
      return this.$t("merchants.primaryMarketOption");
    },
    selectedPrimaryMarkets() {
      return this.primaryMarkets ? this.primaryMarkets.map(el => el.id) : [];
    }
  },
  methods: {
    ...mapActions("merchants", ["fetchList"]),
    fetchData(page) {
      this.fetchList({
        primary_markets: this.selectedPrimaryMarkets,
        branch_name: this.branchName,
        service_type: this.serviceType,
        page
      });
    },
  },
  async mounted() {
    await this.fetchData(1);
  }
};
</script>

<style lang="scss" scoped>
.primary-market--multi-select {
  min-width: 300px;
}
</style>
