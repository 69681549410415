<template>
  <div class="main-content">
    <div class="table-wrapper">
      <table>
        <thead>
          <tr>
            <th>{{ $t("sliders.id") }}</th>
            <th class="w__35--percent">{{ $t("sliders.name") }}</th>
            <th class="text-center">{{ $t("sliders.image") }}</th>
            <th class="text-center">{{ $t("sliders.url") }}</th>
            <th class="text-center">{{ $t("sliders.date_from") }}</th>
            <th class="text-center">{{ $t("sliders.date_to") }}</th>
            <th class="text-center">{{ $t("sliders.slide_order") }}</th>
            <th></th>
          </tr>
        </thead>
        <template v-for="item in listSliders">
          <tr :key="item.id">
            <td>{{ item.id }}</td>
            <td>{{ item.name }}</td>
            <td class="text-center">
              <img class="contain" :src="item.image" width="90" />
            </td>
            <td class="text-center">
              <a target="_blank" :href="item.url">{{ item.url }}</a>
            </td>
            <td class="text-center">{{ formatDatetime(item.date_from) }}</td>
            <td class="text-center">{{ formatDatetime(item.date_to) }}</td>
            <td>{{ item.slide_order }}</td>
            <td>
              <router-link
                :to="{
                  name: 'SlidersEdit',
                  params: { id: item.id }
                }"
              >
                <b-icon-pencil
                  variant="secondary"
                  class="action-icon"
                ></b-icon-pencil>
              </router-link>

              <b-icon-trash
                variant="danger"
                class="action-icon"
                @click="openDeleteModal(item.id)"
              ></b-icon-trash>
            </td>
          </tr>
        </template>
      </table>
    </div>
    <pagination-bar
      :currentPage="currentPage"
      :lastPage="lastPage"
      :canNext="canNext"
      :canPrev="canPrev"
      @change-page="fetchData"
    ></pagination-bar>
    <confirm-modal
      :id="confirmModalId"
      :confirmText="confirmText"
      @confirmed="deleteSliders"
      @modal-discard="cancelModal"
    ></confirm-modal>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";

export default {
  components: {},
  data() {
    return {
      confirmModalId: "sliders-delete-modal",
      deleteId: null
    };
  },
  computed: {
    confirmText() {
      return this.$t("sliders.deleteConfirm");
    },
    listSliders() {
      return this.$store.getters["sliders/listSliders"];
    },
    pageObject() {
      return this.$store.getters["sliders/pageObject"];
    },
    currentPage() {
      return this.pageObject.currentPage;
    },
    lastPage() {
      return this.pageObject.lastPage;
    },
    canNext() {
      return this.pageObject.canNext;
    },
    canPrev() {
      return this.pageObject.canPrev;
    }
  },
  methods: {
    ...mapActions("sliders", ["fetchList", "destroy"]),
    fetchData(page) {
      this.fetchList({ page });
    },
    formatDatetime(datetime) {
      if (datetime) {
        return moment.utc(datetime).format('DD/MM/YYYY HH:mm:ss');
      }
      return '';
    },
    openDeleteModal(id) {
      this.deleteId = id;
      this.$bvModal.show(this.confirmModalId);
    },
    cancelModal() {
      this.deleteId = null;
    },
    deleteSliders() {
      this.destroy({ id: this.deleteId })
        .then(() => {
          this.$bvToast.toast(this.$t("message.commonSuccess"), {
            title: this.$t("message.successTitle"),
            variant: "success",
            autoHideDelay: 3000,
            solid: true
          });
          this.fetchData(this.pageObject.currentPage);
        })
        .catch(data => {
          this.$bvToast.toast(data.message || this.$t("message.commonError"), {
            title: this.$t("message.errorTitle"),
            variant: "danger",
            autoHideDelay: 3000,
            solid: true
          });
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.w__35--percent {
  width: 35%;
}
</style>
