<template>
  <b-form-group>
    <label>
      {{ $t("sliders.description") }}
    </label>
    <b-form-textarea
      v-model="slidersDescription"
      rows="3"
    ></b-form-textarea>
  </b-form-group>
</template>

<script>
import { mapActions } from "vuex";
import { TINE_MCE_API_KEY } from "../../../../constants/common";

export default {
  data() {
    return {
      TINE_MCE_API_KEY
    };
  },
  computed: {
    slidersData() {
      return this.$store.getters["sliders/slidersData"];
    },
    slidersDescription: {
      get() {
        return (this.slidersData && this.slidersData.description)
          ? this.slidersData.description
          : "";
      },
      set(value) {
        this.changeAttributesData({
          key: "description",
          value: value
        });
      }
    },
  },
  methods: {
    ...mapActions("sliders", ["changeAttributesData"])
  }
};
</script>
