import Vue from "vue";
import Vuex from "vuex";
import auth from "./modules/auth";
import campaign from "./modules/campaign";
import location from "./modules/location";
import user from "./modules/user";
import category from "./modules/category";
import rewards from "./modules/rewards";
import notification from "./modules/notification";
import news from "./modules/news";
import userRanking from "./modules/userRanking";
import sliders from "./modules/sliders";
import merchants from "./modules/merchants";
import userCampaign from "./modules/userCampaign";
import settings from "./modules/settings";
import template from "./modules/template";
import report from "./modules/report";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth, campaign, location, user, category,
    notification, rewards, news, userRanking, merchants,
    sliders, userCampaign, settings, template, report
  },
});
