var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        id: _vm.id,
        title: _vm.$t("message.confirmTitle"),
        "ok-variant": _vm.okButtonVariant,
        "ok-title": _vm.$t("button.ok"),
        "cancel-title": _vm.$t("button.cancel")
      },
      on: { hide: _vm.cancel, ok: _vm.confirm }
    },
    [_c("p", { staticClass: "my-4" }, [_vm._v(_vm._s(_vm.confirmText))])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }