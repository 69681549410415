<template>
  <div>
    <v-wait for="loading">
      <template slot="waiting">
        <div
          class="loader-container d-flex justify-content-center align-items-center"
        >
          <clip-loader
            :loading="true"
            color="#e72d2a"
            size="100px"
            class="loader"
          ></clip-loader>
        </div>
      </template>
    </v-wait>
    <transition name="component-fade" mode="out-in">
      <router-view></router-view>
    </transition>
  </div>
</template>
<script>
export default {
  name: "App",

  data: () => ({}),
};
</script>
<style lang="scss" scoped>
.loader-container {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}
.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.3s ease;
}
.component-fade-enter, .component-fade-leave-to
/* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
