<template>
  <div>
    <news-title-block></news-title-block>
    <news-public-block></news-public-block>
    <news-slug-block></news-slug-block>
    <news-image-primary-block></news-image-primary-block>
    <news-image-secondary-block></news-image-secondary-block>
    <news-description-block></news-description-block>
    <news-content-block></news-content-block>
    <news-member-only-block></news-member-only-block>
    <news-order-block></news-order-block>
  </div>
</template>
<script>
import NewsImagePrimaryBlock from './NewsImagePrimaryBlock';
import NewsImageSecondaryBlock from './NewsImageSecondaryBlock';
import NewsTitleBlock from './NewsTitleBlock';
import NewsDescriptionBlock from './NewsDescriptionBlock';
import NewsContentBlock from './NewsContentBlock';
import NewsPublicBlock from './NewsPublicBlock';
import NewsMemberOnlyBlock from './NewsMemberOnlyBlock';
import NewsOrderBlock from './NewsOrderBlock';
import NewsSlugBlock from './NewsSlugBlock';


export default {
  components: {
    NewsTitleBlock,
    NewsDescriptionBlock,
    NewsContentBlock,
    NewsImagePrimaryBlock,
    NewsImageSecondaryBlock,
    NewsPublicBlock,
    NewsMemberOnlyBlock,
    NewsOrderBlock,
    NewsSlugBlock,
  }
};
</script>
<style lang="scss">
</style>
