var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "d-flex justify-content-center align-items-center login-container"
    },
    [
      _c(
        "div",
        {
          staticClass:
            "login-panel col-12 d-flex flex-column align-items-center"
        },
        [
          _c("img", {
            staticClass: "mb-4",
            attrs: { src: "/images/wefree_logo.png", alt: "" }
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.errorMessage,
                  expression: "errorMessage"
                }
              ],
              staticClass: "text-danger"
            },
            [_vm._v("\n      " + _vm._s("※" + _vm.errorMessage) + "\n    ")]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "login-form col-md-4 col-sm-5" },
            [
              _c("ValidationObserver", {
                ref: "observer",
                staticClass: "login-form",
                attrs: { tag: "form" },
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.submitLogin.apply(null, arguments)
                  }
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var invalid = ref.invalid
                      return [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", { attrs: { for: "username" } }, [
                              _vm._v(_vm._s(_vm.$t("auth.username")))
                            ]),
                            _vm._v(" "),
                            _c("ValidationProvider", {
                              attrs: {
                                name: "username",
                                rules: "required|alpha_num",
                                mode: "passive"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.loginData.username,
                                              expression: "loginData.username"
                                            }
                                          ],
                                          class: {
                                            "form-control": true,
                                            error: errors[0]
                                          },
                                          attrs: {
                                            type: "text",
                                            name: "username"
                                          },
                                          domProps: {
                                            value: _vm.loginData.username
                                          },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.loginData,
                                                "username",
                                                $event.target.value
                                              )
                                            }
                                          }
                                        })
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", { attrs: { for: "password" } }, [
                              _vm._v(_vm._s(_vm.$t("auth.password")))
                            ]),
                            _vm._v(" "),
                            _c("ValidationProvider", {
                              attrs: {
                                name: "password",
                                rules: "required",
                                mode: "passive"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.loginData.password,
                                              expression: "loginData.password"
                                            }
                                          ],
                                          class: {
                                            "form-control": true,
                                            error: errors[0]
                                          },
                                          attrs: {
                                            type: "password",
                                            autocomplete: "new-password",
                                            name: "password"
                                          },
                                          domProps: {
                                            value: _vm.loginData.password
                                          },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.loginData,
                                                "password",
                                                $event.target.value
                                              )
                                            }
                                          }
                                        })
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "form-group d-flex justify-content-center"
                          },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-login",
                                attrs: { type: "submit" }
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.$t("button.login")) +
                                    "\n          "
                                )
                              ]
                            )
                          ]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }