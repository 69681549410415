<template>
  <div>
    <b-form-group
      :invalid-feedback="invalidFeedback"
      :state="stateValid"
    >
      <label>
        {{ $t("rewards.name") }}
        <span class="text-danger ml-1 small" v-if="isRequired">{{ $t("subText.required") }}</span>
      </label>
      <b-form-input
        v-model="rewardsName"
        :state="stateValid"
        :required="isRequired"
        trim
      ></b-form-input>
    </b-form-group>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    isRequired: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {};
  },
  computed: {
    rewardsData() {
      return this.$store.getters["rewards/rewardsData"];
    },
    rewardsName: {
      get() {
        return this.rewardsData ? this.rewardsData.name : "";
      },
      set(value) {
        this.changeRewardAttributesData({
          key: "name",
          value: value
        });
      }
    },
    stateValid() {
      if (!this.rewardsData || this.rewardsData.name === null || !this.isRequired) return null;
      if (
        this.rewardsData.name === "" ||
        (this.rewardsData.name && this.rewardsData.name.length > 100)
      )
        return false;
      return true;
    },
    invalidFeedback() {
      if (this.rewardsName && this.rewardsName.length > 100) {
        return this.$t("rewards.maxLength").replace(":length", "100");
      }
      return (
        this.$t("rewards.required") +
        " " +
        this.$t("rewards.name").toLowerCase()
      );
    }
  },
  methods: {
    ...mapActions("rewards", ["changeRewardAttributesData"])
  }
};
</script>
