var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "auth-layout",
    [
      _c(
        "main-header",
        { attrs: { title: _vm.$t("page.notification.create") } },
        [
          _c(
            "router-link",
            {
              staticClass: "btn btn-outline-secondary",
              attrs: { to: { name: "NotificationList" } }
            },
            [_vm._v(_vm._s(_vm.$t("page.notification.list")))]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "card" }, [
        _c(
          "div",
          { staticClass: "card-body" },
          [
            _c("notification-form", {
              attrs: {
                errMessage: _vm.errMessage,
                btnText: _vm.$t("button.add")
              },
              on: { submit: _vm.submit }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            centered: "",
            id: _vm.routeLeaveModalId,
            title: _vm.$t("message.warning"),
            "ok-title": _vm.$t("button.ok"),
            "cancel-title": _vm.$t("button.cancel")
          },
          on: {
            ok: function($event) {
              return _vm.leave(_vm.targetRouteName)
            }
          }
        },
        [_vm._v("\n    " + _vm._s(_vm.$t("message.routeLeave")) + "\n  ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }