import axios from "../../axios";

const state = {
    listSettings: [],
};

const getters = {
    listSettings: state => state.listSettings
};

const actions = {
    fetchSettings({ commit }) {
        return new Promise((resolve, reject) => {
            axios.get('/api/settings')
                .then(({ data }) => {
                    const listSettings = data.data;
                    commit('setSettings', listSettings);
                    resolve();
                })
                .catch(() => reject());
        });
    },

    store({ }, payload) {
        return new Promise((resolve, reject) => {
            axios.post('/api/settings', payload)
                .then(() => resolve())
                .catch(({ response }) => reject(response.data))
        });
    },
}

const mutations = {
    setSettings(state, data) {
        state.listSettings = data;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  };