<template>
  <auth-layout>
    <main-header :title="$t('page.rewards.list')">
      <router-link :to="{ name: 'RewardsAdd' }" class="btn btn-success">{{
        $t("page.rewards.create")
      }}</router-link>
    </main-header>
    <div class="filter-bar">
      <b-col md="3" class="pl-0">
        <b-input
          v-model="keyword"
          :placeholder="$t('rewards.name')"
          class="form-control"
        ></b-input>
      </b-col>
      <b-button variant="primary" @click="fetchData(1)"><b-icon-search></b-icon-search></b-button>
    </div>
    <rewards-navigation></rewards-navigation>
  </auth-layout>
</template>

<script>
import { mapActions } from "vuex";
import RewardsNavigation from "./../../components/Rewards/navigation/RewardsNavigation";

export default {
  components: {
    RewardsNavigation
  },
  data: () => ({
  }),
  computed: {
    keyword: {
      set(keyword) {
        this.changeKeyword({ keyword })
      },
      get() {
        return this.$store.getters["rewards/keyword"];
      }
    }
  },
  methods: {
    ...mapActions('rewards', ['fetchList', 'destroy', 'changeKeyword']),
    fetchData(page) {
      this.fetchList({ page });
    },
  },
  async mounted() {
    await this.fetchData(1);
  },
  created() {
  },
};
</script>

<style lang="scss" scoped>
</style>
