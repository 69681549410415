var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-form-group",
    {
      attrs: { "invalid-feedback": _vm.invalidFeedback, state: _vm.stateValid }
    },
    [
      _c("label", [
        _vm._v("\n    " + _vm._s(_vm.$t("news.slug")) + "\n    "),
        _c("span", { staticClass: "text-danger ml-1 small" }, [
          _vm._v(_vm._s(_vm.$t("subText.required")))
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "input-group" },
        [
          _c("b-form-input", {
            attrs: { state: _vm.stateValid, required: "", trim: "" },
            model: {
              value: _vm.newsSlug,
              callback: function($$v) {
                _vm.newsSlug = $$v
              },
              expression: "newsSlug"
            }
          }),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-primary ml-1",
              attrs: { type: "button" },
              on: { click: _vm.generateSlug }
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("news.AutoGenerateSlug")) + "\n    "
              )
            ]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }