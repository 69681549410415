<template>
  <auth-layout>
    <main-header :title="$t('page.campaign.list')">
      <router-link :to="{ name: 'CampaignCreate' }" class="btn btn-success">{{
        $t("page.campaign.create")
      }}</router-link>
    </main-header>
    <div class="filter-bar">
      <b-col md="3" class="min-w-260">
        <multi-select
          v-model="categories"
          :options="categoryOptions"
          label="display_name"
          track-by="id"
          multiple
          class="campaign-category-selector"
          :selectedLabel="$t('multiselect.selectedLabel')"
          selectLabel=""
          :searchable="false"
          :close-on-select="false"
          :clear-on-select="false"
          :placeholder="$t('campaign.category')"
          :deselectLabel="$t('multiselect.deselectLabel')"
        >
          <template slot="selection" slot-scope="{ values, isOpen }">
            <span class="multiselect__single" v-if="values.length && !isOpen">
              {{ $t('message.selectedCount', { item: $t('item.category'), count: values.length }) }}
            </span>
          </template>
        </multi-select>
      </b-col>
      <b-col md="2">
        <v-date-picker
          v-model="createdFrom"
          mode="date"
          :locale="currentLocale"
          :model-config="dateConfig"
        >
          <template v-slot="{ inputValue, inputEvents }">
            <input
              class="form-control"
              :value="inputValue"
              v-on="inputEvents"
              :placeholder="$t('campaign.createdFrom')"
            />
          </template>
        </v-date-picker>
      </b-col>
      ~
      <b-col md="2">
        <v-date-picker
          v-model="createdTo"
          mode="date"
          :min-date="createdFrom"
          :locale="currentLocale"
          :model-config="dateConfig"
        >
          <template v-slot="{ inputValue, inputEvents }">
            <input
              class="form-control"
              :value="inputValue"
              v-on="inputEvents"
            />
          </template>
        </v-date-picker>
      </b-col>
      <b-col md="3">
        <b-input
          v-model="keyword"
          :placeholder="$t('campaign.name')"
          class="form-control"
        ></b-input>
      </b-col>
      <b-button variant="primary" @click="fetchData(1)"><b-icon-search></b-icon-search></b-button>
    </div>
    <div class="main-content has-filter-bar">
      <div class="table-wrapper">
        <table>
          <thead>
            <tr>
              <th>{{ $t("campaign.id") }}</th>
              <th>{{ $t("campaign.name") }}</th>
              <th>{{ $t("campaign.image") }}</th>
              <th>{{ $t("campaign.endTime") }}</th>
              <th>{{ $t("campaign.userCount") }}</th>
              <th>{{ $t("campaign.category") }}</th>
              <th>{{ $t("campaign.createdAt") }}</th>
              <th>{{ $t("campaign.publicState") }}</th>
              <th>{{ $t("campaign.memberOnly") }}</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <template v-for="item in campaigns">
            <tr>
              <td>{{ item.id }}</td>
              <td>
                <a :href="item.url" target="_blank">
                  {{ item.name }}
                </a>
              </td>
              <td>
                <img
                  class="contain"
                  :src="item.primary_image_url"
                  width="90"
                />
              </td>
              <td>{{ item.end_time | datetimeFormat }}</td>
              <td>{{ item.user_count | numberFormat }}</td>
              <td>{{ item.category_name }}</td>
              <td>{{ item.created_at | datetimeFormat }}</td>
              <td>
                <b-icon-check v-if="item.public" variant="success" class="h1"></b-icon-check>
              </td>
              <td>
                <b-icon-check v-if="item.member_only" variant="success" class="h1"></b-icon-check>
              </td>
              <td>
                <router-link
                  :to="{
                    name: 'UserCampaignList',
                    params: { id: `${item.id}` }
                  }"
                >
                  <b-button variant="primary">{{ $t('campaign.userList') }}</b-button>
                </router-link>
              </td>
              <td>
                <router-link
                  :to="{
                    name: 'CampaignEdit',
                    params: { id: item.id }
                  }"
                >
                  <b-icon-pencil
                    variant="secondary"
                    class="action-icon"
                  ></b-icon-pencil>
                </router-link>

                <b-icon-trash
                  variant="danger"
                  class="action-icon"
                  @click="openDeleteModal(item.id)"
                ></b-icon-trash>
              </td>
            </tr>
          </template>
          <template v-if="campaigns.length == 0">
            <tr>
              <td colspan="8" class="text-center border-0">{{ $t('message.notFound', { item: $t('item.campaign') }) }}</td>
            </tr>
          </template>
        </table>
      </div>
      <pagination-bar
        :currentPage="pageObject.currentPage"
        :lastPage="pageObject.lastPage"
        :canNext="pageObject.canNext"
        :canPrev="pageObject.canPrev"
        @change-page="fetchData"
      ></pagination-bar>
    </div>
    <confirm-modal
      :id="confirmModalId"
      :confirmText="confirmText"
      @confirmed="deleteCampaign"
      @modal-discard="cancelModal"
    ></confirm-modal>
  </auth-layout>
</template>

<script>
import { mapState, mapActions } from "vuex";
import MultiSelect from "vue-multiselect";
import DatePicker from "v-calendar/lib/components/date-picker.umd";

export default {
  components: {
    MultiSelect,
    "v-date-picker": DatePicker
  },

  data: () => ({
    confirmModalId: "campaign-delete-modal",
    deleteId: null,
    categories: null,
    keyword: '',
    createdFrom: null,
    createdTo: null,
    dateConfig: {
      type: 'string',
      mask: 'YYYY-MM-DD'
    }
  }),

  computed: {
    ...mapState("campaign", ["campaigns", "pageObject", "categoryOptions"]),
    confirmText() {
      return this.$t("campaign.deleteConfirm");
    },
    currentLocale() {
      return this.locale;
    },
    selectedCategoryIds() {
      return this.categories ? this.categories.map(el => el.id) : null;
    }
  },

  methods: {
    ...mapActions("campaign", ["fetchCampaigns", "destroy", "fetchCategoryOptions"]),
    fetchData(page) {
      let { selectedCategoryIds, keyword, createdFrom, createdTo } = this;
      this.fetchCampaigns({ page, selectedCategoryIds, keyword, createdFrom, createdTo });
    },
    openDeleteModal(id) {
      this.deleteId = id;
      this.$bvModal.show(this.confirmModalId);
    },
    cancelModal() {
      this.deleteId = null;
    },
    deleteCampaign() {
      this.destroy({ id: this.deleteId })
        .then(() => this.fetchData(1))
        .catch(data => {
          this.$bvToast.toast(data.message || this.$t("message.commonError"), {
            title: this.$t("message.errorTitle"),
            variant: "danger",
            autoHideDelay: 3000,
            solid: true
          });
        });
    }
  },

  async mounted() {
    await this.fetchData(1);

    if (this.categoryOptions.length == 0) {
      this.fetchCategoryOptions();
    }
  }
};
</script>

<style lang="scss" scoped></style>
