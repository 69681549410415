export default {
  auth: {
    username: "Tên đăng nhập",
    password: "Mật khẩu",
    new_password: "Mật khẩu mới",
  },
  sidebar: {
    campaign: "Chiến dịch",
    user: "Thành viên",
    category: "Danh mục chiến dịch",
    rewards: "Phần Thưởng",
    notification: "Thông báo",
    news: "Tin Tức",
    userRanking: "Thứ hạng thành viên",
    merchants: "Merchants",
    sliders: "Sliders",
    settings: "Cài đặt",
    template: "Biểu mẫu email & thông báo",
    changePassword: "Đổi mật khẩu",
    export: "Xuất dữ liệu",
    exportCampaign: "Chiến dịch",
    exportMember: "Thành viên",
  },
  page: {
    campaign: {
      list: "Danh sách chiến dịch",
      create: "Thêm chiến dịch",
      edit: "Cập nhật thông tin chiến dịch"
    },
    user: {
      list: "Danh sách thành viên",
      create: "Thêm thành viên",
      edit: "Cập nhật thông tin thành viên",
      detail: "Thông tin chi tiết thành viên",
      changePassword: "Đổi mật khẩu",
    },
    category: {
      list: "Danh mục chiến dịch",
      create: "Thêm danh mục chiến dịch",
      edit: "Cập nhật danh mục chiến dịch"
    },
    rewards: {
      list: "Danh sách phần thưởng",
      create: "Thêm phần thưởng",
      edit: "Cập nhật thông tin phần thưởng"
    },
    notification: {
      list: "Danh sách thông báo",
      create: "Thêm thông báo"
    },
    news: {
      list: "Danh sách tin tức",
      create: "Thêm tin tức",
      edit: "Cập nhật tin tức"
    },
    userRanking: {
      list: "Danh sách thứ hạng",
      create: "Thêm thứ hạng",
      edit: "Cập nhật thứ hạng"
    },
    sliders: {
      list: "Danh sách slide",
      create: "Thêm slide",
      edit: "Cập nhật slide"
    },
    merchants: {
      list: "Danh sách merchants"
    },
    userCampaign: {
      list: "Danh sách đăng ký '{name}'",
      create: "Thêm thành viên",
      backToList: "Trở lại danh sách đăng ký",
      review: "Chi tiết Review",
    },
    mail: {
      list: "Danh sách biểu mẫu"
    },
    settings: {
      list: "Cài đặt hệ thống",
    }
  },
  button: {
    login: "Đăng nhập",
    logout: "Đăng xuất",
    add: "Tạo",
    update: "Cập nhật",
    addImageSlot: "Thêm hình",
    ok: "OK",
    cancel: "Huỷ",
    approve: "Duyệt",
    unapprove: "Không duyệt",
    delete: "Xoá",
    addMember: "Thêm thành viên",
    close: "Đóng",
    exportExcel: "Xuất data Excel",
    approveReview: "Duyệt Review",
    unapproveReview: "Không duyệt Review",
    back: "Trở lại",
    exportReviewExcel: "Xuất excel Đánh giá",
    exportCampaignReport: "Xuất báo cáo chiến dịch",
    exportCommentReport: "Xuất báo cáo comment",
    exportMemberReport: "Xuất danh sách đăng ký",
    exportUserReport: "Xuất báo cáo thành viên",
    updateSNS: "Cập nhật SNS",
  },
  subText: {
    required: "*Bắt buộc",
    maxImageCount: "(Tối đa {n} hình)",
    unchangePwd: "Để trống khi không thay đổi mật khẩu",
  },
  filePicker: {
    placeHolder: "Kéo thả tệp vào đây",
    dropPlaceHolder: "Thả tệp tại đây",
    browse: "Chọn tệp"
  },
  datePicker: {
    placeHolder: "Không có ngày nào được chọn",
  },
  multiselect: {
    deselectLabel: "Enter để bỏ chọn",
    selectedLabel: "Đã chọn",
    selectLabel: "Enter để chọn",
    districtNoOption: "Hãy chọn tỉnh / thành phố trước",
    wardNoOption: "Hãy chọn quận / huyện trước",
    noOptions: "Chưa có lựa chọn phù hợp",
  },
  pagination: {
    toPreviousPage: "Trang trước",
    toNextPage: "Trang sau",
  },
  message: {
    errorTitle: "Lỗi!!!",
    successTitle: "Thành công",
    confirmTitle: "Xác nhận",
    commonSuccess: "Hoàn tất.",
    commonError: "Đã có lỗi xảy ra. Thao tác thất bại.",
    maxFileCount: "Bạn chỉ có thể upload tối đa {count} hình.",
    notFound: "Không tìm thấy {item} tương ứng.",
    selectedCount: "{count} {item} được chọn",
    routeLeave: "Bạn có chắc chắn muốn rời trang? Những thay đổi sẽ không được lưu lại.",
    warning: "Chú ý",
    defaultChoose: "Không chọn",
    refreshTikTokInfoSuccess: "Cập nhật thông tin Tiktok hoàn tất.",
    refreshTikTokInfoFail: "Cập nhật thông tin Tiktok thất bại.",
    refreshInstagramInfoSuccess: "Cập nhật thông tin Instagram hoàn tất.",
    refreshInstagramInfoFail: "Cập nhật thông tin Instagram thất bại.",
    refreshSNSInfoFail: "Không thể cập nhật thông tin SNS.",
    userHaveNoSNSInfo: "Người dùng chưa nhập thông tin tài khoản {sns}"
  },
  item: {
    campaign: "chiến dịch",
    category: 'danh mục',
    merchants: 'merchants',
    primaryMarket: 'Thị trường chính',
    userCampaign: 'thành viên chiến dịch',
    user: 'người dùng',
  },
  campaign: {
    id: "ID",
    name: "Tên chiến dịch",
    image: "Hình ảnh",
    userCount: "Số lượng thành viên",
    createdAt: "Thời gian khởi tạo",
    createdFrom: "Khởi tạo từ ngày",
    deleteConfirm: "Bạn chắc chắn muốn xoá chiến dịch?",
    description: "Giới thiệu sàn phẩm",
    requirement: "Nguyên tắc review",
    category: "Danh mục",
    price: "Giá gốc",
    salePrice: "Giá cập nhật",
    city: "Khu vực",
    social: "Mạng xã hội",
    endTime: "Hạn đăng ký",
    approveEndTime: "Duyệt danh sách, chuẩn bị sản phẩm",
    postReviewTime: "Ngày đăng bài",
    reviewEndTime: "Hạn báo cáo review",
    closedDate: "Thông báo kết quả",
    reward: "Phần thưởng",
    criteria: "Tiêu chí thành viên review",
    reviewRequirement: "Yêu cầu bài review",
    minReview: "Review tối thiểu",
    maxUser: "Số review",
    initUser: "Số user khởi tạo",
    slug: "Slug",
    primaryImage: "Hình ảnh chính",
    optionalImage: "Hình ảnh khác",
    endCampaignText: "Nội dung hướng dẫn báo cáo",
    userList: "Danh sách đăng ký",
    memberOnly: "Chỉ member",
    onlyForMember: "Chỉ dành cho member",
    warningText: "Nội dung warning",
    crawlingTime: "Thời gian Cập nhật thống kê review",
    public: "Public chiến dịch",
    publicState: "Public",
    AutoGenerateSlug: "Tạo tự động",
    updateSuccess: "Cập nhật chiến dịch thành công",
    createSuccess: "Tạo chiến dịch thành công",
  },
  user: {
    id: "ID",
    avatar: "Ảnh đại diện",
    fullname: "Tên đầy đủ",
    email: "E-mail",
    memberID: "ID thành viên",
    rank: "Cấp độ",
    createdAt: "Ngày tạo",
    disableConfirm: "Bạn chắc chắn muốn khoá thành viên [ {fullname} ]?",
    reactiveConfirm: "Bạn chắc chắn muốn mở khoá thành viên [ {fullname} ]?",
    deleteConfirm: "Bạn chắc chắn muốn xoá thành viên [ {fullname} ]?",
    skinType: "Loại da",
    skinNoChoice: "Không chọn",
    skinDry: "Da khô",
    skinOily: "Da dầu",
    skinNormal: "Bình thường",
    skinMixed: "Nhiều loại",
    tel: "Số điện thoại",
    birthday: "Ngày sinh",
    gender: "Giới tính",
    male: "Nam",
    female: "Nữ",
    genderOther: "Khác",
    address: "Địa chỉ",
    city: "Tỉnh / Thành phố",
    district: "Quận / Huyện",
    ward: "Phường / Xã",
    maritalStatus: "Tình trạng hôn nhân",
    single: "Độc thân",
    married: "Đã kết hôn",
    hasChild: "Có con",
    boyCount: "Số lượng con trai",
    girlCount: "Số lượng con gái",
    pets: "Thú cưng",
    petDog: "Chó",
    petCat: "Mèo",
    petOther: "Khác",
    shirtSize: "Size áo",
    pantsSize: "Size quần",
    shoeSize: "Size giày",
    info: "Thông tin hồ sơ",
    instagramCrawlingTime: "Cập nhật Instagram",
    tiktokCrawlingTime: "Cập nhật Tiktok",
    user_campaigns: {
      history: 'Lịch sử tham gia chiến dịch',
      id: 'System ID',
      campaign_name: 'Tên chiến dịch',
      created_at: 'Ngày đăng ký',
      approved: 'Duyệt',
      review: 'Review',
      not_match_campaign: 'Không có tham gia chiến dịch nào',
      detail_review: 'Chi tiết review'
    },
    friend: {
      list: 'Lịch sử giới thiệu bạn bè',
      no: 'No',
      member_id: 'Member ID',
      fullname: 'Tên thành viên',
      email: 'Email',
      status: 'Trạng thái',
      created_at: 'Ngày tham gia',
      not_match_friend: 'Chưa từng giới thiệu bạn bè',
    },
    points: {
      list: 'Lịch sử điểm',
      no: 'No',
      type: 'Loại điểm',
      point: 'Số điểm',
      relation_object: 'Đối tượng',
      date: 'Ngày',
      sum: 'Tổng điểm',
      no_point: 'Chưa có điểm nào',
      share: "Share",
      like: "Like",
      invite: "Mời bạn bè",
      invited: "Được mời",
      cancel_campaign: "Hủy đăng ký chiến dịch",
      cancel_review: "Hủy review",
    },
    modal: {
      user_campaign_detail_title: "Chi tiết review",
      rating_point: "Điểm đánh giá",
      link_social_1: "Link bài review 1",
      link_social_2: "Link bài review 2",
      link_social_3: "Link bài review 3",
      review_price: "Giá bán so với chất lượng",
      review_customer_care: "Chăm sóc khách hàng",
      review_packaging: "Bao bì, thiết kế hình ảnh",
      review_loyal: "Sẽ trở thành khách hàng",
      count_like: "Số like",
      count_share: "Số share",
      count_comment: "Số comment",
      ARR_REVIEW_PRICE: [
        {
          code: 1,
          text: 'Giá cao'
        },
        {
          code: 2,
          text: 'Giá hợp lý'
        }
      ],
      ARR_REVIEW_PACKAGING: [
        {
          code: 1,
          text: 'Đẹp mắt'
        },
        {
          code: 2,
          text: 'Chưa đẹp mắt'
        }
      ],
      ARR_REVIEW_CUSTOMER_CARE: [
        {
          code: 1,
          text: 'Hài lòng'
        },
        {
          code: 2,
          text: 'Chưa tốt'
        },
        {
          code: 3,
          text: 'Chưa trải nghiệm'
        }
      ],
      ARR_REVIEW_LOYAL: [
        {
          code: 1,
          text: 'Có thể'
        },
        {
          code: 2,
          text: 'Không'
        }
      ],
      review_images: "Hình ảnh review",
    },
    searchable: "Họ và tên / E-mail / Số điện thoại",
    profileFinishDate: "Ngày hoàn tất thông tin cơ bản",
    joinAt: "Ngày tham gia",
    currentPassword: "Mật khẩu hiện tại",
    passwordConfirmation: "Xác nhận mật khẩu",
    campaign: "Chiến dịch",
    user: 'Người dùng',
    facebookAccount: "Tài khoản Facebook",
    facebookFollower: "Số bạn bè/ theo dõi",
    instagramAccount: "Tài khoản Instagram",
    instagramFollower: "Lượt theo dõi",
    tiktokAccount: "Tài khoản TikTok",
    tiktokFollower: "Lượt theo dõi",
    favorite: "Sở thích",
    socialLinked: "Liên kết mạng xã hội",
    mostFollower: "Thông tin người theo dõi (đa số)",
    shortMostFollower: "theo dõi đa số",
    mostFollowerGender: "Giới tính",
    mostFollowerArea: "Khu vực",
    bankInfo: "Thông tin ngân hàng",
    bankName: "Tên ngân hàng",
    bankBranch: "Chi nhánh",
    bankHolder: "Chủ tài khoản",
    bankNumber: "Số tài khoản",
    totalPoint: "Tổng điểm",
    percentProfile: "Cập nhật hồ sơ",
  },
  category: {
    confirmDelete: "Bạn chắc chắn muốn xoá [{title}]?",
    id: "ID",
    displayName: "Tên danh mục",
    icon: "Icon (App)",
    slug: "Slug",
    createdAt: "Thời gian tạo",
    iconInactive: "Icon (App Inactive)",
    iconWeb: "Icon (Web)",
    active: "Hiển thị",
    inactive: "Không hiển thị"
  },
  notification: {
    id: "ID",
    title: "Tiêu đề",
    content: "Nội dung",
    type: "Loại",
    type_all: "Tất cả",
    users: "Người nhận thông báo",
    type_selected_user: "Người dùng cụ thể",
    createdAt: "Ngày gửi"
  },
  rewards: {
    id: "ID",
    userId: "Thành viên",
    campaignId: "Chiến dịch",
    name: "Tên phần thưởng",
    note: "Ghi chú",
    description: "Mô tả",
    thumbnail: "Hình ảnh",
    expireDate: "Hạn sử dụng",
    createdBy: "Người tạo",
    createdAt: "Thời gian tạo",
    updatedBy: "Người cập nhật",
    updatedAt: "Thời gian cập nhật",
    deletedBy: "Người xoá",
    deletedAt: "Thời gian xoá",
    deleteConfirm: "Bạn chắc chắn muốn xoá phần thưởng?",
    required: 'Vui lòng nhập',
    maxLength: 'Chỉ tối đa :length ký tự được phép nhập'
  },
  news: {
    id: "ID",
    imagePrimary: "Hình ảnh chính",
    displayMainImage: "Hiển thị Hình ảnh chính",
    imageSecondary: 'Hình ảnh phụ',
    title: "Tiêu đề",
    description: "Mô tả",
    public: "Public tin tức",
    memberOnly: "Chỉ dành cho member",
    content: "Nội dung",
    order: "Thứ tự",
    createdAt: "Ngày tạo",
    deleteConfirm: "Bạn chắc chắn muốn xoá tin tức?",
    maxLength: 'Chỉ tối đa :length ký tự được phép nhập',
    slug: "Slug",
    alphaDash: "Trường :attribute chỉ có thể chứa chữ cái, số và dấu gạch ngang.",
    AutoGenerateSlug: "Tạo tự động",
    updateSuccess: "Cập nhật tin tức thành công",
    createSuccess: "Tạo tin tức thành công",
  },
  userRanking: {
    id: "ID",
    ranking_name: "Tên thứ hạng",
    percent_complete: 'Chỉ tiêu hoàn thành (%)',
    day_count: "Thời hạn (tháng)",
    createdAt: "Ngày tạo",
    deleteConfirm: "Bạn chắc chắn muốn xoá thứ hạng?",
    unitCountDay: 'tháng',
    maxLength: 'Chỉ tối đa :length ký tự được phép nhập',
    valueLimit: 'Giá trị nhập vào phải lớn hơn :min và nhỏ hơn :max',
    valueMin: 'Giá trị nhập phải lớn hơn :min'
  },
  sliders: {
    id: "ID",
    name: "Tên slide",
    image: 'Hình ảnh',
    url: "Url",
    description: "Mô tả",
    date_from: "Từ ngày",
    date_to: "Đến ngày",
    slide_order: "Thứ tự",
    deleteConfirm: "Bạn chắc chắn muốn xoá sliders?",
    url_invalid: "URL không hợp lệ",
    maxLength: 'Chỉ tối đa :length ký tự được phép nhập',
    valueMin: 'Giá trị nhập phải lớn hơn :min',
    date_from_to_invalid: 'Ngày bắt đầu phải nhỏ hơn ngày kết thúc'
  },
  merchants: {
    id: "ID",
    branch_name: "Nhãn hàng",
    service_type: 'Loại dịch vụ',
    tel: "Điện thoại",
    email: "Email",
    primaryMarket: "Thị trường chính",
    deleteConfirm: "Bạn chắc chắn muốn xoá merchants?",
    primaryMarketOption: [
      {
        id: 1,
        value: "Hà Nội"
      },
      {
        id: 2,
        value: "Hồ Chí Minh"
      },
      {
        id: 3,
        value: "Tỉnh thành khác"
      }
    ],
  },
  campaignCriteria: {
    age: "Tuổi",
    place: "Địa điểm",
    contentType: "Loại nội dung",
    contentTypeImage: "Hình ảnh",
    contentTypeVideo: "Video",
    reviewHistory: "Lịch sử review",
    reviewHistoryPro: "Chuyên review ngành này",
    reviewHistoryUsedTo: "Từng review brand này",
    reviewHistoryNotYet: "Chưa review brand này",
    noChoice: "Không chọn",
    instagramCount: "Người theo dõi Instagram",
    facebookCount: "Người theo dõi trên Facebook",
    tiktokCount: "Người theo dõi trên Tiktok"
  },
  userCampaign: {
    createdAt: "Ngày đăng ký",
    approval: "Duyệt",
    reviewStatus: "Trạng thái review",
    reviewStatusReviewed: "Đã review",
    reviewStatusNotReviewedYet: "Chưa review",
    reviewApproval: "Duyệt review",
    deleteConfirm: "Bạn chắc chắn muốn xoá?",
    approveConfirm: "Bạn chắc chắn muốn duyệt cho thành viên?",
    unapproveConfirm: "Bạn chắc chắn muốn bỏ duyệt cho thành viên?",
    reviewApproveConfirm: "Bạn chắc chắn muốn duyệt cho bài review này?",
    reviewUnapproveConfirm: "Bạn chắc chắn muốn bỏ duyệt cho bài review này?",
  },
  mail: {
    title: "Tiêu đề",
    templateTitle: "Biểu mẫu [{title}]",
    isAutoSend: "Tự động gửi mail",
    isAutoPush: "Tự động gửi thông báo",
    notificationTitle: "Tiêu đề thông báo",
    subject: "Tiêu đề mail",
    mailContent: "Nội dung mail",
    contentRule: "Vui lòng không xoá các từ có định dạng %VARIABLE% vì chúng là giá trị sẽ được thay đổi bằng thông tin thật khi gửi.",
  },
  settings: {
    updateSuccess: "Cập nhật cài đặt thành công",
    forceUpdate: "Bắt buộc update app",
    iosVersion: "Phiên bản iOS",
    androidVersion: "Phiên bản Android",
    campaignWarningText: "Cảnh báo chiến dịch",
    campaignMatchingCriterias: "Tiêu chí chiến dịch phù hợp",
    hobby: "Sở thích",
    age: "Tuổi",
    gender: "Giới tính",
    submit: "Lưu cài đặt",
  },
  validation: {
    required: ':attribute không được bỏ trống',
    min: ':attribute tối thiểu :length ký tự',
    max: ':attribute tối đa :length ký tự',
    not_match: ':attribute không khớp',
  }
};
