import axios from "../../axios";
import moment from "moment";

const state = {
  userRewards: [],
  pageObject: {},
  rewardsData: {},
  campaigns: [],
  users: [],
  thumbnailUrl: "",
  keyword: ''
};

const getters = {
  userRewards: state => state.userRewards,
  rewardsData: state => state.rewardsData,
  pageObject: state => state.pageObject,
  campaigns: state => state.campaigns,
  users: state => state.users,
  keyword: state => state.keyword
};

const actions = {
  fetchList({ commit }, payload) {
    return new Promise((resolve, reject) => {
      let { page } = payload;
      if (!page) {
        page = 1;
      }
      let url = `/api/rewards?page=${page}&name=${state.keyword}`;
      axios.get(url).then(({ data }) => {
        const userRewards = data.data.user_rewards;
        const currentPage = data.data.current_page;
        const lastPage = data.data.last_page;
        commit("setUserRewards", userRewards);
        commit("setPagination", {
          currentPage,
          lastPage,
          canNext: currentPage < lastPage,
          canPrev: currentPage > 1
        });
      });
    });
  },
  fetchCampaigns({ commit }) {
    return new Promise((resolve, reject) => {
      let url = `/api/rewards/campaigns`;
      axios.get(url).then(({ data }) => {
        commit("setCampaigns", data.data);
      });
    });
  },
  fetchUsers({ commit }) {
    return new Promise((resolve, reject) => {
      let url = `/api/rewards/users`;
      axios.get(url).then(({ data }) => {
        commit("setUsers", data.data);
      });
    });
  },
  destroy({}, payload) {
    return new Promise((resolve, reject) => {
      const { id } = payload;
      axios
        .delete(`/api/rewards/${id}`)
        .then(() => {
          resolve();
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },
  changeRewardAttributesData({ commit }, payload) {
    commit("setRewardsAttribute", payload);
  },
  fetchById({ commit }, payload) {
    return new Promise((resolve, reject) => {
      const { id } = payload;
      axios
        .get(`/api/rewards/${id}`)
        .then(({ data }) => {
          const rewards = data.data;
          rewards.expire_date = rewards.expire_date
            ? moment.utc(rewards.expire_date).format()
            : null;
          commit("setRewardsData", rewards);
          resolve(data.data);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },
  store({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/rewards", payload)
        .then(() => resolve())
        .catch(({ response }) => reject(response.data));
    });
  },
  update({ commit }, payload) {
    return new Promise((resolve, reject) => {
      const { id } = payload;
      axios
        .post(`/api/rewards/${id}`, payload)
        .then(() => resolve())
        .catch(({ response }) => reject(response.data));
    });
  },
  changeKeyword({ commit }, payload) {
    const { keyword } = payload;
    commit("setKeyword", keyword);
  }
};

const mutations = {
  setUserRewards(state, data) {
    state.userRewards = data;
  },
  setPagination(state, data) {
    state.pageObject = data;
  },
  setRewardsAttribute(state, payload) {
    const { key, value } = payload;
    state.rewardsData[key] = value;
  },
  setRewardsData(state, data) {
    state.rewardsData = data;
  },
  setCampaigns(state, data) {
    state.campaigns = data;
  },
  setUsers(state, data) {
    state.users = data;
  },
  setKeyword(state, keyword) {
    state.keyword = keyword;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
