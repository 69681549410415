var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "card-body" }, [
          _c("div", { staticClass: "img-ctn" }, [
            _c("img", { attrs: { src: _vm.form.avatar.url, alt: "avatar" } })
          ])
        ])
      ]),
      _vm._v(" "),
      _c("table", { staticClass: "table table-borderless user-info" }, [
        _c("tr", [
          _c("td", [_vm._v(_vm._s(_vm.$t("user.fullname")))]),
          _vm._v(" "),
          _c("td", [_vm._v(_vm._s(_vm.form.fullname))])
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("td", [_vm._v(_vm._s(_vm.$t("user.email")))]),
          _vm._v(" "),
          _c("td", [_vm._v(_vm._s(_vm.form.email))])
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("td", [_vm._v(_vm._s(_vm.$t("user.tel")))]),
          _vm._v(" "),
          _c("td", [_vm._v(_vm._s(_vm.form.tel))])
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("td", [_vm._v(_vm._s(_vm.$t("user.birthday")))]),
          _vm._v(" "),
          _c("td", [_vm._v(_vm._s(_vm.form.birthday))])
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("td", [_vm._v(_vm._s(_vm.$t("user.gender")))]),
          _vm._v(" "),
          _c("td", [
            _vm._v(_vm._s(_vm.form.gender ? _vm.form.gender.display_name : ""))
          ])
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("td", [_vm._v(_vm._s(_vm.$t("user.city")))]),
          _vm._v(" "),
          _c("td", [
            _vm._v(_vm._s(_vm.form.city_id ? _vm.form.city_id.name : ""))
          ])
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("td", [_vm._v(_vm._s(_vm.$t("user.district")))]),
          _vm._v(" "),
          _c("td", [
            _vm._v(
              _vm._s(_vm.form.district_id ? _vm.form.district_id.name : "")
            )
          ])
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("td", [_vm._v(_vm._s(_vm.$t("user.ward")))]),
          _vm._v(" "),
          _c("td", [
            _vm._v(_vm._s(_vm.form.ward_id ? _vm.form.ward_id.name : ""))
          ])
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("td", [_vm._v(_vm._s(_vm.$t("user.address")))]),
          _vm._v(" "),
          _c("td", [_vm._v(_vm._s(_vm.form.address))])
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("td", [_vm._v(_vm._s(_vm.$t("user.rank")))]),
          _vm._v(" "),
          _c("td", [
            _vm._v(
              _vm._s(_vm.form.rank_id ? _vm.form.rank_id.ranking_name : "")
            )
          ])
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("td", [_vm._v(_vm._s(_vm.$t("user.facebookAccount")))]),
          _vm._v(" "),
          _c(
            "td",
            [
              _vm.form.facebook_account
                ? [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: _vm.form.facebook_account.url,
                          target: "_blank",
                          rel: "noopener noreferrer"
                        }
                      },
                      [_vm._v(_vm._s(_vm.form.facebook_account.url))]
                    )
                  ]
                : _vm._e()
            ],
            2
          )
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("td", [_vm._v(_vm._s(_vm.$t("user.facebookFollower")))]),
          _vm._v(" "),
          _c("td", [
            _vm._v(
              _vm._s(
                _vm.form.facebook_account
                  ? _vm.form.facebook_account.number_friend
                  : ""
              )
            )
          ])
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("td", [_vm._v(_vm._s(_vm.$t("user.instagramAccount")))]),
          _vm._v(" "),
          _c(
            "td",
            [
              _vm.form.instagram_account
                ? [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: _vm.form.instagram_account.url,
                          target: "_blank",
                          rel: "noopener noreferrer"
                        }
                      },
                      [_vm._v(_vm._s(_vm.form.instagram_account.url))]
                    )
                  ]
                : _vm._e()
            ],
            2
          )
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("td", [_vm._v(_vm._s(_vm.$t("user.instagramFollower")))]),
          _vm._v(" "),
          _c("td", [
            _vm._v(
              _vm._s(
                _vm.form.instagram_account
                  ? _vm.form.instagram_account.number_friend
                  : ""
              )
            )
          ])
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("td", [_vm._v(_vm._s(_vm.$t("user.tiktokAccount")))]),
          _vm._v(" "),
          _c(
            "td",
            [
              _vm.form.tiktok_account
                ? [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: _vm.form.tiktok_account.url,
                          target: "_blank",
                          rel: "noopener noreferrer"
                        }
                      },
                      [_vm._v(_vm._s(_vm.form.tiktok_account.url))]
                    )
                  ]
                : _vm._e()
            ],
            2
          )
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("td", [_vm._v(_vm._s(_vm.$t("user.tiktokFollower")))]),
          _vm._v(" "),
          _c("td", [
            _vm._v(
              _vm._s(
                _vm.form.tiktok_account
                  ? _vm.form.tiktok_account.number_friend
                  : ""
              )
            )
          ])
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("td", [_vm._v(_vm._s(_vm.$t("user.maritalStatus")))]),
          _vm._v(" "),
          _c("td", [
            _vm._v(
              "\n        " +
                _vm._s(
                  _vm.form.marital_status
                    ? _vm.form.marital_status.display_name
                    : ""
                ) +
                "\n      "
            )
          ])
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("td", [_vm._v(_vm._s(_vm.$t("user.boyCount")))]),
          _vm._v(" "),
          _c("td", [_vm._v(_vm._s(_vm.form.boy_count))])
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("td", [_vm._v(_vm._s(_vm.$t("user.girlCount")))]),
          _vm._v(" "),
          _c("td", [_vm._v(_vm._s(_vm.form.girl_count))])
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("td", [_vm._v(_vm._s(_vm.$t("user.skinType")))]),
          _vm._v(" "),
          _c("td", [
            _vm._v(
              _vm._s(_vm.form.skin_type ? _vm.form.skin_type.display_name : "")
            )
          ])
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("td", [_vm._v(_vm._s(_vm.$t("user.pets")))]),
          _vm._v(" "),
          _c("td", [_vm._v(_vm._s(_vm.formatPets(_vm.form.pets)))])
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("td", [_vm._v(_vm._s(_vm.$t("user.shirtSize")))]),
          _vm._v(" "),
          _c("td", [
            _vm._v(_vm._s(_vm.form.shirt_size ? _vm.form.shirt_size.name : ""))
          ])
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("td", [_vm._v(_vm._s(_vm.$t("user.pantsSize")))]),
          _vm._v(" "),
          _c("td", [_vm._v(_vm._s(_vm.form.pants_size))])
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("td", [_vm._v(_vm._s(_vm.$t("user.shoeSize")))]),
          _vm._v(" "),
          _c("td", [_vm._v(_vm._s(_vm.form.shoe_size))])
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("td", [_vm._v(_vm._s(_vm.$t("user.profileFinishDate")))]),
          _vm._v(" "),
          _c("td", [
            _vm._v(
              _vm._s(_vm._f("datetimeFormatVN")(_vm.form.profile_finish_date))
            )
          ])
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("td", [_vm._v(_vm._s(_vm.$t("user.joinAt")))]),
          _vm._v(" "),
          _c("td", [
            _vm._v(_vm._s(_vm._f("datetimeFormatVN")(_vm.form.created_at)))
          ])
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("td", [_vm._v(_vm._s(_vm.$t("user.favorite")))]),
          _vm._v(" "),
          _c("td", [_vm._v(_vm._s(_vm.form.favorites_text || ""))])
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("td", [
            _vm._v(
              _vm._s(_vm.$t("user.mostFollowerArea")) +
                " " +
                _vm._s(_vm.$t("user.shortMostFollower"))
            )
          ]),
          _vm._v(" "),
          _c("td", [_vm._v(_vm._s(_vm.regionsFollow))])
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("td", [
            _vm._v(
              _vm._s(_vm.$t("user.mostFollowerGender")) +
                " " +
                _vm._s(_vm.$t("user.shortMostFollower"))
            )
          ]),
          _vm._v(" "),
          _c("td", [_vm._v(_vm._s(_vm.genderFollow))])
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("td", [_vm._v(_vm._s(_vm.$t("user.totalPoint")))]),
          _vm._v(" "),
          _c("td", [_vm._v(_vm._s(_vm.totalPoint))])
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("td", [_vm._v(_vm._s(_vm.$t("user.percentProfile")))]),
          _vm._v(" "),
          _c("td", [_vm._v(_vm._s(_vm.percentProfile))])
        ])
      ]),
      _vm._v(" "),
      _c("label", { staticClass: "col-form-label-lg" }, [
        _vm._v(_vm._s(_vm.$t("user.bankInfo")))
      ]),
      _vm._v(" "),
      _c("table", { staticClass: "table table-borderless user-info" }, [
        _c("tr", [
          _c("td", [_vm._v(_vm._s(_vm.$t("user.bankName")))]),
          _vm._v(" "),
          _c("td", [_vm._v(_vm._s(_vm.form.bank_name))])
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("td", [_vm._v(_vm._s(_vm.$t("user.bankBranch")))]),
          _vm._v(" "),
          _c("td", [_vm._v(_vm._s(_vm.form.bank_branch_name))])
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("td", [_vm._v(_vm._s(_vm.$t("user.bankHolder")))]),
          _vm._v(" "),
          _c("td", [_vm._v(_vm._s(_vm.form.bank_account_holders))])
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("td", [_vm._v(_vm._s(_vm.$t("user.bankNumber")))]),
          _vm._v(" "),
          _c("td", [_vm._v(_vm._s(_vm.form.bank_account_number))])
        ])
      ]),
      _vm._v(" "),
      _c("campaign-list", { attrs: { userId: _vm.defaultForm.id } }),
      _vm._v(" "),
      _c("friend-list"),
      _vm._v(" "),
      _c("point-list")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }