<template>
  <div class="d-flex justify-content-center align-items-center login-container">
    <div class="login-panel col-12 d-flex flex-column align-items-center">
      <img src="/images/wefree_logo.png" class="mb-4" alt="">
      <div class="text-danger" v-show="errorMessage">
        {{ "※" + errorMessage }}
      </div>
      <div class="login-form col-md-4 col-sm-5">
        <ValidationObserver
          ref="observer"
          v-slot="{ invalid }"
          tag="form"
          class="login-form"
          @submit.prevent="submitLogin"
        >
          <div class="form-group">
            <label for="username">{{ $t("auth.username") }}</label>
            <ValidationProvider
              name="username"
              rules="required|alpha_num"
              v-slot="{ errors }"
              mode="passive"
            >
              <input
                type="text"
                :class="{ 'form-control': true, error: errors[0] }"
                name="username"
                v-model="loginData.username"
              />
            </ValidationProvider>
          </div>
          <div class="form-group">
            <label for="password">{{ $t("auth.password") }}</label>
            <ValidationProvider
              name="password"
              rules="required"
              v-slot="{ errors }"
              mode="passive"
            >
              <input
                type="password"
                autocomplete="new-password"
                :class="{ 'form-control': true, error: errors[0] }"
                name="password"
                v-model="loginData.password"
              />
            </ValidationProvider>
          </div>
          <div class="form-group d-flex justify-content-center">
            <button class="btn btn-login" type="submit">
              {{ $t("button.login") }}
            </button>
          </div>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "login",

  data: () => ({
    loginData: {
      username: "",
      password: ""
    },
    errorMessage: ""
  }),

  methods: {
    ...mapActions("auth", ["login"]),
    async submitLogin() {
      this.errorMessage = "";
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        setTimeout(() => {
          const errors = Object.entries(this.$refs.observer.errors)
            .map(([key, value]) => ({ key, value }))
            .filter(error => error["value"].length);
          this.$refs.observer.refs[errors[0]["key"]].$el.scrollIntoView({
            behavior: "smooth",
            block: "center"
          });
          this.errorMessage = errors[0]["value"][0];
        }, 100);
        return;
      }

      this.login(this.loginData)
        .then(() => {
          this.$router.push({ name: "CampaignList" });
        })
        .catch(({ message }) => {
          this.errorMessage = message;
        });
    }
  },
};
</script>

<style lang="scss" scoped>
.login-container {
  height: 100vh;
  .login-form {
    max-width: 350px;
  }
  .btn-login {
    margin-top: 7px;
    background: #b560cc;
    color: white;
  }
}
</style>
