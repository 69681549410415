var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("label", { staticClass: "col-form-label-lg" }, [
        _vm._v(_vm._s(_vm.$t("user.user_campaigns.history")))
      ]),
      _vm._v(" "),
      _c("table", { staticClass: "table" }, [
        _c("thead", [
          _c("tr", [
            _c("th", { attrs: { scope: "col" } }, [
              _vm._v(_vm._s(_vm.$t("user.user_campaigns.id")))
            ]),
            _vm._v(" "),
            _c("th", { attrs: { scope: "col" } }, [
              _vm._v(_vm._s(_vm.$t("user.user_campaigns.campaign_name")))
            ]),
            _vm._v(" "),
            _c("th", { attrs: { scope: "col" } }, [
              _vm._v(_vm._s(_vm.$t("user.user_campaigns.created_at")))
            ]),
            _vm._v(" "),
            _c("th", { attrs: { scope: "col" } }, [
              _vm._v(_vm._s(_vm.$t("user.user_campaigns.approved")))
            ]),
            _vm._v(" "),
            _c("th", { attrs: { scope: "col" } }, [
              _vm._v(_vm._s(_vm.$t("user.user_campaigns.review")))
            ])
          ])
        ]),
        _vm._v(" "),
        _c(
          "tbody",
          [
            _vm.campaignHistories.length > 0
              ? _vm._l(_vm.campaignHistories, function(campaignHistory, index) {
                  return _c("tr", { key: index }, [
                    _c("td", [_vm._v(_vm._s(campaignHistory.id))]),
                    _vm._v(" "),
                    _c(
                      "td",
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                name: "CampaignEdit",
                                params: { id: campaignHistory.campaign_id }
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(campaignHistory.name) +
                                "\n            "
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        _vm._s(_vm.formatDatetime(campaignHistory.created_at))
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "td",
                      [
                        campaignHistory.approve
                          ? _c("b-icon-check", {
                              staticClass: "h1",
                              attrs: { variant: "success" }
                            })
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      [
                        campaignHistory.review_status === 1
                          ? _c(
                              "b-button",
                              {
                                attrs: { variant: "outline-primary" },
                                on: {
                                  click: function($event) {
                                    return _vm.openDetail(
                                      campaignHistory.campaign_id
                                    )
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "user.user_campaigns.detail_review"
                                      )
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ])
                })
              : [
                  _c("tr", { staticClass: "text-center" }, [
                    _c("td", { attrs: { colspan: "5" } }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$t("user.user_campaigns.not_match_campaign")
                          ) +
                          "\n          "
                      )
                    ])
                  ])
                ]
          ],
          2
        )
      ]),
      _vm._v(" "),
      _c("user-campaign-detail-modal", {
        attrs: { id: _vm.userCampaignDetailModalId },
        on: { close: _vm.closeModal }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }