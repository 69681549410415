var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "main-content has-filter-bar" },
        [
          _c("div", { staticClass: "table-wrapper" }, [
            _c(
              "table",
              [
                _c("thead", [
                  _c("tr", [
                    _c("th", [_vm._v(_vm._s(_vm.$t("rewards.id")))]),
                    _vm._v(" "),
                    _c("th", [_vm._v(_vm._s(_vm.$t("rewards.name")))]),
                    _vm._v(" "),
                    _c("th", [_vm._v(_vm._s(_vm.$t("rewards.note")))]),
                    _vm._v(" "),
                    _c("th", [_vm._v(_vm._s(_vm.$t("rewards.thumbnail")))]),
                    _vm._v(" "),
                    _c("th", [_vm._v(_vm._s(_vm.$t("rewards.userId")))]),
                    _vm._v(" "),
                    _c("th")
                  ])
                ]),
                _vm._v(" "),
                _vm._l(_vm.userRewards, function(item) {
                  return [
                    _c("tr", { key: item.id }, [
                      _c("td", [_vm._v(_vm._s(item.id))]),
                      _vm._v(" "),
                      _c("td", [_vm._v(_vm._s(item.name))]),
                      _vm._v(" "),
                      _c("td", [_vm._v(_vm._s(item.note))]),
                      _vm._v(" "),
                      _c("td", [
                        _c("img", {
                          staticClass: "contain",
                          attrs: { src: item.thumbnail, width: "90" }
                        })
                      ]),
                      _vm._v(" "),
                      _c("td", [_vm._v(_vm._s(item.fullname))]),
                      _vm._v(" "),
                      _c(
                        "td",
                        [
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: {
                                  name: "RewardsEdit",
                                  params: { id: item.id }
                                }
                              }
                            },
                            [
                              _c("b-icon-pencil", {
                                staticClass: "action-icon",
                                attrs: { variant: "secondary" }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("b-icon-trash", {
                            staticClass: "action-icon",
                            attrs: { variant: "danger" },
                            on: {
                              click: function($event) {
                                return _vm.openDeleteModal(item.id)
                              }
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ]
                })
              ],
              2
            )
          ]),
          _vm._v(" "),
          _c("pagination-bar", {
            attrs: {
              currentPage: _vm.currentPage,
              lastPage: _vm.lastPage,
              canNext: _vm.canNext,
              canPrev: _vm.canPrev
            },
            on: { "change-page": _vm.fetchData }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("confirm-modal", {
        attrs: { id: _vm.confirmModalId, confirmText: _vm.confirmText },
        on: { confirmed: _vm.deleteRewards, "modal-discard": _vm.cancelModal }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }