export default {
  data: () => ({
    canLeave: false,
    targetRouteName: null,
    routeLeaveModalId: 'route-leave-warning'
  }),
  methods: {
    leave(name) {
      this.canLeave = true
      this.$router.push({ name })
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.canLeave) {
      next()
    } else {
      this.targetRouteName = to.name
      this.$bvModal.show(this.routeLeaveModalId)
    }
  },
}
