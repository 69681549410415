var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.variant
        ? _c(
            "b-alert",
            { attrs: { variant: _vm.variant, show: "", dismissible: "" } },
            [_vm._v(_vm._s(_vm.message))]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-form",
        { on: { submit: _vm.onSubmit, reset: _vm.onReset } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                label: _vm.$t("user.currentPassword"),
                "label-for": "current_password",
                "invalid-feedback": _vm.invalidCurrentPassword,
                state: _vm.stateCurrentPassword
              }
            },
            [
              _c("b-form-input", {
                attrs: {
                  type: "password",
                  id: "current_password",
                  state: _vm.stateCurrentPassword,
                  trim: ""
                },
                model: {
                  value: _vm.model.current_password,
                  callback: function($$v) {
                    _vm.$set(_vm.model, "current_password", $$v)
                  },
                  expression: "model.current_password"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-form-group",
            {
              attrs: {
                label: _vm.$t("auth.new_password"),
                "label-for": "password",
                "invalid-feedback": _vm.invalidNewPassword,
                state: _vm.stateNewPassword
              }
            },
            [
              _c("b-form-input", {
                attrs: {
                  type: "password",
                  id: "password",
                  state: _vm.stateNewPassword,
                  trim: ""
                },
                model: {
                  value: _vm.model.password,
                  callback: function($$v) {
                    _vm.$set(_vm.model, "password", $$v)
                  },
                  expression: "model.password"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-form-group",
            {
              attrs: {
                label: _vm.$t("user.passwordConfirmation"),
                "label-for": "password_confirmation",
                "invalid-feedback": _vm.invalidConfirmationPassword,
                state: _vm.stateConfirmationPassword
              }
            },
            [
              _c("b-form-input", {
                attrs: {
                  type: "password",
                  id: "password_confirmation",
                  state: _vm.stateConfirmationPassword,
                  trim: ""
                },
                model: {
                  value: _vm.model.password_confirmation,
                  callback: function($$v) {
                    _vm.$set(_vm.model, "password_confirmation", $$v)
                  },
                  expression: "model.password_confirmation"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("b-button", { attrs: { type: "submit", variant: "primary" } }, [
            _vm._v(_vm._s(_vm.$t("button.update")))
          ]),
          _vm._v(" "),
          _c("b-button", { attrs: { type: "reset", variant: "danger" } }, [
            _vm._v("Reset")
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }