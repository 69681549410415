import axios from "../../axios";

const state = {
  listNews: [],
  pageObject: {},
  newsData: {},
  removalImageIds: [],
  keyword: ''
};

const getters = {
  listNews: state => state.listNews,
  newsData: state => state.newsData,
  pageObject: state => state.pageObject,
  removalImageIds: state => state.removalImageIds,
  keyword: state => state.keyword
};

const actions = {
  fetchList({ commit }, payload) {
    return new Promise((resolve, reject) => {
      let { page } = payload;
      if (!page) {
        page = 1;
      }
      let url = `/api/news?page=${page}&title=${state.keyword}`;
      axios.get(url).then(({ data }) => {
        const listNews = data.data.list_news;
        const currentPage = data.data.current_page;
        const lastPage = data.data.last_page;
        commit("setListNews", listNews);
        commit("setPagination", {
          currentPage,
          lastPage,
          canNext: currentPage < lastPage,
          canPrev: currentPage > 1
        });
      });
    });
  },
  destroy({}, payload) {
    return new Promise((resolve, reject) => {
      const { id } = payload;
      axios
        .delete(`/api/news/${id}`)
        .then(() => {
          resolve();
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },
  changeAttributesData({ commit }, payload) {
    commit("setNewsAttribute", payload);
  },
  addRemovalImageIds({ commit }, payload) {
    commit("setRemovalImageIds", payload);
  },
  fetchById({ commit }, payload) {
    return new Promise((resolve, reject) => {
      const { id } = payload;
      axios
        .get(`/api/news/${id}`)
        .then(({ data }) => {
          const news = data.data;
          commit("setNewsData", news);
          resolve(news);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },
  store({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/news", payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => reject(response.data));
    });
  },
  update({ commit }, payload) {
    return new Promise((resolve, reject) => {
      const { id } = payload;
      axios
        .post(`/api/news/${id}`, payload)
        .then(() => resolve())
        .catch(({ response }) => reject(response.data));
    });
  },
  changeKeyword({ commit }, payload) {
    const { keyword } = payload;
    commit("setKeyword", keyword);
  }
};

const mutations = {
  setListNews(state, data) {
    state.listNews = data;
  },
  setPagination(state, data) {
    state.pageObject = data;
  },
  setNewsAttribute(state, payload) {
    const { key, value } = payload;
    state.newsData[key] = value;
  },
  setNewsData(state, data) {
    const newsImage = data["news_images"];
    if (newsImage.length !== 0) {
      data["primary_image"] = mapPrimaryImage(data);
      data["secondary_image"] = mapSecondaryImage(data);
    }
    state.newsData = data;
  },
  setRemovalImageIds(state, payload) {
    const { id } = payload;
    const index = state.removalImageIds.findIndex(value => value === id);
    if (index !== -1) {
      state.removalImageIds[index] = id;
    } else {
      state.removalImageIds.push(id);
    }
  },
  setKeyword(state, keyword) {
    state.keyword = keyword;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

function mapPrimaryImage(data) {
  const newsImage = data["news_images"];
  if (newsImage) {
    const imageFind = newsImage.find(item => {
      return Number(item.primary) === 1;
    });
    return {
      id: imageFind.id,
      content: null,
      url: imageFind.path,
      isDirty: false
    };
  }
  return null;
}

function mapSecondaryImage(data) {
  const newsImage = data["news_images"];
  let result = [];
  if (newsImage) {
    const imageFiltered = newsImage.filter(item => {
      return Number(item.primary) === 0;
    });
    imageFiltered.forEach(item => {
      result.push({
        id: item.id,
        content: null,
        url: item.path,
        isDirty: false
      });
    });
  }
  return result;
}
