<template>
  <auth-layout>
    <main-header :title="$t('page.notification.create')">
      <router-link
        :to="{ name: 'NotificationList' }"
        class="btn btn-outline-secondary"
        >{{ $t("page.notification.list") }}</router-link
      >
    </main-header>
    <div class="card">
      <div class="card-body">
        <notification-form :errMessage="errMessage" @submit="submit" :btnText="$t('button.add')"></notification-form>
      </div>
    </div>

    <b-modal
      centered
      :id="routeLeaveModalId"
      :title="$t('message.warning')"
      :ok-title="$t('button.ok')"
      :cancel-title="$t('button.cancel')"
      @ok="leave(targetRouteName)"
    >
      {{ $t('message.routeLeave') }}
    </b-modal>
  </auth-layout>
</template>
<script>
import NotificationForm from "../../components/Form/NotificationForm.vue";
import { mapActions } from "vuex";
import routeLeaveWarning from '../../mixins/routeLeaveWarning';

export default {
  mixins: [routeLeaveWarning],

  components: {
    NotificationForm
  },

  data: () => ({
    errMessage: '',
  }),

  methods: {
    ...mapActions('notification', ['store']),
    submit(formData) {
      this.store(formData)
        .then(() => this.leave("NotificationList"))
        .catch((error) => this.errMessage = error.message || this.$t('message.commonError'))
    }
  },
}
</script>
