<template>
  <auth-layout>
    <div ref="mainHeader"></div>
    <main-header :title="$t('page.news.edit')">
      <router-link
        :to="{ name: 'NewsNavigation' }"
        class="btn btn-outline-secondary"
        >{{ $t("page.news.list") }}</router-link
      >
    </main-header>

    <div class="card">
      <div class="card-body">
        <news-data-blocks></news-data-blocks>
        <news-save-button-edit @leave="leave" @scrollToTop="scrollToTop"></news-save-button-edit>
      </div>
    </div>

    <b-modal
      centered
      :id="routeLeaveModalId"
      :title="$t('message.warning')"
      :ok-title="$t('button.ok')"
      :cancel-title="$t('button.cancel')"
      @ok="leave(targetRouteName)"
    >
      {{ $t('message.routeLeave') }}
    </b-modal>
  </auth-layout>
</template>

<script>
import { mapActions } from 'vuex';
import routeLeaveWarning from '../../mixins/routeLeaveWarning';
import NewsDataBlocks from './../../components/News/data/blocks/NewsDataBlocks'
import NewsSaveButtonEdit from './../../components/News/data/edit/NewsSaveButtonEdit'

export default {
  mixins: [routeLeaveWarning],

  components: {
    NewsDataBlocks,
    NewsSaveButtonEdit
  },

  data: () => ({}),

  methods: {
    ...mapActions('news', ['fetchById', 'store']),
    scrollToTop() {
      this.$nextTick(() => this.$refs.mainHeader.scrollIntoView({
        behavior: "smooth",
        block: "start"
      }));
    },
  },

  mounted() {
    this.fetchById({ id: this.$route.params.id });
  }
};
</script>

<style lang="scss" scoped></style>
