var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-form-group",
        {
          attrs: {
            label: _vm.$t("rewards.note"),
            "invalid-feedback": _vm.invalidFeedback,
            state: _vm.stateValid
          }
        },
        [
          _c("b-form-input", {
            attrs: { state: _vm.stateValid, required: "", trim: "" },
            model: {
              value: _vm.rewardsNote,
              callback: function($$v) {
                _vm.rewardsNote = $$v
              },
              expression: "rewardsNote"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }