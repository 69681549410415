<template>
  <div class="main-content has-filter-bar">
    <div class="table-wrapper">
      <table>
        <thead>
          <tr>
            <th>{{ $t("news.id") }}</th>
            <th>{{ $t("news.imagePrimary") }}</th>
            <th class="w__35--percent">{{ $t("news.title") }}</th>
            <th class="text-center">{{ $t("news.public") }}</th>
            <th class="text-center">{{ $t("news.memberOnly") }}</th>
            <th class="text-center">{{ $t("news.order") }}</th>
            <th class="text-center">{{ $t("news.createdAt") }}</th>
            <th class="text-center"></th>
          </tr>
        </thead>
        <template v-for="item in listNews">
          <tr :key="item.id">
            <td>{{ item.id }}</td>
            <td>
              <img class="contain" :src="item.path" width="90" />
            </td>
            <td>
              <a :href="item.url" target="_blank">
                {{ item.title }}
              </a>
            </td>
            <td class="text-center">
              <b-icon
                v-if="item.public"
                icon="check"
                scale="2"
                variant="success"
              ></b-icon>
            </td>
            <td class="text-center">
              <b-icon
                v-if="item.member_only"
                icon="check"
                scale="2"
                variant="success"
              ></b-icon>
            </td>
            <td>{{ item.news_order }}</td>
            <td>{{ formatDatetime(item.created_at) }}</td>
            <td>
              <router-link
                :to="{
                  name: 'NewsEdit',
                  params: { id: item.id }
                }"
              >
                <b-icon-pencil
                  variant="secondary"
                  class="action-icon"
                ></b-icon-pencil>
              </router-link>

              <b-icon-trash
                variant="danger"
                class="action-icon"
                @click="openDeleteModal(item.id)"
              ></b-icon-trash>
            </td>
          </tr>
        </template>
      </table>
    </div>
    <pagination-bar
      :currentPage="currentPage"
      :lastPage="lastPage"
      :canNext="canNext"
      :canPrev="canPrev"
      @change-page="fetchData"
    ></pagination-bar>
    <confirm-modal
      :id="confirmModalId"
      :confirmText="confirmText"
      @confirmed="deleteNews"
      @modal-discard="cancelModal"
    ></confirm-modal>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";

export default {
  components: {},
  data() {
    return {
      confirmModalId: "news-delete-modal",
      deleteId: null
    };
  },
  computed: {
    confirmText() {
      return this.$t("news.deleteConfirm");
    },
    listNews() {
      return this.$store.getters["news/listNews"];
    },
    pageObject() {
      return this.$store.getters["news/pageObject"];
    },
    currentPage() {
      return this.pageObject.currentPage;
    },
    lastPage() {
      return this.pageObject.lastPage;
    },
    canNext() {
      return this.pageObject.canNext;
    },
    canPrev() {
      return this.pageObject.canPrev;
    },
  },
  methods: {
    ...mapActions("news", ["fetchList", "destroy"]),
    fetchData(page) {
      this.fetchList({ page });
    },
    formatDatetime(datetime) {
      if (datetime) {
        return moment.utc(datetime).format('DD/MM/YYYY HH:mm:ss');
      }
      return '';
    },
    openDeleteModal(id) {
      this.deleteId = id;
      this.$bvModal.show(this.confirmModalId);
    },
    cancelModal() {
      this.deleteId = null;
    },
    deleteNews() {
      this.destroy({ id: this.deleteId })
        .then(() => {
          this.$bvToast.toast(this.$t("message.commonSuccess"), {
            title: this.$t("message.successTitle"),
            variant: "success",
            autoHideDelay: 3000,
            solid: true
          });
          this.fetchData(this.pageObject.currentPage);
        })
        .catch(data => {
          this.$bvToast.toast(data.message || this.$t("message.commonError"), {
            title: this.$t("message.errorTitle"),
            variant: "danger",
            autoHideDelay: 3000,
            solid: true
          });
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.w__35--percent {
  width: 35%;
}
</style>
