<template>
  <b-form-group :invalid-feedback="invalidFeedback" :state="stateValid">
    <label>
      {{ $t("news.slug") }}
      <span class="text-danger ml-1 small">{{ $t("subText.required") }}</span>
    </label>
    <div class="input-group">
      <b-form-input
        v-model="newsSlug"
        :state="stateValid"
        required
        trim
      ></b-form-input>
      <button
        class="btn btn-primary ml-1"
        type="button"
        @click="generateSlug"
      >
        {{ $t("news.AutoGenerateSlug") }}
      </button>
    </div>
  </b-form-group>
</template>

<script>
import { mapActions } from "vuex";
let alphaDashRegEx = /^[a-zA-Z0-9-_]+$/;
export default {
  data() {
    return {};
  },
  computed: {
    newsData() {
      return this.$store.getters["news/newsData"];
    },
    newsSlug: {
      get() {
        return this.newsData && this.newsData.slug
          ? this.newsData.slug
          : "";
      },
      set(value) {
        this.changeAttributesData({
          key: "slug",
          value: value
        });
      }
    },
    stateValid() {
      if (!this.newsData || this.newsData.slug === null) return null;
      if (
        this.newsData.slug === "" ||
        (this.newsData.slug && this.newsData.slug.length > 256) ||
        !alphaDashRegEx.test(this.newsData.slug) 
      )
        return false;
      return true;
    },
    invalidFeedback() {
      if (this.newsSlug) {
        if (this.newsSlug.length > 256) {
         return this.$t("news.maxLength").replace(":length", "256");
        } else if (!alphaDashRegEx.test(this.newsData.slug)) {
          return this.$t("news.alphaDash").replace(":attribute", "slug");
        };
      } 
      return (
        this.$t("subText.required") + " " + this.$t("news.slug").toLowerCase()
      );
    }
  },
  methods: {
    ...mapActions("news", ["changeAttributesData"]),
    generateSlug() {
      if (this.newsData.title) {
        let slug = this.newsData.title.toLowerCase();
        slug = slug.normalize("NFD").replace(/[\u0300-\u036f]/g, ""); //Separate and remove diacritics
        slug = slug.replace(/[đĐ]/g, 'd'); //đ changes to d
        slug = slug.replace(/[^a-z0-9 -]/g, ''); //remove non-alphanumeric characters
        slug = slug.replace(/  +/g, ' '); //remove extra whitespaces
        slug = slug.replace(/\s+/g, '-'); // spaces changes to -
        slug = slug.replace(/--+/g, '-'); // remove extra -

        this.newsSlug = slug;
      }
    }
  }
};
</script>
