var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-form-group",
    {
      attrs: { "invalid-feedback": _vm.invalidFeedback, state: _vm.stateValid }
    },
    [
      _c("label", [_vm._v("\n    " + _vm._s(_vm.$t("news.order")) + "\n  ")]),
      _vm._v(" "),
      _c("b-form-input", {
        attrs: {
          state: _vm.stateValid,
          type: "number",
          required: "",
          trim: ""
        },
        model: {
          value: _vm.newsOrder,
          callback: function($$v) {
            _vm.newsOrder = $$v
          },
          expression: "newsOrder"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }