<template>
  <b-form-group :invalid-feedback="invalidFeedback" :state="stateValid">
    <label>
      {{ $t("userRanking.percent_complete") }}
    </label>
    <b-form-input
      v-model="userRankingPercent"
      :state="stateValid"
      type="number"
      required
      trim
    ></b-form-input>
  </b-form-group>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {};
  },
  computed: {
    userRankingData() {
      return this.$store.getters["userRanking/userRankingData"];
    },
    userRankingPercent: {
      get() {
        return (this.userRankingData && this.userRankingData.percent_complete !== null)
          ? Number(this.userRankingData.percent_complete)
          : null;
      },
      set(value) {
        this.changeAttributesData({
          key: "percent_complete",
          value: Number(value)
        });
      }
    },
    stateValid() {
      if (!this.userRankingData || this.userRankingData.percent_complete === null) return null;
      if (
        this.userRankingPercent < 0 ||
        this.userRankingPercent > 100
      )
        return false;
      return true;
    },
    invalidFeedback() {
      if (
        this.userRankingPercent && (this.userRankingPercent < 0 || this.userRankingPercent > 100)
      ) {
        return this.$t("userRanking.valueLimit").replace(":min", "0").replace(":max", "100");
      }
      return (
        this.$t("subText.required") + " " + this.$t("userRanking.percent_complete").toLowerCase()
      );
    }
  },
  methods: {
    ...mapActions("userRanking", ["changeAttributesData"])
  }
};
</script>

<style lang="scss" scoped>
</style>
