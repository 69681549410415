import axios from "../../axios";
import moment from "moment";

export default {
  namespaced: true,
  state: {
    users: [],
    pageObject: {},
    shirtSizes: [],
    pantsSizes: [],
    shoeSizes: [],
    ranks: [],
    detail: {
      campaignHistories: [],
      friendReferralHistories: [],
      pointHistories: [],
      userCampaignDetail: {},
      percentProfile: {},
    },
    reviewDetail: {},
  },
  mutations: {
    setUsers(state, users) {
      if (Array.isArray(users)) {
        state.users.length = 0;
        state.users = [...users];
      }
    },
    setPageObject(state, pageObject) {
      Object.assign(state.pageObject, pageObject);
    },
    setUserStatus(state, payload) {
      const { index, status } = payload;
      state.users[index].status = status;
    },
    setShirtSizes(state, shirtSizes) {
      if (Array.isArray(shirtSizes)) {
        state.shirtSizes.length = 0;
        state.shirtSizes = [...shirtSizes];
      }
    },
    setPantsSizes(state, pantsSizes) {
      if (Array.isArray(pantsSizes)) {
        state.pantsSizes.length = 0;
        state.pantsSizes = [...pantsSizes];
      }
    },
    setShoeSizes(state, shoeSizes) {
      if (Array.isArray(shoeSizes)) {
        state.shoeSizes.length = 0;
        state.shoeSizes = [...shoeSizes];
      }
    },
    setRanks(state, ranks) {
      if (Array.isArray(ranks)) {
        state.ranks.length = 0;
        state.ranks = [...ranks];
      }
    },
    setCampaignHistories(state, data) {
      state.detail.campaignHistories = data;
    },
    setFriendReferralHistories(state, data) {
      state.detail.friendReferralHistories = data;
    },
    setPointHistory(state, data) {
      state.detail.pointHistories = data;
    },
    setUserCampaignDetail(state, data) {
      state.detail.userCampaignDetail = data;
    },
    setPercentProfile(state, data) {
      state.detail.percentProfile = data;
    },
    setReviewDetail(state, data) {
      state.reviewDetail = data;
    },
  },
  actions: {
    fetchUsers({commit}, payload) {
      return new Promise((resolve, reject) => {
        let { page, keyword } = payload;

        if (!page) {
          page = 1
        }

        let url = `/api/users?page=${page}`;

        if (keyword) {
          url += `&search=${encodeURIComponent(keyword)}`;
        }

        axios.get(url)
          .then(({data}) => {
            commit('setUsers', data.data);
            commit('setPageObject', {
              currentPage: data.current_page,
              lastPage: data.last_page,
              canNext: data.current_page < data.last_page,
              canPrev: data.current_page > 1
            });
            resolve();
          })
      });
    },

    switchStatus({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const { id, index } = payload;
        axios.patch(`/api/users/${id}/switch-status`)
          .then(({data}) => {
            commit('setUserStatus', {
              index,
              status: data.new_status
            })
            resolve(data)
          })
          .catch(({response}) => reject(response.data))
      })
    },

    store({}, payload) {
      return new Promise((resolve, reject) => {
        axios.post('/api/users', payload)
          .then(() => resolve())
          .catch(({response}) => reject(response.data))
      });
    },

    fetchUserInfo({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const { id } = payload;
        axios.get(`/api/users/${id}`)
          .then(({ data }) => {
            const campaign_histories = data.campaign_histories;
            commit('setCampaignHistories', campaign_histories);

            const friend_referral_histories = data.friend_referral_histories;
            commit('setFriendReferralHistories', friend_referral_histories);

            const percentProfile = data.percent_profile;
            commit('setPercentProfile', percentProfile);

            const points_histories = data.points_histories;
            commit('setPointHistory', points_histories);

            resolve(data.data)
            resolve(data.campaign_histories)
            resolve(data.friend_referral_histories)
            resolve(data.points_histories)
          })
          .catch(() => reject())
      });
    },

    update({}, payload) {
      return new Promise((resolve, reject) => {
        const { id } = payload;
        axios.post(`/api/users/${id}`, payload)
          .then(() => resolve())
          .catch(({response}) => reject(response.data))
      });
    },

    destroy({}, payload) {
      return new Promise((resolve, reject) => {
        const { id } = payload;
        axios.delete(`/api/users/${id}`, payload)
          .then(() => resolve())
          .catch(({response}) => reject(response.data))
      });
    },

    fetchSizeOptions({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/users/size-options`)
          .then(({data}) => {
            commit('setShirtSizes', data.shirt);
            commit('setPantsSizes', data.pants);
            commit('setShoeSizes', data.shoe);
            resolve()
          })
          .catch(() => reject())
      });
    },

    fetchRankOptions({commit}) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/users/rank-options`)
          .then(({data}) => {
            commit('setRanks', data);
            resolve()
          })
          .catch(() => reject())
      });
    },

    // download user excel
    downloadExcel({}, payload) {
      return new Promise((resolve, reject) => {
        let { page, keyword } = payload;

        if (!page) {
          page = 1
        }

        let url = `/api/users/export`;
        let params = {
          page
        }

        if (keyword) {
          params.search = keyword
        }

        axios({
          method: 'GET',
          url,
          responseType: 'blob', // Don't forget this. This is very important. Read more: https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/Sending_and_Receiving_Binary_Data
          params
        })
          .then((response) => {
            const currentTime = moment().format('YYYYMMDDHHmmss');

            let filename = "users_" + currentTime + ".xlsx";
            let blob = new Blob([response.data], { type: 'text/excel' });
            if (window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveBlob(blob, filename);
            }
            else {
              var elem = window.document.createElement('a');
              elem.href = window.URL.createObjectURL(blob);
              elem.download = filename;
              document.body.appendChild(elem);
              elem.click();
              document.body.removeChild(elem);
            }

            resolve();
          })
          .catch(error => reject(error))
      });
    },

    // fetch review detail
    fetchReviewDetail({ commit }, payload) {
      const { id, userId } = payload;
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/campaigns/${id}/users/${userId}/review`)
          .then(({data}) => {
            commit('setReviewDetail', data);
            resolve();
          })
          .catch(({response}) => reject(response.data))
      });
    },

    // update user's sns info
    refreshSocialInfo({}, payload) {
      return new Promise((resolve, reject) => {
        const { id } = payload
        axios.post(`/api/users/${id}/refresh-sns`)
          .then(({data}) => resolve(data))
          .catch(({response}) => reject(response.data))
      })
    }
  },
}
