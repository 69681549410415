var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ValidationObserver", {
    ref: "observer",
    attrs: { tag: "form", mode: "passive" },
    on: {
      submit: function($event) {
        $event.preventDefault()
        return _vm.submit.apply(null, arguments)
      }
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var invalid = ref.invalid
          var errors = ref.errors
          return [
            invalid || _vm.errMessage
              ? _c(
                  "div",
                  {
                    ref: "errorDiv",
                    staticClass:
                      "text-danger d-flex justify-content-center mt-1"
                  },
                  [
                    _c(
                      "ul",
                      [
                        _vm._l(errors, function(error) {
                          return [
                            error[0]
                              ? _c("li", [_vm._v(_vm._s(error[0]))])
                              : _vm._e()
                          ]
                        }),
                        _vm._v(" "),
                        _vm.errMessage
                          ? _c("li", [_vm._v(_vm._s(_vm.errMessage))])
                          : _vm._e()
                      ],
                      2
                    )
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", { attrs: { for: "" } }, [
                  _vm._v(_vm._s(_vm.$t("campaign.name"))),
                  _c("span", { staticClass: "text-danger ml-1 small" }, [
                    _vm._v(_vm._s(_vm.$t("subText.required")))
                  ])
                ]),
                _vm._v(" "),
                _c("ValidationProvider", {
                  attrs: {
                    mode: "passive",
                    name: _vm.$t("campaign.name"),
                    rules: "required|max:128"
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(ref) {
                          var errors = ref.errors
                          return [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.form.name,
                                  expression: "form.name"
                                }
                              ],
                              class: {
                                "form-control": true,
                                "border border-danger": errors[0]
                              },
                              domProps: { value: _vm.form.name },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.form,
                                    "name",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ]
                        }
                      }
                    ],
                    null,
                    true
                  )
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c(
                  "b-form-row",
                  [
                    _c("b-col", { staticClass: "flex-200px" }, [
                      _c("label", { attrs: { for: "campaign-public" } }, [
                        _vm._v(_vm._s(_vm.$t("campaign.public")))
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "b-col",
                      [
                        _c("b-form-checkbox", {
                          attrs: {
                            id: "campaign-public",
                            switch: "",
                            size: "lg"
                          },
                          model: {
                            value: _vm.form.public,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "public", $$v)
                            },
                            expression: "form.public"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c(
                  "b-form-row",
                  [
                    _c(
                      "b-col",
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v(_vm._s(_vm.$t("campaign.category"))),
                          _c(
                            "span",
                            { staticClass: "text-danger ml-1 small" },
                            [_vm._v(_vm._s(_vm.$t("subText.required")))]
                          )
                        ]),
                        _vm._v(" "),
                        _c("ValidationProvider", {
                          attrs: {
                            mode: "passive",
                            name: _vm.$t("campaign.category"),
                            rules: "required"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("multi-select", {
                                      class: {
                                        "border border-danger": errors[0]
                                      },
                                      attrs: {
                                        options: _vm.categoryOptions,
                                        label: "display_name",
                                        "track-by": "id",
                                        selectedLabel: _vm.$t(
                                          "multiselect.selectedLabel"
                                        ),
                                        selectLabel: _vm.$t(
                                          "multiselect.selectLabel"
                                        ),
                                        placeholder: "",
                                        deselectLabel: _vm.$t(
                                          "multiselect.deselectLabel"
                                        )
                                      },
                                      model: {
                                        value: _vm.form.category_id,
                                        callback: function($$v) {
                                          _vm.$set(_vm.form, "category_id", $$v)
                                        },
                                        expression: "form.category_id"
                                      }
                                    })
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "b-col",
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("campaign.price")) +
                              "\n        "
                          )
                        ]),
                        _vm._v(" "),
                        _c("ValidationProvider", {
                          attrs: {
                            mode: "passive",
                            name: _vm.$t("campaign.price"),
                            rules: "max:100"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("b-form-input", {
                                      class: {
                                        "border border-danger": errors[0]
                                      },
                                      attrs: { type: "text", maxlength: "100" },
                                      model: {
                                        value: _vm.form.price,
                                        callback: function($$v) {
                                          _vm.$set(_vm.form, "price", $$v)
                                        },
                                        expression: "form.price"
                                      }
                                    })
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "b-col",
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v(_vm._s(_vm.$t("campaign.salePrice")))
                        ]),
                        _vm._v(" "),
                        _c("ValidationProvider", {
                          attrs: {
                            mode: "passive",
                            name: _vm.$t("campaign.salePrice"),
                            rules: "max:100"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("b-form-input", {
                                      class: {
                                        "border border-danger": errors[0]
                                      },
                                      attrs: { type: "text", maxlength: "100" },
                                      model: {
                                        value: _vm.form.sale_price,
                                        callback: function($$v) {
                                          _vm.$set(_vm.form, "sale_price", $$v)
                                        },
                                        expression: "form.sale_price"
                                      }
                                    })
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c(
                  "b-form-row",
                  [
                    _c(
                      "b-col",
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v(_vm._s(_vm.$t("campaign.city"))),
                          _c(
                            "span",
                            { staticClass: "text-danger ml-1 small" },
                            [_vm._v(_vm._s(_vm.$t("subText.required")))]
                          )
                        ]),
                        _vm._v(" "),
                        _c("ValidationProvider", {
                          attrs: {
                            mode: "passive",
                            name: _vm.$t("campaign.city"),
                            rules: "required"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("multi-select", {
                                      class: {
                                        "border border-danger": errors[0]
                                      },
                                      attrs: {
                                        multiple: "",
                                        "internal-search": false,
                                        options: _vm.filteredCities,
                                        label: "name",
                                        "track-by": "id",
                                        selectedLabel: _vm.$t(
                                          "multiselect.selectedLabel"
                                        ),
                                        selectLabel: _vm.$t(
                                          "multiselect.selectLabel"
                                        ),
                                        placeholder: "",
                                        deselectLabel: _vm.$t(
                                          "multiselect.deselectLabel"
                                        )
                                      },
                                      on: {
                                        "search-change": _vm.citySearching
                                      },
                                      model: {
                                        value: _vm.form.city_id,
                                        callback: function($$v) {
                                          _vm.$set(_vm.form, "city_id", $$v)
                                        },
                                        expression: "form.city_id"
                                      }
                                    })
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "b-col",
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v(_vm._s(_vm.$t("campaign.social")))
                        ]),
                        _vm._v(" "),
                        _c("ValidationProvider", {
                          attrs: {
                            mode: "passive",
                            name: _vm.$t("campaign.social"),
                            rules: ""
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("multi-select", {
                                      class: {
                                        "border border-danger": errors[0]
                                      },
                                      attrs: {
                                        options: _vm.socialOptions,
                                        multiple: true,
                                        label: "display_name",
                                        "track-by": "id",
                                        selectedLabel: _vm.$t(
                                          "multiselect.selectedLabel"
                                        ),
                                        selectLabel: _vm.$t(
                                          "multiselect.selectLabel"
                                        ),
                                        placeholder: "",
                                        deselectLabel: _vm.$t(
                                          "multiselect.deselectLabel"
                                        ),
                                        searchable: false
                                      },
                                      on: { remove: _vm.socialRemove },
                                      model: {
                                        value: _vm.form.campaign_socials,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "campaign_socials",
                                            $$v
                                          )
                                        },
                                        expression: "form.campaign_socials"
                                      }
                                    })
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c(
                  "b-form-row",
                  [
                    _c(
                      "b-col",
                      { attrs: { md: "4" } },
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v(_vm._s(_vm.$t("campaign.primaryImage"))),
                          _c(
                            "span",
                            { staticClass: "text-danger ml-1 small" },
                            [_vm._v(_vm._s(_vm.$t("subText.required")))]
                          )
                        ]),
                        _vm._v(" "),
                        _c("image-input", {
                          attrs: {
                            data: _vm.form.primary_image,
                            "can-remove": false
                          },
                          on: { "image-input": _vm.primaryImageInput }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "b-col",
                      {
                        staticClass:
                          "d-flex flex-column justify-content-between"
                      },
                      [
                        _c(
                          "b-form-row",
                          [
                            _c(
                              "b-col",
                              { staticClass: "mb-2", attrs: { cols: "6" } },
                              [
                                _c("label", { attrs: { for: "" } }, [
                                  _vm._v(_vm._s(_vm.$t("campaign.maxUser"))),
                                  _c(
                                    "span",
                                    { staticClass: "text-danger ml-1 small" },
                                    [_vm._v(_vm._s(_vm.$t("subText.required")))]
                                  )
                                ]),
                                _vm._v(" "),
                                _c("ValidationProvider", {
                                  attrs: {
                                    mode: "passive",
                                    name: _vm.$t("campaign.maxUser"),
                                    rules: "required|numeric"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var errors = ref.errors
                                          return [
                                            _c("b-form-input", {
                                              class: {
                                                "form-control": true,
                                                "border border-danger":
                                                  errors[0]
                                              },
                                              attrs: { type: "number" },
                                              model: {
                                                value: _vm.form.max_user,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "max_user",
                                                    _vm._n($$v)
                                                  )
                                                },
                                                expression: "form.max_user"
                                              }
                                            })
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "b-col",
                              { staticClass: "mb-2", attrs: { cols: "6" } },
                              [
                                _c("label", { attrs: { for: "" } }, [
                                  _vm._v(_vm._s(_vm.$t("campaign.initUser")))
                                ]),
                                _vm._v(" "),
                                _c("ValidationProvider", {
                                  attrs: {
                                    mode: "passive",
                                    name: _vm.$t("campaign.initUser"),
                                    rules: "numeric"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var errors = ref.errors
                                          return [
                                            _c("b-form-input", {
                                              class: {
                                                "form-control": true,
                                                "border border-danger":
                                                  errors[0]
                                              },
                                              attrs: { type: "number" },
                                              model: {
                                                value: _vm.form.initial_user,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "initial_user",
                                                    _vm._n($$v)
                                                  )
                                                },
                                                expression: "form.initial_user"
                                              }
                                            })
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-form-row",
                          [
                            _c(
                              "b-col",
                              { staticClass: "mb-2" },
                              [
                                _c("label", { attrs: { for: "" } }, [
                                  _vm._v(_vm._s(_vm.$t("campaign.slug"))),
                                  _c(
                                    "span",
                                    { staticClass: "text-danger ml-1 small" },
                                    [_vm._v(_vm._s(_vm.$t("subText.required")))]
                                  )
                                ]),
                                _vm._v(" "),
                                _c(
                                  "b-form-row",
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { md: "9" } },
                                      [
                                        _c("ValidationProvider", {
                                          attrs: {
                                            mode: "passive",
                                            name: _vm.$t("campaign.slug"),
                                            rules: "required|alpha_dash|max:256"
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function(ref) {
                                                  var errors = ref.errors
                                                  return [
                                                    _c("b-form-input", {
                                                      class: {
                                                        "form-control": true,
                                                        "border border-danger":
                                                          errors[0]
                                                      },
                                                      attrs: {
                                                        type: "text",
                                                        maxlength: "256"
                                                      },
                                                      model: {
                                                        value: _vm.form.slug,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form,
                                                            "slug",
                                                            $$v
                                                          )
                                                        },
                                                        expression: "form.slug"
                                                      }
                                                    })
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-col",
                                      {
                                        staticClass: "input-group",
                                        attrs: { md: "3", ml: "auto" }
                                      },
                                      [
                                        _c(
                                          "button",
                                          {
                                            staticClass: "btn btn-primary",
                                            attrs: { type: "button" },
                                            on: { click: _vm.generateSlug }
                                          },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "campaign.AutoGenerateSlug"
                                                  )
                                                ) +
                                                "\n                "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-form-row",
                          [
                            _c(
                              "b-col",
                              { staticClass: "mb-2", attrs: { md: "6" } },
                              [
                                _c("label", { attrs: { for: "" } }, [
                                  _vm._v(_vm._s(_vm.$t("campaign.endTime")))
                                ]),
                                _vm._v(" "),
                                _c("ValidationProvider", {
                                  attrs: {
                                    mode: "passive",
                                    name: _vm.$t("campaign.endTime"),
                                    rules: ""
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var errors = ref.errors
                                          return [
                                            _c("v-date-picker", {
                                              attrs: {
                                                mode: "dateTime",
                                                "min-date": _vm.minEndDate,
                                                is24hr: "",
                                                locale: _vm.currentLocale
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var inputValue =
                                                        ref.inputValue
                                                      var inputEvents =
                                                        ref.inputEvents
                                                      return [
                                                        _c(
                                                          "input",
                                                          _vm._g(
                                                            {
                                                              staticClass:
                                                                "form-control",
                                                              attrs: {
                                                                placeholder: _vm.$t(
                                                                  "datePicker.placeHolder"
                                                                )
                                                              },
                                                              domProps: {
                                                                value: inputValue
                                                              }
                                                            },
                                                            inputEvents
                                                          )
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              ),
                                              model: {
                                                value: _vm.form.end_time,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "end_time",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.end_time"
                                              }
                                            })
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "b-col",
                              { staticClass: "mb-2", attrs: { md: "6" } },
                              [
                                _c("label", { attrs: { for: "" } }, [
                                  _vm._v(
                                    _vm._s(_vm.$t("campaign.approveEndTime"))
                                  )
                                ]),
                                _vm._v(" "),
                                _c("ValidationProvider", {
                                  attrs: {
                                    mode: "passive",
                                    name: _vm.$t("campaign.approveEndTime"),
                                    rules: ""
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var errors = ref.errors
                                          return [
                                            _c("v-date-picker", {
                                              attrs: {
                                                mode: "dateTime",
                                                "min-date": _vm.minEndDate,
                                                is24hr: "",
                                                locale: _vm.currentLocale
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var inputValue =
                                                        ref.inputValue
                                                      var inputEvents =
                                                        ref.inputEvents
                                                      return [
                                                        _c(
                                                          "input",
                                                          _vm._g(
                                                            {
                                                              staticClass:
                                                                "form-control",
                                                              attrs: {
                                                                placeholder: _vm.$t(
                                                                  "datePicker.placeHolder"
                                                                )
                                                              },
                                                              domProps: {
                                                                value: inputValue
                                                              }
                                                            },
                                                            inputEvents
                                                          )
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              ),
                                              model: {
                                                value:
                                                  _vm.form.approve_end_time,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "approve_end_time",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.approve_end_time"
                                              }
                                            })
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "b-col",
                              { staticClass: "mb-2", attrs: { md: "6" } },
                              [
                                _c("label", { attrs: { for: "" } }, [
                                  _vm._v(
                                    _vm._s(_vm.$t("campaign.postReviewTime"))
                                  )
                                ]),
                                _vm._v(" "),
                                _c("ValidationProvider", {
                                  attrs: {
                                    mode: "passive",
                                    name: _vm.$t("campaign.postReviewTime"),
                                    rules: ""
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var errors = ref.errors
                                          return [
                                            _c("v-date-picker", {
                                              attrs: {
                                                mode: "dateTime",
                                                "min-date": _vm.minEndDate,
                                                is24hr: "",
                                                locale: _vm.currentLocale
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var inputValue =
                                                        ref.inputValue
                                                      var inputEvents =
                                                        ref.inputEvents
                                                      return [
                                                        _c(
                                                          "input",
                                                          _vm._g(
                                                            {
                                                              staticClass:
                                                                "form-control",
                                                              attrs: {
                                                                placeholder: _vm.$t(
                                                                  "datePicker.placeHolder"
                                                                )
                                                              },
                                                              domProps: {
                                                                value: inputValue
                                                              }
                                                            },
                                                            inputEvents
                                                          )
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              ),
                                              model: {
                                                value:
                                                  _vm.form.post_review_time,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "post_review_time",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.post_review_time"
                                              }
                                            })
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "b-col",
                              { staticClass: "mb-2", attrs: { md: "6" } },
                              [
                                _c("label", { attrs: { for: "" } }, [
                                  _vm._v(
                                    _vm._s(_vm.$t("campaign.reviewEndTime"))
                                  )
                                ]),
                                _vm._v(" "),
                                _c("ValidationProvider", {
                                  attrs: {
                                    mode: "passive",
                                    name: _vm.$t("campaign.reviewEndTime"),
                                    rules: ""
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var errors = ref.errors
                                          return [
                                            _c("v-date-picker", {
                                              attrs: {
                                                mode: "dateTime",
                                                "min-date": _vm.minEndDate,
                                                is24hr: "",
                                                locale: _vm.currentLocale
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var inputValue =
                                                        ref.inputValue
                                                      var inputEvents =
                                                        ref.inputEvents
                                                      return [
                                                        _c(
                                                          "input",
                                                          _vm._g(
                                                            {
                                                              staticClass:
                                                                "form-control",
                                                              attrs: {
                                                                placeholder: _vm.$t(
                                                                  "datePicker.placeHolder"
                                                                )
                                                              },
                                                              domProps: {
                                                                value: inputValue
                                                              }
                                                            },
                                                            inputEvents
                                                          )
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              ),
                                              model: {
                                                value: _vm.form.review_end_time,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "review_end_time",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.review_end_time"
                                              }
                                            })
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "b-col",
                              { staticClass: "mb-2", attrs: { md: "6" } },
                              [
                                _c("label", { attrs: { for: "" } }, [
                                  _vm._v(_vm._s(_vm.$t("campaign.closedDate")))
                                ]),
                                _vm._v(" "),
                                _c("ValidationProvider", {
                                  attrs: {
                                    mode: "passive",
                                    name: _vm.$t("campaign.closedDate"),
                                    rules: ""
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var errors = ref.errors
                                          return [
                                            _c("v-date-picker", {
                                              attrs: {
                                                mode: "dateTime",
                                                "min-date": _vm.minEndDate,
                                                is24hr: "",
                                                locale: _vm.currentLocale
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var inputValue =
                                                        ref.inputValue
                                                      var inputEvents =
                                                        ref.inputEvents
                                                      return [
                                                        _c(
                                                          "input",
                                                          _vm._g(
                                                            {
                                                              staticClass:
                                                                "form-control",
                                                              attrs: {
                                                                placeholder: _vm.$t(
                                                                  "datePicker.placeHolder"
                                                                )
                                                              },
                                                              domProps: {
                                                                value: inputValue
                                                              }
                                                            },
                                                            inputEvents
                                                          )
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              ),
                                              model: {
                                                value: _vm.form.closed_date,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "closed_date",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.closed_date"
                                              }
                                            })
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "b-col",
                              { staticClass: "mb-2", attrs: { md: "6" } },
                              [
                                _c("label", { attrs: { for: "" } }, [
                                  _vm._v(
                                    _vm._s(_vm.$t("campaign.crawlingTime"))
                                  )
                                ]),
                                _vm._v(" "),
                                _c("ValidationProvider", {
                                  attrs: {
                                    mode: "passive",
                                    name: _vm.$t("campaign.crawlingTime"),
                                    rules: ""
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var errors = ref.errors
                                          return [
                                            _c("v-date-picker", {
                                              attrs: {
                                                mode: "dateTime",
                                                "min-date": _vm.minEndDate,
                                                is24hr: "",
                                                locale: _vm.currentLocale
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var inputValue =
                                                        ref.inputValue
                                                      var inputEvents =
                                                        ref.inputEvents
                                                      return [
                                                        _c(
                                                          "input",
                                                          _vm._g(
                                                            {
                                                              staticClass:
                                                                "form-control",
                                                              attrs: {
                                                                placeholder: _vm.$t(
                                                                  "datePicker.placeHolder"
                                                                )
                                                              },
                                                              domProps: {
                                                                value: inputValue
                                                              }
                                                            },
                                                            inputEvents
                                                          )
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              ),
                                              model: {
                                                value: _vm.form.crawling_time,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "crawling_time",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.crawling_time"
                                              }
                                            })
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", { attrs: { for: "" } }, [
                  _vm._v(_vm._s(_vm.$t("campaign.optionalImage")) + "\n      "),
                  _c("span", { staticClass: "small text-gray" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t("subText.maxImageCount", {
                          n: _vm.MAX_IMAGE_COUNT
                        })
                      )
                    )
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary ml-1 mb-2",
                    attrs: {
                      type: "button",
                      disabled: _vm.images.length >= _vm.MAX_IMAGE_COUNT
                    },
                    on: { click: _vm.addImageSlot }
                  },
                  [
                    _vm._v(
                      "\n      " +
                        _vm._s(_vm.$t("button.addImageSlot")) +
                        "\n    "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "b-form-row",
                  _vm._l(_vm.images, function(image, index) {
                    return _c(
                      "b-col",
                      { key: "image_" + index, attrs: { md: "4" } },
                      [
                        _c("image-input", {
                          attrs: { index: index, data: image },
                          on: {
                            "image-removal": _vm.removeImageSlot,
                            "image-input": _vm.addOrUpdateImage
                          }
                        })
                      ],
                      1
                    )
                  }),
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", { attrs: { for: "" } }, [
                  _vm._v(_vm._s(_vm.$t("campaign.description")))
                ]),
                _vm._v(" "),
                _c("ValidationProvider", {
                  attrs: {
                    rules: "",
                    name: _vm.$t("campaign.description"),
                    mode: "passive"
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(ref) {
                          var errors = ref.errors
                          return [
                            _c(
                              "div",
                              { class: { "border border-danger": errors[0] } },
                              [
                                _c("ckeditor", {
                                  attrs: {
                                    editor: _vm.editor,
                                    config: _vm.editorConfig,
                                    name: "campaign_description",
                                    "tag-name": "textarea"
                                  },
                                  model: {
                                    value: _vm.form.campaign_description,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "campaign_description",
                                        $$v
                                      )
                                    },
                                    expression: "form.campaign_description"
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    true
                  )
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c(
                  "b-form-row",
                  [
                    _c(
                      "b-col",
                      { attrs: { cols: "6" } },
                      [
                        _c(
                          "b-form-group",
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(_vm._s(_vm.$t("campaign.warningText")))
                            ]),
                            _vm._v(" "),
                            _c("ValidationProvider", {
                              attrs: {
                                mode: "passive",
                                name: _vm.$t("campaign.warningText")
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("b-form-textarea", {
                                          class: {
                                            "border border-danger": errors[0]
                                          },
                                          attrs: {
                                            id: "warning_text",
                                            maxlength: "256",
                                            rows: "3",
                                            "max-rows": "6"
                                          },
                                          model: {
                                            value: _vm.form.warning_text,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "warning_text",
                                                $$v
                                              )
                                            },
                                            expression: "form.warning_text"
                                          }
                                        })
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c("label", { attrs: { for: "" } }, [
                _vm._v(_vm._s(_vm.$t("campaign.criteria")))
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "px-5" }, [
                _c("div", { staticClass: "col-group" }, [
                  _c("div", { staticClass: "label" }, [
                    _vm._v(_vm._s(_vm.$t("campaignCriteria.age")))
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "content" },
                    [
                      _c("ValidationProvider", {
                        attrs: {
                          rules: "numeric",
                          name: "age_from",
                          mode: "passive"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var errors = ref.errors
                                return [
                                  _c("b-form-input", {
                                    class: {
                                      "border border-danger": errors[0],
                                      "max-w-200px": true
                                    },
                                    attrs: { type: "number", min: "0" },
                                    model: {
                                      value: _vm.form.criteria.age_from,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.form.criteria,
                                          "age_from",
                                          $$v
                                        )
                                      },
                                      expression: "form.criteria.age_from"
                                    }
                                  })
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "period_separator mx-3" }, [
                        _vm._v("~")
                      ]),
                      _vm._v(" "),
                      _c("ValidationProvider", {
                        attrs: {
                          rules: "numeric",
                          name: "age_to",
                          mode: "passive"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var errors = ref.errors
                                return [
                                  _c("b-form-input", {
                                    class: {
                                      "border border-danger": errors[0],
                                      "max-w-200px": true
                                    },
                                    attrs: { type: "number", min: "0" },
                                    model: {
                                      value: _vm.form.criteria.age_to,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.form.criteria,
                                          "age_to",
                                          $$v
                                        )
                                      },
                                      expression: "form.criteria.age_to"
                                    }
                                  })
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-group" }, [
                  _c("div", { staticClass: "label" }, [
                    _vm._v(_vm._s(_vm.$t("user.gender")))
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "content" },
                    [
                      _c(
                        "b-form-checkbox-group",
                        {
                          model: {
                            value: _vm.form.criteria.gender,
                            callback: function($$v) {
                              _vm.$set(_vm.form.criteria, "gender", $$v)
                            },
                            expression: "form.criteria.gender"
                          }
                        },
                        [
                          _c(
                            "b-form-checkbox",
                            { attrs: { value: _vm.GENDER_MALE } },
                            [_vm._v(_vm._s(_vm.$t("user.male")))]
                          ),
                          _vm._v(" "),
                          _c(
                            "b-form-checkbox",
                            { attrs: { value: _vm.GENDER_FEMALE } },
                            [_vm._v(_vm._s(_vm.$t("user.female")))]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-group" }, [
                  _c("div", { staticClass: "label" }, [
                    _vm._v(_vm._s(_vm.$t("campaignCriteria.instagramCount")))
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "content" },
                    [
                      _c("ValidationProvider", {
                        attrs: {
                          rules: "numeric",
                          name: "instagram_count",
                          mode: "passive"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var errors = ref.errors
                                return [
                                  _c("b-form-input", {
                                    class: {
                                      "border border-danger": errors[0],
                                      "max-w-200px": true
                                    },
                                    attrs: { type: "number", min: "0" },
                                    model: {
                                      value: _vm.form.criteria.instagram_count,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.form.criteria,
                                          "instagram_count",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "form.criteria.instagram_count"
                                    }
                                  })
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-group" }, [
                  _c("div", { staticClass: "label" }, [
                    _vm._v(_vm._s(_vm.$t("campaignCriteria.facebookCount")))
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "content" },
                    [
                      _c("ValidationProvider", {
                        attrs: {
                          rules: "numeric",
                          name: "facebook_count",
                          mode: "passive"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var errors = ref.errors
                                return [
                                  _c("b-form-input", {
                                    class: {
                                      "border border-danger": errors[0],
                                      "max-w-200px": true
                                    },
                                    attrs: { type: "number", min: "0" },
                                    model: {
                                      value: _vm.form.criteria.facebook_count,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.form.criteria,
                                          "facebook_count",
                                          $$v
                                        )
                                      },
                                      expression: "form.criteria.facebook_count"
                                    }
                                  })
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-group" }, [
                  _c("div", { staticClass: "label" }, [
                    _vm._v(_vm._s(_vm.$t("campaignCriteria.tiktokCount")))
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "content" },
                    [
                      _c("ValidationProvider", {
                        attrs: {
                          rules: "numeric",
                          name: "tiktok_count",
                          mode: "passive"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var errors = ref.errors
                                return [
                                  _c("b-form-input", {
                                    class: {
                                      "border border-danger": errors[0],
                                      "max-w-200px": true
                                    },
                                    attrs: { type: "number", min: "0" },
                                    model: {
                                      value: _vm.form.criteria.tiktok_count,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.form.criteria,
                                          "tiktok_count",
                                          $$v
                                        )
                                      },
                                      expression: "form.criteria.tiktok_count"
                                    }
                                  })
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-group" }, [
                  _c("div", { staticClass: "label" }, [
                    _vm._v(_vm._s(_vm.$t("user.maritalStatus")))
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "content" },
                    [
                      _c(
                        "b-form-checkbox-group",
                        {
                          model: {
                            value: _vm.form.criteria.marital_status,
                            callback: function($$v) {
                              _vm.$set(_vm.form.criteria, "marital_status", $$v)
                            },
                            expression: "form.criteria.marital_status"
                          }
                        },
                        [
                          _c("b-form-checkbox", { attrs: { value: "1" } }, [
                            _vm._v(_vm._s(_vm.$t("user.single")))
                          ]),
                          _vm._v(" "),
                          _c("b-form-checkbox", { attrs: { value: "2" } }, [
                            _vm._v(_vm._s(_vm.$t("user.married")))
                          ]),
                          _vm._v(" "),
                          _c("b-form-checkbox", { attrs: { value: "3" } }, [
                            _vm._v(_vm._s(_vm.$t("user.hasChild")))
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-group" }, [
                  _c("div", { staticClass: "label" }, [
                    _vm._v(_vm._s(_vm.$t("campaignCriteria.place")))
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "content" },
                    [
                      _c("multi-select", {
                        class: {
                          "border border-danger": errors[0],
                          "max-w-400px": true
                        },
                        attrs: {
                          options: _vm.cityOptions,
                          label: "name",
                          "track-by": "id",
                          selectedLabel: _vm.$t("multiselect.selectedLabel"),
                          selectLabel: _vm.$t("multiselect.selectLabel"),
                          placeholder: "",
                          deselectLabel: _vm.$t("multiselect.deselectLabel")
                        },
                        model: {
                          value: _vm.form.criteria.city_id,
                          callback: function($$v) {
                            _vm.$set(_vm.form.criteria, "city_id", $$v)
                          },
                          expression: "form.criteria.city_id"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-group" }, [
                  _c("div", { staticClass: "label" }, [
                    _vm._v(_vm._s(_vm.$t("campaignCriteria.contentType")))
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "content" },
                    [
                      _c(
                        "b-form-checkbox-group",
                        {
                          model: {
                            value: _vm.form.criteria.content_type,
                            callback: function($$v) {
                              _vm.$set(_vm.form.criteria, "content_type", $$v)
                            },
                            expression: "form.criteria.content_type"
                          }
                        },
                        [
                          _c("b-form-checkbox", { attrs: { value: "1" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t("campaignCriteria.contentTypeImage")
                              )
                            )
                          ]),
                          _vm._v(" "),
                          _c("b-form-checkbox", { attrs: { value: "2" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t("campaignCriteria.contentTypeVideo")
                              )
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-group" }, [
                  _c("div", { staticClass: "label" }, [
                    _vm._v(_vm._s(_vm.$t("campaignCriteria.reviewHistory")))
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "content" },
                    [
                      _c(
                        "b-form-radio-group",
                        {
                          model: {
                            value: _vm.form.criteria.review_history,
                            callback: function($$v) {
                              _vm.$set(_vm.form.criteria, "review_history", $$v)
                            },
                            expression: "form.criteria.review_history"
                          }
                        },
                        [
                          _c("b-form-radio", { attrs: { value: "0" } }, [
                            _vm._v(_vm._s(_vm.$t("campaignCriteria.noChoice")))
                          ]),
                          _vm._v(" "),
                          _c("b-form-radio", { attrs: { value: "1" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t("campaignCriteria.reviewHistoryPro")
                              )
                            )
                          ]),
                          _vm._v(" "),
                          _c("b-form-radio", { attrs: { value: "2" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t("campaignCriteria.reviewHistoryUsedTo")
                              )
                            )
                          ]),
                          _vm._v(" "),
                          _c("b-form-radio", { attrs: { value: "3" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t("campaignCriteria.reviewHistoryNotYet")
                              )
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ])
              ])
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", { attrs: { for: "" } }, [
                  _vm._v(_vm._s(_vm.$t("campaign.reviewRequirement")))
                ]),
                _vm._v(" "),
                _c("ckeditor", {
                  attrs: {
                    editor: _vm.editor,
                    config: _vm.editorConfig,
                    name: "review_requirement",
                    "tag-name": "textarea"
                  },
                  model: {
                    value: _vm.form.review_requirement,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "review_requirement", $$v)
                    },
                    expression: "form.review_requirement"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", { attrs: { for: "" } }, [
                  _vm._v(_vm._s(_vm.$t("campaign.requirement")))
                ]),
                _vm._v(" "),
                _c("ValidationProvider", {
                  attrs: {
                    rules: "",
                    name: _vm.$t("campaign.requirement"),
                    mode: "passive"
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(ref) {
                          var errors = ref.errors
                          return [
                            _c(
                              "div",
                              { class: { "border border-danger": errors[0] } },
                              [
                                _c("ckeditor", {
                                  attrs: {
                                    editor: _vm.editor,
                                    config: _vm.editorConfig,
                                    name: "campaign_requirement",
                                    "tag-name": "textarea"
                                  },
                                  model: {
                                    value: _vm.form.campaign_requirement,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "campaign_requirement",
                                        $$v
                                      )
                                    },
                                    expression: "form.campaign_requirement"
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    true
                  )
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", { attrs: { for: "" } }, [
                  _vm._v(_vm._s(_vm.$t("campaign.endCampaignText")))
                ]),
                _vm._v(" "),
                _c("ckeditor", {
                  attrs: {
                    editor: _vm.editor,
                    config: _vm.editorConfig,
                    name: "end_campaign_text",
                    "tag-name": "textarea"
                  },
                  model: {
                    value: _vm.form.end_campaign_text,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "end_campaign_text", $$v)
                    },
                    expression: "form.end_campaign_text"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c(
                  "b-form-row",
                  [
                    _c("b-col", { staticClass: "flex-200px" }, [
                      _c("label", { attrs: { for: "campaign-only-member" } }, [
                        _vm._v(_vm._s(_vm.$t("campaign.onlyForMember")))
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "b-col",
                      [
                        _c("b-form-checkbox", {
                          attrs: {
                            id: "campaign-only-member",
                            switch: "",
                            size: "lg"
                          },
                          model: {
                            value: _vm.form.member_only,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "member_only", $$v)
                            },
                            expression: "form.member_only"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c("div", { staticClass: "group-title" }, [
              _vm._v(_vm._s(_vm.$t("campaign.reward")))
            ]),
            _vm._v(" "),
            _c("rewards-thumbnail-block", { attrs: { isRequired: false } }),
            _vm._v(" "),
            _c("rewards-name-block", { attrs: { isRequired: false } }),
            _vm._v(" "),
            _c("rewards-description-block"),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-lg btn-success",
                  attrs: { type: "submit", disabled: _vm.processing }
                },
                [_vm._v("\n      " + _vm._s(_vm.submitText) + "\n    ")]
              )
            ])
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }