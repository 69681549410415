var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "auth-layout",
    [
      _c(
        "main-header",
        {
          attrs: {
            title: _vm.$t("page.userCampaign.list", { name: _vm.campaignName })
          }
        },
        [
          _c(
            "div",
            { staticClass: "d-flex" },
            [
              _c(
                "router-link",
                {
                  staticClass: "btn btn-outline-secondary",
                  attrs: { to: { name: "CampaignList" } }
                },
                [_vm._v(_vm._s(_vm.$t("page.campaign.list")))]
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "filter-bar justify-content-end" },
        [
          _c(
            "b-button",
            { attrs: { variant: "primary" }, on: { click: _vm.download } },
            [_vm._v(_vm._s(_vm.$t("button.exportExcel")))]
          ),
          _vm._v(" "),
          _c(
            "b-button",
            {
              staticClass: "ml-2",
              attrs: { variant: "primary" },
              on: { click: _vm.downloadReview }
            },
            [_vm._v(_vm._s(_vm.$t("button.exportReviewExcel")))]
          ),
          _vm._v(" "),
          _c(
            "router-link",
            {
              staticClass: "btn btn-success ml-2",
              attrs: {
                to: { name: "UserCampaignCreate", params: { id: _vm.id } }
              }
            },
            [_vm._v(_vm._s(_vm.$t("page.userCampaign.create")))]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "main-content has-filter-bar" },
        [
          _c("div", { staticClass: "table-wrapper" }, [
            _c(
              "table",
              [
                _c("thead", [
                  _c("tr", [
                    _c("th", [_vm._v(_vm._s(_vm.$t("user.id")))]),
                    _vm._v(" "),
                    _c("th", [_vm._v(_vm._s(_vm.$t("user.avatar")))]),
                    _vm._v(" "),
                    _c("th", [_vm._v(_vm._s(_vm.$t("user.fullname")))]),
                    _vm._v(" "),
                    _c("th", [_vm._v(_vm._s(_vm.$t("user.email")))]),
                    _vm._v(" "),
                    _c("th", [_vm._v(_vm._s(_vm.$t("user.tel")))]),
                    _vm._v(" "),
                    _c("th", [_vm._v(_vm._s(_vm.$t("user.memberID")))]),
                    _vm._v(" "),
                    _c("th", [
                      _vm._v(_vm._s(_vm.$t("userCampaign.createdAt")))
                    ]),
                    _vm._v(" "),
                    _c("th", [_vm._v(_vm._s(_vm.$t("userCampaign.approval")))]),
                    _vm._v(" "),
                    _c("th", [
                      _vm._v(_vm._s(_vm.$t("userCampaign.reviewStatus")))
                    ]),
                    _vm._v(" "),
                    _c("th", [
                      _vm._v(_vm._s(_vm.$t("userCampaign.reviewApproval")))
                    ]),
                    _vm._v(" "),
                    _c("th", [_vm._v("Action")])
                  ])
                ]),
                _vm._v(" "),
                _vm._l(_vm.users, function(item) {
                  return [
                    _c("tr", [
                      _c("td", [_vm._v(_vm._s(item.user ? item.user.id : ""))]),
                      _vm._v(" "),
                      _c("td", [
                        _c("div", { staticClass: "avatar-rounded" }, [
                          _c(
                            "a",
                            {
                              attrs: {
                                target: "_blank",
                                href: "/users/" + item.user.id + "/detail"
                              }
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: item.user
                                    ? item.user.avatar_url
                                    : "/images/avatar.png",
                                  alt: item.user ? item.user.fullname : ""
                                }
                              })
                            ]
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(_vm._s(item.user ? item.user.fullname : ""))
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(_vm._s(item.user ? item.user.email : ""))
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(_vm._s(item.user ? item.user.tel : ""))
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(_vm._s(item.user ? item.user.member_id : ""))
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(
                          _vm._s(_vm._f("datetimeFormat")(item.created_at))
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        [
                          item.approve == _vm.USER_CAMPAIGN_APPROVED
                            ? _c("b-icon-check", {
                                staticClass: "h1",
                                attrs: { variant: "success" }
                              })
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        [
                          item.review_status === 1
                            ? _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      name: "UserCampaignReview",
                                      params: {
                                        id: _vm.id,
                                        userId: item.user_id + ""
                                      }
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.$t(
                                          "userCampaign.reviewStatusReviewed"
                                        )
                                      ) +
                                      "\n              "
                                  )
                                ]
                              )
                            : [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.$t(
                                        "userCampaign.reviewStatusNotReviewedYet"
                                      )
                                    ) +
                                    "\n              "
                                )
                              ]
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        [
                          item.approve_review == 1
                            ? _c("b-icon-check", {
                                staticClass: "h1",
                                attrs: { variant: "success" }
                              })
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        [
                          item.approve != _vm.USER_CAMPAIGN_APPROVED
                            ? _c(
                                "b-button",
                                {
                                  attrs: { variant: "success" },
                                  on: {
                                    click: function($event) {
                                      return _vm.openApprovalModal(item.id)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$t("button.approve")) +
                                      "\n              "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          item.approve == _vm.USER_CANPAIGN_NOT_APPROVED_YET
                            ? _c(
                                "b-button",
                                {
                                  attrs: { variant: "danger" },
                                  on: {
                                    click: function($event) {
                                      return _vm.openCancelApprovalModal(
                                        item.id
                                      )
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$t("button.unapprove")) +
                                      "\n              "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          item.approve != _vm.USER_CAMPAIGN_APPROVED
                            ? _c(
                                "b-button",
                                {
                                  attrs: {
                                    variant: "outline-danger",
                                    outline: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.openDeleteModal(item.id)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$t("button.delete")) +
                                      "\n              "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          item.review_status === 1
                            ? _c(
                                "router-link",
                                {
                                  staticClass: "btn btn-outline-primary",
                                  attrs: {
                                    to: {
                                      name: "UserCampaignReview",
                                      params: {
                                        id: _vm.id,
                                        userId: item.user_id + ""
                                      }
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.$t(
                                          "user.user_campaigns.detail_review"
                                        )
                                      ) +
                                      "\n              "
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ])
                  ]
                }),
                _vm._v(" "),
                _vm.users.length == 0
                  ? [
                      _c("tr", [
                        _c(
                          "td",
                          {
                            staticClass: "text-center border-0",
                            attrs: { colspan: "8" }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t("message.notFound", {
                                  item: _vm.$t("item.userCampaign")
                                })
                              )
                            )
                          ]
                        )
                      ])
                    ]
                  : _vm._e()
              ],
              2
            )
          ]),
          _vm._v(" "),
          _c("pagination-bar", {
            attrs: {
              currentPage: _vm.pageObject.currentPage,
              lastPage: _vm.pageObject.lastPage,
              canNext: _vm.pageObject.canNext,
              canPrev: _vm.pageObject.canPrev
            },
            on: { "change-page": _vm.fetchData }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("confirm-modal", {
        attrs: { id: _vm.confirmModalId, confirmText: _vm.confirmText },
        on: {
          confirmed: _vm.removeUserFromCampaign,
          "modal-discard": _vm.cancelModal
        }
      }),
      _vm._v(" "),
      _c("confirm-modal", {
        attrs: {
          id: _vm.approveModalId,
          confirmText: _vm.$t("userCampaign.approveConfirm"),
          okButtonVariant: "primary"
        },
        on: {
          confirmed: _vm.approveUser,
          "modal-discard": _vm.discardApprovalModal
        }
      }),
      _vm._v(" "),
      _c("confirm-modal", {
        attrs: {
          id: _vm.cancelApproveModalId,
          confirmText: _vm.$t("userCampaign.unapproveConfirm")
        },
        on: {
          confirmed: _vm.cancelUser,
          "modal-discard": _vm.discardCancelModal
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }