<template>
  <b-form-group :invalid-feedback="invalidFeedback" :state="stateValid">
    <label>
      {{ $t("sliders.slide_order") }}
    </label>
    <b-form-input
      v-model="slideOrder"
      :state="stateValid"
      type="number"
      required
      trim
    ></b-form-input>
  </b-form-group>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {};
  },
  computed: {
    slidersData() {
      return this.$store.getters["sliders/slidersData"];
    },
    slideOrder: {
      get() {
        return this.slidersData && this.slidersData.slide_order !== ""
          ? Number(this.slidersData.slide_order)
          : "";
      },
      set(value) {
        this.changeAttributesData({
          key: "slide_order",
          value: value != "" ? Number(value) : ""
        });
      }
    },
    stateValid() {
      if (this.slideOrder < 0) return false;
      return null;
    },
    invalidFeedback() {
      if (this.slideOrder && this.slideOrder < 0) {
        return this.$t("sliders.valueMin").replace(":min", "0");
      }
      return (
        this.$t("subText.required") +
        " " +
        this.$t("sliders.slide_order").toLowerCase()
      );
    }
  },
  methods: {
    ...mapActions("sliders", ["changeAttributesData"])
  }
};
</script>

<style lang="scss" scoped></style>
