var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("news-title-block"),
      _vm._v(" "),
      _c("news-public-block"),
      _vm._v(" "),
      _c("news-slug-block"),
      _vm._v(" "),
      _c("news-image-primary-block"),
      _vm._v(" "),
      _c("news-image-secondary-block"),
      _vm._v(" "),
      _c("news-description-block"),
      _vm._v(" "),
      _c("news-content-block"),
      _vm._v(" "),
      _c("news-member-only-block"),
      _vm._v(" "),
      _c("news-order-block")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }