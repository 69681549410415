import VueRouter from "vue-router";
import Cookies from "js-cookie";
import VueJwtDecode from "vue-jwt-decode";
import moment from "moment";
import store from "../store";

import Login from "./../views/Login.vue";
import CampaignList from "./../views/Campaign/Index.vue";
import CreateCampaign from "./../views/Campaign/CreateCampaign.vue";
import EditCampaign from "./../views/Campaign/EditCampaign.vue";
import UserList from "./../views/User/Index.vue";
import UserCreate from "./../views/User/CreateUser.vue";
import UserEdit from "./../views/User/EditUser.vue";
import UserDetail from "./../views/User/DetailUser.vue";
import ChangePassword from "./../views/User/ChangePassword.vue";
import CategoryList from "./../views/Category/Index.vue";
import CategoryCreate from "./../views/Category/CreateCategory.vue";
import CategoryEdit from "./../views/Category/EditCategory.vue";
import RewardsNavigation from "./../views/Rewards/navigation.vue";
import RewardsAdd from "./../views/Rewards/add.vue";
import RewardsEdit from "./../views/Rewards/edit.vue";
import NotificationList from "./../views/Notification/Index.vue";
import NotificationCreate from "./../views/Notification/CreateNotification.vue";
import NewsNavigation from "./../views/News/navigation.vue";
import NewsAdd from "./../views/News/add.vue";
import NewsEdit from "./../views/News/edit.vue";
import UserRankingNavigation from "./../views/UserRanking/navigation.vue";
import UserRankingAdd from "./../views/UserRanking/add.vue";
import UserRankingEdit from "./../views/UserRanking/edit.vue";
import MerchantsNavigation from "./../views/Merchants/navigation.vue";

import SlidersNavigation from "./../views/Sliders/navigation.vue";
import SlidersAdd from "./../views/Sliders/add.vue";
import SlidersEdit from "./../views/Sliders/edit.vue";

import UserCampaignList from "./../views/UserCampaign/Index.vue";
import UserCampaignCreate from "./../views/UserCampaign/CreateCampaignUser.vue";
import UserCampaignReview from "./../views/UserCampaign/ReviewCampaignUser.vue";

import TemplateList from "./../views/Template/Index.vue";
import TemplateEdit from "./../views/Template/Edit.vue";

import SettingsList from "./../views/Settings/Index.vue";

import ExportCampaigns from "./../views/Export/CampaignReport.vue"
import ExportMembers from "./../views/Export/MemberReport.vue"

const ifNotAuthenticated = (to, from, next) => {
  if (Cookies.get("wf-token")) {
    next("/campaigns");
  } else {
    next();
  }
};

const ifAuthenticated = async (to, from, next) => {
  const token = Cookies.get("wf-token");
  if (token) {
    const user = VueJwtDecode.decode(token);
    const now = moment().unix();
    if (user && user.sub && now < user.exp) {
      store.dispatch("auth/setUser", Cookies.getJSON("wf-user"));
      next();
    } else {
      Cookies.remove("wf-token");
      next("/login");
    }
  } else {
    next("/login");
  }
};

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      title: "Login"
    },
    beforeEnter: ifNotAuthenticated
  },
  {
    path: "/campaigns",
    name: "CampaignList",
    component: CampaignList,
    meta: {
      title: "Campaign"
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: "/campaigns/create",
    name: "CampaignCreate",
    component: CreateCampaign,
    meta: {
      title: "Campaign"
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: "/campaigns/:id/edit",
    name: "CampaignEdit",
    component: EditCampaign,
    meta: {
      title: "Campaign"
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: "/campaigns/:id/users",
    name: "UserCampaignList",
    component: UserCampaignList,
    meta: {
      title: "User Campaign"
    },
    props: true,
    beforeEnter: ifAuthenticated
  },
  {
    path: "/campaigns/:id/users/create",
    name: "UserCampaignCreate",
    component: UserCampaignCreate,
    meta: {
      title: "User Campaign"
    },
    props: true,
    beforeEnter: ifAuthenticated
  },
  {
    path: "/campaigns/:id/users/:userId/review",
    name: "UserCampaignReview",
    component: UserCampaignReview,
    meta: {
      title: "User Campaign Review"
    },
    props: true,
    beforeEnter: ifAuthenticated
  },
  {
    path: "/users",
    name: "UserList",
    component: UserList,
    meta: {
      title: "User"
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: "/users/create",
    name: "UserCreate",
    component: UserCreate,
    meta: {
      title: "User"
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: "/users/:id/edit",
    name: "UserEdit",
    component: UserEdit,
    meta: {
      title: "User"
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: "/users/:id/detail",
    name: "UserDetail",
    component: UserDetail,
    meta: {
      title: "User"
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: "/categories",
    name: "CategoryList",
    component: CategoryList,
    meta: {
      title: "Category"
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: "/categories/create",
    name: "CategoryCreate",
    component: CategoryCreate,
    meta: {
      title: "Category"
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: "/categories/:id/edit",
    name: "CategoryEdit",
    component: CategoryEdit,
    meta: {
      title: "Category"
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: "/rewards",
    name: "RewardsNavigation",
    component: RewardsNavigation,
    meta: {
      title: "Rewards"
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: "/notifications",
    name: "NotificationList",
    component: NotificationList,
    meta: {
      title: "Notification"
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: "/rewards/add",
    name: "RewardsAdd",
    component: RewardsAdd,
    meta: {
      title: "Rewards"
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: "/rewards/:id/edit",
    name: "RewardsEdit",
    component: RewardsEdit,
    meta: {
      title: "Rewards"
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: "/notifications/create",
    name: "NotificationCreate",
    component: NotificationCreate,
    meta: {
      title: "Notification"
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: "/news",
    name: "NewsNavigation",
    component: NewsNavigation,
    meta: {
      title: "News"
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: "/news/add",
    name: "NewsAdd",
    component: NewsAdd,
    meta: {
      title: "News"
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: "/news/:id/edit",
    name: "NewsEdit",
    component: NewsEdit,
    meta: {
      title: "News"
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: "/user-ranking",
    name: "UserRankingNavigation",
    component: UserRankingNavigation,
    meta: {
      title: "UserRanking"
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: "/user-ranking/add",
    name: "UserRankingAdd",
    component: UserRankingAdd,
    meta: {
      title: "UserRanking"
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: "/user-ranking/:id/edit",
    name: "UserRankingEdit",
    component: UserRankingEdit,
    meta: {
      title: "UserRanking"
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: "/merchants",
    name: "MerchantsNavigation",
    component: MerchantsNavigation,
    meta: {
      title: "Merchants"
    },
    beforeEnter: ifAuthenticated
  },

  {
    path: "/sliders",
    name: "SlidersNavigation",
    component: SlidersNavigation,
    meta: {
      title: "Sliders"
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: "/sliders/add",
    name: "SlidersAdd",
    component: SlidersAdd,
    meta: {
      title: "Sliders"
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: "/sliders/:id/edit",
    name: "SlidersEdit",
    component: SlidersEdit,
    meta: {
      title: "Sliders"
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: "/settings",
    name: "SettingsList",
    component: SettingsList,
    meta: {
      title: "Settings"
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: "/templates",
    name: "TemplateList",
    component: TemplateList,
    meta: {
      title: "Templates"
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: "/templates/:id",
    name: "TemplateEdit",
    component: TemplateEdit,
    meta: {
      title: "Templates"
    },
    props: true,
    beforeEnter: ifAuthenticated
  },
  {
    path: "/change-password",
    name: "ChangePassword",
    component: ChangePassword,
    meta: {
      title: "Change Password"
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: "/exports/campaigns",
    name: "ExportCampaigns",
    component: ExportCampaigns,
    meta: {
      title: "Export - Campaign reports"
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: "/exports/members",
    name: "ExportMembers",
    component: ExportMembers,
    meta: {
      title: "Export - Member reports"
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: "*",
    redirect: "/campaigns"
  }
];

const router = new VueRouter({
  mode: "history",
  routes: routes
});
export default router;
