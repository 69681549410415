var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ValidationObserver", {
    ref: "observer",
    attrs: { tag: "form", mode: "passive" },
    on: {
      submit: function($event) {
        $event.preventDefault()
        return _vm.submit.apply(null, arguments)
      }
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var invalid = ref.invalid
          var errors = ref.errors
          return [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: invalid || _vm.errMessage,
                    expression: "invalid || errMessage"
                  }
                ],
                ref: "errorDiv",
                staticClass: "text-danger d-flex justify-content-center mt-1"
              },
              [
                _c(
                  "ul",
                  [
                    _vm._l(errors, function(error) {
                      return [
                        error[0]
                          ? _c("li", [_vm._v(_vm._s(error[0]))])
                          : _vm._e()
                      ]
                    }),
                    _vm._v(" "),
                    _vm.errMessage
                      ? _c("li", [_vm._v(_vm._s(_vm.errMessage))])
                      : _vm._e()
                  ],
                  2
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "b-form-group",
              [
                _c("label", { attrs: { for: "" } }, [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("notification.title")) +
                      "\n      "
                  ),
                  _c("span", { staticClass: "text-danger small" }, [
                    _vm._v(_vm._s(_vm.$t("subText.required")))
                  ])
                ]),
                _vm._v(" "),
                _c("ValidationProvider", {
                  attrs: {
                    mode: "passive",
                    name: _vm.$t("notification.title"),
                    rules: "required|max:256"
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(ref) {
                          var errors = ref.errors
                          return [
                            _c("b-form-input", {
                              class: { "border border-danger": errors[0] },
                              attrs: { maxlength: "256" },
                              model: {
                                value: _vm.form.title,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "title", $$v)
                                },
                                expression: "form.title"
                              }
                            })
                          ]
                        }
                      }
                    ],
                    null,
                    true
                  )
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-form-group",
              [
                _c("label", { attrs: { for: "" } }, [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("notification.content")) +
                      "\n      "
                  ),
                  _c("span", { staticClass: "text-danger small" }, [
                    _vm._v(_vm._s(_vm.$t("subText.required")))
                  ])
                ]),
                _vm._v(" "),
                _c("ValidationProvider", {
                  attrs: {
                    mode: "passive",
                    name: _vm.$t("notification.content"),
                    rules: "required|max:256"
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(ref) {
                          var errors = ref.errors
                          return [
                            _c("b-form-textarea", {
                              class: { "border border-danger": errors[0] },
                              attrs: { maxlength: "256" },
                              model: {
                                value: _vm.form.content,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "content", $$v)
                                },
                                expression: "form.content"
                              }
                            })
                          ]
                        }
                      }
                    ],
                    null,
                    true
                  )
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-form-row",
              [
                _c(
                  "b-col",
                  { attrs: { md: "4" } },
                  [
                    _c(
                      "b-form-group",
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("notification.type")) +
                              "\n          "
                          ),
                          _c("span", { staticClass: "text-danger small" }, [
                            _vm._v(_vm._s(_vm.$t("subText.required")))
                          ])
                        ]),
                        _vm._v(" "),
                        _c("ValidationProvider", {
                          attrs: {
                            mode: "passive",
                            name: _vm.$t("notification.type"),
                            rules: "required"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("multi-select", {
                                      class: {
                                        "border border-danger": errors[0]
                                      },
                                      attrs: {
                                        options: _vm.typeOptions,
                                        searchable: false,
                                        label: "display_name",
                                        "track-by": "id",
                                        selectedLabel: _vm.$t(
                                          "multiselect.selectedLabel"
                                        ),
                                        selectLabel: _vm.$t(
                                          "multiselect.selectLabel"
                                        ),
                                        placeholder: "",
                                        deselectLabel: _vm.$t(
                                          "multiselect.deselectLabel"
                                        )
                                      },
                                      model: {
                                        value: _vm.form.type,
                                        callback: function($$v) {
                                          _vm.$set(_vm.form, "type", $$v)
                                        },
                                        expression: "form.type"
                                      }
                                    })
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-col",
                  [
                    _vm.form.type && _vm.form.type.id == _vm.TYPE_SELECTED_USER
                      ? [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("notification.users")) +
                                "\n          "
                            ),
                            _c("span", { staticClass: "text-danger small" }, [
                              _vm._v(_vm._s(_vm.$t("subText.required")))
                            ])
                          ]),
                          _vm._v(" "),
                          _c("ValidationProvider", {
                            attrs: {
                              mode: "passive",
                              name: _vm.$t("notification.users"),
                              rules: "required"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var errors = ref.errors
                                    return [
                                      _c(
                                        "multi-select",
                                        {
                                          class: {
                                            "border border-danger": errors[0]
                                          },
                                          attrs: {
                                            id: "ajax",
                                            options: _vm.userOptions,
                                            multiple: "",
                                            label: "fullname",
                                            "track-by": "id",
                                            selectedLabel: _vm.$t(
                                              "multiselect.selectedLabel"
                                            ),
                                            selectLabel: _vm.$t(
                                              "multiselect.selectLabel"
                                            ),
                                            placeholder: "",
                                            deselectLabel: _vm.$t(
                                              "multiselect.deselectLabel"
                                            ),
                                            "clear-on-select": false,
                                            "close-on-select": false,
                                            "internal-search": false,
                                            "show-no-results": false,
                                            "hide-selected": true,
                                            limit: 3,
                                            "options-limit": 300,
                                            "open-direction": "bottom",
                                            loading: _vm.isLoading
                                          },
                                          on: {
                                            "search-change": _vm.asyncFind
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "clear",
                                                fn: function(props) {
                                                  return [
                                                    _vm.form.users.length
                                                      ? _c("div", {
                                                          staticClass:
                                                            "multiselect__clear",
                                                          on: {
                                                            mousedown: function(
                                                              $event
                                                            ) {
                                                              $event.preventDefault()
                                                              $event.stopPropagation()
                                                              return _vm.clearAll(
                                                                props.search
                                                              )
                                                            }
                                                          }
                                                        })
                                                      : _vm._e()
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          ),
                                          model: {
                                            value: _vm.form.users,
                                            callback: function($$v) {
                                              _vm.$set(_vm.form, "users", $$v)
                                            },
                                            expression: "form.users"
                                          }
                                        },
                                        [
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            {
                                              attrs: { slot: "noResult" },
                                              slot: "noResult"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("message.notFound", {
                                                    item: _vm.$t("item.user")
                                                  })
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          })
                        ]
                      : _vm._e()
                  ],
                  2
                )
              ],
              1
            ),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c(
              "b-form-group",
              [
                _c(
                  "b-button",
                  { attrs: { type: "submit", size: "lg", variant: "success" } },
                  [_vm._v(_vm._s(_vm.btnText))]
                )
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }