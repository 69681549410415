<template>
  <auth-layout>
    <main-header :title="$t('page.user.list')"></main-header>

    <div class="filter-bar justify-content-between">
      <b-input-group class="w-auto">
        <b-input
          type="search"
          class="search-input"
          v-model="keyword"
          @keyup.enter="search"
          :placeholder="$t('user.searchable')"
        ></b-input>
        <b-input-group-append>
          <b-button @click="search" class="search-button"><b-icon-search></b-icon-search></b-button>
        </b-input-group-append>
      </b-input-group>
      <div class="d-flex justify-content-end">
        <b-button class="mr-3" variant="primary" @click="download">{{ $t('button.exportExcel') }}</b-button>

        <router-link :to="{ name: 'UserCreate' }" class="btn btn-success new-item-link">{{
          $t("page.user.create")
        }}</router-link>
      </div>
    </div>

    <div class="main-content has-filter-bar">
      <div class="table-wrapper">
        <table>
          <thead>
            <tr>
              <th>{{ $t("user.id") }}</th>
              <th>{{ $t("user.avatar") }}</th>
              <th>{{ $t("user.fullname") }}</th>
              <th>{{ $t("user.email") }}</th>
              <th>{{ $t("user.memberID") }}</th>
              <th>{{ $t("user.rank") }}</th>
              <th>{{ $t("user.createdAt") }}</th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(item, index) in users">
              <tr :key="item.id">
                <td>{{ item.id }}</td>
                <td>
                  <div class="avatar-rounded">
                    <a :href="'/users/' + item.id + '/detail'">
                      <img :src="item.avatar_url" :alt="item.fullname" />
                    </a>
                  </div>
                </td>
                <td>{{ item.fullname }}</td>
                <td>{{ item.email }}</td>
                <td>{{ item.member_id }}</td>
                <td>{{ item.rank_name }}</td>
                <td>{{ item.created_at | datetimeFormat }}</td>
                <td>
                  <router-link
                    :to="{
                      name: 'UserEdit',
                      params: { id: item.id }
                    }"
                  >
                    <b-icon-pencil
                      variant="secondary"
                      class="action-icon"
                    ></b-icon-pencil>
                  </router-link>
                </td>
                <td>
                  <template v-if="item.status == USER_STATUS_ACTIVE">
                    <b-icon-unlock
                      variant="success"
                      class="action-icon"
                      @click="openDisableUserModal(item.id, item.fullname, index)"
                    ></b-icon-unlock>
                  </template>
                  <template v-if="item.status == USER_STATUS_STOPPED">
                    <b-icon-lock
                      variant="danger"
                      class="action-icon"
                      @click="openReactiveUserModal(item.id, item.fullname, index)"
                    ></b-icon-lock>
                  </template>
                </td>
                <td>
                  <b-icon-trash
                    variant="danger"
                    class="action-icon"
                    @click="openDeleteUserModal(item.id, item.fullname)"
                  ></b-icon-trash>
                </td>
                <td>
                  <router-link
                    :to="{
                      name: 'UserDetail',
                      params: { id: item.id }
                    }"
                  >
                    <b-icon-info-circle
                      class="action-icon"
                      variant="info"
                    ></b-icon-info-circle>
                  </router-link>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <pagination-bar
        :currentPage="pageObject.currentPage"
        :lastPage="pageObject.lastPage"
        :canNext="pageObject.canNext"
        :canPrev="pageObject.canPrev"
        @change-page="fetchData"
      ></pagination-bar>
    </div>
    <confirm-modal
      :id="confirmModalId"
      :confirmText="confirmText"
      @confirmed="disableUser"
      @modal-discard="cancelModal"
    ></confirm-modal>
    <confirm-modal
      :id="reactiveModalId"
      okButtonVariant="success"
      :confirmText="reactiveConfirmText"
      @confirmed="reactiveUser"
      @modal-discard="cancelReactiveModal"
    ></confirm-modal>
    <confirm-modal
      :id="deleteModalId"
      :confirmText="deleteConfirmText"
      @confirmed="deleteUser"
      @modal-discard="cancelDeleteModal"
    ></confirm-modal>
  </auth-layout>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { USER_STATUS_ACTIVE, USER_STATUS_STOPPED } from "../../constants/user";

export default {
  data: () => ({
    USER_STATUS_ACTIVE: USER_STATUS_ACTIVE,
    USER_STATUS_STOPPED: USER_STATUS_STOPPED,
    confirmModalId: "user-disable-confirm",
    reactiveModalId: "user-reactive-confirm",
    deleteModalId: "user-delete-confirm",
    disableTarget: {
      id: null,
      fullname: "",
      index: null
    },
    reactiveTarget: {
      id: null,
      fullname: "",
      index: null
    },
    deleteTarget: {
      id: null,
      fullname: "",
    },
    keyword: '',
    oldKeyword: '',
  }),

  computed: {
    ...mapState("user", ["users", "pageObject"]),
    confirmText() {
      return this.$t("user.disableConfirm", {
        fullname: this.disableTarget.fullname
      });
    },
    reactiveConfirmText() {
      return this.$t("user.reactiveConfirm", {
        fullname: this.reactiveTarget.fullname
      });
    },
    deleteConfirmText() {
      return this.$t("user.deleteConfirm", {
        fullname: this.deleteTarget.fullname
      });
    }
  },

  methods: {
    ...mapActions("user", ["fetchUsers", "switchStatus", "destroy", "downloadExcel"]),
    fetchData(page) {
      this.fetchUsers({ page });
    },
    search() {
      const { keyword } = this;
      this.oldKeyword = this.keyword;
      this.fetchUsers({ keyword });
    },
    openDisableUserModal(id, fullname, index) {
      this.disableTarget.id = id;
      this.disableTarget.fullname = fullname;
      this.disableTarget.index = index;
      this.$bvModal.show(this.confirmModalId);
    },
    cancelModal() {
      this.disableTarget.id = null;
      this.disableTarget.fullname = "";
      this.disableTarget.index = "";
    },
    changeStatus(id, index) {
      this.switchStatus({ id, index })
        .then(data => {
          this.$bvToast.toast(data.message || this.$t("message.commonSuccess"), {
            title: this.$t("message.successTitle"),
            variant: "success",
            autoHideDelay: 3000,
            solid: true
          });
        })
        .catch(error => {
          this.$bvToast.toast(error.message || this.$t("message.commonError"), {
            title: this.$t("message.errorTitle"),
            variant: "danger",
            autoHideDelay: 3000,
            solid: true
          });
        });
    },
    disableUser() {
      this.changeStatus(this.disableTarget.id, this.disableTarget.index);
    },
    openReactiveUserModal(id, fullname, index) {
      this.reactiveTarget.id = id;
      this.reactiveTarget.fullname = fullname;
      this.reactiveTarget.index = index;
      this.$bvModal.show(this.reactiveModalId);
    },
    cancelReactiveModal() {
      this.reactiveTarget.id = null;
      this.reactiveTarget.fullname = "";
      this.reactiveTarget.index = "";
    },
    reactiveUser() {
      this.changeStatus(this.reactiveTarget.id, this.reactiveTarget.index);
    },
    openDeleteUserModal(id, fullname, index) {
      this.deleteTarget.id = id;
      this.deleteTarget.fullname = fullname;
      this.$bvModal.show(this.deleteModalId);
    },
    cancelDeleteModal() {
      this.deleteTarget.id = null;
      this.deleteTarget.fullname = "";
    },
    deleteUser() {
      this.destroy({ id: this.deleteTarget.id })
        .then(() => {
          this.fetchData(1)
        })
        .catch((error) => {
          this.$bvToast.toast(error.message || this.$t("message.commonError"), {
            title: this.$t("message.errorTitle"),
            variant: "danger",
            autoHideDelay: 3000,
            solid: true
          });
        })
    },
    download() {
      const { oldKeyword } = this;
      const page = this.pageObject.currentPage;

      this.downloadExcel({ page, keyword: oldKeyword });
    }
  },

  async mounted() {
    await this.fetchData(1);
  }
};
</script>
