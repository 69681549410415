<template>
  <div>
    <rewards-thumbnail-block></rewards-thumbnail-block>
    <rewards-name-block></rewards-name-block>
    <rewards-note-block></rewards-note-block>
    <rewards-description-block></rewards-description-block>
    <!-- <rewards-campaign-block></rewards-campaign-block> -->
    <rewards-user-block></rewards-user-block>
    <rewards-expire-date-block></rewards-expire-date-block>
  </div>
</template>
<script>
import RewardsNameBlock from './RewardsNameBlock';
import RewardsNoteBlock from './RewardsNoteBlock';
import RewardsThumbnailBlock from './RewardsThumbnailBlock';
import RewardsDescriptionBlock from './RewardsDescriptionBlock';
// import RewardsCampaignBlock from './RewardsCampaignBlock';
import RewardsUserBlock from './RewardsUserBlock';
import RewardsExpireDateBlock from './RewardsExpireDateBlock';

export default {
  components: {
    RewardsNameBlock,
    RewardsNoteBlock,
    RewardsThumbnailBlock,
    RewardsDescriptionBlock,
    // RewardsCampaignBlock,
    RewardsUserBlock,
    RewardsExpireDateBlock
  }
};
</script>
<style lang="scss">
</style>
