var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-form-group",
    {
      attrs: { "invalid-feedback": _vm.invalidFeedback, state: _vm.stateValid }
    },
    [
      _c("label", [
        _vm._v("\n    " + _vm._s(_vm.$t("news.content")) + "\n    "),
        _c("span", { staticClass: "text-danger ml-1 small" }, [
          _vm._v(_vm._s(_vm.$t("subText.required")))
        ])
      ]),
      _vm._v(" "),
      _c("ckeditor", {
        attrs: {
          editor: _vm.editor,
          config: _vm.editorConfig,
          name: "newsContent",
          "tag-name": "textarea"
        },
        model: {
          value: _vm.newsContent,
          callback: function($$v) {
            _vm.newsContent = $$v
          },
          expression: "newsContent"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }