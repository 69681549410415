<template>
  <auth-layout>
    <main-header :title="$t('page.news.create')">
      <router-link
        :to="{ name: 'NewsNavigation' }"
        class="btn btn-outline-secondary"
        >{{ $t("page.news.list") }}</router-link
      >
    </main-header>

    <div class="card">
      <div class="card-body">
        <news-data-blocks></news-data-blocks>
        <news-save-button-add @leave="leave" @successLeave="successLeave"></news-save-button-add>
      </div>
    </div>

    <b-modal
      centered
      :id="routeLeaveModalId"
      :title="$t('message.warning')"
      :ok-title="$t('button.ok')"
      :cancel-title="$t('button.cancel')"
      @ok="leave(targetRouteName)"
    >
      {{ $t('message.routeLeave') }}
    </b-modal>
  </auth-layout>
</template>

<script>
import { mapActions } from 'vuex';
import NewsDataBlocks from './../../components/News/data/blocks/NewsDataBlocks'
import NewsSaveButtonAdd from './../../components/News/data/add/NewsSaveButtonAdd'
import routeLeaveWarning from '../../mixins/routeLeaveWarning';

export default {
  mixins: [routeLeaveWarning],

  components: {
    NewsDataBlocks,
    NewsSaveButtonAdd
  },
  data: () => ({}),
  methods: {
    ...mapActions('news', ['fetchById', 'store']),
    successLeave(id) {
      this.canLeave = true;
      this.$router.push({ name: "NewsEdit", params: { id: id } }, () =>{
        this.$root.$bvToast.toast(this.$t("news.createSuccess"), {
          title: this.$t("message.successTitle"),
          variant: "success",
          autoHideDelay: 3000,
          solid: true
        });
      });
    },
  },
  mounted() {
    this.fetchById({ id: 0 });
  }
};
</script>

<style lang="scss" scoped></style>
