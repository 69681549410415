var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("header", [_c("Header")], 1),
    _vm._v(" "),
    _c("main", { staticClass: "d-flex" }, [
      _c("div", { staticClass: "sidebar-ctn" }, [_c("Sidebar")], 1),
      _vm._v(" "),
      _c("div", { staticClass: "main-ctn" }, [_vm._t("default")], 2)
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }