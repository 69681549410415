var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ValidationObserver", {
    ref: "observer",
    attrs: { tag: "form", mode: "passive" },
    on: {
      submit: function($event) {
        $event.preventDefault()
        return _vm.submit.apply(null, arguments)
      }
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var invalid = ref.invalid
          var errors = ref.errors
          return [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: invalid || _vm.errMessage,
                    expression: "invalid || errMessage"
                  }
                ],
                ref: "errorDiv",
                staticClass: "text-danger d-flex justify-content-center mt-1"
              },
              [
                _c(
                  "ul",
                  [
                    _vm._l(errors, function(error) {
                      return [
                        error[0]
                          ? _c("li", [_vm._v(_vm._s(error[0]))])
                          : _vm._e()
                      ]
                    }),
                    _vm._v(" "),
                    _vm.errMessage
                      ? _c("li", [_vm._v(_vm._s(_vm.errMessage))])
                      : _vm._e()
                  ],
                  2
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "b-form-row",
              {
                staticClass:
                  "mb-2 p-2 justify-content-center align-items-center"
              },
              [
                _c("b-col", { attrs: { md: "3" } }, [_vm._v("Thành viên")]),
                _vm._v(" "),
                _c(
                  "b-col",
                  [
                    _c(
                      "multi-select",
                      {
                        class: { "border border-danger": errors[0] },
                        attrs: {
                          options: _vm.userOptions,
                          label: "fullname",
                          "track-by": "id",
                          selectedLabel: _vm.$t("multiselect.selectedLabel"),
                          selectLabel: _vm.$t("multiselect.selectLabel"),
                          placeholder: "",
                          deselectLabel: _vm.$t("multiselect.deselectLabel"),
                          "internal-search": false,
                          "show-no-results": true,
                          limit: 3,
                          "options-limit": 300,
                          "open-direction": "bottom",
                          loading: _vm.isLoading
                        },
                        on: { "search-change": _vm.asyncFind },
                        model: {
                          value: _vm.form.user,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "user", $$v)
                          },
                          expression: "form.user"
                        }
                      },
                      [
                        _c(
                          "span",
                          { attrs: { slot: "noResult" }, slot: "noResult" },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t("message.notFound", {
                                  item: _vm.$t("item.user")
                                })
                              )
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          { attrs: { slot: "noOptions" }, slot: "noOptions" },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t("message.notFound", {
                                  item: _vm.$t("item.user")
                                })
                              )
                            )
                          ]
                        )
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _vm.form.user
              ? [
                  _c("hr"),
                  _vm._v(" "),
                  _c(
                    "b-form-row",
                    {
                      staticClass:
                        "mb-2 p-2 justify-content-center align-items-center"
                    },
                    [
                      _c("b-col", { staticClass: "text-center h2" }, [
                        _vm._v(_vm._s(_vm.$t("user.info")))
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-form-row",
                    {
                      staticClass:
                        "mb-2 info-row justify-content-center align-items-center"
                    },
                    [
                      _c("b-col", { attrs: { md: "2", offset: "2" } }, [
                        _c("strong", [_vm._v(_vm._s(_vm.$t("user.fullname")))])
                      ]),
                      _vm._v(" "),
                      _c("b-col", [
                        _vm._v(_vm._s(_vm.form.user.fullname || ""))
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-form-row",
                    {
                      staticClass:
                        "mb-2 info-row justify-content-center align-items-center"
                    },
                    [
                      _c("b-col", { attrs: { md: "2", offset: "2" } }, [
                        _c("strong", [_vm._v(_vm._s(_vm.$t("user.email")))])
                      ]),
                      _vm._v(" "),
                      _c("b-col", [_vm._v(_vm._s(_vm.form.user.email || ""))])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-form-row",
                    {
                      staticClass:
                        "mb-2 info-row justify-content-center align-items-center"
                    },
                    [
                      _c("b-col", { attrs: { md: "2", offset: "2" } }, [
                        _c("strong", [_vm._v(_vm._s(_vm.$t("user.tel")))])
                      ]),
                      _vm._v(" "),
                      _c("b-col", [_vm._v(_vm._s(_vm.form.user.tel || ""))])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-form-row",
                    {
                      staticClass:
                        "mb-2 info-row justify-content-center align-items-center"
                    },
                    [
                      _c("b-col", { attrs: { md: "2", offset: "2" } }, [
                        _c("strong", [_vm._v(_vm._s(_vm.$t("user.memberID")))])
                      ]),
                      _vm._v(" "),
                      _c("b-col", [
                        _vm._v(_vm._s(_vm.form.user.member_id || ""))
                      ])
                    ],
                    1
                  )
                ]
              : _vm._e(),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c(
              "b-form-group",
              [
                _c(
                  "b-button",
                  {
                    attrs: {
                      type: "submit",
                      size: "lg",
                      variant: "success",
                      disabled: !_vm.form.user
                    }
                  },
                  [_vm._v(_vm._s(_vm.btnText))]
                )
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }