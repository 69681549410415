var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-form-group",
    [
      _c("label", [
        _vm._v("\n    " + _vm._s(_vm.$t("news.imageSecondary")) + "\n    "),
        _c("span", { staticClass: "small text-gray" }, [
          _vm._v(
            "\n      " +
              _vm._s(
                _vm.$t("subText.maxImageCount", { n: _vm.MAX_IMAGE_COUNT })
              ) +
              "\n    "
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-primary ml-1 mb-2",
          attrs: { type: "button", disabled: _vm.disabled },
          on: { click: _vm.addImageSlot }
        },
        [_vm._v("\n    " + _vm._s(_vm.$t("button.addImageSlot")) + "\n  ")]
      ),
      _vm._v(" "),
      _c(
        "b-form-row",
        _vm._l(_vm.secondaryImage, function(image, index) {
          return _c(
            "b-col",
            { key: "image_" + index, attrs: { md: "4" } },
            [
              _c("image-input", {
                attrs: { index: index, data: image },
                on: {
                  "image-removal": _vm.removeImageSlot,
                  "image-input": _vm.addOrUpdateImage
                }
              })
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }