var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("label", { staticClass: "col-form-label-lg" }, [
      _vm._v(_vm._s(_vm.$t("user.friend.list")))
    ]),
    _vm._v(" "),
    _c("table", { staticClass: "table" }, [
      _c("thead", [
        _c("tr", [
          _c("th", { attrs: { scope: "col" } }, [
            _vm._v(_vm._s(_vm.$t("user.friend.no")))
          ]),
          _vm._v(" "),
          _c("th", { attrs: { scope: "col" } }, [
            _vm._v(_vm._s(_vm.$t("user.friend.member_id")))
          ]),
          _vm._v(" "),
          _c("th", { attrs: { scope: "col" } }, [
            _vm._v(_vm._s(_vm.$t("user.friend.fullname")))
          ]),
          _vm._v(" "),
          _c("th", { attrs: { scope: "col" } }, [
            _vm._v(_vm._s(_vm.$t("user.friend.email")))
          ]),
          _vm._v(" "),
          _c("th", { attrs: { scope: "col" } }, [
            _vm._v(_vm._s(_vm.$t("user.friend.status")))
          ]),
          _vm._v(" "),
          _c("th", { attrs: { scope: "col" } }, [
            _vm._v(_vm._s(_vm.$t("user.friend.created_at")))
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "tbody",
        [
          _vm.friendReferralHistories.length > 0
            ? _vm._l(_vm.friendReferralHistories, function(
                friendReferralHistory,
                index
              ) {
                return _c("tr", { key: index }, [
                  _c("td", [_vm._v(_vm._s(index + 1))]),
                  _vm._v(" "),
                  _c("td", [
                    _c(
                      "a",
                      {
                        attrs: { href: "javascript:;" },
                        on: {
                          click: function($event) {
                            return _vm.redirectDetail(friendReferralHistory.id)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(friendReferralHistory.member_id) +
                            "\n            "
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(friendReferralHistory.fullname))]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(friendReferralHistory.email))]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(friendReferralHistory.status))]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      _vm._s(
                        _vm.formatDatetime(friendReferralHistory.created_at)
                      )
                    )
                  ])
                ])
              })
            : [
                _c("tr", { staticClass: "text-center" }, [
                  _c("td", { attrs: { colspan: "6" } }, [
                    _vm._v(_vm._s(_vm.$t("user.friend.not_match_friend")))
                  ])
                ])
              ]
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }