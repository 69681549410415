<template>
  <div>
    <div class="main-content has-filter-bar">
      <div class="table-wrapper">
        <table>
          <thead>
            <tr>
              <th>{{ $t("rewards.id") }}</th>
              <th>{{ $t("rewards.name") }}</th>
              <th>{{ $t("rewards.note") }}</th>
              <th>{{ $t("rewards.thumbnail") }}</th>
              <th>{{ $t("rewards.userId") }}</th>
              <!-- <th>{{ $t("rewards.campaignId") }}</th> -->
              <th></th>
            </tr>
          </thead>
          <template v-for="item in userRewards">
            <tr :key="item.id">
              <td>{{ item.id }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.note }}</td>
              <td>
                <img class="contain" :src="item.thumbnail" width="90" />
              </td>
              <td>{{ item.fullname }}</td>
              <!-- <td>{{ item.campaign_name }}</td> -->
              <td>
                <router-link
                  :to="{
                    name: 'RewardsEdit',
                    params: { id: item.id }
                  }"
                >
                  <b-icon-pencil
                    variant="secondary"
                    class="action-icon"
                  ></b-icon-pencil>
                </router-link>

                <b-icon-trash
                  variant="danger"
                  class="action-icon"
                  @click="openDeleteModal(item.id)"
                ></b-icon-trash>
              </td>
            </tr>
          </template>
        </table>
      </div>
      <pagination-bar
        :currentPage="currentPage"
        :lastPage="lastPage"
        :canNext="canNext"
        :canPrev="canPrev"
        @change-page="fetchData"
      ></pagination-bar>
    </div>
    <confirm-modal
      :id="confirmModalId"
      :confirmText="confirmText"
      @confirmed="deleteRewards"
      @modal-discard="cancelModal"
    ></confirm-modal>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  components: {},
  data() {
    return {
      confirmModalId: "rewards-delete-modal",
      deleteId: null
    };
  },
  computed: {
    confirmText() {
      return this.$t("rewards.deleteConfirm");
    },
    userRewards() {
      return this.$store.getters["rewards/userRewards"];
    },
    pageObject() {
      return this.$store.getters["rewards/pageObject"];
    },
    currentPage() {
      return this.pageObject.currentPage;
    },
    lastPage() {
      return this.pageObject.lastPage;
    },
    canNext() {
      return this.pageObject.canNext;
    },
    canPrev() {
      return this.pageObject.canPrev;
    }
  },
  methods: {
    ...mapActions("rewards", ["fetchList", "destroy"]),
    fetchData(page) {
      this.fetchList({ page });
    },
    openDeleteModal(id) {
      this.deleteId = id;
      this.$bvModal.show(this.confirmModalId);
    },
    cancelModal() {
      this.deleteId = null;
    },
    deleteRewards() {
      this.destroy({ id: this.deleteId })
        .then(() => {
          this.$bvToast.toast(this.$t("message.commonSuccess"), {
            title: this.$t("message.successTitle"),
            variant: "success",
            autoHideDelay: 3000,
            solid: true
          });
          this.fetchData(this.pageObject.currentPage);
        })
        .catch(data => {
          this.$bvToast.toast(data.message || this.$t("message.commonError"), {
            title: this.$t("message.errorTitle"),
            variant: "danger",
            autoHideDelay: 3000,
            solid: true
          });
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.w__35--percent {
  width: 35%;
}
</style>
