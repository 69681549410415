export const USER_STATUS_ACTIVE = 1;
export const USER_STATUS_STOPPED = 2;
export const USER_SKIN_NO_CHOICE = 0;
export const USER_SKIN_DRY = 1;
export const USER_SKIN_OIL = 2;
export const USER_SKIN_NORMAL = 3;
export const USER_SKIN_MIXED = 4;
export const GENDER_MALE = 1;
export const GENDER_FEMALE = 2;
export const GENDER_OTHER = 3;
export const USER_SINGLE = 1;
export const USER_MARRIED = 2;
export const PET_DOG = 1;
export const PET_CAT = 2;
export const PET_OTHER = 3;
export const TYPE_RELATE_USER = [3, 4];
export const TYPE_RELATE_CAMPAIGN = [1, 2, 5, 6, 7, 8];
