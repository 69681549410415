var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("rewards-thumbnail-block"),
      _vm._v(" "),
      _c("rewards-name-block"),
      _vm._v(" "),
      _c("rewards-note-block"),
      _vm._v(" "),
      _c("rewards-description-block"),
      _vm._v(" "),
      _c("rewards-user-block"),
      _vm._v(" "),
      _c("rewards-expire-date-block")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }