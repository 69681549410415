<template>
  <div>
    <b-form-group
      :label="$t('rewards.note')"
      :invalid-feedback="invalidFeedback"
      :state="stateValid"
    >
      <b-form-input
        v-model="rewardsNote"
        :state="stateValid"
        required
        trim
      ></b-form-input>
    </b-form-group>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {};
  },
  computed: {
    rewardsData() {
      return this.$store.getters["rewards/rewardsData"];
    },
    rewardsNote: {
      get() {
        return this.rewardsData ? this.rewardsData.note : "";
      },
      set(value) {
        this.changeRewardAttributesData({
          key: "note",
          value: value
        });
      }
    },
    stateValid() {
      if (!this.rewardsData || this.rewardsData.note === null) return null;
      if (this.rewardsData.note && this.rewardsData.note.length > 100)
        return false;
      return true;
    },
    invalidFeedback() {
      if (this.rewardsNote && this.rewardsNote.length > 100) {
        return this.$t("rewards.maxLength").replace(":length", "100");
      }
    }
  },
  methods: {
    ...mapActions("rewards", ["changeRewardAttributesData"])
  }
};
</script>
