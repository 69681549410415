<template>
  <auth-layout>
    <main-header :title="$t('page.campaign.create')">
      <router-link
        :to="{ name: 'CampaignList' }"
        class="btn btn-outline-secondary"
        >{{ $t("page.campaign.list") }}</router-link
      >
    </main-header>

    <div class="card">
      <div class="card-body">
        <campaign-form
          :errMessage="errMessage"
          :submitText="$t('button.add')"
          :warningText="warningText"
          @setErrorMessage="setErrorMessage"
          @submit="submit"
        ></campaign-form>
      </div>
    </div>

    <b-modal
      centered
      :id="routeLeaveModalId"
      :title="$t('message.warning')"
      :ok-title="$t('button.ok')"
      :cancel-title="$t('button.cancel')"
      @ok="leave(targetRouteName)"
    >
      {{ $t('message.routeLeave') }}
    </b-modal>
  </auth-layout>
</template>

<script>
import { mapActions } from 'vuex';
import CampaignForm from "../../components/Form/CampaignForm.vue"
import routeLeaveWarning from '../../mixins/routeLeaveWarning';

export default {
  mixins: [routeLeaveWarning],

  components: {
    CampaignForm
  },

  data: () => ({
    errMessage: "",
    warningText: "",
  }),

  methods: {
    ...mapActions('campaign', ['store', 'getDefaultWarningText']),
    ...mapActions('rewards', ['fetchById']),
    setErrorMessage(message) {
      this.errMessage = message;
    },

    submit(formData) {
      this.store(formData)
        .then((data) => {
          let id = parseInt(data.id);
          this.canLeave = true;
          this.$router.push({ name: "CampaignEdit", params: { id: id } }, () =>{
            this.$root.$bvToast.toast(this.$t("campaign.createSuccess"), {
              title: this.$t("message.successTitle"),
              variant: "success",
              autoHideDelay: 3000,
              solid: true
            });
          });
        })
        .catch((error) => this.errMessage = error.message);
    }
  },

  mounted() {
    this.getDefaultWarningText()
      .then((data) => this.warningText = data);
  },

  created() {
    this.fetchById({ id: 0 });
  }
};
</script>

<style lang="scss" scoped></style>
