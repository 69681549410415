<template>
  <div class="form-group">
    <button
      type="button"
      class="btn btn-lg btn-success"
      :disabled="!canSave"
      @click="onSave()"
    >
      {{ $t('button.update') }}
    </button>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      canSave: false
    };
  },
  computed: {
    newsData() {
      return this.$store.getters["news/newsData"];
    },
    removalImageIds() {
      return this.$store.getters["news/removalImageIds"];
    },
    primaryImage() {
      return this.newsData ? this.newsData.primary_image : null;
    },
    secondaryImage() {
      return this.newsData ? this.newsData.secondary_image : [];
    }
  },
  watch: {
    newsData: {
      handler: function(val, oldVal) {
        this.canSave = this.isValid();
      },
      deep: true
    },
  },
  methods: {
    ...mapActions("news", ["update"]),
    isValid() {
      return (
        (this.primaryImage !== null) &&
        this.newsData.title &&
        this.newsData.content
      );
    },
    onSave() {
      const formData = new FormData();
      if (this.newsData.primary_image && this.newsData.primary_image.content) {
        formData.append('primary_image', this.newsData.primary_image.content);
      }
      formData.append('display_main_image', this.newsData.display_main_image);
      formData.append('title', this.newsData.title);
      if (this.newsData.description) {
        formData.append('description', this.newsData.description);
      } else {
        formData.append('description', "");
      }
      formData.append('content', this.newsData.content);
      formData.append('public', this.newsData.public);
      formData.append('slug', this.newsData.slug);
      if (this.newsData.news_order) {
        formData.append('news_order', this.newsData.news_order);
      } else {
        formData.append('news_order', "");
      }
      formData.append('member_only', this.newsData.member_only ? this.newsData.member_only : 0);
      if (this.removalImageIds) {
        formData.append(`removal_image_ids`, JSON.stringify(this.removalImageIds));
      }

      if (this.newsData.secondary_image) {
        this.secondaryImage.forEach((item, index) => {
          if (item.content) {
            formData.append(`secondary_image[${index}]`, item.content);
          }
        })
      }

      formData.id = this.$route.params.id;

      this.update(formData)
        .then(() => {
          this.$emit("scrollToTop");
          this.$bvToast.toast(this.$t("news.updateSuccess"), {
            title: this.$t("message.successTitle"),
            variant: "success",
            autoHideDelay: 3000,
            solid: true
          });
        })
        .catch((error) => {
          this.$bvToast.toast(error.message || this.$t("message.commonError"), {
            title: this.$t("message.errorTitle"),
            variant: "danger",
            autoHideDelay: 3000,
            solid: true
          });
        });
    }
  }
};
</script>
