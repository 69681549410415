<template>
  <auth-layout>
    <main-header :title="$t('page.sliders.create')">
      <router-link
        :to="{ name: 'SlidersNavigation' }"
        class="btn btn-outline-secondary"
        >{{ $t("page.sliders.list") }}</router-link
      >
    </main-header>

    <div class="card">
      <div class="card-body">
        <sliders-data-blocks></sliders-data-blocks>
        <sliders-save-button-add @leave="leave"></sliders-save-button-add>
      </div>
    </div>

    <b-modal
      centered
      :id="routeLeaveModalId"
      :title="$t('message.warning')"
      :ok-title="$t('button.ok')"
      :cancel-title="$t('button.cancel')"
      @ok="leave(targetRouteName)"
    >
      {{ $t('message.routeLeave') }}
    </b-modal>
  </auth-layout>
</template>

<script>
import { mapActions } from 'vuex';
import SlidersDataBlocks from './../../components/Sliders/data/blocks/SlidersDataBlocks'
import SlidersSaveButtonAdd from './../../components/Sliders/data/add/SlidersSaveButtonAdd'
import routeLeaveWarning from '../../mixins/routeLeaveWarning';

export default {
  mixins: [routeLeaveWarning],

  components: {
    SlidersDataBlocks,
    SlidersSaveButtonAdd
  },
  data: () => ({}),
  methods: {
    ...mapActions('sliders', ['fetchById', 'store'])
  },
  mounted() {
    this.fetchById({ id: 0 });
  }
};
</script>

<style lang="scss" scoped></style>
