<template>
  <div>
    <b-form-group :state="stateValid">
      <label>
        {{ $t("rewards.description") }}
      </label>
      <ckeditor :editor="editor" v-model="rewardsDescription" :config="editorConfig"></ckeditor>
    </b-form-group>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { TINE_MCE_API_KEY } from "./../../../../constants/common";
import MyUploadAdapter from "./../../../../uploadAdapter";

export default {
  data() {
    return {
      TINE_MCE_API_KEY,
      editor: ClassicEditor,
    };
  },
  computed: {
    editorConfig() {
      return {
        fontSize: {
            options: [
                9,
                11,
                13,
                'default',
                17,
                19,
                21
            ]
        },
        toolbar: [
          'heading',
          '|',
          'bold',
          'italic',
          'link',
          'bulletedList',
          'numberedList',
          'underline',
          '|',
          'outdent',
          'indent',
          '|',
          'fontBackgroundColor',
          'fontColor',
          'fontSize',
          'highlight',
          '|',
          'imageInsert',
          'blockQuote',
          'insertTable',
          'undo',
          'redo',
          'sourceEditing'
        ],
        extraPlugins: [this.uploader],
      }
    },
    rewardsData() {
      return this.$store.getters["rewards/rewardsData"];
    },
    rewardsDescription: {
      get() {
        return this.rewardsData && this.rewardsData.description ? this.rewardsData.description : "";
      },
      set(value) {
        this.changeRewardAttributesData({
          key: "description",
          value: value
        });
      }
    },
    stateValid() {
      if (!this.rewardsData)
        return null;
      return true;
    },
  },
  methods: {
    ...mapActions("rewards", ["changeRewardAttributesData"]),
    uploader(editor) {
      editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
        return new MyUploadAdapter( loader, 'rewards' );
      };
    },
  },
  beforeDestroy() {
    this.rewardsDescription = null;
  },
};
</script>
