import axios from "../../axios";

export default {
  namespaced: true,
  state: {
    categories: [],
  },
  mutations: {
    setCategories(state, categories) {
      if (Array.isArray(categories)) {
        state.categories.length = 0;
        state.categories = [...categories];
      }
    },
    removeCategory(state, id) {
      const index = state.categories.findIndex(el => el.id == id);
      if (index != -1) {
        state.categories.splice(index, 1);
      }
    }
  },
  actions: {
    fetchCategories({commit}) {
      return new Promise((resolve, reject) => {
        axios.get('/api/categories')
          .then(({data}) => {
            commit('setCategories', data.data);
            resolve();
          })
          .catch(() => reject())
      });
    },

    store({}, payload) {
      return new Promise((resolve, reject) => {
        axios.post('/api/categories', payload)
          .then(() => resolve())
          .catch(({response}) => reject(response.data))
      });
    },

    fetchCategory({}, payload) {
      return new Promise((resolve, reject) => {
        const { id } = payload;
        axios.get(`/api/categories/${id}`)
          .then(({data}) => resolve(data.data))
          .catch(() => reject())
      });
    },

    update({}, payload) {
      return new Promise((resolve, reject) => {
        const { id } = payload;
        axios.post(`/api/categories/${id}`, payload)
          .then(() => resolve())
          .catch(({response}) => reject(response.data))
      });
    },

    destroy({commit}, payload) {
      return new Promise((resolve, reject) => {
        const { id } = payload;
        axios.delete(`/api/categories/${id}`)
          .then(() => {
            commit('removeCategory', id);
            resolve();
          })
          .catch(({response}) => reject(response.data))
      });
    }
  },
};
