import axios from "../../axios";

const state = {
  listUserRanking: [],
  pageObject: {},
  userRankingData: {},
};

const getters = {
  listUserRanking: state => state.listUserRanking,
  userRankingData: state => state.userRankingData,
  pageObject: state => state.pageObject,
};

const actions = {
  fetchList({ commit }, payload) {
    return new Promise((resolve, reject) => {
      let { page } = payload;
      if (!page) {
        page = 1;
      }
      let url = `/api/user-ranking?page=${page}`;
      axios.get(url).then(({ data }) => {
        const listUserRanking = data.data.list_user_ranking;
        const currentPage = data.data.current_page;
        const lastPage = data.data.last_page;
        commit("setListUserRanking", listUserRanking);
        commit("setPagination", {
          currentPage,
          lastPage,
          canNext: currentPage < lastPage,
          canPrev: currentPage > 1
        });
      });
    });
  },
  destroy({}, payload) {
    return new Promise((resolve, reject) => {
      const { id } = payload;
      axios
        .delete(`/api/user-ranking/${id}`)
        .then(() => {
          resolve();
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },
  changeAttributesData({ commit }, payload) {
    commit("setAttribute", payload);
  },
  fetchById({ commit }, payload) {
    return new Promise((resolve, reject) => {
      const { id } = payload;
      axios
        .get(`/api/user-ranking/${id}`)
        .then(({ data }) => {
          const userRanking = data.data;
          commit("setUserRankingData", userRanking);
          resolve(userRanking);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },
  store({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/user-ranking", payload)
        .then(() => resolve())
        .catch(({ response }) => reject(response.data));
    });
  },
  update({ commit }, payload) {
    return new Promise((resolve, reject) => {
      const { id } = payload;
      axios
        .post(`/api/user-ranking/${id}`, payload)
        .then(() => resolve())
        .catch(({ response }) => reject(response.data));
    });
  }
};

const mutations = {
  setListUserRanking(state, data) {
    state.listUserRanking = data;
  },
  setPagination(state, data) {
    state.pageObject = data;
  },
  setAttribute(state, payload) {
    const { key, value } = payload;
    state.userRankingData[key] = value;
  },
  setUserRankingData(state, data) {
    state.userRankingData = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
