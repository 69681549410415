<template>
  <div>
    <header>
      <Header />
    </header>
    <main class="d-flex">
      <div class="sidebar-ctn">
        <Sidebar />
      </div>
      <div class="main-ctn">
          <slot />
      </div>
    </main>
  </div>
</template>
<script>
import Header from "../components/common/Header.vue";
import Sidebar from "../components/common/Sidebar.vue";

export default {
  name: "AuthenticatedLayout",

  components: {
    Header,
    Sidebar
  },
};
</script>
