<template>
  <div class="main-header">
    <h2 class="title">{{ title }}</h2>
    <slot />
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    }
  }
}
</script>
<style lang="scss" scoped>
.main-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 42px;
  h2 {
    font-size: 1.5rem;
  }
}
</style>
