var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-content" },
    [
      _c("div", { staticClass: "table-wrapper" }, [
        _c(
          "table",
          [
            _c("thead", [
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("sliders.id")))]),
                _vm._v(" "),
                _c("th", { staticClass: "w__35--percent" }, [
                  _vm._v(_vm._s(_vm.$t("sliders.name")))
                ]),
                _vm._v(" "),
                _c("th", { staticClass: "text-center" }, [
                  _vm._v(_vm._s(_vm.$t("sliders.image")))
                ]),
                _vm._v(" "),
                _c("th", { staticClass: "text-center" }, [
                  _vm._v(_vm._s(_vm.$t("sliders.url")))
                ]),
                _vm._v(" "),
                _c("th", { staticClass: "text-center" }, [
                  _vm._v(_vm._s(_vm.$t("sliders.date_from")))
                ]),
                _vm._v(" "),
                _c("th", { staticClass: "text-center" }, [
                  _vm._v(_vm._s(_vm.$t("sliders.date_to")))
                ]),
                _vm._v(" "),
                _c("th", { staticClass: "text-center" }, [
                  _vm._v(_vm._s(_vm.$t("sliders.slide_order")))
                ]),
                _vm._v(" "),
                _c("th")
              ])
            ]),
            _vm._v(" "),
            _vm._l(_vm.listSliders, function(item) {
              return [
                _c("tr", { key: item.id }, [
                  _c("td", [_vm._v(_vm._s(item.id))]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(item.name))]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-center" }, [
                    _c("img", {
                      staticClass: "contain",
                      attrs: { src: item.image, width: "90" }
                    })
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-center" }, [
                    _c("a", { attrs: { target: "_blank", href: item.url } }, [
                      _vm._v(_vm._s(item.url))
                    ])
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(_vm.formatDatetime(item.date_from)))
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(_vm.formatDatetime(item.date_to)))
                  ]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(item.slide_order))]),
                  _vm._v(" "),
                  _c(
                    "td",
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "SlidersEdit",
                              params: { id: item.id }
                            }
                          }
                        },
                        [
                          _c("b-icon-pencil", {
                            staticClass: "action-icon",
                            attrs: { variant: "secondary" }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("b-icon-trash", {
                        staticClass: "action-icon",
                        attrs: { variant: "danger" },
                        on: {
                          click: function($event) {
                            return _vm.openDeleteModal(item.id)
                          }
                        }
                      })
                    ],
                    1
                  )
                ])
              ]
            })
          ],
          2
        )
      ]),
      _vm._v(" "),
      _c("pagination-bar", {
        attrs: {
          currentPage: _vm.currentPage,
          lastPage: _vm.lastPage,
          canNext: _vm.canNext,
          canPrev: _vm.canPrev
        },
        on: { "change-page": _vm.fetchData }
      }),
      _vm._v(" "),
      _c("confirm-modal", {
        attrs: { id: _vm.confirmModalId, confirmText: _vm.confirmText },
        on: { confirmed: _vm.deleteSliders, "modal-discard": _vm.cancelModal }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }