import axios from "./axios";

export default class UploadAdapter {
    constructor (loader, folder) {
        this.loader = loader;
        this.uploadPath = '/api/ck-editor/upload';
        this.folder = folder;
    }

    // Starts the upload process.
    upload() {
        return this.loader.file
            .then( file => new Promise( ( resolve, reject ) => {
                const loader = this.loader;
                const data = new FormData;
                data.append('upload', file);
                data.append('folder', this.folder);
                axios
                  .post(this.uploadPath, data, {
                    onUploadProgress(evt) {
                      if (evt.lengthComputable) {
                        loader.uploadTotal = evt.total;
                        loader.uploaded = evt.loaded;
                      }
                    }
                  })
                  .then(({data}) => resolve(data))
                  .catch(({response}) => reject(response.data.message || ''))
            } ) );
    }

    // Aborts the upload process.
    abort() {
    }
}
