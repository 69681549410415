var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "auth-layout",
    [
      _c(
        "main-header",
        { attrs: { title: _vm.$t("page.notification.list") } },
        [
          _c(
            "router-link",
            {
              staticClass: "btn btn-success",
              attrs: { to: { name: "NotificationCreate" } }
            },
            [_vm._v(_vm._s(_vm.$t("page.notification.create")))]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "main-content" },
        [
          _c("div", { staticClass: "table-wrapper" }, [
            _c("table", [
              _c("thead", [
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("notification.id")))]),
                  _vm._v(" "),
                  _c("th", [_vm._v(_vm._s(_vm.$t("notification.title")))]),
                  _vm._v(" "),
                  _c("th", [_vm._v(_vm._s(_vm.$t("notification.content")))]),
                  _vm._v(" "),
                  _c("th", [_vm._v(_vm._s(_vm.$t("notification.type")))]),
                  _vm._v(" "),
                  _c("th", [_vm._v(_vm._s(_vm.$t("notification.createdAt")))])
                ])
              ]),
              _vm._v(" "),
              _c(
                "tbody",
                [
                  _vm._l(_vm.notifications, function(item) {
                    return [
                      _c("tr", [
                        _c("td", [_vm._v(_vm._s(item.id))]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(item.title))]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(item.content))]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(_vm._s(_vm.transNotificationType(item.type)))
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            _vm._s(_vm._f("datetimeFormat")(item.created_at))
                          )
                        ])
                      ])
                    ]
                  })
                ],
                2
              )
            ])
          ]),
          _vm._v(" "),
          _c("pagination-bar", {
            attrs: {
              currentPage: _vm.pageObject.currentPage,
              lastPage: _vm.pageObject.lastPage,
              canNext: _vm.pageObject.canNext,
              canPrev: _vm.pageObject.canPrev
            },
            on: { "change-page": _vm.fetchData }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }