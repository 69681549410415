var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-form-group",
        {
          attrs: {
            "invalid-feedback": _vm.invalidFeedback,
            state: _vm.stateValid
          }
        },
        [
          _c("label", [
            _vm._v("\n      " + _vm._s(_vm.$t("rewards.name")) + "\n      "),
            _vm.isRequired
              ? _c("span", { staticClass: "text-danger ml-1 small" }, [
                  _vm._v(_vm._s(_vm.$t("subText.required")))
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("b-form-input", {
            attrs: {
              state: _vm.stateValid,
              required: _vm.isRequired,
              trim: ""
            },
            model: {
              value: _vm.rewardsName,
              callback: function($$v) {
                _vm.rewardsName = $$v
              },
              expression: "rewardsName"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }