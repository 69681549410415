<template>
  <div>
    <b-form-group>
      <label>
        {{ $t("sliders.image") }}
        <span class="text-danger ml-1 small">{{ $t("subText.required") }}</span>
      </label>
      <div>
        <input
          style="display: none;"
          type="file"
          class="hide"
          ref="fileInput"
          @change="onChange"
          accept="image/*"
        />
        <b-img
          thumbnail
          fluid
          :src="imageURL"
          :alt="slidersName"
          @click="$refs.fileInput.click()"
          class="thumbnail"
        ></b-img>
      </div>
    </b-form-group>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import ImageInput from "./../../../Input/ImageInput.vue";

export default {
  components: {
    ImageInput
  },
  data() {
    return {
      imageURL: "/images/img_plHolder.png"
    };
  },
  computed: {
    slidersData() {
      return this.$store.getters["sliders/slidersData"];
    },
    slidersName() {
      return this.slidersData ? this.slidersData.name : "";
    },
    slidersImage() {
      return this.slidersData ? this.slidersData.image : null;
    }
  },
  watch: {
    slidersImage: {
      handler: function(val, oldVal) {
        if (val && typeof val === 'string') {
          this.imageURL = val;
        }
      },
      deep: true
    }
  },
  methods: {
    ...mapActions("sliders", ["changeAttributesData"]),
    onChange(event) {
      const { files } = event.target;
      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        this.imageURL = fileReader.result;
      });
      fileReader.readAsDataURL(files[0]);
      this.changeAttributesData({
        key: "image",
        value: files[0]
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.thumbnail {
  max-width: 250px;
  max-height: 250px;
  cursor: pointer;
}
</style>
