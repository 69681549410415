<template>
  <b-form-group :invalid-feedback="invalidFeedback" :state="stateValid">
    <label>
      {{ $t("news.description") }}
    </label>
    <b-form-textarea
      v-model="newsDescription"
      :state="stateValid"
      rows="3"
    ></b-form-textarea>
  </b-form-group>
</template>

<script>
import { mapActions } from "vuex";
import { TINE_MCE_API_KEY } from "../../../../constants/common";

export default {
  data() {
    return {
      TINE_MCE_API_KEY
    };
  },
  computed: {
    newsData() {
      return this.$store.getters["news/newsData"];
    },
    newsDescription: {
      get() {
        return (this.newsData && this.newsData.description)
          ? this.newsData.description
          : "";
      },
      set(value) {
        this.changeAttributesData({
          key: "description",
          value: value
        });
      }
    },
    stateValid() {
      if (!this.newsData || this.newsData.description === null) return null;
      if (this.newsData.description && this.newsData.description.length > 500) return false;
      return true;
    },
    invalidFeedback() {
      if (this.newsData.description && this.newsData.description.length > 500) {
        return this.$t("news.maxLength").replace(":length", "500");
      }
    }
  },
  methods: {
    ...mapActions("news", ["changeAttributesData"])
  }
};
</script>
