var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "auth-layout",
    [
      _c(
        "main-header",
        { attrs: { title: _vm.$t("page.news.list") } },
        [
          _c(
            "router-link",
            {
              staticClass: "btn btn-success",
              attrs: { to: { name: "NewsAdd" } }
            },
            [_vm._v(_vm._s(_vm.$t("page.news.create")))]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "filter-bar" },
        [
          _c(
            "b-col",
            { staticClass: "pl-0", attrs: { md: "3" } },
            [
              _c("b-input", {
                staticClass: "form-control",
                attrs: { placeholder: _vm.$t("news.title") },
                model: {
                  value: _vm.keyword,
                  callback: function($$v) {
                    _vm.keyword = $$v
                  },
                  expression: "keyword"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-button",
            {
              attrs: { variant: "primary" },
              on: {
                click: function($event) {
                  return _vm.fetchData(1)
                }
              }
            },
            [_c("b-icon-search")],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("news-navigation")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }