var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-form-group",
    [
      _c("label", [
        _vm._v("\n    " + _vm._s(_vm.$t("sliders.description")) + "\n  ")
      ]),
      _vm._v(" "),
      _c("b-form-textarea", {
        attrs: { rows: "3" },
        model: {
          value: _vm.slidersDescription,
          callback: function($$v) {
            _vm.slidersDescription = $$v
          },
          expression: "slidersDescription"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }